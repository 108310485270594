import React from "react";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { InputField } from "./InputField";
import { Typography } from "../Typography";

interface RangeInputFieldProps {
  error?: string;
  onChange?: (values?: (number | undefined)[]) => void;
  values?: (number | undefined)[];
  label?: string;
}

export const RangeInputField: React.FC<RangeInputFieldProps> = ({
  error,
  onChange,
  values,
  label,
}) => {
  return (
    <FormFieldWrapper
      label={label}
      error={error}
      gap={8}
      textVariant="body"
      textStyle={{ fontSize: 16 }}
    >
      <div style={{ display: "flex", flexDirection: "row", width: 350 }}>
        <div style={{ flex: 1 }}>
          <InputField
            value={values?.[0]}
            onChange={(val) => {
              onChange?.([val as number, values?.[1]]);
            }}
            variant="number"
            placeholder="Nuo"
          />
        </div>
        <Typography
          textStyle={{
            marginHorizontal: 15,
            alignSelf: "center",
            marginTop: -5,
          }}
        >
          -
        </Typography>
        <div style={{ flex: 1 }}>
          <InputField
            value={values?.[1]}
            onChange={(val) => {
              onChange?.([values?.[0], val as number]);
            }}
            variant="number"
            placeholder="Iki"
          />
        </div>
      </div>
    </FormFieldWrapper>
  );
};
