import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteComparableMessage } from "../../api/api";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import { useParams } from "react-router-dom";
import { ComparableMessage } from "../../types/api.types";
import { useMessagesStatusInvalidate } from "./hooks";

interface ComparableMessageDeleteModalProps {
  isOpen: boolean;
  messageId: string;
  onClose?: () => void;
}

export const ComparableMessageDeleteModal: React.FC<
  ComparableMessageDeleteModalProps
> = ({ isOpen, onClose, messageId }) => {
  const queryClient = useQueryClient();
  const { comparableId } = useParams();
  const { invalidate } = useMessagesStatusInvalidate();

  const { mutate, isLoading } = useMutation(deleteComparableMessage, {
    onSuccess: () => {
      queryClient.setQueryData<ComparableMessage[]>(
        ["comparable_messages", comparableId ?? ""],
        (data) => {
          return data?.filter((message) => message._id !== messageId) ?? [];
        }
      );
      invalidate();
      onClose?.();
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{"Ar tikrai?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Ar tikrai norite ištrinti šį pranešimą?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Atšaukti</Button>
        <MUILoadingButton loading={isLoading} onClick={() => mutate(messageId)}>
          Ištrinti
        </MUILoadingButton>
      </DialogActions>
    </Dialog>
  );
};
