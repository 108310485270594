import { Comparable } from "common/types/common.types";
import React, { useState } from "react";

interface MapListInteractionsProviderProps {
  children?: React.ReactNode;
}

export interface MapListInteractionsContextProps {
  onListComparableClick?: (comp: Comparable) => void;
  setOnListComparableClickHandler?: (
    handler: (comp: Comparable) => void
  ) => void;
}

export const MapListInteractionsContext =
  React.createContext<MapListInteractionsContextProps>({});

export const MapListInteractionsProvider: React.FC<
  MapListInteractionsProviderProps
> = ({ children }) => {
  const [onListComparableClick, setOnListComparableClick] =
    useState<(comp: Comparable) => void>();

  const setOnListComparableClickHandler = (
    handler: (comp: Comparable) => void
  ) => {
    setOnListComparableClick(() => handler);
  };

  return (
    <MapListInteractionsContext.Provider
      value={{
        onListComparableClick,
        setOnListComparableClickHandler,
      }}
    >
      {children}
    </MapListInteractionsContext.Provider>
  );
};
