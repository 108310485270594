import React from "react";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { Comparable, ComparableFull } from "common/types/common.types";
import { getRoundedPercent } from "common/helpers/common.helpers";
import _ from "lodash";
import { ComparableNumbersEditModal } from "./ComparableNumbersEditModal";

interface ComparableWeightEditModalProps {
  open: boolean;
  onClose?: () => void;
  propertyId: string;
  comps?: ComparableFull[];
}

export const ComparableWeightEditModal: React.FC<
  ComparableWeightEditModalProps
> = ({ propertyId, comps, open, onClose }) => {
  const { updateWeights } = usePricePredictions(propertyId);

  const getDefaultWeights = () => {
    if (!comps) {
      return [];
    }
    return comps.map((comp) =>
      getRoundedPercent(comp.comparable_weight ?? 1 / comps.length)
    );
  };

  return (
    <ComparableNumbersEditModal
      open={open}
      onClose={onClose}
      comps={comps}
      updateValues={updateWeights}
      getDefaultValues={getDefaultWeights}
      headerText="Koreguoti svertinį svorio koeficientą"
      units="%"
    />
  );
};
