import React from "react";
import { MUILoadingButton } from "../components/buttons/MUILoadingButton";
import { Button, Dialog } from "@mui/material";
import { DialogHeader } from "../components/dialogs/DialogHeader";
import { PropertyLeadShareSettings } from "./property/PropertyLeadShareSettings";
import { useProperty } from "../hooks/property.hooks";

interface PredictionsConfirmButtonProps {
  disabled?: boolean;
  loading?: boolean;
  propertyId: string;
  onConfirm?: () => void;
  onClick?: () => Promise<boolean>;
}

export const PredictionsConfirmButton: React.FC<
  PredictionsConfirmButtonProps
> = ({ propertyId, disabled, onConfirm, loading, onClick }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const { data: property } = useProperty(propertyId);

  if (!property?.leadContact) {
    return null;
  }

  return (
    <div>
      <MUILoadingButton
        disabled={disabled}
        loading={loading}
        onClick={async () => {
          if (await onClick?.()) {
            setIsDialogOpen(true);
          }
        }}
        style={{ width: "300px" }}
      >
        Patvirtinti ataskaitą
      </MUILoadingButton>
      <Dialog
        scroll="body"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogHeader
          title="Bendrinti"
          onClose={() => setIsDialogOpen(false)}
        />

        <PropertyLeadShareSettings
          property={property}
          onSuccess={() => {
            setIsDialogOpen(false);
            onConfirm?.();
          }}
        />
      </Dialog>
    </div>
  );
};
