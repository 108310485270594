import { Box } from "@mui/material";
import React from "react";
import AreaSelectIcon from "../../assets/icons/areaSelect.svg";
import BuyIcon from "../../assets/icons/buy.svg";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import { HighlightButton } from "common/components/buttons/base/HighlightButton";
import DownloadIcon from "../../assets/icons/download.svg";

interface MapActionButtonProps {
  onClick?: () => void;
  icon?: React.ReactNode;
  title: string;
}

export const MapActionButton: React.FC<MapActionButtonProps> = ({
  onClick,
  icon,
  title,
}) => {
  return (
    <HighlightButton onPress={onClick} borderRadius={35}>
      <Box
        sx={{
          boxShadow: 4,
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          width: "70px",
          height: "70px",
          borderRadius: "35px",
          userSelect: "none",
        }}
      >
        {icon}
        <Typography
          textStyle={{
            fontSize: 12,
            color: colors.purple2,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>
    </HighlightButton>
  );
};
