import { getListString } from "common/helpers/common.helpers";
import { getYearText } from "./comparable.helpers";
import { getFinishingLabel } from "../components/transactions/helpers";

interface PropertyFieldsForListString {
  rooms?: number | null;
  area?: number | null;
  floorNr?: number | null;
  floorCount?: number | null;
  year?: number | null;
  renovationYear?: number | null;
  finishing?: boolean | null;
  energyClass?: string | null;
}

export const getPropertyListString = (fields: PropertyFieldsForListString) => {
  return getListString([
    fields.area !== null && fields.area !== undefined && `${fields.area} m²`,
    fields.rooms !== null &&
      fields.rooms !== undefined &&
      `${fields.rooms} kamb.`,
    fields.floorNr !== null && fields.floorNr !== undefined
      ? `${fields.floorNr}/${fields.floorCount} aukšt.`
      : fields.floorCount !== null && fields.floorCount !== undefined
      ? `${fields.floorCount} aukšt.`
      : undefined,
    fields.year !== null &&
      fields.year !== undefined &&
      getYearText(fields.year, fields.renovationYear),
    !!fields.energyClass && fields.energyClass,
    getFinishingLabel(fields.finishing, undefined),
  ]);
};
