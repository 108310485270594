import React, { useEffect, useState } from "react";
import Lightbox from "react-spring-lightbox";
import { GalleryHeader } from "./GalleryHeader";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "common/components/buttons/IconButton";

interface ImageGalleryProps {
  images: string[];
  isOpen: boolean;
  onClose?: () => void;
  headerText?: string;
  getHeaderSubtext?: (index: number) => string | undefined;
  defaultIndex?: number;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  isOpen,
  onClose,
  headerText,
  getHeaderSubtext,
  defaultIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (defaultIndex !== undefined) {
      setCurrentIndex(defaultIndex);
    }
  }, [defaultIndex]);

  const onNext = () => {
    if (currentIndex + 1 < images.length) setCurrentIndex(currentIndex + 1);
  };
  const onPrev = () => {
    if (currentIndex - 1 >= 0) setCurrentIndex(currentIndex - 1);
  };
  return (
    <Lightbox
      images={images.map((img, index) => ({
        src: img,
        alt: `Image ${index}`,
        loading: "lazy",
      }))}
      isOpen={isOpen}
      currentIndex={currentIndex}
      onNext={onNext}
      onPrev={onPrev}
      onClose={onClose}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        backdropFilter: "blur(2px)",
        zIndex: 10000,
      }}
      renderHeader={() => (
        <GalleryHeader
          selectedImageIndex={currentIndex}
          galleryLength={images.length}
          onClose={onClose}
          text={headerText}
          subtext={getHeaderSubtext?.(currentIndex)}
        />
      )}
      renderPrevButton={() => (
        <IconButton
          onPress={onPrev}
          style={{ zIndex: 1000, marginLeft: 10 }}
          isDisabled={currentIndex === 0}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </IconButton>
      )}
      renderNextButton={() => (
        <IconButton
          onPress={onNext}
          style={{ marginRight: 10 }}
          isDisabled={currentIndex + 1 === images.length}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </IconButton>
      )}
    />
  );
};
