import { LeadContact } from "common/types/common.types";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PropertyAddonType, PropertyType } from "../../types/api.types";
import { useRouteQuery } from "../../hooks/router.hooks";

interface PropertyCreationProviderProps {
  fromLead?: boolean;
}

interface PropertyCreationContextProps {
  fromLead?: boolean;
  selectedAddons?: PropertyAddonType[];
  toggleAddon?: (addon: PropertyAddonType) => void;
  isAddonSelected?: (addon: PropertyAddonType) => boolean;
}

export const PropertyCreationContext =
  React.createContext<PropertyCreationContextProps>({});

export const PropertyCreationProvider: React.FC<
  PropertyCreationProviderProps
> = ({ fromLead }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const [selectedAddons, setSelectedAddons] = React.useState<
    PropertyAddonType[]
  >([]);

  const [isAddonsSynced, setIsAddonsSynced] = React.useState(false);

  // sync with session storage
  useEffect(() => {
    const addons = sessionStorage.getItem("selectedAddons");
    if (addons) {
      setSelectedAddons(JSON.parse(addons));
    }
    setIsAddonsSynced(true);
  }, []);

  useEffect(() => {
    if (!isAddonsSynced) return;
    sessionStorage.setItem("selectedAddons", JSON.stringify(selectedAddons));
  }, [selectedAddons, isAddonsSynced]);

  const toggleAddon = (addon: PropertyAddonType) => {
    setSelectedAddons((prev) =>
      prev.includes(addon) ? prev.filter((a) => a !== addon) : [...prev, addon]
    );
  };

  const isAddonSelected = (addon: PropertyAddonType) =>
    selectedAddons.includes(addon);

  const navigate = useNavigate();

  const simpleType = useRouteQuery().get("simpleType");

  useEffect(() => {
    if (lastSegment !== "landing" && !!fromLead && !simpleType?.length) {
      navigate(pathSegments.slice(0, -1).join("/") + "/landing", {
        replace: true,
      });
    }
  }, [lastSegment, fromLead, simpleType]);

  return (
    <PropertyCreationContext.Provider
      value={{
        fromLead,
        selectedAddons,
        toggleAddon,
        isAddonSelected,
      }}
    >
      <div style={{ maxWidth: "800px", margin: "20px auto" }}>
        <Outlet />
      </div>
    </PropertyCreationContext.Provider>
  );
};
