import React from "react";
import { ChartData, ChartType } from "../../types/api.types";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import colors from "common/styles/colors";
import { trimText } from "../../helpers/text.helpers";

interface ChartProps {
  chartData: ChartData;
}

export const Chart: React.FC<ChartProps> = ({ chartData }) => {
  const {
    title,
    chart_type,
    data,
    x_axis_label,
    y_axis_label,
    source,
    subtitle,
  } = chartData;

  const xAxisData = data.map((item) => item.x);
  const yAxisData = data.map((item) => item.y);

  const commonProps = {
    xAxis: [
      {
        data: xAxisData,
        label: x_axis_label,
        scaleType: "band" as const,
      },
    ],
    yAxis: [
      {
        label: y_axis_label,
        min: 0,
      },
    ],
    series: [
      {
        data: yAxisData,
        color: colors.purple2,
      },
    ],
    // width: 600,
    height: 400,
    margin: {
      left: 80,
    },
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-30px)",
      },
    },
  };

  return (
    <div>
      <h3
        style={{
          marginBottom: "4px",
        }}
      >
        {title}
      </h3>
      {subtitle && (
        <div
          style={{
            color: colors.grey,
          }}
        >
          {subtitle}
        </div>
      )}
      {chart_type === ChartType.Bars ? (
        <BarChart {...commonProps} />
      ) : (
        <LineChart {...commonProps} />
      )}
      <div
        style={{
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        Šaltinis:{" "}
        <a href={source} target="_blank">
          {trimText(source)}
        </a>
      </div>
    </div>
  );
};
