import { WallType } from "../../web-valuations/src/helpers/comparableFields.helpers";
import { DesignationType } from "common/types/comparableFields.types";
import { AdjustmentDescription } from "../../web-valuations/src/types/api.types";
import { PropertyPermission } from "../../web-valuations/src/routes/property/PropertyShareSettings";

export type LocationCapability = InsightsSectionTabTypes;

export interface Location {
  id: number;
  name: string;
  zoom: number;
  key: string;
  lat: number;
  lng: number;
  avatar: Visual;
  capabilities: LocationCapability[];
}

export interface Visual {
  url_medium: string;
  url_avatar: string;
  id?: number;
  url_thumb: string;
}

export interface NotificationProperty {
  id: number;
  name: string;
  locationId: number;
  visuals: Visual[];
}

export interface BaseProperty {
  id: number;
  name: string;
  region?: string | null;
  price_shown?: string | null;
  visuals: Visual[];
}

export enum PropertyStatus {
  Finished = "Baigtas",
  Developed = "Statomas",
  Planned = "Planuojamas",
}

export interface DeveloperProperty extends BaseProperty {
  status: PropertyStatus;
}

export interface InfraObject {
  name: string;
  type: number;
  position: {
    lat: number;
    lng: number;
  };
}

export interface InfraGroup {
  category_key: string;
  category_label: string;
  points: InfraObject[];
}

export interface DevelopmentGallery {
  id: number;
  images: Visual[];
  created: string;
}

export interface Milestone {
  date: string | null;
  name: string;
  completed: boolean | null;
}

export interface File {
  id: number;
  url: string;
}

export interface PropertyDevelopment extends BaseProperty {
  milestones: Milestone[];
}

export interface Property extends BaseProperty {
  address: string;
  coords: number[][];
  lat: number;
  lng: number;
  has_units: 0 | 1;
  show_on_map: 0 | 1;
  sorters?: PropertySorters;
}

export interface Panorama {
  url: string;
  web: string;
  lat: number;
  lng: number;
  created: string;
}

export enum CommuteMode {
  Driving = "driving",
  Walking = "walking",
  Transit = "transit",
  Bicycling = "bicycling",
}

export enum CommuteModel {
  Default = "default",
  Pessimistic = "pessimistic",
  Optimistic = "optimistic",
}

export interface CommuteElement {
  distance: number;
  mode: CommuteMode;
  model?: CommuteModel;
  duration: number;
}

export interface PropertySorters {
  price_increasing: number | null;
  distance_increasing: number | null;
}

export enum BusTypes {
  trolleybus = "trol",
  bus = "bus",
  expressBus = "expressbus",
  nightBus = "nightbus",
  miniBus = "minibus",
}

export interface BusAtStop {
  bus_type: BusTypes;
  bus_number: string;
}
export interface TimeType {
  minute: number;
  hour: number;
}
export interface WorkTime {
  start: TimeType;
  end: TimeType;
}
export interface WorkTimes {
  day: number;
  work_time: WorkTime;
}
export interface InfraMeta {
  busses_at_stop?: BusAtStop[];
  location?: MyLocation;
  work_times?: WorkTimes[];
}
export interface InfraPoint {
  name: string;
  subheader: string;
  lat: number;
  lng: number;
  marker: string;
  completed: 0 | 1;
  coords?: number[][];
  meta?: InfraMeta;
}
export interface InfraPointWithCommutes extends InfraPoint {
  commutes?: CommuteElement[];
}

interface InfraPointsBasic {
  category_key: number;
  category_label: string;
  marker: string;
}
export interface InfraPoints extends InfraPointsBasic {
  points: InfraPoint[];
}
export interface InfraPointsWithCommutes extends InfraPointsBasic {
  points: InfraPointWithCommutes[];
}

export interface Article {
  www: string;
  created: string;
  title: string;
}

export interface DetailedProperty extends Property {
  builder?: Developer | null;
  website?: string | null;
  infra: InfraGroup[];
  status: string;
  permalink: string;
  start_year: number | null;
  start_quarter: number | null;
  finish_year: number | null;
  finish_quarter: number | null;
  contact: Contact[] | null;
  detailed_status: string | null;
  progress: number;
  energy: string | null;
  floors: number | null;
  proposals: File[];
  subcategory: string | null;
  recommendations_by_price: BaseProperty[];
  recommendations_by_location: BaseProperty[];
  galleries: DevelopmentGallery[];
  panoramas?: Panorama[];
  articles?: Article[];
}

export interface FinishingDetails {
  content: string;
  id: number;
  label: string;
}
export interface Finishing {
  id: number;
  details: FinishingDetails[];
  label: string;
}
export interface CompareProperty {
  start_year: number | null;
  start_quarter: number | null;
  finish_year: number | null;
  finish_quarter: number | null;
  detailed_status: string | null;
  energy: string | null;
  galleries: DevelopmentGallery[];
  builder?: Developer | null;
  floors: number | null;
  finishing: Finishing[] | null;
}

export interface Developer {
  id: number;
  website: string;
  name: string;
  email: string;
  director: string | null;
  phone: string;
  facebook: string | null;
  avatar: Visual;
  address: string;
}

export interface DetailedDeveloper extends Developer {
  projects: DeveloperProperty[];
}

export type ApartmentStatusEffective = "Laisvas" | "Rezervuotas" | "Parduotas";
export interface Apartment {
  status_effective: ApartmentStatusEffective;
  floor?: number | null;
  price_www?: number | null;
  price_average?: number | null;
  area: number;
  sold_date: string | null;
  released_date: string | null;
  reserved_date: string | null;
  rooms: number;
  www?: string | null;
  external_id: string;
}

export type ApartmentsByRoomsDetails = {
  availableApartments: Apartment[];
  allApartmentsLength: number;
  areaMin: number | null;
  areaMax: number | null;
  areaAverage: number | null;
  priceMin: number | null;
  priceMax: number | null;
  priceAverage: number | null;
  priceM2Min: number | null;
  priceM2Max: number | null;
  priceM2Average: number | null;
  availabilityPercent: number;
};

export type ApartmentsByRooms = {
  [rooms: number]: ApartmentsByRoomsDetails | undefined;
};

export interface User {
  _id: string;
  likedProjects: number[];
  recommendations?: { [locationId: number]: number[] };
  notificationSubscription: NotificationSubscriptionType;
  email?: string;
  firstname?: string;
  lastname?: string;
  pictureUrl?: string;
  selectedLocation: number;
  citynowToken?: string;
  updatedAt: Date;
}

export interface UserSubscription {
  hasUsedTrial?: boolean;
  revenuecatId: string;
}

export enum NotificationType {
  new_project = 1,
  permit = 2,
  proposal = 3,
  price_change = 4,
  gallery = 5,
  url = 6,
}

export interface PropertyNotification {
  _id: string;
  message: string;
  type?: NotificationType;
  createdAt?: Date;
}

export interface Notification extends PropertyNotification {
  project?: NotificationProperty;
  isRead: boolean;
  url?: string;
}

export enum NotificationSubscriptionType {
  all = "all",
  subscribed = "subscribed",
}

export enum PropertiesSortBy {
  importance = "importance",
  new_sales = "new_sales",
  new_planned = "new_planned",
}

export interface Contact {
  phone: string | null;
  email: string | null;
  avatar: Visual | null;
  name: string | null;
}

export enum ProjectInteractionType {
  projectView = "projectView",
  developmentView = "developmentView",
  fullDevelopmentView = "fullDevelopmentView",
  apartmentsView = "apartmentsView",
  apartmentsDetailsView = "apartmentsDetailsView",
  projectDetailsView = "projectDetailsView",
  projectPDFView = "projectPDFView",
  websiteView = "websiteView",
  mapClick = "mapClick",
  searchClick = "searchClick",
}

export enum RecommendationInteractionType {
  recommendationClick = "recommendationClick",
  recommendationView = "recommendationView",
}

export interface ProjectInteractionRequest {
  projectInteractionType: ProjectInteractionType;
  projectId: number;
  filters?: FiltersInteractionRequest;
}

export enum SearchInteractionType {
  click = "click",
  scroll = "scroll",
  miss = "miss",
}

export interface SearchInteractionRequest {
  type: SearchInteractionType;
  query: string;
}

export interface RecommendationInteractionRequest {
  type: RecommendationInteractionType;
  projectId: number;
}

export interface FiltersInteractionRequest {
  query: string;
  queryObject: any;
}

export interface SliderStep {
  min: number;
  max: number;
  cnt: number;
}

export interface ColumnChartDataValue {
  cnt: number;
  data_distribution: { [key: string]: number | undefined };
  min: number;
  max: number;
}

export interface LineChartDataValue {
  t: string;
  value: number;
}

export interface SliderFinishYearStep {
  cnt: number;
  quarter: number;
  year: number;
}

export interface SliderDistributions {
  area: SliderStep[];
  price: SliderStep[];
  avg_price: SliderStep[];
  finish: SliderFinishYearStep[];
}

export interface FilteredProperty {
  id: number;
  cnt: number;
}

export interface Radar {
  lat: number;
  lng: number;
  radius: number;
}

export interface NotificationSettings {
  liked_projects: {
    isEnabled: boolean;
  };
  territory: {
    isEnabled: boolean;
    territory: Radar;
  };
}

export interface LocationInsightsSpec {
  area_max?: number | null;
  area_min?: number | null;
  radius: number;
  room: number[];
  year_min?: number | null;
  year_max?: number | null;
}

export interface MapInsightItem {
  lat: number;
  lng: number;
  group?: string;
  price: number | null;
  area: number | null;
}

export interface LocationInsightsLimits {
  min_radius: number;
  max_radius: number;
}

export interface DataCategory {
  color: string;
  id: string;
  label: string;
}

export interface LocationInsightsDescriptions {
  sqm_price: string;
  trend_price_project: string;
  price: string;
  trend_price: string;
  trend_supply_project: string;
  trend_on_market: string;
  trend_count: string;
}

export interface ChartConfig {
  color: string;
}

export interface ChartConfigs {
  trend_price?: ChartConfig;
  trend_on_market?: ChartConfig;
  trend_count?: ChartConfig;
  trend_price_project?: ChartConfig;
  trend_supply_project?: ChartConfig;
}

export interface LocationInsightsDistributions {
  price?: SliderStep[];
  area?: SliderStep[];
  year?: SliderStep[];
}

export interface LocationInsights {
  price?: ColumnChartDataValue[];
  sqm_price?: ColumnChartDataValue[];
  total: number;
  spec: LocationInsightsSpec;
  with_filters: boolean;
  items?: MapInsightItem[];
  limits: LocationInsightsLimits;
  data_categories: DataCategory[];
  trend_price?: LineChartDataValue[];
  trend_on_market?: LineChartDataValue[];
  trend_count?: LineChartDataValue[];
  trend_price_project?: LineChartDataValue[];
  trend_supply_project?: LineChartDataValue[];
  report_url?: string;
  descriptions?: LocationInsightsDescriptions;
  labels?: LocationInsightsDescriptions;
  chart_configs?: ChartConfigs;
  distributions?: LocationInsightsDistributions;
}

export interface LocationInsightsInfra {
  points: InfraPointsWithCommutes[];
}

export interface MyLocationAddRequest {
  label: string;
  lat: number;
  lng: number;
  address?: string;
}

export interface MyLocation extends MyLocationAddRequest {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface MyPropertyAddRequest extends MyLocationAddRequest {
  area: number;
  rooms: number;
  year: number;
  floorNr?: number;
  floorCount: number;
  isRenovated: boolean;
  finishing: boolean;
  finishingFull?: number;
  renovationYear?: number;
  apartmentNumber?: number;
  street?: string;
  streetNumber?: string;
  city?: string;
  condition?: number;
  designationType?: string;
  plotArea?: number;
  walls?: WallType;
  energyClass?: string;
}

export interface MyProperty extends MyPropertyAddRequest {
  _id: string;
  valuationZone?: string;
  propertyToken?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PredictedPrice {
  average_price_area: number;
  min: number;
  max: number;
  price_with_assets?: number;
  price_area_with_assets?: number;
}

export interface SimilarityByField {
  field: string;
  score: number;
  distance: number;
}

export interface AppraiserComparableSimilarities {
  joint_similarity: number;
}

export interface ComparableSimilarities
  extends AppraiserComparableSimilarities {
  similarities_by_field: SimilarityByField[];
}

export interface Image {
  id?: number | string;
  url: string;
}

export enum PropertyPhotoCategory {
  LivingRoom = "living_room",
  Kitchen = "kitchen",
  Bathroom = "bathroom",
  Bedroom = "bedroom",
  Balcony = "balcony",
  Exterior = "exterior",
  Garden = "garden",
  Garage = "garage",
  Stairway = "stairway",
  OtherRoom = "other_room",
  Other = "other",
}

export const PropertyPhotoCategoryTranslations: Record<
  PropertyPhotoCategory,
  string | undefined
> = {
  [PropertyPhotoCategory.Exterior]: "Išorė",
  [PropertyPhotoCategory.Bathroom]: "Vonia",
  [PropertyPhotoCategory.LivingRoom]: "Svetainė",
  [PropertyPhotoCategory.Kitchen]: "Virtuvė",
  [PropertyPhotoCategory.Bedroom]: "Miegamasis",
  [PropertyPhotoCategory.Balcony]: "Balkonas",
  [PropertyPhotoCategory.Garden]: "Kiemas",
  [PropertyPhotoCategory.Garage]: "Garažas",
  [PropertyPhotoCategory.Stairway]: "Laiptinė",
  [PropertyPhotoCategory.OtherRoom]: "Kita patalpa",
  [PropertyPhotoCategory.Other]: undefined,
};

export interface PropertyImage extends Image {
  category?: PropertyPhotoCategory;
}

export interface AdjustedField {
  field: keyof ComparableTransaction;
  percent: number;
  price_area: number;
  price: number;
  config_fullname: string;
  config_name: string;
}

export enum AdditionalREType {
  Basement = "basement",
  Garage = "garage",
  PlotArea = "plot_area",
}

export interface AdditionalRE {
  type: AdditionalREType;
  price: number;
}

export interface AdditionalField {
  appraiserPropertyFieldId: string;
  comparableDescription: string;
  percent: number;
}

export interface Adjustments {
  adjusted_fields: AdjustedField[];
  price_increase_area: number;
  adjusted_price_area: number;
  additional_re?: AdditionalRE[];
  additional_fields?: AdditionalField[];
  is_corrected?: boolean;
}

export interface PossibleAddress {
  location: {
    lat: number;
    lng: number;
  };
  address: string;
}

export interface AppraiserComparableTransaction {
  id: string;
  sold_price: number;
  area: number;
}

export interface RelatedRE {
  type: AdditionalREType;
  details: string;
  area: number;
  area_total: number;
}

export interface ProjectUnit extends Project {
  sold_price_area: number | null;
  sold_price: number | null;
  area: number;
  finishing: 0 | 1;
  rooms: number;
  floor: number;
  sold_date: string;
}

export interface Project {
  url: string;
  visuals: { url_medium: string }[];
  address: string;
  lat: number;
  lng: number;
  construction_year: number;
  floor_total: number;
  project_name: string;
}

export interface MatchedListing {
  images: string[];
  sold_price: number;
  sold_price_area: number;
  area: number;
  rooms: number;
  floor: number;
  floor_total: number;
  floor_position: number;
  reconstruction_year: number | null;
  construction_year: number;
  finishing: 0 | 1;
  lat: number;
  lng: number;
  sold_date: string;
  url: string;
}

export interface ComparableTransaction {
  id: string;
  address: string;
  area: number;
  sold_date: string;
  construction_year: number;
  valuation_zone: string;
  related_re: RelatedRE[];
  designation_type_id: DesignationType;
  matched_listing?: MatchedListing | null;
  matched_project?: Project | null;
  location: {
    lat: number;
    lng: number;
  };
}

export interface ComparableTransactionFull extends ComparableTransaction {
  sold_price: number;
  sold_price_area: number;
  walls: WallType;
  floor: number;
  floor_total: number;
  floor_position: number;
  rooms: number;
  finishing: number;
  finishing_full: number;
  reconstruction_year: number | null;
  energy_class?: string;
}

export interface Comparable {
  comparable_transaction: ComparableTransaction;
  similarities: ComparableSimilarities;
  adjustments?: Adjustments;
  comparable_weight?: number;
}

export interface ComparableFull {
  comparable_transaction: ComparableTransactionFull;
  similarities: ComparableSimilarities;
  adjustments?: Adjustments;
  comparable_weight?: number;
}

export interface ComparablePagination {
  next_cursor?: number | null;
  step_distance: number;
}

export interface AppraiserComparable {
  comparable_transaction: AppraiserComparableTransaction;
  similarities: AppraiserComparableSimilarities;
  adjustments: Adjustments;
  comparable_weight?: number;
}

export interface ComparableInclusive extends Comparable {
  included_in_price_prediction?: boolean;
}

export enum TransactionType {
  Transactions = "transactions",
  Listings = "listings",
  TransactionsMatched = "transactions_matched",
}

export interface IPricePredictions {
  transactionType: TransactionType;
}

export interface PricePredictions extends IPricePredictions {
  _id: string;
  predicted_price: PredictedPrice;
  comparables: Comparable[];
  price_chart?: LineChartDataValue[];
  comparables_set_size?: number;
}

export interface AdditionalPropertyField {
  appraiserPropertyFieldId: string;
  fieldValue: string;
}
export interface AppraiserPricePredictions {
  _id?: string;
  predicted_price: PredictedPrice;
  comparables: AppraiserComparable[];
  comparables_adjusted: AppraiserComparable[];
  additional_property_fields?: AdditionalPropertyField[];
  applied_filters?: any;
  descriptions?: AdjustmentDescription[];
}

export interface FinalPricePredictions {
  _id?: string;
  predicted_price: PredictedPrice;
  comparables: ComparableFull[];
  additional_property_fields?: AdditionalPropertyField[];
  descriptions?: AdjustmentDescription[];
}

export interface MyPropertyPrediction extends MyProperty {
  pricePredictions?: PricePredictions[];
}

export interface MyPropertyListItemType extends MyProperty {
  pricePredictions?: Omit<PricePredictions, "comparables">[];
}

export type MyListItem = MyPropertyListItemType | MyLocation;

export interface DeleteResult {
  deletedCount?: number;
}

export enum InsightsSectionTypes {
  salesPrimary = "sales_primary",
  salesSecondary = "sales_secondary",
  rent = "rent",
}
export enum InsightsSectionOtherTypes {
  location = "location",
}

export type InsightsSectionTabTypes =
  | InsightsSectionTypes
  | InsightsSectionOtherTypes;

export enum InsightsSourceMode {
  supply = "supply",
  transactions = "transactions",
  realized = "realized",
}

export type InsightsSourcePeriod = {
  start: string;
  finish: string;
  key: string;
  label: string;
  cnt?: number;
  default: boolean;
};

export type InsightsSource = {
  source: InsightsSourceMode;
  label: string;
  periods?: InsightsSourcePeriod[];
  default: boolean;
  description: string;
};

export type UploadedPhoto = {
  _id: string;
  url: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

export type LeadContact = {
  name: string;
  email: string;
  phone: string;
  additionalInfo?: string | null;
};

export interface LeadContactWithPermissions extends LeadContact {
  permissions: PropertyPermission[];
  uuid: string;
  link: string;
  pdfUrl?: string;
}

export type Coordinates = {
  lat: number;
  lng: number;
};

export type PredictionSubstringMatch = {
  length: number;
  offset: number;
};

export type GoogleMapsPrediction = {
  description: string;
  matched_substring: PredictionSubstringMatch;
  place_id: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: PredictionSubstringMatch[];
    secondary_text: string;
  };
};

export type GoogleMapsPredictions = {
  predictions: GoogleMapsPrediction[];
};

export type AddressType = {
  label: string;
  street?: string;
  streetNumber?: string;
  city?: string;
};

export type AddressWithCoords = {
  address: AddressType;
  lat: number;
  lng: number;
};

export type Rating = {
  rating: number;
  description: string;
};

export type PlannedProject = {
  id: number;
  name: string;
  address?: string;
  visuals: Visual[];
  distance?: number;
};

export enum ExpertServiceType {
  CONSULT = "CONSULT",
  SALE = "SALE",
  RENT = "RENT",
}

export enum ExpertSpecialtyType {
  BROKER = "BROKER",
  FINANCE_CONSULTANT = "FINANCE_CONSULTANT",
}

export enum ExpertServicePricingType {
  COMMISION = "COMMISION",
  TIME = "TIME",
}

export type ExpertServicePricing = {
  type: ExpertServicePricingType;
  rateValue: number;
  maxRateValue?: number;
  durationMin?: number;
  currency: string;
};

export type ExpertService = {
  type: ExpertServiceType;
  name: string;
  pricing: ExpertServicePricing;
};

export type ExpertCompany = {
  name?: string;
  website?: string;
  description?: string;
};

export type Expert = {
  _id: string;
  name: string;
  specialty: string;
  specialtyTypes: ExpertSpecialtyType[];
  phoneNumber: string;
  description?: string;
  languages: string[];
  imageUrl?: string;
  reservationUrl?: string;
  company?: ExpertCompany;
  services: ExpertService[];
};

export type MonthlyAllowance = {
  monthlyAllowance: number;
  leftMonthlyAllowance: number;
};
