import React from "react";
import {
  ActivityIndicator,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";

import { Typography } from "common/components/Typography";
import { HighlightButton } from "common/components/buttons/base/HighlightButton";
import colors from "../../styles/colors";
import { defaultButtonStyle } from "../../styles/button.styles";
import { shadow5 } from "../../styles/shadows.styles";

export interface ButtonProps {
  onPress?: () => void;
  backgroundColor?: keyof typeof colors;
  textStyle?: TextStyle;
  leftIcon?: React.ReactElement;
  leftIconByText?: boolean;
  rightIcon?: React.ReactElement;
  noShadow?: boolean;
  style?: StyleProp<ViewStyle>;
  isLoading?: boolean;
  loadingText?: string;
  borderRadius?: number;
  children?: React.ReactNode;
  disabled?: boolean;
  flex?: number;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const isDisabled = props.isLoading || props.disabled;
  const borderRadius = props.borderRadius ?? defaultButtonStyle.borderRadius;
  return (
    <HighlightButton
      onPress={() => props.onPress?.()}
      style={[defaultButtonStyle, props.noShadow ? {} : shadow5, props.style]}
      borderRadius={borderRadius ?? 0}
      backgroundColor={colors[props.backgroundColor ?? "white"]}
      disabled={isDisabled}
      flex={props.flex}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: props.backgroundColor
            ? colors[props.backgroundColor]
            : undefined,
          borderRadius,
          flexDirection: "row",
        }}
      >
        {props.isLoading ? (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <ActivityIndicator color={props.textStyle?.color ?? colors.black} />
            <Typography
              variant="button"
              textStyle={{ ...props.textStyle, marginLeft: 8 }}
            >
              {props.loadingText ?? props.children}
            </Typography>
          </View>
        ) : (
          <>
            {!!props.leftIcon && (
              <View
                style={
                  props.leftIconByText
                    ? { marginRight: 14 }
                    : { position: "absolute", left: 15 }
                }
              >
                {props.leftIcon}
              </View>
            )}
            <Typography variant="button" textStyle={props.textStyle}>
              {props.children}
            </Typography>
            {!!props.rightIcon && props.rightIcon}
          </>
        )}
      </View>
    </HighlightButton>
  );
};
