import { Box } from "@mui/material";
import colors from "common/styles/colors";
import React from "react";

interface LinkButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        color: colors.purple2,
        ":hover": {
          textDecoration: "underline",
        },
        fontWeight: 500,
      }}
    >
      {children}
    </Box>
  );
};
