import { addAlpha } from "common/helpers/colors.helpers";
import colors from "common/styles/colors";
import React from "react";
import { TagComponent } from "../tags/TagComponent";
import { PropertyRequestType } from "../../types/appraiser.types";

interface PropertyRequestTypeLabelProps {
  type?: PropertyRequestType;
}

export const PropertyRequestTypeLabel: React.FC<
  PropertyRequestTypeLabelProps
> = ({ type }) => {
  if (type === PropertyRequestType.LeadOrganization) {
    return <TagComponent color={colors.purple2}>Įmonės užklausa</TagComponent>;
  }
  if (type === PropertyRequestType.LeadAppraiser) {
    return (
      <TagComponent color={colors.green}>Vertintojo užklausa</TagComponent>
    );
  }
  return null;
};
