import React from "react";
import { useQuery } from "react-query";
import { getMarketInsights } from "../../../api/api";
import { ContentCard } from "../../../components/ContentCard";
import { Chart } from "../../../components/charts/Chart";
import { Grid } from "@mui/material";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { ChartData, ChartSection } from "../../../types/api.types";

interface PropertyMarketInsightsProps {
  chartsData: ChartData[];
}

export const PropertyMarketInsights: React.FC<PropertyMarketInsightsProps> = ({
  chartsData,
}) => {
  const dataSections = [
    {
      sectionTitle: "Rinkos apžvalga",
      charts: chartsData.filter(
        (chart) => chart.section === ChartSection.MarketInsights
      ),
    },
    {
      sectionTitle: "Gyventojų statistika",
      charts: chartsData.filter(
        (chart) => chart.section === ChartSection.PopulationStatistics
      ),
    },
  ];

  return (
    <div>
      {dataSections.map((section, index) => {
        return (
          <ContentCard title={section.sectionTitle} key={index}>
            <Grid container spacing={2}>
              {section.charts.map((data, index) => {
                if (!data.data.length) return null;
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    style={{
                      pageBreakInside: "avoid",
                    }}
                  >
                    <Chart key={`${data.title}|${index}`} chartData={data} />
                  </Grid>
                );
              })}
            </Grid>
          </ContentCard>
        );
      })}
    </div>
  );
};
