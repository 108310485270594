import { ComparableSummary } from "./ComparablesSummaryTable";

export function getUniqueAdjustedFields(comps: ComparableSummary[]) {
  return Array.from(
    new Set(
      comps?.flatMap(
        (comp) =>
          comp.adjustments?.adjusted_fields.map((field) => field.field) ?? []
      )
    )
  );
}
