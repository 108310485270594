import CommonConfig from "../commonConfig";
import {
  DeleteResult,
  MyProperty,
  MyPropertyAddRequest,
  UploadedPhoto,
  User,
} from "../types/common.types";
import { TokenServiceInstance } from "./TokenService";

export const getUser = async () => {
  const { data } = await CommonConfig.axiosInstance.get<User | undefined>(
    `/users`
  );

  if (!!data?.citynowToken)
    TokenServiceInstance.setCitynowToken(data.citynowToken);
  else {
    TokenServiceInstance.removeCitynowToken();
  }

  return data;
};

export const addMyPropertyFromUrl = async (url: string) => {
  const { data } = await CommonConfig.axiosInstance.post<MyProperty>(
    "/properties/url",
    {},
    {
      params: {
        url,
      },
    }
  );
  return data;
};

export const addMyProperty = async (property: MyPropertyAddRequest) => {
  const { data } = await CommonConfig.axiosInstance.post<MyProperty>(
    "/properties",
    property
  );
  return data;
};

export const updateMyProperty = async (
  property: MyPropertyAddRequest,
  propertyId: string
) => {
  const { data } = await CommonConfig.axiosInstance.put<MyProperty>(
    `/properties/${propertyId}`,
    property
  );
  return data;
};

export const getMyPropertyPhotos = async (propertyId: string) => {
  const { data } = await CommonConfig.axiosInstance.get<UploadedPhoto[]>(
    `/properties/${propertyId}/photos`
  );
  return data;
};

export const deleteMyPropertyPhotos = async (
  photos: string[],
  propertyId?: string
) => {
  const { data } = await CommonConfig.axiosInstance.delete<DeleteResult>(
    "/photos",
    {
      data: { photos },
    }
  );
  return data;
};

export const uploadMyPropertyPhotos = async (
  propertyId: string,
  formData: FormData
) => {
  const { data } = await CommonConfig.axiosInstance.post<UploadedPhoto>(
    `/properties/${propertyId}/photos`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data;
};
