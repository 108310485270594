import { createTheme } from "@mui/material";
import colors from "common/styles/colors";

export const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: colors.purple4,
      main: colors.purple2,
    },
    success: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          height: "50px",
          fontSize: 12,
          letterSpacing: 1,
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: 500,
          letterSpacing: "1px",
          textTransform: "none",
        },
      },
    },
  },
});
