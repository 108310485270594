import React from "react";
import Close from "../../assets/icons/close.svg";
import colors from "../../styles/colors";

interface CloseIconProps {
  stroke?: string;
}

export const CloseIcon: React.FC<CloseIconProps> = ({ stroke }) => {
  // @ts-ignore
  return <Close stroke={stroke ?? colors.black} strokeWidth="2" />;
};
