import { DesignationType } from "common/types/comparableFields.types";

export enum FloorType {
  All = -1,
  Basement = 0,
  First = 1,
  Middle = 2,
  Last = 3,
}

// Also requires changes in backend (valuations)
export enum WallType {
  StoneConcrete = "StoneConcrete",
  Blocks = "Blocks",
  ReinforcedConcretePlates = "ReinforcedConcretePlates",
  MonolithicReinforcedConcrete = "MonolithicReinforcedConcrete",
  WoodWithFrame = "WoodWithFrame",
  MetalWithFrame = "MetalWithFrame",
  Bricks = "Bricks",
  Logs = "Logs",
}

export const DesignationTypeTranslations: Record<DesignationType, string> = {
  [DesignationType.ResidentialApartments]: "Gyvenamoji (butų)",
  [DesignationType.Recreation]: "Poilsio",
  [DesignationType.Residential1Flat]: "Gyvenamoji (1 butas)",
  [DesignationType.Residential2Flats]: "Gyvenamoji (2 butai)",
  [DesignationType.Residential3OrMoreFlats]: "Gyvenamoji (3 ir daugiau butų)",
  [DesignationType.Hotels]: "Viešbučiai",
  [DesignationType.PlotArea]: "Sklypai",
  [DesignationType.Administrative]: "Administracinė",
  [DesignationType.ManufacturingIndustry]: "Gamybos, pramonės",
  [DesignationType.Manufacturing]: "Gamybos",
  [DesignationType.Other]: "Kita",
  [DesignationType.Garage]: "Garažų",
  [DesignationType.OtherSubFarm]: "Kita (pagalbinio ūkio)",
};

export const WallTypeTranslations: Record<WallType, string> = {
  [WallType.StoneConcrete]: "Akmenbetonis",
  [WallType.Blocks]: "Blokeliai",
  [WallType.ReinforcedConcretePlates]: "Gelžbetonio plokštės",
  [WallType.MonolithicReinforcedConcrete]: "Monolitinis gelžbetonis",
  [WallType.Bricks]: "Plytos",
  [WallType.Logs]: "Rąstai",
  [WallType.WoodWithFrame]: "Medis su karkasu",
  [WallType.MetalWithFrame]: "Metalas su karkasu",
};

export const DesignationTypesForValuation = [
  DesignationType.ResidentialApartments,
  DesignationType.Recreation,
  DesignationType.Residential1Flat,
  DesignationType.Residential2Flats,
  DesignationType.Residential3OrMoreFlats,
  DesignationType.Hotels,
  DesignationType.Administrative,
  // DesignationType.ManufacturingIndustry,
  DesignationType.Manufacturing,
  DesignationType.Other,
];
