import { DesignationType } from "common/types/comparableFields.types";
import { PropertyType } from "../types/api.types";

export const getDesignationTypeFromSimpleType = (
  simpleType: PropertyType
): DesignationType => {
  switch (simpleType) {
    case PropertyType.Apartment:
      return DesignationType.ResidentialApartments;
    case PropertyType.House:
      return DesignationType.Residential1Flat;
    case PropertyType.Cottage:
      return DesignationType.Residential2Flats;
  }
};
