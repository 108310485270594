import { Dialog } from "@mui/material";
import { Button } from "common/components/buttons/Button";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { FormInput } from "common/components/form/hookForms/FormInput";
import colors from "common/styles/colors";
import PlusIcon from "../../assets/icons/plus.svg";
import { Typography } from "common/components/Typography";
import { FormFieldWrapperProps } from "common/components/form/FormFieldWrapper";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { IconButton } from "common/components/buttons/IconButton";
import { Divider } from "common/components/listItems/Divider";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";
import { FormAutocomplete } from "../form/FormAutocomplete";

export interface AppraiserPropertyFieldOption {
  _id: string;
  name: string;
  fieldValue?: string;
  used?: boolean;
}
interface AddAdditionalAdjustmentProps {
  options: AppraiserPropertyFieldOption[];
  onSubmit: (additional: AdditionalFieldForm) => void;
}

export type AdditionalFieldForm = {
  appraiserPropertyFieldId?: string;
  fieldName: string;
  fieldValue: string;
  comparableDescription: string;
  percent: number;
};

const validationSchema: yup.ObjectSchema<AdditionalFieldForm> = yup.object({
  appraiserPropertyFieldId: yup.string(),
  fieldName: yup.string().required(),
  fieldValue: yup.string().required(),
  comparableDescription: yup.string().required(),
  percent: yup.number().required(),
});

const wrapperProps: Partial<FormFieldWrapperProps> = {
  gap: 8,
  textVariant: "body",
  textStyle: {
    fontSize: 16,
  },
};

const defaultValues: Partial<AdditionalFieldForm> = {
  appraiserPropertyFieldId: undefined,
  fieldName: "",
  fieldValue: "",
  comparableDescription: "",
  percent: 0,
};

export const AddAdditionalAdjustment: React.FC<
  AddAdditionalAdjustmentProps
> = ({ onSubmit, options }) => {
  const [open, setOpen] = useState(false);
  const methods = useForm<AdditionalFieldForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      methods.reset(defaultValues);
    }, 200);
  };

  return (
    <div>
      <OutlinedButton
        onPress={() => setOpen(true)}
        leftIcon={<PlusIcon />}
        leftIconByText
        style={{ paddingLeft: "16px", paddingRight: "24px" }}
      >
        PRIDĖTI PATAISĄ
      </OutlinedButton>
      <Dialog open={open} onClose={closeModal} maxWidth={false}>
        <div
          style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            width: "800px",
            maxWidth: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h1">Pridėti naują pataisą</Typography>
            <div style={{ marginTop: "-10px", marginRight: "-10px" }}>
              <IconButton onPress={closeModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Divider style={{ marginTop: 30 }} />
          <div style={{ margin: "30px 0" }}>
            <FormProvider {...methods}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",

                  gap: "30px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    gap: "30px",
                  }}
                >
                  <FormAutocomplete
                    label="Vertės pataisa"
                    name="fieldName"
                    isRequired
                    wrapperProps={wrapperProps}
                    options={options
                      .filter((option) => !option.used)
                      .map((option) => option.name)}
                    freeSolo
                    onChange={(value) => {
                      const option = options.find((opt) => opt.name === value);
                      methods.setValue("fieldValue", option?.fieldValue ?? "");
                      methods.setValue(
                        "appraiserPropertyFieldId",
                        option?._id ?? ""
                      );
                    }}
                  />
                  <FormInput
                    label="Vertinamo objekto aprašymas"
                    name="fieldValue"
                    isRequired
                    variant="text"
                    wrapperProps={wrapperProps}
                    maxWidth={400}
                  />
                </div>
                <div style={{ flex: 1, alignSelf: "flex-end" }}>
                  <FormInput
                    label="Palyginamojo objekto aprašymas"
                    name="comparableDescription"
                    variant="text"
                    isRequired
                    wrapperProps={wrapperProps}
                    maxWidth={400}
                  />
                </div>
              </div>
            </FormProvider>
          </div>
          <CenteredContentWrapper width="345px">
            <Button
              backgroundColor="purple2"
              onPress={methods.handleSubmit((data) => {
                onSubmit(data);
                closeModal();
              })}
              textStyle={{ color: colors.white }}
            >
              IŠSAUGOTI PATAISĄ
            </Button>
          </CenteredContentWrapper>
        </div>
      </Dialog>
    </div>
  );
};
