import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface DeleteDialogProps {
  open: boolean;
  confirmationText: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  confirmationText,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Ar tikrai norite ištrinti?</DialogTitle>
      <DialogContent>{confirmationText}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Atšaukti</Button>
        <Button onClick={onConfirm}>ištrinti</Button>
      </DialogActions>
    </Dialog>
  );
};
