import { formatPrice } from "common/helpers/text.helpers";
import colors from "common/styles/colors";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";

export interface OrderSummaryItem {
  title: string;
  price?: number;
}

interface OrderSummarySectionProps {
  items: OrderSummaryItem[];
  total: number;
  title: string;
  isPurchased?: boolean;
}

export const OrderSummarySection: React.FC<OrderSummarySectionProps> = ({
  isPurchased,
  items,
  total,
  title,
}) => {
  return (
    <div
      style={{
        ...(isPurchased
          ? {}
          : {
              borderRadius: "10px",
              border: `1px solid ${colors.purple2}`,
              padding: "15px 20px",
            }),
        fontSize: isPurchased ? "14px" : "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{title}</div>
        {!!total && !isPurchased && (
          <div style={{ fontWeight: 600, fontSize: "20px" }}>
            {formatPrice(total, 2)}
          </div>
        )}
      </div>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginLeft: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <CheckIcon color="primary" fontSize="small" />
            {item.title}
          </div>
          {item.price !== undefined && <div>{formatPrice(item.price, 2)}</div>}
        </div>
      ))}
    </div>
  );
};
