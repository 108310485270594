import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { ComparableCard } from "./ComparableCard";
import { Comparable } from "common/types/common.types";
import { IconButton } from "common/components/buttons/IconButton";
import BackwardIcon from "../../assets/icons/arrowBackward.svg";
import { Typography } from "common/components/Typography";
import ForwardIcon from "../../assets/icons/arrowForward.svg";
import colors from "common/styles/colors";
import { useMessagesStatus } from "../messages/hooks";
import { isComparableFull } from "../../helpers/comparable.helpers";
import { TransactionsBuyConfirmationModal } from "../transactions/TransactionsBuyConfirmationModal";

interface ComparableCardWithPricePredictionsProps {
  comparables: Comparable[];
  selectedCompId?: string;
  onClick?: () => void;
}

export const ComparableCardWithPricePredictions: React.FC<
  ComparableCardWithPricePredictionsProps
> = ({ comparables, selectedCompId, onClick }) => {
  const { propertyId } = useParams();

  const [selectedComparableIndex, setSelectedComparableIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedComparable = comparables[selectedComparableIndex];

  const {
    isComparableInCart,
    addComparableToCart,
    removeComparableFromCart,
    getComparableAdjustments,
  } = usePricePredictions(propertyId ?? "");

  const adjustments = getComparableAdjustments(selectedComparable);

  useEffect(() => {
    const index = comparables.findIndex(
      (comp) => comp.comparable_transaction.id === selectedCompId
    );
    if (index !== -1) {
      setSelectedComparableIndex(index);
    }
  }, [selectedCompId]);

  const compId = selectedComparable.comparable_transaction.id;

  const getComparableIndex = (toAdd: number) => {
    return (
      (selectedComparableIndex + toAdd + comparables.length) %
      comparables.length
    );
  };
  const isInCart = isComparableInCart(compId);
  const isCorrected = adjustments?.is_corrected;

  const { data: messagesStatus } = useMessagesStatus();

  const status = messagesStatus?.[compId];

  return (
    <>
      <ComparableCard
        comparable={selectedComparable}
        onComparableAdd={() => {
          if (
            localStorage.getItem("doNotShowTransactionsBuyConfirmationModal") ||
            isComparableFull(selectedComparable)
          ) {
            addComparableToCart({ ...selectedComparable, adjustments });
            return;
          }
          setIsModalOpen(true);
        }}
        onComparableRemove={() => {
          removeComparableFromCart(compId);
        }}
        isInCart={isInCart}
        isCorrected={isCorrected}
        onClick={onClick}
        adjustments={adjustments}
        status={status}
      >
        {comparables.length > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              backgroundColor: colors.greybg,
              borderBottomRightRadius: "10px",
            }}
          >
            <IconButton
              onPress={() => {
                setSelectedComparableIndex(getComparableIndex(-1));
              }}
            >
              <BackwardIcon />
            </IconButton>
            <Typography variant="caption" textStyle={{ fontSize: 12 }}>{`${
              selectedComparableIndex + 1
            }/${comparables.length} sandoris`}</Typography>
            <IconButton
              onPress={() => {
                setSelectedComparableIndex(getComparableIndex(1));
              }}
            >
              <ForwardIcon />
            </IconButton>
          </div>
        )}
      </ComparableCard>

      <TransactionsBuyConfirmationModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={(doNotShowAgain) => {
          if (doNotShowAgain) {
            localStorage.setItem(
              "doNotShowTransactionsBuyConfirmationModal",
              "true"
            );
          }
          addComparableToCart({ ...selectedComparable, adjustments });
          setIsModalOpen(false);
        }}
      />
    </>
  );
};
