import React from "react";
import { TableRow } from "./types";
import { TableRowComponent } from "./TableRowComponent";
import { Box, SxProps } from "@mui/material";

interface TableComponentProps {
  rows: TableRow[];
  minHeaderWidth?: number;
  sx?: SxProps;
  paddingY?: string;
  maxElementWidth?: number;
  maxHeaderWidth?: number;
}

export const TableComponent: React.FC<TableComponentProps> = ({
  rows,
  minHeaderWidth,
  sx,
  paddingY,
  maxElementWidth,
  maxHeaderWidth,
}) => {
  return (
    <Box sx={{ overflowX: "auto", py: paddingY }}>
      <Box
        component="table"
        style={{
          borderCollapse: "collapse",
        }}
        sx={sx}
      >
        <tbody>
          {rows.map((row, index) => (
            <TableRowComponent
              key={index}
              row={row}
              minHeaderWidth={minHeaderWidth}
              maxElementWidth={maxElementWidth}
              maxHeaderWidth={maxHeaderWidth}
            />
          ))}
        </tbody>
      </Box>
    </Box>
  );
};
