import { formatPrice } from "common/helpers/text.helpers";

export interface RawTransactionField {
  key: string;
  label: string;
  value: string | undefined | null;
}

export type RCFieldParser = {
  key: string;
  parser: (value: string | undefined | null) => string | undefined;
};

const areaParser = (value: string | undefined | null) =>
  !!value ? `${value} m²` : undefined;

export const rc_fields_parsers: RCFieldParser[] = [
  {
    key: "obj_plotas",
    parser: areaParser,
  },
  {
    key: "isig_plotas",
    parser: areaParser,
  },
  {
    key: "baigtumas",
    parser: (value: string | undefined | null) => `${value ?? "-"}%`,
  },
  { key: "pagalbinis_pl", parser: areaParser },
  { key: "rusio_pl", parser: areaParser },
  { key: "garazo_pl", parser: areaParser },
  { key: "gyvenamasis_pl", parser: areaParser },
  { key: "naudingas_pl", parser: areaParser },
  {
    key: "pagalbinis_nenaud_pl",
    parser: areaParser,
  },
  {
    key: "pagalbinis_naud_pl",
    parser: areaParser,
  },
  { key: "arim_pl", parser: areaParser },
  { key: "sodu_pl", parser: areaParser },
  { key: "misko_pl", parser: areaParser },
  { key: "kelio_pl", parser: areaParser },
  { key: "vand_pl", parser: areaParser },
  { key: "pelk_pl", parser: areaParser },
  { key: "pazeist_pl", parser: areaParser },
  { key: "nenaud_pl", parser: areaParser },
];
