import { getRawTransactionExcel } from "../api/api";

export const downloadTransactionsExcel = async (
  transactionIds: string[],
  propertyId: string
) => {
  const response = await getRawTransactionExcel(
    transactionIds.join(","),
    propertyId
  );

  const filename = `sandoriai_citynow_${new Date().toISOString()}.xlsx`;

  // Create a Blob from the PDF Stream
  const file = new Blob([response.data], { type: "application/vnd.ms-excel" });
  // Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  // Create a temp <a> tag to download file
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", filename); // Set file name
  document.body.appendChild(fileLink);

  fileLink.click();

  // Clean up and remove the link
  fileLink.parentNode?.removeChild(fileLink);
};
