import React from "react";
import { PropertyLocation } from "./PropertyLocation";
import { useQuery } from "react-query";
import { getPropertyInfra } from "../../api/api";
import { useProperty } from "../../hooks/property.hooks";
import { PropertyDistances } from "./distances/PropertyDistances";
import { CircularProgress } from "@mui/material";

interface PropertyLocationReportProps {
  propertyId: string;
}

export const PropertyLocationReport: React.FC<PropertyLocationReportProps> = ({
  propertyId,
}) => {
  const { data, isLoading } = useQuery(["property_infra", propertyId], () =>
    getPropertyInfra(propertyId)
  );

  const { data: property } = useProperty(propertyId);

  return (
    <div>
      <PropertyLocation propertyId={propertyId} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {!!data && !!property && (
            <PropertyDistances infras={data} property={property} />
          )}
        </>
      )}
    </div>
  );
};
