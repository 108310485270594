import React from "react";
import { AnyTransaction } from "./tableTransaction.utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import { TransactionsScrollContext } from "./TransactionsScrollContext";

interface TransactionScrollProps {
  transaction: AnyTransaction;
  index: number;
  count: number;
}

export const TransactionScroll: React.FC<TransactionScrollProps> = ({
  transaction,
  index,
  count,
}) => {
  const { onNextPress, onPrevPress, selectedTransactions } = React.useContext(
    TransactionsScrollContext
  );

  const hasScroll = count > 1;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: hasScroll ? "space-between" : "center",
      }}
    >
      {hasScroll && (
        <IconButton onClick={() => onPrevPress?.(index)}>
          <ArrowBackIosIcon />
        </IconButton>
      )}
      <div style={{ fontWeight: 500 }}>ID {transaction.id}</div>
      {hasScroll && !!selectedTransactions && (
        <div>{`${selectedTransactions[index] + 1}/${count}`}</div>
      )}
      {hasScroll && (
        <IconButton onClick={() => onNextPress?.(index)}>
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </div>
  );
};
