import React from "react";
import { View, TextInput } from "react-native";
import colors from "../../styles/colors";
import SearchIcon from "../../assets/icons/search.svg";
import { useForwardRef } from "../../hooks/ref.hooks";
import { IconButton } from "../buttons/IconButton";
import { CloseIcon } from "../icons/CloseIcon";

interface SearchBoxInputProps {
  input: string;
  onInputChange: (text: string) => void;
}

export const SearchBoxInput = React.forwardRef<TextInput, SearchBoxInputProps>(
  ({ input, onInputChange }, ref) => {
    const inputRef = useForwardRef(ref);

    return (
      <View
        style={{
          flexDirection: "row",
          height: 43,
          borderRadius: 5,
          borderWidth: 1,
          paddingLeft: 15,
          borderColor: colors.lightgrey,
          marginBottom: 20,
          marginTop: 10,
          alignItems: "center",
        }}
      >
        <SearchIcon />
        <TextInput
          ref={inputRef}
          style={{ flex: 1, marginHorizontal: 10 }}
          placeholder="Įveskite vietos pavadinimą"
          value={input}
          onChangeText={onInputChange}
          autoCorrect={false}
        />
        <IconButton onPress={() => onInputChange("")}>
          <CloseIcon />
        </IconButton>
      </View>
    );
  }
);
