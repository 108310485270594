import React from "react";
import { View } from "react-native";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";

interface BadgeProps {
  count: number;
  size?: "normal" | "big";
  fontSize?: number;
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const textStyle =
    props.size === "big"
      ? { fontSize: 15 }
      : props.fontSize !== undefined
      ? { fontSize: props.fontSize }
      : undefined;
  return (
    <View
      style={{
        paddingHorizontal: 5,
        paddingVertical: 2,
        borderRadius: 20,
        backgroundColor: colors.red,
        minWidth: props.size === "big" ? 26 : undefined,
        alignItems: "center",
      }}
    >
      <Typography variant="badge" textStyle={textStyle}>
        {props.count > 99 ? "99+" : props.count}
      </Typography>
    </View>
  );
};
