import { MessageType } from "../../types/api.types";
import FakedIcon from "../../assets/icons/messageTypes/faked.svg";
import WrongDataIcon from "../../assets/icons/messageTypes/wrongData.svg";
import InfoIcon from "../../assets/icons/messageTypes/info.svg";

export const getMessageTypeTitle = (type: MessageType) => {
  switch (type) {
    case MessageType.FAKED:
      return "Netikras/fiktyvus sandoris";
    case MessageType.WRONG_DATA:
      return "Neteisingi duomenys";
    case MessageType.INFO:
      return "Kiti duomenys";
  }
};

export const getMessageTypeIcon = (type: MessageType) => {
  switch (type) {
    case MessageType.FAKED:
      return FakedIcon;
    case MessageType.WRONG_DATA:
      return WrongDataIcon;
    case MessageType.INFO:
      return InfoIcon;
  }
};

export const getMessageTypeDescription = (type: MessageType) => {
  switch (type) {
    case MessageType.FAKED:
      return "Sandoris pažymėtas kaip netikras/fiktyvus sandoris.";
    case MessageType.WRONG_DATA:
      return "Sandoris pažymėtas kaip turi netikslią informaciją ir/ar dubliuojasi.";
    case MessageType.INFO:
      return "Sandoris turi papildomų informacinių pranešimų.";
  }
};
