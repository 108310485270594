import { enqueueSnackbar } from "notistack";

const getText = (message: string, caption?: string) => {
  return !!caption ? message + "\n" + caption : message;
};

const style: React.CSSProperties = { whiteSpace: "pre-line" };

export function showToastError(
  message: string = "Įvyko klaida",
  caption?: string
) {
  const text = getText(message, caption);
  enqueueSnackbar(text, {
    variant: "error",
    style,
  });
}

export function showToastSuccess(message: string, caption?: string) {
  const text = getText(message, caption);
  enqueueSnackbar(text, {
    variant: "success",
    style,
  });
}

export function showToastWarning(message: string, caption?: string) {
  const text = getText(message, caption);
  enqueueSnackbar(text, {
    variant: "warning",
    style,
  });
}
