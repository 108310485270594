import React, { useState } from "react";
import { useOrganization } from "../../hooks/organization.hooks";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppraiser, useLogout } from "../../hooks/auth.hooks";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuLinkComponent } from "./MenuLinkComponent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppraiserRole } from "../../types/appraiser.types";
import { setClipboard } from "common/clipboard/clipboard";
import { showToastSuccess } from "common/toast/toast";
import LinkIcon from "@mui/icons-material/Link";
import SettingsIcon from "@mui/icons-material/Settings";
import { getLeadLink } from "../../helpers/url.helpers";
import { useQuery } from "react-query";
import { getAppraiserOrganizationLink, getOrganization } from "../../api/api";
import { StyledImage } from "common/components/images/StyledImage";

interface LayoutProps {
  children?: React.ReactNode;
  noBackButton?: boolean;
}

const headerHeight = "60px";

export const Layout: React.FC<LayoutProps> = ({ children, noBackButton }) => {
  const { data: organization } = useOrganization();
  const { data: appraiser } = useAppraiser();

  const { logout } = useLogout();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { data: organizationLink } = useQuery(
    ["organization_link", appraiser?._id],
    () => getAppraiserOrganizationLink()
  );

  return (
    <div>
      <Box
        sx={{
          height: headerHeight,
          top: 0,
          width: "100%",
          boxShadow: 3,
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          zIndex: 1000,
          backgroundColor: colors.white,
          alignItems: "center",
          px: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {!noBackButton && (
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography variant="h2">{organization?.name}</Typography>
          </Link>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>
          <MenuLinkComponent
            to="https://docs.valuations.lt"
            target="_blank"
            label="Apie produktą"
          />
          <MenuLinkComponent to="/" label="Peržiūrėti vertinimus" />
          {appraiser?.role === AppraiserRole.ADMIN && (
            <MenuLinkComponent to="/appraisers" label="Valdyti organizaciją" />
          )}
          <IconButton
            size="small"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            style={{ marginLeft: "15px" }}
          >
            {!!appraiser?.profileImageUrl ? (
              <StyledImage
                borderRadius={40}
                width={40}
                height={40}
                showLoader
                imageProps={{
                  source: { uri: appraiser.profileImageUrl },
                }}
              />
            ) : (
              <AccountCircleIcon fontSize="large" />
            )}
          </IconButton>
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e: any) => {
          if (!!e.stopPropagation) e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        <ListItemText sx={{ px: "16px", mb: "8px" }}>
          {appraiser?.email}
        </ListItemText>
        <Divider />
        {["org", "appr"].map((linkType) => (
          <MenuItem
            key={linkType}
            sx={{ mt: "8px" }}
            onClick={() => {
              if (!appraiser || !organizationLink) {
                return;
              }
              setClipboard(
                linkType === "appr"
                  ? getLeadLink({ appraiserId: appraiser._id })
                  : getLeadLink({ appraiserId: organizationLink.appraiserId })
              );
              showToastSuccess("Nuoroda nukopijuota į iškarpinę");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText>
              {linkType === "appr" ? "Mano" : "Įmonės"} užklausos nuoroda
            </ListItemText>
          </MenuItem>
        ))}
        <Link
          to="/account-settings"
          style={{ textDecoration: "none", color: "unset" }}
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem sx={{ mt: "8px" }}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Profilio nustatymai</ListItemText>
          </MenuItem>
        </Link>
        <MenuItem sx={{ mt: "8px" }} onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Atsijungti</ListItemText>
        </MenuItem>
      </Menu>
      {children}
    </div>
  );
};
