import React, { useContext } from "react";
import { ContentCard } from "../../components/ContentCard";
import { Button, MenuItem, Select } from "@mui/material";
import {
  DesignationTypesForValuation,
  DesignationTypeTranslations,
} from "../../helpers/comparableFields.helpers";
import { DesignationType } from "common/types/comparableFields.types";
import { LinkForButton } from "../../components/links/LinkForButton";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { PropertyCreationContext } from "./PropertyCreationProvider";
interface PropertyTypeSelectRouteProps {}

export const PropertyTypeSelectRoute: React.FC<
  PropertyTypeSelectRouteProps
> = () => {
  const query = useRouteQuery();
  const designationType = query.get("type") as DesignationType;
  const propertyId = query.get("propertyId");

  const [propertyType, setPropertyType] = React.useState<DesignationType>(
    designationType ?? DesignationType.ResidentialApartments
  );

  const designationTypeOptions = DesignationTypesForValuation.map((type) => ({
    value: type,
    label: DesignationTypeTranslations[type],
  }));

  const { fromLead } = useContext(PropertyCreationContext);
  const { getUpdatedQueryParam } = useUpdateQueryParam();

  return (
    <div>
      <ContentCard title="Pasirinkti turto paskirtį">
        <div
          style={{
            maxWidth: "600px",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>Turto paskirtis</div>
            <Select
              value={propertyType ?? ""}
              style={{ width: "300px" }}
              onChange={(e) =>
                setPropertyType(e.target.value as DesignationType)
              }
            >
              {designationTypeOptions.map((designationType) => (
                <MenuItem
                  key={designationType.value}
                  value={designationType.value}
                >
                  {designationType.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <LinkForButton
            to={
              !!fromLead
                ? {
                    pathname: "../details",
                    search: getUpdatedQueryParam([
                      { paramName: "type", newValue: propertyType },
                    ]).search,
                  }
                : `/properties/${
                    !!propertyId ? propertyId : "create"
                  }/details?type=${propertyType}`
            }
          >
            <Button variant="contained" style={{ width: "250px" }}>
              Tęsti
            </Button>
          </LinkForButton>
        </div>
      </ContentCard>
    </div>
  );
};
