import React, { useContext } from "react";
import { DatePickerProps, DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/lt";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";

interface LocalizedDatepickerProps {}

export const LocalizedDatepicker: React.FC<
  LocalizedDatepickerProps & DatePickerProps<Dayjs>
> = ({ ...props }) => {
  const { maxInputWidth, disabled } = useContext(FormSettingsContext);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="lt">
      <DatePicker
        {...props}
        value={props.value ?? null}
        disabled={disabled}
        sx={{
          ...props.sx,
          maxWidth: maxInputWidth,
        }}
      />
    </LocalizationProvider>
  );
};
