import { useMemo } from "react";
import LockUnlockedIcon from "../assets/icons/lockUnlocked.svg";
import LockLockedIcon from "../assets/icons/lockLocked.svg";
import colors from "common/styles/colors";

export type IconType = "full" | "notFull" | "inCart";

export const mainPropertyBGColor = colors.black;
export const comparablePropertyBGColor = colors.purple2;

export function usePropertyIconsProps() {
  const lockedIcon = useMemo(() => <LockLockedIcon />, []);
  const unlockedIcon = useMemo(() => <LockUnlockedIcon />, []);

  const getIcon = (type: IconType) => {
    switch (type) {
      case "notFull":
        return lockedIcon;
      case "full":
        return unlockedIcon;
      default:
        return undefined;
    }
  };

  const getIconBGColor = (type: IconType) => {
    switch (type) {
      case "notFull":
        return colors.white;
      case "full":
        return colors.purple3;
      default:
        return comparablePropertyBGColor;
    }
  };

  return { getIcon, getIconBGColor };
}
