import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { SimpleDialog } from "../../../components/dialogs/SimpleDialog";
import {
  DiscountCode,
  DiscountCodeCreateRequest,
} from "../../../types/api.types";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormDatepicker } from "../../../components/form/FormDatepicker";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQueryClient } from "react-query";
import { createDiscountCode, updateDiscountCode } from "../../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import dayjs from "dayjs";
import { getDayjsDate } from "common/helpers/dateStringHelpers";
import { getErrorMessage } from "common/helpers/error.helpers";

interface DiscountCodeEditProps {
  discountCode?: DiscountCode;
}

const validationSchema: Yup.ObjectSchema<DiscountCodeCreateRequest> =
  Yup.object({
    code: Yup.string().required(),
    discount: Yup.number()
      .required()
      .min(0, "Nuolaida negali būti neigiamas skaičius")
      .max(100, "Nuolaida negali būti didesnė nei 100%"),
    validFrom: Yup.date().required(),
    validUntil: Yup.date()
      .required()
      .min(Yup.ref("validFrom"), "Data iki turi būti vėlesnė nei data nuo"),
  });

export const DiscountCodeEdit: React.FC<DiscountCodeEditProps> = ({
  discountCode,
}) => {
  const [open, setOpen] = useState(false);

  const defaultValues = {
    ...discountCode,
    validFrom: getDayjsDate(discountCode?.validFrom),
    validUntil: getDayjsDate(discountCode?.validUntil),
  };

  const methods = useForm<DiscountCodeCreateRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const queryClient = useQueryClient();

  const onSuccess = () => {
    setOpen(false);
    if (!discountCode) methods.reset(defaultValues);
    queryClient.invalidateQueries("discountCodes");
  };

  const { mutate: createDiscount } = useMutation(createDiscountCode, {
    onSuccess: () => {
      onSuccess();
      showToastSuccess("Nuolaidos kodas sėkmingai sukurtas");
    },
    onError: (err) => {
      showToastError(getErrorMessage(err));
    },
  });
  const { mutate: updateDiscount } = useMutation(updateDiscountCode, {
    onSuccess: () => {
      onSuccess();
      showToastSuccess("Nuolaidos kodas sėkmingai atnaujintas");
    },
    onError: (err) => {
      showToastError();
    },
  });

  const openDialog = () => {
    setOpen(true);
  };

  return (
    <div>
      {!!discountCode ? (
        <IconButton onClick={openDialog}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={openDialog}
        >
          Sukurti kodą
        </Button>
      )}

      <SimpleDialog
        title={
          !!discountCode ? "Koreguoti nuolaidos kodą" : "Sukurti nuolaidos kodą"
        }
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={methods.handleSubmit((data) => {
          const validFrom = dayjs(data.validFrom).startOf("day").toDate();
          const validUntil = dayjs(data.validUntil).endOf("day").toDate();
          data.validFrom = validFrom;
          data.validUntil = validUntil;

          if (!!discountCode) {
            updateDiscount({ id: discountCode._id, ...data });
          } else {
            createDiscount(data);
          }
        })}
      >
        <FormProvider {...methods}>
          <form>
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ flex: 1 }}>
                <FormInput
                  label="Nuolaidos pavadinimas"
                  name="code"
                  variant="text"
                  maxWidth={400}
                />
              </div>
              <div style={{ flex: 1 }}>
                <FormInput
                  label="Nuolaidos dydis"
                  name="discount"
                  variant="number"
                  units="%"
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
              <div style={{ flex: 1 }}>
                <FormDatepicker label="Galioja nuo" name="validFrom" />
              </div>
              <div style={{ flex: 1 }}>
                <FormDatepicker label="Galioja iki" name="validUntil" />
              </div>
            </div>
          </form>
        </FormProvider>
      </SimpleDialog>
    </div>
  );
};
