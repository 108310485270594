import React from "react";
import { PropertyAssessmentStatus } from "../types/api.types";
import { usePropertiesTotal } from "../hooks/property.hooks";
import { Badge, Tab } from "@mui/material";

interface PropertyListTabProps {
  value: PropertyAssessmentStatus;
  label: string;
  onClick?: () => void;
}

export const PropertyListTab: React.FC<PropertyListTabProps> = ({
  value,
  label,
  onClick,
}) => {
  const { data } = usePropertiesTotal(value);

  return (
    <Badge
      badgeContent={value === PropertyAssessmentStatus.Final ? 0 : data?.total}
      color="primary"
      style={{ marginTop: "20px" }}
    >
      <Tab
        value={value}
        label={label}
        onClick={onClick}
        style={{
          overflow: "visible",
        }}
      />
    </Badge>
  );
};
