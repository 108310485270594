import {
  ComparableTransaction,
  ComparableTransactionFull,
} from "common/types/common.types";
import React, { useState } from "react";
import {
  getComparableAddress,
  getComparableImages,
} from "common/helpers/comparables.helpers";
import { Typography } from "common/components/Typography";
import { Divider } from "common/components/listItems/Divider";
import { getListString } from "common/helpers/common.helpers";
import { formatPrice } from "common/helpers/text.helpers";
import colors from "common/styles/colors";
import { RelatedRETag } from "./RelatedRETag";
import { AppraiserProperty } from "../types/appraiser.types";
import { ViewOnMapButton } from "./properties/ViewOnMapButton";
import { PropertyImageGallery } from "./imageGallery/PropertyImageGallery";
import { getReservationText, getYearText } from "../helpers/comparable.helpers";
import { TransactionModal } from "./transactions/TransactionModal";
import { getFinishingLabel } from "./transactions/helpers";
import {
  PropertyInfoCompare,
  priceComparableFontSize,
} from "./PropertyInfoCompare";
import { Text } from "react-native";
import { getPropertyListString } from "../helpers/property.helpers";

interface PropertyInfoProps {
  property?: AppraiserProperty;
  comparable?: ComparableTransactionFull;
  onViewOnMapClick?: () => void;
  distance?: number;
}

export const PropertyInfo: React.FC<PropertyInfoProps> = ({
  property,
  comparable,
  onViewOnMapClick,
  distance,
}) => {
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);

  if (!property && !comparable) {
    return null;
  }

  const caption = !!property ? "Vertinamas objektas" : "Palyginimasis objektas";

  const address =
    property?.address ?? (!!comparable ? getComparableAddress(comparable) : "");

  const images = property?.images ?? getComparableImages(comparable);

  const priceText = !!property
    ? "Kaina vertinama"
    : getListString([
        "Parduotas",
        comparable?.sold_date,
        formatPrice(comparable?.sold_price),
        `(${formatPrice(comparable?.sold_price_area)}/m²)`,
      ]);

  const area = property?.area ?? comparable?.area;
  const rooms = property?.rooms ?? comparable?.rooms;
  const floor = property?.floorNr ?? comparable?.floor;
  const floorTotal = property?.floorCount ?? comparable?.floor_total;
  const constructionYear = property?.year ?? comparable?.construction_year;
  const renovationYear = !!property
    ? property.renovationYear
    : // : comparable?.reconstruction_year;
      undefined;

  const captionString = getPropertyListString({
    area,
    rooms,
    floorNr: floor,
    floorCount: floorTotal,
    year: constructionYear,
    renovationYear,
    energyClass: property?.energyClass ?? comparable?.energy_class,
    finishing: property?.finishing,
  });
  // const projectUnit = comparable?.matched_project_unit;
  // const matchedProject = projectUnit ?? comparable?.matched_project;

  const getMatchedText = () => {
    let matchedTransactions = [];
    if (comparable?.matched_listing) {
      matchedTransactions.push("skelbimu");
    }
    // if (matchedProject) {
    //   matchedTransactions.push("projektu");
    // }
    if (!matchedTransactions.length) {
      return undefined;
    }
    return `Sandoris identifikuotas su ${matchedTransactions.join(" ir ")}`;
  };
  const matchedText = getMatchedText();

  return (
    <div style={{ display: "flex", flexDirection: "column", width: 450 }}>
      <Typography variant="caption" textStyle={{ fontSize: 16 }}>
        {caption}
      </Typography>
      <Typography variant="h4" textStyle={{ fontSize: 20, marginVertical: 4 }}>
        {address}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <PropertyImageGallery images={images} headerText={address} />
      <div style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
        <Typography textStyle={{ fontSize: 16, marginTop: 20 }}>
          {priceText}
        </Typography>
        {!!matchedText && (
          <Typography
            variant="caption"
            textStyle={{ fontSize: 16, color: colors.green }}
          >
            {matchedText}
          </Typography>
        )}
        {/* {!!matchedProject && (
          <Text style={{ marginTop: 10 }}>
            <Typography textStyle={{ fontSize: 16 }}>
              {matchedProject.project_name}
            </Typography>
            {!!projectUnit && (
              <Typography textStyle={{ fontSize: 16, color: colors.purple2 }}>
                {` ${getReservationText(projectUnit)}`}
              </Typography>
            )}
          </Text>
        )} */}
        <Typography variant="caption" textStyle={{ fontSize: 16 }}>
          {captionString}
          {comparable && (
            <>
              {" · "}
              <Typography
                variant="h2"
                textStyle={{ fontSize: 16, color: colors.purple2 }}
                onPress={() => setIsTransactionModalOpen(true)}
              >
                {"Daugiau"}
              </Typography>
            </>
          )}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            flexWrap: "wrap",
          }}
        >
          {comparable?.related_re?.map((related, index) => (
            <RelatedRETag key={index} re={related} full />
          ))}
        </div>
        <ViewOnMapButton onViewOnMapClick={onViewOnMapClick} />
      </div>
      {!!comparable && <PropertyInfoCompare comparable={comparable} />}
      {distance !== undefined && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 0",
          }}
        >
          <Typography textStyle={{ fontSize: priceComparableFontSize }}>
            Atstumas iki objekto
          </Typography>
          <Typography textStyle={{ fontSize: priceComparableFontSize }}>
            {`${distance} m`}
          </Typography>
        </div>
      )}
      {!!comparable && (
        <TransactionModal
          open={isTransactionModalOpen}
          id={comparable.id}
          onClose={() => setIsTransactionModalOpen(false)}
          prefetch
        />
      )}
    </div>
  );
};
