import React from "react";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

export const MUILoadingButton: React.FC<LoadingButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <LoadingButton
      loadingPosition="start"
      startIcon={<></>}
      variant="contained"
      {...props}
    >
      {children}
    </LoadingButton>
  );
};
