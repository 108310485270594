import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getComparableMessages, getMessagesStatus } from "../../api/api";

export function useComparableMessages() {
  const { comparableId } = useParams();

  return useQuery(["comparable_messages", comparableId ?? ""], () =>
    getComparableMessages(comparableId ?? "")
  );
}

export function useMessagesStatus() {
  return useQuery("messages_status", () => getMessagesStatus(), {
    staleTime: 5000,
  });
}

export function useMessagesStatusInvalidate() {
  const queryClient = useQueryClient();

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: "messages_status" });
  };

  return { invalidate };
}
