import React from "react";
import { AppraiserRole } from "../../types/appraiser.types";
import { useAppraiser } from "../../hooks/auth.hooks";

interface RoleRouteProps {
  allowedRoles: AppraiserRole[];
  children: React.ReactNode;
}

export const RoleRoute: React.FC<RoleRouteProps> = ({
  allowedRoles,
  children,
}) => {
  const { data: appraiser } = useAppraiser();

  if (allowedRoles.some((role) => role === appraiser?.role)) {
    return <>{children}</>;
  }

  return <div>Neturite teisių šio puslapio peržiūrai</div>;
};
