export const getGoogleMapsBoundsFromCoords = (
  coords: { lat: number; lng: number }[],
  extensionFactor: number = 0.001 // Adjust this factor to control the extension
) => {
  const bounds = new google.maps.LatLngBounds();

  // Extend the bounds to include each coordinate
  coords.forEach((coord) => {
    bounds.extend(coord);
  });

  if (coords.length > 0) {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    // Check if the bounds are already extended
    const latDiff = ne.lat() - sw.lat();
    const lngDiff = ne.lng() - sw.lng();

    if (latDiff < extensionFactor * 2 || lngDiff < extensionFactor * 2) {
      // Calculate new extended bounds
      const extendedSW = new google.maps.LatLng(
        sw.lat() - extensionFactor,
        sw.lng() - extensionFactor
      );
      const extendedNE = new google.maps.LatLng(
        ne.lat() + extensionFactor,
        ne.lng() + extensionFactor
      );

      // Update the bounds to the new extended bounds
      bounds.extend(extendedSW);
      bounds.extend(extendedNE);
    }
  }

  return bounds;
};
