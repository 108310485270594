import colors from "common/styles/colors";
import React from "react";

interface ComparableNumberProps {
  compNumber: number;
}

export const ComparableNumber: React.FC<ComparableNumberProps> = ({
  compNumber,
}) => {
  const size = "20px";
  return (
    <div
      style={{
        minWidth: size,
        maxWidth: size,
        minHeight: size,
        maxHeight: size,
        borderRadius: "10px",
        marginRight: "10px",
        fontSize: "14px",
        color: colors.white,
        backgroundColor: colors.purple2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {compNumber}
    </div>
  );
};
