import React, { useState, useEffect } from "react";
import { AppraiserWithLimits } from "../../types/appraiser.types";
import {
  formatMonthlyLimitPrice,
  formatPrice,
} from "common/helpers/text.helpers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "common/components/Typography";
import { InputField } from "common/components/form/InputField";
import { Button } from "common/components/buttons/Button";
import { useMutation } from "react-query";
import { setAppraiserMonthlyCreditLimit } from "../../api/api";
import { useAppraisersInOrganizationCredits } from "../../hooks/auth.hooks";
import { MonthlyLimitDialog } from "./MonthlyLimitDialog";
import { showToastError } from "common/toast/toast";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";

interface PatymentRowProps {
  appraiser: AppraiserWithLimits;
}

export const PaymentRow: React.FC<PatymentRowProps> = ({ appraiser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { refetch } = useAppraisersInOrganizationCredits();

  const { mutate, isLoading } = useMutation(
    async (data: {
      monthlyCreditLimit: number | null;
      appraiserId: string;
    }) => {
      await setAppraiserMonthlyCreditLimit(
        data.appraiserId,
        data.monthlyCreditLimit
      );
      await refetch();
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
      },
      onError: () => {
        showToastError();
      },
    }
  );

  return (
    <SimpleTableRow>
      <SimpleTableElement isFirstElement>{appraiser.email}</SimpleTableElement>
      <SimpleTableElement>
        {formatMonthlyLimitPrice(appraiser.monthlyCreditLimit)}
      </SimpleTableElement>
      <SimpleTableElement>
        {formatMonthlyLimitPrice(appraiser.totalCreditsUsed)}
      </SimpleTableElement>
      <SimpleTableElement>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <EditIcon />
        </IconButton>
      </SimpleTableElement>
      <MonthlyLimitDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contentText={`Pakeiskite vertintojo ${appraiser.email} limitą`}
        onSubmit={(value) => {
          mutate({
            appraiserId: appraiser._id,
            monthlyCreditLimit: value === undefined ? null : value,
          });
        }}
        initialLimit={appraiser.monthlyCreditLimit}
        isLoading={isLoading}
      />
    </SimpleTableRow>
  );
};
