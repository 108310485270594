import { useFormFieldErrors } from "common/hooks/form.hooks";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlWrapper } from "./FormControlWrapper";
import { SliderWithInput } from "./SliderWithInput";

interface FormSliderWithInputProps {
  label?: string;
  name: string;
  isRequired?: boolean;
  min: number;
  max: number;
  onChange?: (value: number) => void;
}

export const FormSliderWithInput: React.FC<FormSliderWithInputProps> = ({
  name,
  label,
  min,
  max,
  ...props
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlWrapper
          label={label}
          errorText={errorText}
          isRequired={false}
        >
          <SliderWithInput
            value={value}
            onChange={(value) => {
              props.onChange?.(value as number);
              onChange(value);
            }}
            min={min}
            max={max}
          />
        </FormControlWrapper>
      )}
    ></Controller>
  );
};
