import React from "react";
import { FormFieldWrapper } from "../FormFieldWrapper";
import { FormInput } from "./FormInput";
import { useFormFieldErrors } from "../../../hooks/form.hooks";
interface FormFractionInputProps {
  name1: string;
  name2: string;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
}

export const FormFractionInput: React.FC<FormFractionInputProps> = ({
  name1,
  name2,
  label,
  isRequired,
  disabled,
}) => {
  const { errorText: errorText1 } = useFormFieldErrors(name1, label);
  const { errorText: errorText2 } = useFormFieldErrors(name2, label);

  return (
    <FormFieldWrapper
      label={label}
      error={errorText1 || errorText2}
      isRequired={isRequired}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90px",
          }}
        >
          <FormInput
            name="assetPart1"
            variant="number"
            disableErrors
            disabled={disabled}
          />
        </div>
        <span style={{ margin: "0 20px" }}> / </span>
        <div
          style={{
            width: "90px",
          }}
        >
          <FormInput
            name="assetPart2"
            variant="number"
            disableErrors
            disabled={disabled}
          />
        </div>
      </div>
    </FormFieldWrapper>
  );
};
