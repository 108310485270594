import { calculateFieldSum, round } from "common/helpers/common.helpers";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import {
  Adjustments,
  AppraiserComparable,
  AppraiserComparableTransaction,
  AppraiserPricePredictions,
  Comparable,
  ComparableFull,
  ProjectUnit,
  RelatedRE,
} from "common/types/common.types";

export const comparableToAppraiserComparable = (
  comparable: ComparableFull
): AppraiserComparable => {
  return {
    comparable_transaction: {
      id: comparable.comparable_transaction.id,
      sold_price: comparable.comparable_transaction.sold_price,
      area: comparable.comparable_transaction.area,
    },
    similarities: {
      joint_similarity: comparable.similarities.joint_similarity,
    },
    adjustments: comparable.adjustments ?? {
      price_increase_area: 0,
      adjusted_price_area: 0,
      adjusted_fields: [],
    },
  };
};

export const calcSoldPrice = (
  adjustments: Adjustments | undefined,
  comparable: AppraiserComparableTransaction
) => {
  if (!adjustments) {
    return comparable.sold_price;
  }
  return (
    comparable.sold_price -
    calculateFieldSum(adjustments.additional_re ?? [], (obj) => obj.price ?? 0)
  );
};

export const recalcAdjustments = (
  adjustments: Adjustments,
  comparable: AppraiserComparableTransaction
): Adjustments => {
  const soldPrice = calcSoldPrice(adjustments, comparable);
  const soldPriceArea = soldPrice / comparable.area;

  const adjustedFields = adjustments.adjusted_fields.map((adjustedField) => ({
    ...adjustedField,
    price: soldPrice * adjustedField.percent,
    price_area: soldPriceArea * adjustedField.percent,
  }));
  const priceIncreaseArea =
    calculateFieldSum(adjustedFields, (obj) => obj.price_area) +
    calculateFieldSum(
      adjustments.additional_fields ?? [],
      (field) => field.percent * soldPriceArea
    );
  const adjustedPriceArea = soldPriceArea + priceIncreaseArea;

  const newAdjustments: Adjustments = {
    ...adjustments,
    adjusted_fields: adjustedFields,
    price_increase_area: priceIncreaseArea,
    adjusted_price_area: adjustedPriceArea,
  };

  return newAdjustments;
};

export const recalcWeights = (
  comparables: AppraiserComparable[],
  comparablesToSkip?: string[]
) => {
  const compsToSkip: string[] = [...(comparablesToSkip ?? [])];

  for (const comp of comparables) {
    if (comp.comparable_weight === undefined) {
      compsToSkip.push(comp.comparable_transaction.id);
      comp.comparable_weight = 1 / comparables.length;
    }
  }

  let weightSum = 0;
  let compToSkipWeight = 0;
  for (const comp of comparables) {
    if (compsToSkip?.includes(comp.comparable_transaction.id)) {
      compToSkipWeight = comp.comparable_weight ?? 0;
      continue;
    }
    weightSum += comp.comparable_weight ?? 0;
  }

  for (const comp of comparables) {
    if (
      comp.comparable_weight === undefined ||
      compsToSkip?.includes(comp.comparable_transaction.id)
    ) {
      continue;
    }
    comp.comparable_weight *= (1 - compToSkipWeight) / weightSum;
  }
};

export const recalcPredictions = (
  pricePredictions: AppraiserPricePredictions
): AppraiserPricePredictions => {
  const comparables = pricePredictions.comparables.map((comparable) => {
    return {
      ...comparable,
      adjustments: recalcAdjustments(
        comparable.adjustments,
        comparable.comparable_transaction
      ),
    };
  });

  recalcWeights(comparables);

  const averagePrice = comparables.length
    ? calculateFieldSum(
        comparables,
        (comp) =>
          comp.adjustments.adjusted_price_area * (comp.comparable_weight ?? 0)
      )
    : 0;

  return {
    ...pricePredictions,
    predicted_price: {
      average_price_area: averagePrice,
      min: averagePrice,
      max: averagePrice,
    },
    comparables,
  };
};

export const getYearText = (year: number, renovationYear?: number | null) =>
  `${year}${renovationYear ? `/${renovationYear}` : ""}`;

export const getPercentage = (
  adjustedPriceArea: number,
  soldPriceArea: number
) => {
  const percentage = round((adjustedPriceArea / soldPriceArea - 1) * 100, 1);
  const percentageText = `${percentage > 0 ? "+" : ""}${percentage}%`;
  return { percentage, percentageText };
};

export function uniqueByType(relatedREs: RelatedRE[]): RelatedRE[] {
  return relatedREs.reduce((uniqueList, currentRE) => {
    if (!uniqueList.some((item) => item.type === currentRE.type)) {
      uniqueList.push(currentRE);
    }
    return uniqueList;
  }, [] as RelatedRE[]);
}

export function getReservationText(projectUnit: ProjectUnit) {
  return `(rezervacija ${getLithuanianDateString(projectUnit.sold_date)})`;
}

export function isComparableFull(
  obj: Comparable | ComparableFull
): obj is ComparableFull {
  return "sold_price" in obj.comparable_transaction;
}
