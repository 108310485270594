import React from "react";
import { View } from "react-native";
import { ListItem } from "../listItems/ListItem";
import { Typography } from "common/components/Typography";
import RadioChecked from "../../assets/icons/form/radioChecked.svg";
import RadioUnchecked from "../../assets/icons/form/radioUnchecked.svg";
import { Divider } from "../listItems/Divider";

export type SelectType<T> = {
  label: string;
  value: T;
};

interface RadioButtonGroupProps<T> {
  values: SelectType<T>[];
  onSelect?: (value: T) => void;
  selectedValue?: T;
}

export function RadioButtonGroup<T>(props: RadioButtonGroupProps<T>) {
  return (
    <View>
      {props.values.map((value, index) => (
        <React.Fragment key={`RadioButton${index}`}>
          <ListItem
            height={60}
            customIcon={
              value.value === props.selectedValue ? (
                <RadioChecked />
              ) : (
                <RadioUnchecked />
              )
            }
            onPress={() => {
              if (value.value === props.selectedValue) return;
              props.onSelect?.(value.value);
            }}
          >
            <Typography variant="caption" textStyle={{ fontSize: 16 }}>
              {value.label}
            </Typography>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </View>
  );
}
