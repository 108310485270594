import {
  StyledImage,
  StyledImageProps,
} from "common/components/images/StyledImage";
import React from "react";
import placeholderUrl from "../../assets/placeholders/propertyPlaceholder.png";

interface PropertyPlaceholderImageProps {
  uri?: string;
}

export const PropertyPlaceholderImage: React.FC<
  PropertyPlaceholderImageProps & Omit<StyledImageProps, "imageProps">
> = ({ uri, ...props }) => {
  return (
    <StyledImage
      {...props}
      key={uri ?? placeholderUrl}
      showLoader
      imageProps={{
        source: {
          uri: uri ?? placeholderUrl,
        },
      }}
    />
  );
};
