export const getLeadLink = (params: {
  appraiserId?: string;
  organizationId?: string;
  discountCode?: string;
}) => {
  const url = new URL(`${window.location.origin}/lead/landing`);
  if (params.appraiserId) {
    url.searchParams.set("appraiserId", params.appraiserId);
  } else if (params.organizationId) {
    url.searchParams.set("organizationId", params.organizationId);
  }
  if (params.discountCode) {
    url.searchParams.set("discountCode", params.discountCode);
  }
  return url.toString();
};
