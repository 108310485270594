import { round } from "common/helpers/common.helpers";
import colors from "common/styles/colors";
import React from "react";

interface LimitBarProps {
  percent: number;
  height?: string;
}

export const LimitBar: React.FC<LimitBarProps> = ({ percent, height }) => {
  const realHeight = height ?? "10px";
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: colors.greyborder,
        height: realHeight,
        position: "relative",
        borderRadius: realHeight,
      }}
    >
      <div
        style={{
          width: `${round(Math.min(percent, 1) * 100)}%`,
          position: "absolute",
          height: realHeight,
          borderRadius: realHeight,
          backgroundColor: colors.purple2,
        }}
      />
    </div>
  );
};
