export const modifyErrorText = (error: string, label?: string) => {
  const errorText =
    error.includes("required") || error.includes("cast from the value `NaN`")
      ? `${label} yra privalomas`
      : error;
  return errorText;
};

export const getMinErrorText = (label: string, min: number) => {
  return `${label} turi būti bent ${min}`;
};

export const getMaxErrorText = (label: string, max: number) => {
  return `${label} turi būti iki ${max}`;
};
