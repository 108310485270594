import React from "react";
import { labelStyle } from "../../styles/label.styles";
import { MonthDate } from "./types";
import { getMonthLabel } from "./helpers";
import ArrowDownwardIcon from "../../assets/icons/arrowDownward.svg";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";

interface DatesSelectButtonProps {
  selectedDates?: { start: MonthDate; end: MonthDate };
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const DatesSelectButton: React.FC<DatesSelectButtonProps> = ({
  selectedDates,
  onClick,
}) => {
  const label = !!selectedDates
    ? `${getMonthLabel(selectedDates.start)} - ${getMonthLabel(
        selectedDates.end
      )}`
    : "Pasirinkti datas";
  return (
    <div
      style={{
        ...labelStyle,
        justifyContent: "space-between",
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={onClick}
    >
      <Typography
        textStyle={{
          fontSize: 16,
          color: !!selectedDates ? colors.black : colors.grey,
        }}
      >
        {label}
      </Typography>
      <ArrowDownwardIcon />
    </div>
  );
};
