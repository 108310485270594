import React from "react";
import { ContentCard } from "../../../components/ContentCard";
import { useMutation, useQuery } from "react-query";
import { deleteDiscountCode, getDiscountCodes } from "../../../api/api";
import { useAppraiser } from "../../../hooks/auth.hooks";
import { SimpleTableComponent } from "../../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../../components/tables/simpleTable/SimpleTableHeader";
import { SimpleTableElement } from "../../../components/tables/simpleTable/SimpleTableElement";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { DiscountCodeEdit } from "./DiscountCodeEdit";
import { AppraiserRole } from "../../../types/appraiser.types";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton } from "@mui/material";
import { setClipboard } from "common/clipboard/clipboard";
import { getLeadLink } from "../../../helpers/url.helpers";
import { TagComponent } from "../../../components/tags/TagComponent";
import colors from "common/styles/colors";

interface DiscountCodesTableProps {}

export const DiscountCodesTable: React.FC<DiscountCodesTableProps> = () => {
  const { data: appraiser } = useAppraiser();
  const { data, isLoading, refetch } = useQuery(
    ["discountCodes", appraiser?._id],
    getDiscountCodes
  );

  const [confirmDeleteId, setConfirmDeleteId] = React.useState<
    string | undefined
  >(undefined);

  const { mutate: deleteCode, isLoading: isDeleting } = useMutation(
    deleteDiscountCode,
    {
      onSuccess: () => {
        showToastSuccess("Nuolaidos kodas sėkmingai ištrintas");
        refetch();
        setConfirmDeleteId(undefined);
      },
      onError: () => {
        showToastError();
      },
    }
  );

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!data) {
    return null;
  }

  return (
    <ContentCard title="Nuolaidų kodai">
      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader textAlign="left">
            Nuolaidos kodas
          </SimpleTableHeader>
          <SimpleTableHeader textAlign="left">
            Nuolaidos dydis
          </SimpleTableHeader>
          <SimpleTableHeader textAlign="left">Galioja nuo</SimpleTableHeader>
          <SimpleTableHeader textAlign="left">Galioja iki</SimpleTableHeader>
          <SimpleTableHeader textAlign="left">
            Panaudotas kartų
          </SimpleTableHeader>
          <SimpleTableHeader textAlign="left">Statusas</SimpleTableHeader>
          <SimpleTableHeader textAlign="right">
            Tvarkyti nuolaidą
          </SimpleTableHeader>
        </SimpleTableRow>
        {data?.map((discountCode) => (
          <SimpleTableRow key={discountCode._id}>
            <SimpleTableElement textAlign="left">
              {discountCode.code}
            </SimpleTableElement>
            <SimpleTableElement textAlign="left">
              {discountCode.discount}%
            </SimpleTableElement>
            <SimpleTableElement textAlign="left">
              {getLithuanianDateString(discountCode.validFrom)}
            </SimpleTableElement>
            <SimpleTableElement textAlign="left">
              {getLithuanianDateString(discountCode.validUntil)}
            </SimpleTableElement>
            <SimpleTableElement textAlign="left">
              {discountCode.usedCount}
            </SimpleTableElement>
            <SimpleTableElement>
              <div style={{ display: "flex" }}>
                {discountCode.isActive ? (
                  <TagComponent color={colors.green}>Aktyvus</TagComponent>
                ) : (
                  <TagComponent color={colors.grey}>Neaktyvus</TagComponent>
                )}
              </div>
            </SimpleTableElement>
            <SimpleTableElement>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <DiscountCodeEdit discountCode={discountCode} />

                <IconButton
                  onClick={() => {
                    setConfirmDeleteId(discountCode._id);
                  }}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={async () => {
                    if (!appraiser) return;
                    await setClipboard(
                      getLeadLink({
                        appraiserId: appraiser._id,
                        discountCode: discountCode.code,
                      })
                    );
                    showToastSuccess("Nuoroda nukopijuota į iškarpinę");
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </div>
            </SimpleTableElement>
          </SimpleTableRow>
        ))}
      </SimpleTableComponent>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {!data.length && <p>Nėra sukurtų nuolaidų kodų</p>}
        <DiscountCodeEdit />
      </div>
      <ConfirmationDialog
        isOpen={!!confirmDeleteId}
        onClose={() => setConfirmDeleteId(undefined)}
        onConfirm={() => {
          if (!confirmDeleteId) return;
          deleteCode(confirmDeleteId);
        }}
        title={`Ar tikrai norite ištrinti kodą "${
          data.find((code) => code._id === confirmDeleteId)?.code
        }"?`}
        isLoading={isDeleting}
        actionButtonText="IŠTRINTI"
        hideDoNotShowAgain
      />
    </ContentCard>
  );
};
