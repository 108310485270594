import React, { HTMLAttributeAnchorTarget } from "react";
import { Link, To } from "react-router-dom";
import { MenuClickLinkComponent } from "./MenuClickLinkComponent";

interface MenuLinkComponentProps {
  to: To;
  label: string;
  target?: HTMLAttributeAnchorTarget;
}

export const MenuLinkComponent: React.FC<MenuLinkComponentProps> = ({
  to,
  label,
  target,
}) => (
  <Link to={to} target={target} style={{ textDecoration: "none" }}>
    <MenuClickLinkComponent label={label} />
  </Link>
);
