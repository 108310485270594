import { Box, Divider } from "@mui/material";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import React from "react";
import { ContentCardTitle } from "./ContentCardTitle";

interface ContentCardProps {
  title: string | undefined;
  caption?: string;
  children?: React.ReactNode;
  borderRadius?: number;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}

export const contentCardSx = {
  borderRadius: "10px",
  backgroundColor: colors.white,
  padding: { xs: "20px", sm: "30px" },
};

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  children,
  caption,
  borderRadius,
  icon,
  style,
}) => {
  return (
    <Box
      sx={{
        pageBreakInside: "avoid",
        py: "10px",
        px: { xs: "10px", md: 0 },
      }}
    >
      <Box
        style={{
          ...style,
        }}
        sx={{
          ...contentCardSx,
          borderRadius: borderRadius ?? contentCardSx.borderRadius,
        }}
      >
        {!!title && (
          <ContentCardTitle title={title} caption={caption} icon={icon} />
        )}

        {children}
      </Box>
    </Box>
  );
};
