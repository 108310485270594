import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ImageInput } from "./ImageInput";
import { FormFieldWrapper } from "common/components/form/FormFieldWrapper";
import { useFormFieldErrors } from "common/hooks/form.hooks";

interface FormImageInputProps {
  label?: string;
  name: string;
}

export const FormImageInput: React.FC<FormImageInputProps> = ({
  label,
  name,
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <FormFieldWrapper label={label} error={errorText}>
            <ImageInput onImageUploaded={onChange} imageUrl={value} />
          </FormFieldWrapper>
        );
      }}
    />
  );
};
