import { FormControl, FormHelperText } from "@mui/material";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import React from "react";
import { Text } from "react-native";

interface FormControlWrapperProps {
  children: React.ReactNode;
  label?: string;
  errorText?: string;
  isRequired?: boolean;
}

export const FormControlWrapper: React.FC<FormControlWrapperProps> = ({
  children,
  label,
  errorText,
  isRequired,
}) => {
  return (
    <FormControl
      error={!!errorText}
      style={{ paddingTop: "10px", marginTop: "10px" }}
    >
      <Text style={{ marginBottom: "5px" }}>
        {!!label && <Typography variant="h3">{label}</Typography>}
        {isRequired && (
          <Typography variant="h3" textStyle={{ color: colors.red }}>
            *
          </Typography>
        )}
      </Text>
      {children}
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};
