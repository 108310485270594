import { Backdrop } from "@mui/material";
import React from "react";

interface TransactionsBackdropProps {
  open: boolean;
  children?: React.ReactNode;
  gap?: string;
}

export const TransactionsBackdrop: React.FC<TransactionsBackdropProps> = ({
  open,
  children,
  gap,
}) => {
  return (
    <Backdrop open={open} style={{ zIndex: 100000 }}>
      <div
        style={{
          width: "700px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: gap ?? "10px",
          paddingBottom: "35px",
          paddingTop: "60px",
          borderRadius: "10px",
        }}
      >
        {children}
      </div>
    </Backdrop>
  );
};
