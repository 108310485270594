import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPricePrediction,
  createAppraiserPropertyFields,
  getAppraiserPropertyFields,
  getPricePrediction,
} from "../api/api";
import {
  AdditionalPropertyField,
  Adjustments,
  AppraiserPricePredictions,
  Comparable,
  ComparableFull,
  ComparableTransaction,
  FinalPricePredictions,
} from "common/types/common.types";
import CommonConfig from "common/commonConfig";
import {
  calcSoldPrice,
  comparableToAppraiserComparable,
  isComparableFull,
  recalcPredictions,
} from "../helpers/comparable.helpers";
import { useComparables } from "./property.hooks";
import { AppraiserPropertyField } from "../types/api.types";
import { showToastError } from "common/toast/toast";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useCallback, useContext, useMemo } from "react";
import { TransactionsRCContext } from "../components/TransactionsRCProvider";
import { getPredictionsWithRealPrices } from "../helpers/pricePredictions.helpers";
import { getComparableHiddenAddress } from "common/helpers/comparables.helpers";
import { Mutex } from "async-mutex";

export const pricePredictionsQueryKey = "pricePredictions";

export function useFinalPricePredictions(propertyId: string) {
  const queryKey = ["pricePredictionsFinal", propertyId];
  return useQuery(queryKey, () =>
    getPricePrediction<FinalPricePredictions>(propertyId, true)
  );
}

export function useAppraiserPropertyFields() {
  const { data: appraiserPropertyFields } = useQuery(
    "appraiserPropertyFields",
    getAppraiserPropertyFields
  );
  const getAppraiserPropertyFieldName = (id: string) =>
    appraiserPropertyFields?.find((field) => field._id === id)?.name;
  return { appraiserPropertyFields, getAppraiserPropertyFieldName };
}

export function useAppraiserPropertyFieldsMutation() {
  const queryClient = useQueryClient();

  return useMutation(createAppraiserPropertyFields, {
    onSuccess: (propertyField) => {
      queryClient.setQueryData<AppraiserPropertyField[]>(
        "appraiserPropertyFields",
        (data) => {
          return [...(data ?? []), propertyField];
        }
      );
    },
    onError: (error) => {
      showToastError(getErrorMessage(error));
    },
  });
}

const mutexForComparableCart = new Mutex();

export function usePricePredictions(propertyId: string, readonly?: boolean) {
  const queryClient = useQueryClient();

  const queryKey = [pricePredictionsQueryKey, propertyId];
  const { data, isLoading: isLoadingPricePredictions } = useQuery(
    queryKey,
    () => getPricePrediction<AppraiserPricePredictions>(propertyId),
    {
      staleTime: 1000 * 60,
      enabled: !readonly,
    }
  );

  const { buyTransaction } = useContext(TransactionsRCContext);
  const { dataFull: comparablesFull } = useComparables();

  const findAppraiserComparable = (comparableId: string) => {
    return [
      ...(data?.comparables ?? []),
      ...(data?.comparables_adjusted ?? []),
    ].find(
      (comp) =>
        comp.comparable_transaction.id.toString() === comparableId.toString()
    );
  };

  const findComparableFull = (comparableId: string) => {
    return comparablesFull?.find(
      (comp) =>
        comp.comparable_transaction.id.toString() === comparableId.toString()
    );
  };

  const getComparableAdjustments = (comparable: Comparable) => {
    return (
      findAppraiserComparable(comparable.comparable_transaction.id)
        ?.adjustments ?? comparable.adjustments
    );
  };

  const getComparablesInCartIds = useCallback(() => {
    return data?.comparables.map((comp) =>
      comp.comparable_transaction.id.toString()
    );
  }, [data?.comparables]);

  const getComparableIndex = useCallback(
    (comp: Comparable) => {
      return (
        getComparablesInCartIds()?.findIndex(
          (x) => x == comp.comparable_transaction.id.toString()
        ) ?? 0
      );
    },
    [getComparablesInCartIds]
  );

  const isComparableInCart = useCallback(
    (comparableId: string) => {
      return getComparablesInCartIds()?.includes(comparableId.toString());
    },
    [getComparablesInCartIds]
  );

  const { mutate, mutateAsync, isLoading } = useMutation(
    (pricePrediction: AppraiserPricePredictions) =>
      addPricePrediction(propertyId, pricePrediction),
    {
      onMutate: async (pricePrediction) => {
        await queryClient.cancelQueries(queryKey);
        const previousPredictions =
          queryClient.getQueryData<AppraiserPricePredictions>(queryKey);
        queryClient.setQueryData(queryKey, pricePrediction);
        return { previousPredictions };
      },
      onError: (error, _, context) => {
        CommonConfig.errorHandler(error);
        if (context?.previousPredictions) {
          queryClient.setQueryData(queryKey, context.previousPredictions);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries("compositeAssets");
      },
    }
  );

  const removeComparableFromCart = (compId: string) => {
    if (!data) return;

    const comparablesAdjusted = [...data.comparables_adjusted];
    const adjustedComparable = findAppraiserComparable(compId);
    if (adjustedComparable?.adjustments?.is_corrected) {
      comparablesAdjusted.push(adjustedComparable);
    }

    const newData = {
      ...data,
      comparables: data.comparables.filter(
        (c) => c.comparable_transaction.id.toString() !== compId.toString()
      ),
      comparables_adjusted: comparablesAdjusted,
    };

    mutate(recalcPredictions(newData));
  };

  const addComparableToCart = async (comparable: Comparable) => {
    if (!data || !buyTransaction) return;

    const boughtComparable = isComparableFull(comparable)
      ? comparable
      : await buyTransaction(comparable.comparable_transaction.id, propertyId);
    try {
      await mutexForComparableCart.acquire();

      const latestData = queryClient.getQueryData<
        AppraiserPricePredictions & { createdAt: string; updatedAt: string }
      >(queryKey);

      if (!latestData) {
        return;
      }

      const newData = {
        ...latestData,
        comparables: [
          ...latestData.comparables,
          comparableToAppraiserComparable(boughtComparable),
        ],
        comparables_adjusted: latestData.comparables_adjusted.filter(
          (c) =>
            c.comparable_transaction.id.toString() !==
            boughtComparable.comparable_transaction.id.toString()
        ),
      };
      await mutateAsync(recalcPredictions(newData));
    } catch (error) {
      throw error;
    } finally {
      mutexForComparableCart.release();
    }
  };

  const updateComparableAdjustments = async (
    comparableId: string,
    adjustments: Adjustments,
    additionalPropertyFields?: AdditionalPropertyField[]
  ) => {
    let comparable = findAppraiserComparable(comparableId);
    if (!comparable) {
      const comp = findComparableFull(comparableId);
      if (!!comp) {
        comparable = comparableToAppraiserComparable(comp);
        data?.comparables_adjusted.push(comparable);
      }
    }
    if (!!comparable && !!data) {
      comparable.adjustments = { ...adjustments, is_corrected: true };

      data.additional_property_fields =
        additionalPropertyFields ?? data.additional_property_fields;
      await mutateAsync(recalcPredictions(data));
    }
  };

  const updateWeights = async (weights: number[]) => {
    if (!data) return;
    data.comparables.forEach((comp, index) => {
      comp.comparable_weight = weights[index];
    });
    await mutateAsync(recalcPredictions(data));
  };

  const updateAdjustmentDescription = async (
    field: string,
    description: string
  ) => {
    if (!data) return;
    data.descriptions =
      data.descriptions?.filter((d) => d.field !== field) ?? [];
    data.descriptions?.push({ field, description });

    // Needs recalc because it is updated simultaneously with adjustments
    await mutateAsync(recalcPredictions(data));
  };

  const updateAdditionalAdjustmentDescription = async (
    appraiserPropertyFieldId: string,
    description: string
  ) => {
    if (!data) return;
    data.descriptions = data.descriptions?.filter(
      (d) => d.appraiserPropertyFieldId !== appraiserPropertyFieldId
    );
    data.descriptions?.push({ appraiserPropertyFieldId, description });
    await mutateAsync(data);
  };

  const getFinalPricePredictions = (): FinalPricePredictions | undefined => {
    if (!data || !comparablesFull) {
      return undefined;
    }

    const finalPricePredictions: FinalPricePredictions = {
      predicted_price: data.predicted_price,
      comparables: data.comparables
        .map((comparable) => ({
          ...(comparablesFull.find(
            (comp) =>
              comparable.comparable_transaction.id.toString() ===
              comp.comparable_transaction.id.toString()
          ) as ComparableFull),
          adjustments: comparable.adjustments,
          comparable_weight: comparable.comparable_weight,
        }))
        .map((comparable) => ({
          ...comparable,
          comparable_transaction: {
            ...comparable.comparable_transaction,
            address: getComparableHiddenAddress(
              comparable.comparable_transaction
            ),
          },
        })),
      additional_property_fields: data.additional_property_fields?.filter(
        (field) =>
          data.comparables.some((comp) =>
            comp.adjustments.additional_fields?.some(
              (additional) =>
                additional.appraiserPropertyFieldId ===
                field.appraiserPropertyFieldId
            )
          )
      ),
    };
    return finalPricePredictions;
  };

  return {
    isComparableInCart,
    getComparablesInCartIds,
    getComparableIndex,
    removeComparableFromCart,
    addComparableToCart,
    getComparableAdjustments,
    updateComparableAdjustments,
    getFinalPricePredictions,
    updateWeights,
    isLoading,
    isLoadingPricePredictions,
    pricePredictions: data,
    updateAdjustmentDescription,
    updateAdditionalAdjustmentDescription,
  };
}

export function useComparablesSummary(propertyId: string) {
  const { getFinalPricePredictions } = usePricePredictions(propertyId);
  const finalPricePredictions = useMemo(
    () => getFinalPricePredictions(),
    [getFinalPricePredictions]
  );

  const comps = getPredictionsWithRealPrices(finalPricePredictions);

  return { comps };
}
