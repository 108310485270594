import React from "react";
import { useParams } from "react-router-dom";
import { useUsedCreditsForProperty } from "../../hooks/transactions.hooks";
import { PropertyCreditsRow } from "./PropertyCreditsRow";
import { Typography } from "common/components/Typography";

interface PropertyCreditsOverviewProps {
  style?: React.CSSProperties;
  withHeader?: boolean;
}

export const PropertyCreditsOverview: React.FC<
  PropertyCreditsOverviewProps
> = ({ style, withHeader }) => {
  const { propertyId } = useParams();

  const { data } = useUsedCreditsForProperty(propertyId ?? "");

  if (!data?.length) {
    return null;
  }

  return (
    <>
      {withHeader && (
        <Typography>
          {"Vertinimo išlaidos (papildomai isigyti sandoriai)"}
        </Typography>
      )}
      <div
        style={
          style ?? {
            borderRadius: "10px",
            padding: "10px 15px",
            backgroundColor: "white",
          }
        }
      >
        <table>
          <tbody>
            {data.map((usedCreditsRow) => (
              <PropertyCreditsRow
                key={usedCreditsRow.actionType}
                usedCreditsRow={usedCreditsRow}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
