import React from "react";
import { StyleSheet, Text, TextProps, TextStyle } from "react-native";
import colors from "../styles/colors";
import { TypographyStyle, TypographyType } from "../types/typography.types";
import { isWeb } from "../environment";

interface TypographyProps {
  variant?: TypographyType;
  textStyle?: TextStyle;
  children?: React.ReactNode;
}

const getFontStyles = (fontFamily: string) => {
  return {
    fontFamily: !isWeb() ? fontFamily : "Inter",
    fontWeight: fontFamily.slice(6, 9) as TextStyle["fontWeight"],
  };
};

export const Typography: React.FC<TypographyProps & TextProps> = ({
  numberOfLines,
  textStyle,
  variant,
  ...props
}) => {
  return (
    <Text
      numberOfLines={numberOfLines}
      style={{ ...styles[variant ?? "body"], ...textStyle }}
      {...props}
    >
      {props.children}
    </Text>
  );
};

const styles = StyleSheet.create<TypographyStyle>({
  h1: {
    ...getFontStyles("Inter_600SemiBold"),
    fontSize: 30,
    color: colors.black,
  },
  h2: {
    ...getFontStyles("Inter_600SemiBold"),
    fontSize: 24,
    color: colors.black,
  },
  h3: {
    ...getFontStyles("Inter_600SemiBold"),
    fontSize: 18,
    color: colors.black,
  },
  h4: {
    ...getFontStyles("Inter_500Medium"),
    fontSize: 16,
    color: colors.black,
  },
  h5: {
    ...getFontStyles("Inter_600SemiBold"),
    fontSize: 14,
    color: colors.black,
  },
  body: {
    ...getFontStyles("Inter_400Regular"),
    fontSize: 18,
    color: colors.black,
  },
  caption: {
    ...getFontStyles("Inter_400Regular"),
    fontSize: 14,
    color: colors.grey,
  },
  button: {
    ...getFontStyles("Inter_600SemiBold"),
    fontSize: 12,
    color: colors.purple2,
    letterSpacing: 1,
  },
  percentage: {
    ...getFontStyles("Inter_700Bold"),
    fontSize: 12,
    color: colors.purple2,
  },
  percentageMini: {
    ...getFontStyles("Inter_900Black"),
    fontSize: 8,
    color: colors.purple2,
  },
  percentageBig: {
    ...getFontStyles("Inter_500Medium"),
    fontSize: 12,
    color: colors.purple3,
  },
  badge: {
    ...getFontStyles("Inter_800ExtraBold"),
    fontSize: 8,
    color: colors.white,
  },
  link: {
    ...getFontStyles("Inter_400Regular"),
    fontSize: 14,
    color: colors.black,
    textDecorationLine: "underline",
  },
});
