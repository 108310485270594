import { TabItemType } from "common/components/tabs/types";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";

interface WebTabItemsProps<T> {
  selected: TabItemType<T>;
  tabItems: TabItemType<T>[];
  onClick?: (value: TabItemType<T>) => void;
}

export function WebTabItems<T>({
  selected,
  tabItems,
  onClick,
}: WebTabItemsProps<T>) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
      }}
    >
      {tabItems.map((tabItem, index) => {
        const isSelected = tabItem.value === selected.value;
        return (
          <div
            key={index}
            onClick={() => onClick?.(tabItem)}
            style={{
              borderBottom: `${isSelected ? 5 : 0}px solid ${colors.purple2}`,
              height: 40,
              cursor: "pointer",
            }}
          >
            <Typography
              variant={isSelected ? "h5" : "body"}
              textStyle={{
                color: isSelected ? colors.purple2 : colors.black,
                fontSize: 16,
              }}
            >
              {tabItem.name}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
