import React from "react";

interface TransactionsScrollContextProps {
  onPrevPress?: (index: number) => void;
  onNextPress?: (index: number) => void;
  selectedTransactions?: number[];
}

export const TransactionsScrollContext =
  React.createContext<TransactionsScrollContextProps>({});
