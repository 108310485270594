import { Typography } from "common/components/Typography";
import React from "react";
import { AdminListItem } from "./AdminListItem";

interface AdminMenuProps {
  children?: React.ReactNode;
}

export const AdminMenu: React.FC<AdminMenuProps> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          minWidth: "200px",
          backgroundColor: "white",
          position: "fixed",
          height: "100%",
        }}
      >
        <AdminListItem to="/settings" label="Pagrindiniai nustatymai" />
        <AdminListItem to="/appraisers" label="Organizacijos nariai" />
        <AdminListItem to="/payments" label="Mokėjimai" />
        <AdminListItem to="/adjustments" label="Pataisos" />
        <AdminListItem to="/import" label="Importavimas" />
      </div>
      <div style={{ paddingLeft: "200px", width: "100%" }}>{children}</div>
    </div>
  );
};
