import { MenuItem, Select } from "@mui/material";
import { useFormFieldErrors } from "common/hooks/form.hooks";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlWrapper } from "./FormControlWrapper";
import { FormFieldWrapper } from "common/components/form/FormFieldWrapper";

interface FormSelectProps {
  label?: string;
  name: string;
  values: string[];
  getIcon?: (value: string) => string;
  getTitle?: (value: string) => string;
  isRequired?: boolean;
  disabled?: boolean;
}

export function FormSelect({
  name,
  label,
  values,
  getIcon,
  getTitle,
  isRequired,
  disabled,
}: FormSelectProps) {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        // const Icon = getIcon?.(value);
        return (
          <FormFieldWrapper
            label={label}
            error={errorText}
            isRequired={isRequired}
          >
            <Select
              value={value ?? ""}
              onChange={onChange}
              style={{ zIndex: 100000 }}
              renderValue={(value) => {
                return getTitle?.(value) ?? value;
              }}
              disabled={disabled}
            >
              {values.map((value) => {
                const Icon = getIcon?.(value);
                return (
                  <MenuItem key={value} value={value}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {!!Icon && <Icon />}
                      {getTitle?.(value) ?? value}
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormFieldWrapper>
        );
      }}
    />
  );
}
