import { PixelRatio } from "react-native";
import { showToastError } from "../toast/toast";

export const getImageWidth = (width: number, height?: number) => {
  const maxWidth = Math.max(width, (height ?? 0) * (4 / 3));
  return maxWidth * PixelRatio.get();
};

const extractId = (url: string) => {
  const parts = url.split("/");
  if (parts.length > 5) return parts[4];
  throw new Error(`ID not found ${url}`);
};

export const getResponsiveImageUri = (uri: string, width: number) => {
  let newUri = uri;
  if (newUri.includes("https://imagedelivery.net")) {
    newUri = `https://images.dataplatform.lt/images/${extractId(newUri)}`;
  }

  if (newUri.includes("https://images.dataplatform.lt")) {
    return newUri + `?width=${Math.round(width)}`;
  }
  return newUri;
};
