import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPropertyShare } from "../api/api";
import { ContentCard } from "../components/ContentCard";
import { PropertyInfoContent } from "./property/PropertyInfoContent";
import { PredictionOverviewPrice } from "../components/PredictionOverviewPrice";
import { useRouteQuery } from "../hooks/router.hooks";
import { PredictionOverviewComparables } from "../components/PredictionOverviewComparables";
import { usePropertyShare } from "../hooks/property.hooks";
import { ErrorCentered } from "../components/loaders/ErrorCentered";
import { PropertyShare } from "./PropertyShare";
import { LoaderCentered } from "../components/loaders/LoaderCentered";
import { PropertyWithGallery } from "./property/PropertyWithGallery";

interface PropertyShareLinkProps {}

export const PropertyShareLink: React.FC<PropertyShareLinkProps> = () => {
  const query = useRouteQuery();

  const token = query.get("token");

  const { data, isError, isLoading } = usePropertyShare(token ?? "");

  if (isError) {
    return <ErrorCentered />;
  }

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!data) {
    return null;
  }

  return (
    <PropertyWithGallery property={data.property}>
      <PropertyShare data={data} />
    </PropertyWithGallery>
  );
};
