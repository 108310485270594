import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../styles/MUITheme";
import colors from "common/styles/colors";
import { StyledImage } from "common/components/images/StyledImage";
import { InfoRow } from "./InfoRow";

interface BasicInfoCardProps {
  imageUrl?: string;
  name: string;
  description?: string;
  infoRows: { label: string; value?: string }[];
  logoUrl?: string;
  marginTop?: number;
}

export const BasicInfoCard: React.FC<BasicInfoCardProps> = ({
  imageUrl,
  name,
  description,
  infoRows,
  logoUrl,
  marginTop,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageSize = isMobile ? 250 : 240;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: { sm: "row", xs: "column" },
        alignItems: { xs: "center", sm: "flex-start" },
        gap: "20px",
        backgroundColor: colors.white,
        borderRadius: "10px",
        pt: { xs: "20px", sm: 0 },
        my: "30px",
        mx: { xs: "10px", md: 0 },
        mt: marginTop,
      }}
    >
      <div>
        <StyledImage
          width={imageSize}
          height={imageSize}
          borderRadius={isMobile ? 125 : undefined}
          borderBottomLeftRadius={10}
          borderTopLeftRadius={10}
          imageProps={{ source: { uri: imageUrl } }}
        />
      </div>
      <div
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          display: "flex",
          flexDirection: "column",
          minHeight: imageSize,
        }}
      >
        <div style={{ fontSize: 20, fontWeight: 600 }}>{name}</div>
        <Box
          sx={{
            color: colors.grey,
            marginBottom: "20px",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          {description}
        </Box>
        {infoRows.map((infoRow) => {
          if (!infoRow.value) return null;
          return (
            <InfoRow
              key={infoRow.label}
              label={infoRow.label}
              value={infoRow.value}
            />
          );
        })}
        {!!logoUrl && (
          <div style={{ display: "flex", marginTop: "auto" }}>
            <StyledImage
              height={50}
              imageProps={{ source: { uri: logoUrl }, resizeMode: "contain" }}
            />
          </div>
        )}
      </div>
    </Box>
  );
};
