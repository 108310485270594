import React from "react";
import colors from "common/styles/colors";
import { HighlightButton } from "common/components/buttons/base/HighlightButton";
import { shadow10 } from "common/styles/shadows.styles";

interface CircleIconButtonProps {
  size?: number;
  onPress?: () => void;
  children?: React.ReactNode;
  backgroundColor?: string;
  disabled?: boolean;
}

export const CircleIconButton: React.FC<CircleIconButtonProps> = (props) => {
  const size = props.size ?? 43;

  return (
    <HighlightButton
      onPress={props.onPress}
      disabled={props.disabled}
      style={[
        {
          width: size,
          height: size,

          justifyContent: "center",
          alignItems: "center",
        },
        shadow10,
      ]}
      backgroundColor={props.backgroundColor ?? colors.white}
      borderRadius={size / 2}
    >
      {props.children}
    </HighlightButton>
  );
};
