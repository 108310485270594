import { ComparableFull } from "common/types/common.types";
import React, { useState } from "react";
import {
  useAppraiserPropertyFields,
  usePricePredictions,
} from "../../hooks/pricePredictions.hooks";
import { getAdjustmentPercent } from "../../helpers/pricePredictions.helpers";
import _ from "lodash";
import { LinkButton } from "../buttons/LinkButton";
import { ComparableNumbersEditModal } from "./ComparableNumbersEditModal";

interface AdditionalAdjustmentHeaderProps {
  comps: ComparableFull[];
  propertyId: string;
  readonly?: boolean;
  appraiserPropertyFieldId: string;
}

export const AdditionalAdjustmentHeader: React.FC<
  AdditionalAdjustmentHeaderProps
> = ({ comps, propertyId, readonly, appraiserPropertyFieldId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { updateComparableAdjustments } = usePricePredictions(propertyId);
  const { getAppraiserPropertyFieldName } = useAppraiserPropertyFields();

  const adjustments = comps.map((comp) =>
    comp.adjustments?.additional_fields?.find(
      (field) => field.appraiserPropertyFieldId === appraiserPropertyFieldId
    )
  );

  const getDefaultValues = () => {
    if (!comps) {
      return [];
    }
    return adjustments.map((adj) => getAdjustmentPercent(adj?.percent));
  };

  const getDisabledIndexes = () => {
    let indexes = [];

    for (let i = 0; i < adjustments.length; i++) {
      if (!adjustments[i]) {
        indexes.push(i);
      }
    }
    return indexes;
  };

  const updateAdjustments = async (values: number[]) => {
    await Promise.all(
      comps.map((comp, index) => {
        if (!comp.adjustments) {
          return null;
        }

        const newAdjustments = _.cloneDeep(comp.adjustments);
        const adjustedField = newAdjustments?.additional_fields?.find(
          (field) => field.appraiserPropertyFieldId === appraiserPropertyFieldId
        );
        if (!adjustedField) {
          return null;
        }
        adjustedField.percent = values[index] - 1;

        return updateComparableAdjustments(
          comp.comparable_transaction.id,
          newAdjustments
        );
      })
    );
  };

  const name = getAppraiserPropertyFieldName(appraiserPropertyFieldId) ?? "";

  return (
    <div>
      {name}
      {!readonly && (
        <>
          <LinkButton onClick={() => setIsModalOpen(true)}>
            Koreguoti pataisas
          </LinkButton>
          <ComparableNumbersEditModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            comps={comps}
            getDefaultValues={getDefaultValues}
            updateValues={updateAdjustments}
            headerText={`Koreguoti pataisą "${name}"`}
            withUpdateAll
            disabledIndexes={getDisabledIndexes()}
          />
        </>
      )}
    </div>
  );
};
