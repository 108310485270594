import { Button } from "@mui/material";
import { Typography } from "common/components/Typography";
import { PriceComparableRow } from "common/components/comparables/PriceComparableRow";
import { Divider } from "common/components/listItems/Divider";
import { ListItem } from "common/components/listItems/ListItem";
import { isCompMatched } from "common/helpers/comparables.helpers";
import { ComparableTransaction } from "common/types/common.types";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { LinkForButton } from "./links/LinkForButton";

interface PropertyInfoCompareProps {
  comparable: ComparableTransaction;
}
export const priceComparableFontSize = 16;
export const PropertyInfoCompare: React.FC<PropertyInfoCompareProps> = ({
  comparable,
}) => {
  if (!isCompMatched(comparable)) {
    return null;
  }
  // const matchedProject =
  //   comparable.matched_project_unit ?? comparable.matched_project;

  return (
    <div style={{ marginTop: 4, display: "flex", flexDirection: "column" }}>
      {comparable.matched_listing?.url && (
        <ListItem height={50}>
          <a href={comparable.matched_listing?.url} target="_blank">
            <Typography
              style={{ fontSize: 16, textDecorationLine: "underline" }}
            >
              Peržiūrėti skelbimą
            </Typography>
          </a>
        </ListItem>
      )}
      {/* {!!matchedProject?.url && (
        <ListItem height={50}>
          <a href={matchedProject.url} target="_blank">
            <Typography
              style={{ fontSize: 16, textDecorationLine: "underline" }}
            >
              {!!comparable.matched_project_unit
                ? "Peržiūrėti butą projekte"
                : "Peržiūrėti projektą"}
            </Typography>
          </a>
        </ListItem>
      )} */}
      <Divider />
      <div style={{ marginTop: 20 }}>
        <Typography variant="h4">Kainų palyginimas</Typography>
        {comparable.matched_listing && (
          <PriceComparableRow
            label="Skelbimo kaina"
            price={comparable.matched_listing.sold_price}
            price_area={comparable.matched_listing.sold_price_area}
            fontSize={priceComparableFontSize}
          />
        )}
        {/* {matchedProject && (
          <PriceComparableRow
            label="Buto projekte kaina"
            price={comparable.matched_project_unit?.sold_price}
            price_area={comparable.matched_project_unit?.sold_price_area}
            fontSize={priceComparableFontSize}
          />
        )} */}
        {/* <PriceComparableRow
          label="Sandorio kaina"
          price={comparable.sold_price}
          price_area={comparable.sold_price_area}
          fontSize={priceComparableFontSize}
        /> */}
      </div>
      {isCompMatched(comparable) && (
        <LinkForButton
          to={`../compare`}
          relative="path"
          style={{ alignSelf: "center", width: "100%", marginTop: 5 }}
        >
          <Button sx={{ width: "100%" }} endIcon={<NavigateNextIcon />}>
            pilnas palyginimas
          </Button>
        </LinkForButton>
      )}
    </div>
  );
};
