import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

interface DividerProps {
  style?: StyleProp<ViewStyle>;
  color?: string;
}

export const Divider: React.FC<DividerProps> = (props) => {
  return (
    <View
      style={[
        {
          borderBottomWidth: 1,
          borderBottomColor: props.color ?? "#EFEFEF",
        },
        props.style,
      ]}
    />
  );
};
