import { Box } from "@mui/material";
import colors from "common/styles/colors";
import React from "react";

interface InfoRowProps {
  label: string;
  value: string;
  customLabelWidth?: string;
}

export const InfoRow: React.FC<InfoRowProps> = ({
  label,
  value,
  customLabelWidth,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        marginBottom: { xs: "10px", sm: 0 },
        gap: { xs: 0, sm: "10px" },
        textAlign: { xs: "center", sm: "left" },
      }}
    >
      <Box
        sx={{
          width: { xs: "unset", sm: customLabelWidth ?? "140px" },
          fontSize: "14px",
          color: colors.grey,
        }}
      >
        {label}
      </Box>
      <div
        style={{
          fontSize: "14px",
          whiteSpace: "pre-wrap",
          flex: 1,
        }}
      >
        {value}
      </div>
    </Box>
  );
};
