import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "common/components/buttons/IconButton";
import { setClipboard } from "common/clipboard/clipboard";
import { showToastSuccess } from "common/toast/toast";
import { Typography } from "common/components/Typography";
import { useMutation } from "react-query";
import { regenerateAppraiserLink } from "../../api/api";
import { useAppraisersInOrganization } from "../../hooks/auth.hooks";
import { Appraiser } from "../../types/appraiser.types";
import LoadingButton from "@mui/lab/LoadingButton";
import { DecodedAppraiserToken } from "../../types/api.types";
import { LinkBox } from "../links/LinkBox";

interface AppraiserPasswordLinkProps {
  appraiser: Appraiser;
  type: "registration" | "reset";
  link?: string | null;
  decodedToken?: DecodedAppraiserToken;
}

export const AppraiserPasswordLink: React.FC<AppraiserPasswordLinkProps> = ({
  appraiser,
  type,
  link,
  decodedToken,
}) => {
  const copyLinkToClipboard = async () => {
    await setClipboard(link ?? "");
    showToastSuccess("Nuoroda nukopijuota!");
  };

  const { refetch } = useAppraisersInOrganization();

  const { mutate, isLoading } = useMutation(
    async (appraiserId: string) => {
      await regenerateAppraiserLink(appraiserId);
      await refetch();
    },
    {
      onSuccess: () => {
        showToastSuccess("Nuoroda atnaujinta!");
      },
    }
  );

  const isTokenValid = !decodedToken?.error;

  if (type === "reset" && !link) {
    return (
      <LoadingButton
        loading={isLoading}
        onClick={() => mutate(appraiser._id)}
        variant="outlined"
      >
        Atstatyti slaptažodį
      </LoadingButton>
    );
  }

  if (!appraiser.registrationLink && !appraiser.resetLink) {
    return null;
  }

  return (
    <LinkBox
      link={link}
      customButton={
        !isTokenValid ? (
          <IconButton
            isDisabled={isLoading}
            onPress={() => mutate(appraiser._id)}
          >
            <RefreshIcon
              sx={
                isLoading
                  ? {
                      animation: "rotate 2s linear infinite",
                      "@keyframes rotate": {
                        to: { transform: "rotate(360deg)" },
                      },
                    }
                  : undefined
              }
            />
          </IconButton>
        ) : undefined
      }
      customOpacity={isTokenValid ? 1 : 0.5}
    />
  );
};
