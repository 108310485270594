import { Dialog, DialogProps, useMediaQuery } from "@mui/material";
import React from "react";
import { ContentCardTitle } from "../ContentCardTitle";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { theme } from "../../styles/MUITheme";

interface FullScreenDialogProps {
  children: React.ReactNode;
  title: string;
  onClose: () => void;
}

export const FullScreenDialog: React.FC<
  FullScreenDialogProps & DialogProps
> = ({ children, onClose, title, ...props }) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      {...props}
      maxWidth={false}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: "20px",
          width: "1200px",
          height: fullScreen ? undefined : "800px",
        },
      }}
      fullScreen={fullScreen}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ContentCardTitle title={title} dividerMargin="20px 0">
          <div style={{ position: "absolute", right: 20, top: 16 }}>
            <IconButton onPress={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </ContentCardTitle>
        {children}
      </div>
    </Dialog>
  );
};
