import React from "react";
import { useQuery } from "react-query";
import { getPropertyLocationDetails } from "../../api/api";
import { StyledImage } from "common/components/images/StyledImage";
import { ContentCard } from "../../components/ContentCard";
import { PropertyLocationDetailsType } from "../../types/api.types";
import { PropertyLocationMap } from "./PropertyLocationMap";
import { Grid } from "@mui/material";
import { PropertyLocationContent } from "./PropertyLocationContent";

interface PropertyLocationProps {
  propertyId: string;
}

const order = [
  PropertyLocationDetailsType.REGIA1,
  PropertyLocationDetailsType.REGIA2,
  PropertyLocationDetailsType.FLOODS,
];

export const PropertyLocation: React.FC<PropertyLocationProps> = ({
  propertyId,
}) => {
  const { data, refetch } = useQuery(
    ["property_location", propertyId],
    () => getPropertyLocationDetails(propertyId),
    {
      refetchInterval: (data) =>
        data?.some((location) => !location.generatingCompleted) ? 5000 : false,
    }
  );

  if (!data) return null;

  return (
    <PropertyLocationContent locations={data} onRegenerate={() => refetch()} />
  );
};
