import { FinalPricePredictions } from "common/types/common.types";
import { calcSoldPrice } from "./comparable.helpers";
import { round } from "common/helpers/common.helpers";

export const getPredictionsWithRealPrices = (
  finalPricePredictions: FinalPricePredictions | undefined
) => {
  const comps =
    finalPricePredictions?.comparables.map((comp) => {
      const realSoldPrice = calcSoldPrice(
        comp.adjustments,
        comp.comparable_transaction
      );
      const realSoldPriceArea =
        realSoldPrice / comp.comparable_transaction.area;
      return {
        ...comp,
        realSoldPrice,
        realSoldPriceArea,
      };
    }) ?? [];

  return comps;
};

export const getAdjustmentPercent = (percent: number | undefined) => {
  return round((percent ?? 0) + 1, 3);
};
