import { Button } from "@mui/material";
import React from "react";
import { CompositeAssetsForm } from "./CompositeAssetsForm";
import { CompositeAssetsTable } from "./CompositeAssetsTable";
import AddIcon from "@mui/icons-material/Add";

interface CompositeAssetsProps {
  propertyId: string;
  pricePredictionId?: string;
  isCustomer?: boolean;
}

export const CompositeAssets: React.FC<CompositeAssetsProps> = ({
  propertyId,
  isCustomer,
  pricePredictionId,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CompositeAssetsTable
        propertyId={propertyId}
        isCustomer={isCustomer}
        pricePredicitionId={pricePredictionId}
      />
      <CompositeAssetsForm
        isCustomer={isCustomer}
        open={open}
        onClose={() => setOpen(false)}
        propertyId={propertyId}
      />
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        startIcon={<AddIcon />}
        style={{
          alignSelf: "end",
          marginTop: "20px",
        }}
      >
        pridėti priklausomą turtą
      </Button>
    </div>
  );
};
