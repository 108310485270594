import React, { useState, useEffect } from "react";
import { ImageProps, ViewStyle, Image, View, Platform } from "react-native";
import { Skeleton } from "../skeleton/Skeleton";
import {
  getImageWidth,
  getResponsiveImageUri,
} from "../../helpers/image.helpers";
import { FLICKER_AVOIDANCE_DELAY_MS } from "../../constants/loaders.constants";

export interface StyledImageProps {
  borderRadius?: number;
  borderTopLeftRadius?: number;
  borderBottomLeftRadius?: number;
  width?: number;
  height: number | string;
  style?: ViewStyle;
  imageProps: ImageProps;
  showLoader?: boolean;
  noBorderRadius?: boolean;
}

type ObjectFit = "fill" | "contain" | "cover" | "none" | "scale-down";

export const StyledImage: React.FC<StyledImageProps> = ({
  borderRadius,
  width,
  height,
  style,
  imageProps,
  showLoader,
  noBorderRadius,
  borderBottomLeftRadius,
  borderTopLeftRadius,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isLongWait, setIsLongWait] = useState(false);

  // Add timeout to avoid "flickering" effect
  useEffect(() => {
    setTimeout(() => {
      if (isLoading) {
        setIsLongWait(true);
      }
    }, FLICKER_AVOIDANCE_DELAY_MS);
  }, []);

  const showSkeleton = isLoading && !!showLoader && isLongWait;

  const [imageUri, setImageUri] = useState<string | null>();

  return (
    <View
      style={[
        style,
        {
          borderRadius,
          borderTopLeftRadius,
          borderBottomLeftRadius,
          overflow: Platform.OS === "android" ? "hidden" : "visible",
        },
      ]}
      onLayout={(e) => {
        if (imageUri !== undefined) return;
        const { width, height } = e.nativeEvent.layout;
        if (width === 0 && height === 0) return;
        const imageWidth = getImageWidth(width, height);

        // @ts-ignore
        const uri = imageProps.source.uri;
        if (typeof uri === "string" && uri.includes("http")) {
          const responsiveUri = getResponsiveImageUri(uri, imageWidth);
          setImageUri(responsiveUri);
          return;
        }
        setImageUri(uri);
      }}
    >
      <View style={{ width, height }}>
        {!!imageUri &&
          (Platform.OS === "web" ? (
            <img
              src={imageUri}
              style={{
                height: "100%",
                width: "100%",
                borderRadius,
                objectFit: (imageProps.resizeMode as ObjectFit) ?? "cover",
                ...(borderRadius === undefined
                  ? { borderBottomLeftRadius, borderTopLeftRadius }
                  : {}),
              }}
              onLoad={() => {
                setIsLoading(false);
              }}
              onError={() => {
                setImageUri(null);
                setIsLoading(false);
              }}
            />
          ) : (
            <Image
              {...imageProps}
              source={imageUri ? { uri: imageUri } : imageProps.source}
              style={{
                height,
                width,
                borderRadius:
                  Platform.OS === "android" && noBorderRadius
                    ? undefined
                    : borderRadius,
                borderBottomLeftRadius,
                borderTopLeftRadius,
              }}
              onLoad={() => {
                setIsLoading(false);
              }}
            />
          ))}
        {showSkeleton && typeof height === "number" && (
          <View
            style={{
              position: "absolute",
              width: width ?? "100%",
              borderRadius,
              overflow: "hidden",
              backgroundColor: "white",
            }}
          >
            <Skeleton height={height} width={width} />
          </View>
        )}
      </View>
    </View>
  );
};
