import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useFormFieldErrors } from "common/hooks/form.hooks";
import { Autocomplete, TextField } from "@mui/material";
import {
  FormFieldWrapper,
  FormFieldWrapperProps,
} from "common/components/form/FormFieldWrapper";

interface FormAutocompleteProps {
  label?: string;
  name: string;
  options: string[];
  freeSolo?: boolean;
  wrapperProps?: Partial<FormFieldWrapperProps>;
  isRequired?: boolean;
  onChange?: (value: string) => void;
  disableFilterOptions?: boolean;
  disabled?: boolean;
  onSelect?: (value: string) => void;
  isLoading?: boolean;
}

export const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  name,
  label,
  options,
  freeSolo,
  wrapperProps,
  isRequired,
  disabled,
  isLoading,
  ...props
}) => {
  const { control, watch } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  const watchedValue = watch(name);

  useEffect(() => {
    if (!watchedValue?.length) {
      props.onSelect?.("");
      props.onChange?.("");
    }
  }, [watchedValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const onChangeHandler = (value: string) => {
          onChange(value);
          props.onChange?.(value);
          props.onSelect?.(value);
        };

        return (
          <FormFieldWrapper
            {...wrapperProps}
            label={label}
            error={errorText}
            isRequired={isRequired}
          >
            <Autocomplete
              disabled={disabled}
              loading={isLoading}
              freeSolo={freeSolo}
              options={options}
              value={value ?? null}
              onInputChange={(_, value) => {
                if (!freeSolo) return;
                onChangeHandler(value);
              }}
              onChange={(_, value) => onChangeHandler(value)}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => props.onChange?.(e.target.value)}
                />
              )}
            />
          </FormFieldWrapper>
        );
      }}
    />
  );
};
