import { Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { Appraiser, AppraiserRole } from "../../types/appraiser.types";
import { CreateAppraiserRequest } from "../../types/api.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormSelect } from "../form/FormSelect";
import { FormContainer } from "../containers/FormContainer";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import { createAppraiser } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { ErrorAlert } from "../alerts/ErrorAlert";

interface AddAppraiserModalProps {}

const validationSchema: yup.ObjectSchema<CreateAppraiserRequest> = yup.object({
  email: yup.string().email().required(),
  role: yup.string().oneOf(Object.values(AppraiserRole)).required(),
});

export const AddAppraiserModal: React.FC<AddAppraiserModalProps> = () => {
  const [open, setOpen] = useState(false);

  const methods = useForm<CreateAppraiserRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      role: AppraiserRole.APPRAISER,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(createAppraiser, {
    onSuccess: (appraiser) => {
      queryClient.setQueryData<Appraiser[]>(
        "appraisersInOrganization",
        (data) => {
          return [appraiser, ...(data ?? [])];
        }
      );
      setOpen(false);
    },
  });

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained">
        Pridėti vertintoją
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
        <div
          style={{
            width: "700px",
            paddingBottom: "30px",
          }}
        >
          <FormContainer
            onSubmit={methods.handleSubmit((data) => mutate(data))}
          >
            <ErrorAlert error={error} />
            <FormProvider {...methods}>
              <FormInput
                label="El. paštas"
                name="email"
                variant="text"
                wrapperProps={{ gap: 5 }}
                maxWidth={500}
              />
              <FormSelect
                label="Rolė"
                name="role"
                values={Object.values(AppraiserRole)}
              />
            </FormProvider>
            <MUILoadingButton
              loading={isLoading}
              type="submit"
              style={{ marginTop: "30px" }}
            >
              Pridėti vertintoją
            </MUILoadingButton>
          </FormContainer>
        </div>
      </Dialog>
    </>
  );
};
