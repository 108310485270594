// Overloads
export function splitIntoChunks<T>(
  chunkSize: number,
  array: T[],
  withNulls: false
): T[][];
export function splitIntoChunks<T>(
  chunkSize: number,
  array: T[],
  withNulls: true
): (T | null)[][];
export function splitIntoChunks<T>(
  chunkSize: number,
  array: T[]
): (T | null)[][];

export function splitIntoChunks<T>(
  chunkSize: number,
  array: T[],
  withNulls = true
): (T | null)[][] {
  let chunks: (T | null)[][] = [];
  if (!array.length) {
    chunks = [[]];
  }
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  if (withNulls) {
    while (chunks[chunks.length - 1].length % chunkSize !== 0) {
      chunks[chunks.length - 1].push(null);
    }
  }
  return chunks;
}

export function getListString(
  items: (string | null | undefined | false)[]
): string {
  return items.filter((item) => !!item).join(" · ");
}

export function calculateFieldSum<T>(
  arr: T[],
  getField: (obj: T) => number
): number {
  return arr.reduce((accumulator, currentObject) => {
    const value = getField(currentObject);
    return accumulator + value;
  }, 0);
}

export function round(x: number, digits?: number) {
  let n = 1;
  for (let i = 0; i < (digits ?? 0); i++) {
    n *= 10;
  }
  return Math.round(x * n) / n;
}

export function getRoundedPercent(x: number, digits: number = 2) {
  return round(x * 100, digits);
}

export function haveSameKeys<T, K extends keyof T>(
  arr1: T[],
  arr2: T[],
  key: K
): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const keys1 = arr1.map((obj) => obj[key]).sort();
  const keys2 = arr2.map((obj) => obj[key]).sort();

  return keys1.every((k, index) => k === keys2[index]);
}
