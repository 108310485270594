import { Comparable, ComparableFull } from "common/types/common.types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DrawingManagerF,
  GoogleMap,
  OverlayViewF,
} from "@react-google-maps/api";
import Supercluster from "supercluster";
import { AnyProps, PointFeature } from "supercluster";
import { ClusterMarkerIcon } from "common/components/icons/ClusterMarkerIcon";
import {
  ComparableFilterType,
  getBoundsFilterFunction,
  getPolygonFilterFunction,
  groupComparablesByCoordinates,
} from "../../helpers/comparableFilters.helpers";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import { MapActionButton } from "./MapActionButton";
import { MapListInteractionsContext } from "../MapListInteractionsProvider";
import colors from "common/styles/colors";
import { ComparableIconOverlayView } from "./ComparableIconOverlayView";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ComparableMarkerWithPricePredictions } from "./ComparableMarkerWithPricePredictions";
import { MY_PROPERTY_SIZE_MULTIPLIER } from "./constants";
import { CircleIconButton } from "common/components/buttons/CircleIconButton";
import PropertyIcon from "../../assets/icons/property.svg";
import { getGoogleMapsBoundsFromCoords } from "../../helpers/map.helpers";
import { useComparablesData } from "../../hooks/property.hooks";
import { BulkBuyButton } from "./BulkBuyButton";
import { MapInfo } from "./legend/MapInfo";
import { mainPropertyBGColor } from "../../hooks/propertyIcons.hooks";
import { BulkDownloadButton } from "./BulkDownloadButton";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import AreaSelectIcon from "../../assets/icons/areaSelect.svg";
import RemoveSelectionIcon from "../../assets/icons/removeSelection.svg";

interface ComparablesMapProps {
  property: AppraiserProperty;
  // cursor: number;
}
interface ClusterOnMap {
  lat: number;
  lng: number;
  property_count: number;
  cluster_id: number;
}

const comparableToGeoJSONFeature = (
  comparable: Comparable
): PointFeature<AnyProps> => {
  return {
    type: "Feature",
    geometry: {
      coordinates: [
        comparable.comparable_transaction.location.lng,
        comparable.comparable_transaction.location.lat,
      ],
      type: "Point",
    },
    properties: {
      comparableId: comparable.comparable_transaction.id,
    },
  };
};

const MAX_CLUSTER_ZOOM = 15;

const getMaxClusterZoom = () =>
  window.innerWidth < 1600 ? MAX_CLUSTER_ZOOM : MAX_CLUSTER_ZOOM + 1;

export const ComparablesMap: React.FC<ComparablesMapProps> = ({
  property,
  // cursor,
}) => {
  const superCluster = useRef<Supercluster | null>(null);

  const { dataCombined: comparables, comparablesInCart } = useComparablesData();

  const { getFilteredComparables, addFilter, getAppliedFilter, removeFilter } =
    useContext(ComparablesFiltersContext);
  const filteredComparables = useMemo(
    () =>
      getFilteredComparables?.(comparables, [ComparableFilterType.Bounds]) ??
      [],

    [getFilteredComparables, comparables]
  );

  const { searchCount } = useContext(TransactionsRCContext);

  const maxClusterZoom = useRef(getMaxClusterZoom());

  useEffect(() => {
    maxClusterZoom.current = getMaxClusterZoom();

    superCluster.current = new Supercluster({
      radius: 150,
      maxZoom: maxClusterZoom.current,
      minPoints: 1,
    });
    const remainingCompsInCart = comparablesInCart.filter(
      (comp) =>
        !filteredComparables.some(
          (fComp) =>
            fComp.comparable_transaction.id.toString() ===
            comp.comparable_transaction.id.toString()
        )
    );

    superCluster.current.load(
      [...remainingCompsInCart, ...filteredComparables].map((comp) =>
        comparableToGeoJSONFeature(comp)
      )
    );
    updateMapMarkers();
  }, [filteredComparables, comparablesInCart]);

  const getComparablesInitialBounds = () => {
    return getGoogleMapsBoundsFromCoords([
      ...(filteredComparables?.map((comp) => ({
        lat: comp.comparable_transaction.location.lat,
        lng: comp.comparable_transaction.location.lng,
      })) ?? []),
      {
        lat: property.lat,
        lng: property.lng,
      },
    ]);
  };

  const onBoundsChanged = (bounds: google.maps.LatLngBounds) => {
    addFilter?.({
      type: ComparableFilterType.Bounds,
      filterFunction: getBoundsFilterFunction(bounds),
      values: bounds,
    });
  };

  const mapRef = useRef<google.maps.Map>();
  const [clustersOnMap, setClustersOnMap] = useState<ClusterOnMap[]>([]);
  const [comparablesOnMap, setComparablesOnMap] = useState<
    (Comparable | ComparableFull)[][]
  >([]);

  const initialBoundsRef = useRef<google.maps.LatLngBounds>();

  const setMapBounds = () => {
    initialBoundsRef.current = getComparablesInitialBounds();
    if (!!initialBoundsRef.current) {
      mapRef.current?.fitBounds(initialBoundsRef.current);
    }
    // mapRef.current = map;
    onRegionChangeComplete();
  };

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      mapRef.current = map;
      setMapBounds();
    },
    [filteredComparables]
  );

  useEffect(() => {
    if (!mapRef.current) return;
    setMapBounds();
  }, [searchCount]);

  const onClusterPress = (cluster: ClusterOnMap) => {
    if (!superCluster.current || !mapRef.current) return;
    const zoom = mapRef.current.getZoom();
    if (zoom === undefined) return;
    const clusterExpansionZoom = superCluster.current.getClusterExpansionZoom(
      cluster.cluster_id
    );
    if (clusterExpansionZoom === undefined) return;
    mapRef.current.setZoom(clusterExpansionZoom);
    mapRef.current.panTo({
      lat: cluster.lat,
      lng: cluster.lng,
    });
  };

  const updateMapMarkers = () => {
    if (!superCluster.current || !mapRef.current) return;
    const bounds = mapRef.current.getBounds();
    const bBox = bounds?.toJSON();
    // convert bBox to geojson bbox

    const geoJsonBbox = [
      bBox?.west,
      bBox?.south,
      bBox?.east,
      bBox?.north,
    ] as GeoJSON.BBox;

    const zoom = mapRef.current.getZoom();
    if (zoom === undefined) return;

    const clusters = superCluster.current?.getClusters(geoJsonBbox, zoom);
    const singleClusters = clusters.filter(
      (cluster) => (cluster.properties.point_count || 1) === 1
    );
    const multipleClusters = clusters.filter(
      (cluster) => (cluster.properties.point_count || 1) > 1
    );
    const mapClusters: ClusterOnMap[] = multipleClusters.map((cluster) => ({
      lng: cluster.geometry.coordinates[0],
      lat: cluster.geometry.coordinates[1],
      property_count: cluster.properties.point_count,
      cluster_id: cluster.properties.cluster_id,
    }));
    const mapComparables = comparables.filter((comp) =>
      singleClusters.some(
        (cluster) =>
          cluster.properties.comparableId === comp.comparable_transaction.id
      )
    );
    setClustersOnMap(mapClusters);
    setComparablesOnMap(groupComparablesByCoordinates(mapComparables));
  };

  const onRegionChangeComplete = () => {
    if (!mapRef.current) return;
    const bounds = mapRef.current.getBounds();
    if (bounds) {
      onBoundsChanged?.(bounds);
    }
    updateMapMarkers();
  };
  const scrollTimer = useRef<NodeJS.Timeout>();
  const [selectedCompId, setSelectedCompId] = useState<string>();

  const [isHovered, setIsHovered] = useState(false);

  const [selectedDrawingMode, setSelectedDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);

  const selectedPolygons =
    getAppliedFilter?.(ComparableFilterType.Polygon)?.values ?? [];

  const onMapClick = () => {
    setSelectedCompId(undefined);
  };

  const { setOnListComparableClickHandler } = useContext(
    MapListInteractionsContext
  );

  useEffect(() => {
    setOnListComparableClickHandler?.((comp) => {
      if (!mapRef.current) return;
      const currentZoom = mapRef.current.getZoom() ?? 0;
      if (
        currentZoom <= maxClusterZoom.current ||
        !comparablesOnMap.some((comps) =>
          comps.some(
            (c) =>
              c.comparable_transaction.id === comp.comparable_transaction.id
          )
        )
      ) {
        mapRef.current?.setZoom(
          Math.max(maxClusterZoom.current + 1, currentZoom)
        );
        mapRef.current?.panTo({
          lat: comp.comparable_transaction.location.lat,
          lng: comp.comparable_transaction.location.lng,
        });
      }
      setSelectedCompId(comp.comparable_transaction.id);
    });
  }, [comparablesOnMap]);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  const onCompClick = useCallback(
    (compId: string) => {
      setSelectedCompId(compId);
    },
    [setSelectedCompId]
  );

  return (
    <>
      <GoogleMap
        options={{
          fullscreenControl: false,
          clickableIcons: false,
          disableDoubleClickZoom: isHovered,
        }}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        onBoundsChanged={() => {
          clearTimeout(scrollTimer.current);
          scrollTimer.current = setTimeout(function () {
            onRegionChangeComplete();
          }, 100);
        }}
        zoom={15}
        onClick={onMapClick}
        onLoad={onLoad}
      >
        <DrawingManagerF
          onPolygonComplete={(polygon) => {
            polygon.addListener("click", onMapClick);
            const newPolygonsArray = [...selectedPolygons, polygon];
            addFilter?.({
              type: ComparableFilterType.Polygon,
              filterFunction: getPolygonFilterFunction(newPolygonsArray),
              values: newPolygonsArray,
            });
            setSelectedDrawingMode(null);
          }}
          options={{
            drawingControl: false,
            drawingMode: selectedDrawingMode,
          }}
        />
        <ComparableIconOverlayView
          lat={property.lat}
          lng={property.lng}
          backgroundColor={mainPropertyBGColor}
          sizeMultiplier={MY_PROPERTY_SIZE_MULTIPLIER}
        />
        {comparablesOnMap.map((comps) => (
          <ComparableMarkerWithPricePredictions
            key={comps[0].comparable_transaction.id}
            comparables={comps}
            onClick={onCompClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            selectedCompId={selectedCompId}
          />
        ))}

        {clustersOnMap.map((cluster) => (
          <OverlayViewF
            key={cluster.cluster_id}
            mapPaneName="overlayMouseTarget"
            position={{
              lat: cluster.lat,
              lng: cluster.lng,
            }}
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2,
              y: -height / 2,
            })}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                onClusterPress(cluster);
              }}
              style={{ cursor: "pointer" }}
            >
              <ClusterMarkerIcon
                key={cluster.cluster_id}
                propertyCount={cluster.property_count}
                color={colors.white}
              />
            </div>
          </OverlayViewF>
        ))}
        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
          <MapInfo />
        </div>
        <div
          style={{
            position: "absolute",
            right: "10px",
            bottom: "200px",
          }}
        >
          <CircleIconButton
            onPress={() => {
              if (!initialBoundsRef.current) {
                return;
              }
              mapRef.current?.fitBounds(initialBoundsRef.current);
            }}
          >
            <PropertyIcon />
          </CircleIconButton>
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            bottom: "20px",
            transform: "translateX(-50%)",
            display: "flex",
            gap: "15px",
          }}
        >
          {!!selectedDrawingMode && (
            <MapActionButton
              onClick={() => {
                setSelectedDrawingMode(null);
              }}
              title="Atšaukti"
            />
          )}
          {!selectedDrawingMode && (
            <MapActionButton
              onClick={() => {
                setSelectedDrawingMode(google.maps.drawing.OverlayType.POLYGON);
              }}
              icon={<AreaSelectIcon />}
              title="Žymėti plotą"
            />
          )}
          {!!selectedPolygons.length && (
            <>
              <MapActionButton
                title="Naikinti žymėjimą"
                icon={<RemoveSelectionIcon />}
                onClick={() => {
                  for (const polygon of selectedPolygons) {
                    polygon.setMap(null);
                  }
                  removeFilter?.(ComparableFilterType.Polygon);
                }}
              />
              <BulkBuyButton filteredComparables={filteredComparables} />
              <BulkDownloadButton filteredComparables={filteredComparables} />
            </>
          )}
        </div>
      </GoogleMap>
    </>
  );
};
