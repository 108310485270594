import { Typography } from "common/components/Typography";
import React from "react";

interface FilterFieldWrapperProps {
  children: React.ReactNode;
  label: string;
}

export const FilterFieldWrapper: React.FC<FilterFieldWrapperProps> = ({
  children,
  label,
}) => {
  return (
    <div style={{ width: "345px", marginBottom: "15px", flex: 1 }}>
      <Typography textStyle={{ fontSize: 16 }}>{label}</Typography>
      {children}
    </div>
  );
};
