import { Typography } from "common/components/Typography";
import React from "react";

interface PropertyRowProps {
  label: string;
  value: React.ReactNode;
}

export const PropertyRow: React.FC<PropertyRowProps> = ({ label, value }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div style={{ minWidth: "180px" }}>
        <Typography variant="caption" textStyle={{ fontSize: 16 }}>
          {label}
        </Typography>
      </div>
      <Typography variant="body" numberOfLines={1}>
        {value}
      </Typography>
    </div>
  );
};
