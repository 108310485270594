import { setClipboard } from "common/clipboard/clipboard";
import { IconButton } from "common/components/buttons/IconButton";
import { Typography } from "common/components/Typography";
import { showToastSuccess } from "common/toast/toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React from "react";
import colors from "common/styles/colors";

interface LinkBoxProps {
  customButton?: React.ReactElement;
  customOpacity?: number;
  link?: string | null;
  withBorder?: boolean;
}

export const LinkBox: React.FC<LinkBoxProps> = ({
  customButton,
  customOpacity,
  link,
  withBorder,
}) => {
  const copyLinkToClipboard = async () => {
    await setClipboard(link ?? "");
    showToastSuccess("Nuoroda nukopijuota!");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "400px",
        padding: "0 10px",
        border: withBorder ? `1px solid ${colors.beige}` : "none",
        borderRadius: "5px",
      }}
    >
      <Typography
        numberOfLines={1}
        onPress={!customButton ? copyLinkToClipboard : undefined}
        textStyle={{
          opacity: customOpacity ?? 1,
          textDecorationLine: "underline",
        }}
      >
        {link}
      </Typography>
      <div>
        {!!customButton ? (
          customButton
        ) : (
          <IconButton onPress={copyLinkToClipboard}>
            <ContentCopyIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
