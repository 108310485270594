import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LabelField } from "../LabelField";
import { useFormFieldErrors } from "../../../hooks/form.hooks";

interface FormLabelFieldProps {
  name: string;
  label?: string;
  onPress?: () => void;
  isRequired?: boolean;
  caption?: React.ReactNode;
}

export const FormLabelField: React.FC<FormLabelFieldProps> = ({
  name,
  label,
  onPress,
  isRequired,
  caption,
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => {
        return (
          <LabelField
            value={value}
            error={errorText}
            label={label}
            onPress={onPress}
            isRequired={isRequired}
            caption={caption}
          />
        );
      }}
    />
  );
};
