import { AxiosInstance } from "axios";

class CommonConfigClass {
  errorHandler!: (error: any) => void;
  axiosInstance!: AxiosInstance;
  loggingHandler!: (eventName: string, meta?: any) => void;
  init({
    errorHandler,
    axiosInstance,
    loggingHandler,
  }: {
    errorHandler: (error: any) => void;
    axiosInstance: AxiosInstance;
    loggingHandler: (eventName: string, meta?: any) => void;
  }) {
    this.axiosInstance = axiosInstance;
    this.errorHandler = errorHandler;
    this.loggingHandler = loggingHandler;
  }
}
const CommonConfig = new CommonConfigClass();

export default CommonConfig;
