export const getBoundingBoxFromCoords = (
  coords: { lat: number; lng: number }[]
) => {
  let minLat = 200,
    maxLat = -200,
    minLng = 200,
    maxLng = -200;
  for (const coord of coords) {
    minLat = Math.min(coord.lat, minLat);
    maxLat = Math.max(coord.lat, maxLat);
    minLng = Math.min(coord.lng, minLng);
    maxLng = Math.max(coord.lng, maxLng);
  }
  return {
    minLat,
    maxLat,
    minLng,
    maxLng,
  };
};

const RADIUS_OF_EARTH_IN_KM = 6371;

export const haversineDistance = (
  [lat1, lon1]: [number, number],
  [lat2, lon2]: [number, number],
  isMiles = false
) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

export const addKmToLatLng = (
  latitude: number,
  longitude: number,
  km: number
) => {
  const new_latitude =
    latitude + (km / RADIUS_OF_EARTH_IN_KM) * (180 / Math.PI);
  const new_longitude =
    longitude +
    ((km / RADIUS_OF_EARTH_IN_KM) * (180 / Math.PI)) /
      Math.cos((latitude * Math.PI) / 180);

  return {
    latitude: new_latitude,
    longitude: new_longitude,
  };
};
