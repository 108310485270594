import { Typography } from "common/components/Typography";
import React from "react";

interface InfoBoxProps {
  text: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({ text }) => {
  return (
    <div
      style={{
        padding: "24px",
        borderRadius: "10px",
        backgroundColor: "white",
      }}
    >
      <Typography variant="caption" textStyle={{ fontSize: 16 }}>
        {text}
      </Typography>
    </div>
  );
};
