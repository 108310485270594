import React, { useState, useEffect } from "react";
import { KeyboardTypeOptions, Platform, TextInput, View } from "react-native";
import { Typography } from "common/components/Typography";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";
import { inputFieldStyle } from "../../styles/form.styles";
import colors from "../../styles/colors";
import { FormSettingsContext } from "./hookForms/FormSettingsContext";

export type InputFieldVariant =
  | "text"
  | "number"
  | "number-text"
  | "decimal"
  | "password";

interface InputFieldProps {
  units?: string;
  value?: number | null;
  onChange?: (value?: number | string) => void;
  label?: string;
  error?: string;
  isRequired?: boolean;
  variant?: InputFieldVariant;
  placeholder?: string;
  fontSize?: number;
  wrapperProps?: Partial<FormFieldWrapperProps>;
  maxWidth?: number;
  disabled?: boolean;
}

export const InputField: React.FC<InputFieldProps> = ({
  value,
  units,
  onChange,
  label,
  error,
  isRequired,
  variant,
  placeholder,
  fontSize,
  wrapperProps,
  maxWidth,
  disabled: disabledProp,
}) => {
  const {
    maxInputWidth,
    desktopColumns,
    inputHeight,
    disabled: disabledContext,
  } = React.useContext(FormSettingsContext);

  const disabled = disabledProp || disabledContext;

  const [customValue, setCustomValue] = useState<string>();
  const currentValue =
    typeof value === "number" && isNaN(value) ? undefined : value?.toString();

  useEffect(() => {
    if (variant !== "decimal") return;

    if (currentValue === undefined) {
      setCustomValue("");
    }
  }, [currentValue]);
  const onChangeTextHandler: (text: string) => void = (() => {
    if (variant === "decimal") {
      return (text) => {
        if (text === "") {
          setCustomValue("");
          onChange?.(undefined);

          return;
        }
        let numberText = text.replace(/[^0-9.,]/g, "").trim();
        numberText = numberText.replace(",", ".");
        setCustomValue(numberText);
        if (numberText[numberText.length - 1] === ".") {
          return;
        }
        const numberValue = parseFloat(numberText);
        if (isNaN(numberValue)) {
          onChange?.(undefined);
          return;
        }
        onChange?.(numberValue);
      };
    } else if (variant === "number") {
      return (text) => {
        if (text === "") {
          onChange?.(undefined);
          return;
        }
        const numberText = text.replace(/[^0-9]/g, "").trim();
        const numberValue = parseFloat(numberText);
        if (isNaN(numberValue)) {
          onChange?.(undefined);
          return;
        }
        onChange?.(numberValue);
      };
    } else if (variant === "number-text") {
      return (text) => {
        if (text === "") {
          onChange?.(undefined);
          return;
        }
        const value = text
          .replace(/[^0-9a-zA-Z]/g, "")
          .trim()
          .toUpperCase();
        onChange?.(value);
      };
    }

    return (text) => {
      if (text === "") {
        onChange?.(undefined);
        return;
      }
      onChange?.(text);
    };
  })();

  const getKeyboardType: () => KeyboardTypeOptions = () => {
    switch (variant) {
      case "decimal":
        return "decimal-pad";
      case "number":
        return "number-pad";
      case "number-text":
        return Platform.OS === "android" ? "visible-password" : "default";
      default:
        return "default";
    }
  };

  return (
    <FormFieldWrapper
      {...wrapperProps}
      label={label}
      error={error}
      isRequired={isRequired}
    >
      <View
        style={[
          inputFieldStyle,
          {
            paddingRight: 12,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            borderColor: error ? colors.red : inputFieldStyle.borderColor,
            maxWidth: maxWidth ?? maxInputWidth,
            height: inputHeight,
          },
        ]}
      >
        <TextInput
          keyboardType={getKeyboardType()}
          value={customValue ?? currentValue ?? ""}
          onChangeText={onChangeTextHandler}
          secureTextEntry={variant === "password"}
          style={[
            {
              fontSize: desktopColumns ? 16 : fontSize ?? 20,
              paddingLeft: 20,
              width: 0,
              flex: 1,
              paddingVertical: 12,
              color: disabled ? colors.grey : colors.black,
            },
          ]}
          onBlur={() => {
            setCustomValue(undefined);
          }}
          placeholder={placeholder}
          placeholderTextColor={colors.grey}
          editable={!disabled}
        />
        <Typography textStyle={{ color: colors.grey }}>{units}</Typography>
      </View>
    </FormFieldWrapper>
  );
};
