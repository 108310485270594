import React, { useContext } from "react";
import { LinkButton } from "../buttons/LinkButton";
import { Button, Dialog, MenuItem, Select } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import {
  getAdjustmentsByField,
  getAdjustmentsProvidersByField,
} from "../../api/api";
import { ContentCardTitle } from "../ContentCardTitle";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { ComparableContext } from "../../routes/ComparableAdjustments";
import { useParams } from "react-router-dom";
import { showToastError } from "common/toast/toast";
import { AdjustedField } from "common/types/common.types";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import colors from "common/styles/colors";

interface ChangeMethodologyProps {
  field: string;
  fieldname: string;
  selected: string;
  adjustmentDescription?: string;
  onChange?: (value: AdjustedField) => void;
}

export const ChangeMethodology: React.FC<ChangeMethodologyProps> = ({
  field,
  selected,
  fieldname,
  onChange,
  adjustmentDescription,
}) => {
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery(
    ["methodologies", field],
    () => getAdjustmentsProvidersByField(field),
    {
      staleTime: Infinity,
    }
  );

  const [selectedMethodology, setSelectedMethodology] =
    React.useState(selected);

  const { mutate, isLoading } = useMutation(getAdjustmentsByField, {
    onSuccess: (data) => {
      onChange?.(data);
      setOpen(false);
    },
    onError: () => {
      showToastError();
    },
  });

  const { comparable } = useContext(ComparableContext);

  const { propertyId } = useParams();

  if (!comparable) {
    return null;
  }

  return (
    <>
      <LinkButton
        onClick={() => {
          setOpen(true);
        }}
      >
        Keisti metodologiją
      </LinkButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { width: "800px", minHeight: "500px" },
        }}
        scroll="body"
      >
        <div
          style={{
            padding: "30px 20px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContentCardTitle title={`Keisti "${fieldname}" metodologiją`}>
            <div style={{ position: "absolute", right: 20, top: 16 }}>
              <IconButton onPress={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </ContentCardTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <div style={{ marginBottom: "5px" }}>Metodologija</div>
              <Select
                value={selectedMethodology}
                style={{ width: "300px" }}
                onChange={(e) => {
                  setSelectedMethodology(e.target.value);
                }}
              >
                {data?.map((methodology) => (
                  <MenuItem key={methodology.name} value={methodology.name}>
                    {methodology.fullname}
                  </MenuItem>
                ))}
              </Select>
              {!!adjustmentDescription && (
                <>
                  <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                    Pataisos aprašymas
                  </div>
                  <div style={{ color: colors.grey, whiteSpace: "pre-wrap" }}>
                    {adjustmentDescription}
                  </div>
                </>
              )}
            </div>
            <MUILoadingButton
              variant="contained"
              style={{ width: "300px", alignSelf: "center", marginTop: "20px" }}
              onClick={() => {
                mutate({
                  comparable: comparable?.comparable_transaction,
                  provider: selectedMethodology,
                  field,
                  propertyId: propertyId ?? "",
                });
              }}
              loading={isLoading}
            >
              Išsaugoti
            </MUILoadingButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};
