import React from "react";
import { AdjustmentProvider } from "../../types/api.types";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AdjustmentsAdminDialog } from "./AdjustmentsAdminDialog";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import { useMutation, useQueryClient } from "react-query";
import {
  setAdminAdjustment,
  setAdminAdjustmentAdditional,
} from "../../api/api";
import { showToastError } from "common/toast/toast";

interface AdjustmentsAdminRowProps {
  field: string;
  fieldFullName?: string;
  description?: string;
  appraiserPropertyFieldId?: string;
  providers?: AdjustmentProvider[];
  providerSelected?: string;
}

export const AdjustmentsAdminRow: React.FC<AdjustmentsAdminRowProps> = ({
  field,
  description,
  appraiserPropertyFieldId,
  providers,
  providerSelected,
  fieldFullName,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const selectedProviderFullName = providers?.find(
    (provider) => provider.name === providerSelected
  )?.fullname;

  const fieldName = fieldFullName ?? field;

  const queryClient = useQueryClient();

  const onSuccessfulSubmit = () => {
    queryClient.invalidateQueries("admin_adjustments");
    queryClient.invalidateQueries("adjustments_descriptions");
    setIsModalOpen(false);
  };

  const onErrorSubmit = () => {
    showToastError();
  };

  const { mutate, isLoading } = useMutation(setAdminAdjustment, {
    onSuccess: onSuccessfulSubmit,
    onError: onErrorSubmit,
  });

  const { mutate: mutateAdditional, isLoading: isLoadingAdditional } =
    useMutation(setAdminAdjustmentAdditional, {
      onSuccess: onSuccessfulSubmit,
      onError: onErrorSubmit,
    });

  return (
    <>
      <SimpleTableRow>
        <SimpleTableElement textAlign="left">{fieldName}</SimpleTableElement>
        <SimpleTableElement
          style={{
            maxWidth: "300px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            paddingLeft: "20px",
          }}
        >
          {description}
        </SimpleTableElement>
        <SimpleTableElement>{selectedProviderFullName}</SimpleTableElement>
        <SimpleTableElement>
          <IconButton onClick={() => setIsModalOpen(true)}>
            <EditIcon />
          </IconButton>
        </SimpleTableElement>
      </SimpleTableRow>
      <AdjustmentsAdminDialog
        key={isModalOpen ? "open" : "closed"}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        providers={providers}
        initialProvider={providerSelected}
        initialDescription={description}
        initialName={fieldName}
        isNameEditable={!!appraiserPropertyFieldId}
        isLoading={isLoading || isLoadingAdditional}
        onSubmit={(provider, description, name) => {
          if (!!appraiserPropertyFieldId) {
            if (!name?.length) {
              showToastError("Pavadinimas yra privalomas");
              return;
            }
            mutateAdditional({
              appraiserPropertyFieldId,
              name,
              description,
            });
          } else if (!!provider) {
            mutate({
              field,
              provider,
              description,
            });
          }
        }}
      />
    </>
  );
};
