import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextStyle } from "react-native";
import { CheckboxInput } from "../CheckboxInput";

interface FormCheckboxInputProps {
  label?: string;
  name: string;
  style?: TextStyle;
}

export const FormCheckboxInput: React.FC<FormCheckboxInputProps> = ({
  label,
  name,
  style,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <CheckboxInput
            isChecked={value}
            onChange={onChange}
            label={label}
            style={style}
          />
        );
      }}
    />
  );
};
