import React from "react";
import { View } from "react-native";
import { ListItem } from "../listItems/ListItem";
import CheckboxChecked from "../../assets/icons/form/checkboxChecked.svg";
import CheckboxUnchecked from "../../assets/icons/form/checkboxUnchecked.svg";
import { Typography } from "common/components/Typography";
import { Divider } from "../listItems/Divider";
import { SelectType } from "../../types/form.types";
import colors from "../../styles/colors";

interface CheckboxButtonGroupProps<T> {
  values: SelectType<T>[];
  onSelect?: (value: T) => void;
  onUnselect?: (value: T) => void;
  selectedValues?: T[];
  disabledValues?: T[];
  icons?: React.ReactElement[];
  backgroundColor?: string;
}

export function CheckboxButtonGroup<T>(props: CheckboxButtonGroupProps<T>) {
  return (
    <View>
      {props.values.map((value, index) => (
        <React.Fragment key={`RadioButton${index}`}>
          <ListItem
            backgroundColor={props.backgroundColor ?? colors.bg}
            height={60}
            disabled={props.disabledValues?.includes(value.value)}
            customIcon={
              props.selectedValues?.includes(value.value) ? (
                <CheckboxChecked />
              ) : (
                <CheckboxUnchecked />
              )
            }
            onPress={() => {
              if (props.selectedValues?.includes(value.value)) {
                props.onUnselect?.(value.value);
              } else {
                props.onSelect?.(value.value);
              }
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 4,
              }}
            >
              {props.icons?.[index]}
              <Typography
                variant="caption"
                textStyle={{ fontSize: 16, marginLeft: 24 }}
              >
                {value.label}
              </Typography>
            </View>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </View>
  );
}
