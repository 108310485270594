import { useMutation, useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import {
  createProviderSession,
  getAppraiser,
  getAppraisersCreditsInOrganization,
  getAppraisersInOrganization,
  getDecodedAppraiserToken,
  getOrganizationUsedCredits,
  logoutAppraiser,
  setAppraiserMonthlyCreditLimit,
} from "../api/api";
import { Appraiser } from "../types/appraiser.types";
import { useNavigate } from "react-router-dom";
import { useRouteQuery } from "./router.hooks";
import { getUsedCreditsByMonth } from "../api/rcTransactions.api";
import { MonthDate } from "../components/datepicker/types";
import * as Sentry from "@sentry/react";

export function useGoogleLogin() {
  const queryClient = useQueryClient();
  return useMutation(createProviderSession, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
  });
}

export function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(logoutAppraiser, {
    onSuccess: () => {
      queryClient.setQueryData("appraiser", undefined);
      navigate("/");
    },
  });
  return { logout: mutate, isLoading };
}

export function useAppraiser() {
  return useQuery("appraiser", getAppraiser, {
    onSuccess: (data) => {
      if (data) {
        Sentry.setUser({
          email: data.email,
        });
      }
    },
  });
}

export function useAuthSideEffects() {
  const queryClient = useQueryClient();

  const onAuthSuccess = (data: Appraiser) => {
    queryClient.setQueryData("appraiser", data);
  };
  return { onAuthSuccess };
}

export function useAppraisersInOrganization() {
  return useQuery("appraisersInOrganization", getAppraisersInOrganization);
}

export function useAppraisersInOrganizationCredits() {
  return useQuery(
    "appraisersInOrganizationCredits",
    getAppraisersCreditsInOrganization
  );
}

export function useUsedCreditsByMonth(
  startDate: MonthDate,
  endDate: MonthDate
) {
  return useQuery(["usedCreditsByMonth", startDate, endDate], () =>
    getUsedCreditsByMonth(startDate, endDate)
  );
}

export function useOrganizationUsedCredits() {
  return useQuery("organizationUsedCredits", getOrganizationUsedCredits);
}

export function useDecodedToken() {
  const query = useRouteQuery();
  const token = query.get("token") ?? "";

  return useQuery(["decodedToken", token], () =>
    getDecodedAppraiserToken(token)
  );
}
