import React from "react";
import { TableRow } from "../tables/types";
import { AdjustmentDescription } from "../../types/api.types";
import { AdjustmentsDescriptionParagraphs } from "./AdjustmentsDescriptionParagraphs";
import { IconButton } from "common/components/buttons/IconButton";
import EditIcon from "../../assets/icons/edit.svg";
import { TableComponent } from "../tables/TableComponent";
import { Typography } from "common/components/Typography";

interface DescriptionsTableProps {
  descriptions: AdjustmentDescription[];
  onEdit?: (description: AdjustmentDescription) => void;
}

export const DescriptionsTable: React.FC<DescriptionsTableProps> = ({
  descriptions,
  onEdit,
}) => {
  const tableRows: TableRow[] = descriptions.map((d) => {
    return {
      header: d.header ?? "",
      elements: [
        <AdjustmentsDescriptionParagraphs description={d.description} />,
        onEdit ? (
          <IconButton
            onPress={() => {
              onEdit?.(d);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : null,
      ],
    };
  });

  return (
    <>
      <Typography variant="h2" textStyle={{ fontSize: 20 }}>
        Sandorių pataisų pagrindimas
      </Typography>
      <div style={{ height: "10px" }} />
      <TableComponent
        rows={tableRows}
        sx={{
          width: "100%",
        }}
      />
    </>
  );
};
