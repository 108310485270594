import {
  formatArea,
  formatAreaPrice,
  formatPrice,
} from "common/helpers/text.helpers";
import {
  Comparable,
  ComparableTransaction,
  ComparableTransactionFull,
  MatchedListing,
  // MatchedListing,
  Project,
  ProjectUnit,
} from "common/types/common.types";
import { getFinishingLabel } from "./helpers";
import { PropertyImageGallery } from "../imageGallery/PropertyImageGallery";
import { TransactionModal } from "./TransactionModal";
import { useState } from "react";
import { Button, ButtonProps } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "common/components/Typography";
import { TableRow } from "../tables/types";
import { isComparableFull } from "../../helpers/comparable.helpers";
import { LinkButton } from "../buttons/LinkButton";
import { LinkForButton } from "../links/LinkForButton";
import { TransactionScroll } from "./TransactionScroll";
import { CompareHeader } from "./CompareHeader";

const ButtonComponent = (props: ButtonProps) => (
  <Button
    {...props}
    sx={{
      px: 0,
      ...props.sx,
    }}
    endIcon={<NavigateNextIcon />}
  >
    žiūrėti daugiau
  </Button>
);

export type AnyTransaction = ProjectUnit &
  Project & { type: TransactionType; source: string } & MatchedListing &
  ComparableTransaction;

const TransactionModalComponent = ({
  transaction,
}: {
  transaction: AnyTransaction;
}) => {
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);

  if (transaction.type !== TransactionType.RCTransaction) {
    return null;
  }

  return (
    <>
      <ButtonComponent onClick={() => setIsTransactionModalOpen(true)} />
      <TransactionModal
        open={isTransactionModalOpen}
        id={transaction.id}
        onClose={() => setIsTransactionModalOpen(false)}
      />
    </>
  );
};

const getHeaderText = (transaction: AnyTransaction) => {
  switch (transaction.type) {
    case TransactionType.AruodasListing:
      return (
        <CompareHeader label="Identifikavimas su skelbimu" title="Aruodas.lt" />
      );
    case TransactionType.RealuListing:
      return (
        <CompareHeader label="Identifikavimas su skelbimu" title="Realu.lt" />
      );
    case TransactionType.Project:
      return (
        <CompareHeader
          label="Identifikavimas su pirmine rinka"
          title={transaction.project_name}
        />
      );
    case TransactionType.RCTransaction:
      return <CompareHeader title="Lyginamasis sandoris" />;
  }
};

type TransactionTableRow = {
  label: string;
  children?: TransactionTableRow[];
  parser: (
    transaction: AnyTransaction,
    index: number,
    transactionsCount: number
  ) => React.ReactNode;
  noBorder?: boolean;
};

const tableRows: TransactionTableRow[] = [
  {
    label: "",
    parser: (transaction) => (
      <Typography variant="h4">{getHeaderText(transaction)}</Typography>
    ),
    noBorder: true,
  },
  {
    label: "",
    parser: (transaction, index, transactionsCount) => (
      <TransactionScroll
        transaction={transaction}
        index={index}
        count={transactionsCount}
      />
    ),
    noBorder: true,
  },
  {
    label: "",
    parser: (transaction, index) => (
      <PropertyImageGallery
        key={transaction.id ?? index}
        height={226}
        width={268}
        images={
          transaction.images?.map((i) => ({ url: i })) ??
          transaction.visuals?.map((visual) => ({ url: visual.url_medium }))
        }
        boxShadow={3}
        borderRadius={5}
      />
    ),
    noBorder: true,
  },
  {
    label: "Sandorio data",
    parser: (transaction) => transaction["sold_date"],
  },
  {
    label: "Adresas",
    parser: (transaction) => transaction["address"],
  },
  {
    label: "Kainos palyginimas",
    parser: () => null,
    children: [
      {
        label: "Kv. kaina",
        parser: (transaction) =>
          !!transaction["sold_price_area"]
            ? formatAreaPrice(transaction["sold_price_area"])
            : "-",
      },
      {
        label: "Kaina",
        parser: (transaction) =>
          !!transaction["sold_price"]
            ? formatPrice(transaction["sold_price"])
            : "-",
      },
    ],
  },
  {
    label: "Objekto ypatybės",
    parser: () => null,
    children: [
      {
        label: "Plotas",
        parser: (transaction) => formatArea(transaction["area"]),
      },
      {
        label: "Kambarių skaičius",
        parser: (transaction) => transaction["rooms"],
      },
      {
        label: "Aukštas",
        parser: (transaction) => transaction["floor"],
      },
      {
        label: "Aukštų skaičius",
        parser: (transaction) => transaction["floor_total"],
      },
      {
        label: "Statybų metai",
        parser: (transaction) => transaction["construction_year"],
      },
      {
        label: "Rekonstrukcijos metai",
        parser: (transaction) => transaction["reconstruction_year"] ?? "-",
      },
      {
        label: "Baigtumas",
        parser: (transaction) =>
          getFinishingLabel(transaction["finishing"], undefined),
      },
    ],
  },
  {
    label: "Išsami informacija",
    parser: (transaction) => {
      if (transaction.type === TransactionType.RCTransaction) {
        if (
          !isComparableFull({
            comparable_transaction: transaction,
          } as unknown as Comparable)
        ) {
          return null;
        }
        return <TransactionModalComponent transaction={transaction} />;
      }

      const url = transaction.url;

      if (!!url) {
        return (
          <LinkForButton target="_blank" to={url}>
            <ButtonComponent />
          </LinkForButton>
        );
      }

      return null;
    },
  },
];

export enum TransactionType {
  AruodasListing,
  RealuListing,
  RCTransaction,
  ProjectUnit,
  Project,
}

export const getTransactionsTableRows = (
  transactions: AnyTransaction[][],
  selectedTransactions: number[],
  rows: TransactionTableRow[] = tableRows
): TableRow[] => {
  return rows.map((row) => ({
    header: row.label,
    elements: transactions.map((transaction, index) =>
      row.parser(
        transaction[selectedTransactions[index]],
        index,
        transaction.length
      )
    ),
    noBorder: row.noBorder,
    children: getTransactionsTableRows(
      transactions,
      selectedTransactions,
      row.children ?? []
    ),
  }));
};
