import { Button, Dialog } from "@mui/material";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { Divider } from "common/components/listItems/Divider";
import { Comparable, ComparableFull } from "common/types/common.types";
import React, { useEffect, useMemo, useState } from "react";
import { PropertyPlaceholderImage } from "../imageGallery/PropertyPlaceholderImage";
import { Typography } from "common/components/Typography";
import {
  getComparableAddress,
  getComparableImages,
} from "common/helpers/comparables.helpers";
import { InputField } from "common/components/form/InputField";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import _ from "lodash";
import colors from "common/styles/colors";
import { showToastError } from "common/toast/toast";

interface ComparableNumbersEditModalProps {
  open: boolean;
  onClose?: () => void;
  comps?: ComparableFull[];
  getDefaultValues: () => number[];
  updateValues: (values: number[]) => Promise<void>;
  headerText?: string;
  units?: string;
  withUpdateAll?: boolean;
  getDescriptions?: (
    comp: ComparableFull,
    value: number
  ) => { label: string; value: string }[];

  disabledIndexes?: number[];
}

export const ComparableNumbersEditModal: React.FC<
  ComparableNumbersEditModalProps
> = ({
  open,
  onClose,
  comps,
  getDefaultValues,
  updateValues,
  headerText,
  units,
  withUpdateAll,
  getDescriptions,
  disabledIndexes,
}) => {
  const [values, setValues] = useState(getDefaultValues);

  const [updateAllValue, setUpdateAllValue] = useState<number>();

  useEffect(() => {
    setValues(getDefaultValues());
  }, [comps?.length, open]);

  const [isLoading, setIsLoading] = useState(false);

  const descriptions = useMemo(() => {
    if (!comps) {
      return [];
    }
    return comps.map((comp, index) => {
      return getDescriptions?.(comp, values[index]);
    });
  }, [comps, values]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <div
        style={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h2">{headerText}</Typography>
          <div style={{ marginTop: "-10px", marginRight: "-10px" }}>
            <IconButton onPress={() => onClose?.()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ margin: "30px 0", width: "100%" }}>
          <Divider />
        </div>
        <div style={{ overflowY: "scroll" }}>
          {withUpdateAll && (
            <div
              style={{
                alignSelf: "flex-start",
                marginBottom: "20px",
              }}
            >
              <div style={{ marginBottom: "15px" }}>
                Pritaikyti vienodą pataisą visiems objektams
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "180px" }}>
                  <InputField
                    variant="decimal"
                    value={updateAllValue}
                    onChange={(value) => {
                      setUpdateAllValue(value as number);
                    }}
                    units={units}
                    fontSize={16}
                  />
                </div>
                <Button
                  disabled={updateAllValue === undefined}
                  onClick={() => {
                    if (!updateAllValue) return;
                    setValues(values.map(() => updateAllValue));
                    setUpdateAllValue(undefined);
                  }}
                >
                  pritaikyti visiems
                </Button>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "40px",
              overflowX: "scroll",
              width: "100%",
            }}
          >
            {comps?.map((comp, index) => (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  minWidth: "160px",
                }}
                key={index}
              >
                <PropertyPlaceholderImage
                  height={120}
                  width={150}
                  borderRadius={5}
                  uri={getComparableImages(comp.comparable_transaction)[0]?.url}
                />
                <Typography textStyle={{ fontSize: 16 }}>
                  {getComparableAddress(comp.comparable_transaction)}
                </Typography>
                {descriptions[index]?.map((description) => (
                  <div
                    key={description.label}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      textStyle={{ fontSize: 12, color: colors.grey }}
                    >
                      {description.label}
                    </Typography>
                    <Typography textStyle={{ fontSize: 12 }}>
                      {description.value}
                    </Typography>
                  </div>
                ))}
                <InputField
                  variant="decimal"
                  value={
                    disabledIndexes?.includes(index) ? null : values[index]
                  }
                  onChange={(value) => {
                    const newValues = _.cloneDeep(values);
                    newValues[index] = value as number;
                    setValues(newValues);
                  }}
                  units={units}
                  fontSize={16}
                  disabled={disabledIndexes?.includes(index)}
                />
              </div>
            ))}
          </div>
        </div>
        <MUILoadingButton
          loading={isLoading}
          onClick={async () => {
            if (
              values.some(
                (value) => value === undefined || value === null || isNaN(value)
              )
            ) {
              showToastError("Visi laukai turi būti užpildyti");
              return;
            }
            setIsLoading(true);
            await updateValues(values);
            onClose?.();
            setIsLoading(false);
          }}
          style={{ width: "345px", marginTop: "40px" }}
        >
          išsaugoti
        </MUILoadingButton>
      </div>
    </Dialog>
  );
};
