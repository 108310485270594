import { CheckboxButtonGroup } from "common/components/form/CheckboxButtonGroup";
import React, { useState } from "react";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { showToastError } from "common/toast/toast";
import { LinkBox } from "../../components/links/LinkBox";

interface PropertyShareSettingsProps {
  onChange?: (permissions: PropertyPermission[]) => void;
  initialPermissions?: PropertyPermission[];
  purchasedPermissions?: PropertyPermission[];
  children?: React.ReactNode;
}

export enum PropertyPermission {
  Edit = "edit",
  ViewProperty = "viewInfo",
  ViewValue = "viewValue",
  ViewComparables = "viewComparables",
  ViewCompositeAssets = "viewCompositeAssets",
  ViewCalculations = "viewCalculations",
  ViewDocuments = "viewDocuments",
  ViewDistances = "viewDistances",
  ViewMarketInsights = "viewMarketInsights",
}

const selectValues = [
  {
    label: "Informacija apie turtą",
    value: PropertyPermission.ViewProperty,
  },
  {
    label: "Turto kaina",
    value: PropertyPermission.ViewValue,
  },
  {
    label: "Atrinkti sandoriai",
    value: PropertyPermission.ViewComparables,
  },
  {
    label: "Priklausomas turtas",
    value: PropertyPermission.ViewCompositeAssets,
  },
  {
    label: "Skaičiavimai",
    value: PropertyPermission.ViewCalculations,
  },
  {
    label: "Dokumentai",
    value: PropertyPermission.ViewDocuments,
  },
  {
    label: "Atstumai",
    value: PropertyPermission.ViewDistances,
  },
  {
    label: "Rinkos apžvalga",
    value: PropertyPermission.ViewMarketInsights,
  },
];

export const PropertyShareSettings: React.FC<PropertyShareSettingsProps> = ({
  initialPermissions,
  purchasedPermissions,
  onChange,
  children,
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<
    PropertyPermission[]
  >(initialPermissions ?? []);

  return (
    <div style={{ width: "500px", padding: "20px" }}>
      <CheckboxButtonGroup
        values={selectValues.map((v) => ({
          ...v,
          label: purchasedPermissions?.includes(v.value)
            ? `${v.label} (Įsigyta)`
            : v.label,
        }))}
        disabledValues={purchasedPermissions}
        selectedValues={selectedPermissions}
        onSelect={(perm) => {
          const newPermissions = [...selectedPermissions, perm];
          setSelectedPermissions(newPermissions);
          onChange?.(newPermissions);
        }}
        onUnselect={(perm) => {
          const newPermissions = selectedPermissions.filter((p) => p !== perm);
          if (!newPermissions.length) {
            showToastError("Pasirinkite bent vieną leidimą.");
            return;
          }
          setSelectedPermissions(newPermissions);
          onChange?.(newPermissions);
        }}
        backgroundColor="white"
      />
      {children}
    </div>
  );
};
