import React from "react";
import { WithoutFeedbackButton } from "common/components/buttons/base/WithoutFeedbackButton";
import { Typography } from "common/components/Typography";
import { FormFieldWrapper } from "./FormFieldWrapper";
import EditIcon from "../../assets/icons/edit.svg";
import { baseInputFieldStyle } from "../../styles/form.styles";
import colors from "../../styles/colors";

interface LabelFieldProps {
  label?: string;
  error?: string;
  onPress?: () => void;
  value?: string;
  isRequired?: boolean;
  caption?: React.ReactNode;
}

export const LabelField: React.FC<LabelFieldProps> = ({
  label,
  error,
  onPress,
  value,
  isRequired,
  caption,
}) => {
  return (
    <FormFieldWrapper label={label} error={error} isRequired={isRequired}>
      {caption}
      <WithoutFeedbackButton
        onPress={onPress}
        style={[
          baseInputFieldStyle,
          {
            borderColor: error ? colors.red : baseInputFieldStyle.borderColor,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 20,
            height: 50,
          },
        ]}
      >
        <Typography style={{ fontSize: 20 }}>{value}</Typography>
        {!!onPress && <EditIcon />}
      </WithoutFeedbackButton>
    </FormFieldWrapper>
  );
};
