import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

interface PageContainerProps {}

export const pageContainerSxProps = {
  maxWidth: "1300px",
  padding: { xs: "15px 0px", md: "30px 30px" },
  margin: "0 auto",
};

export const PageContainer: React.FC<PageContainerProps> = ({}) => {
  return (
    <Box sx={pageContainerSxProps}>
      <Outlet />
    </Box>
  );
};
