import React from "react";
import { View } from "react-native";
import { formatPrice } from "common/helpers/text.helpers";
import { Divider } from "common/components/listItems/Divider";
import { Typography } from "common/components/Typography";

interface PriceComparableRowProps {
  label: string;
  price_area?: number | null;
  price?: number | null;
  fontSize?: number;
}

export const PriceComparableRow: React.FC<PriceComparableRowProps> = ({
  label,
  price_area,
  price,
  ...props
}) => {
  const fontSize = props.fontSize ?? 14;

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: 10,
        }}
      >
        <Typography
          textStyle={{
            fontSize,
          }}
        >
          {label}
        </Typography>
        <Typography
          textStyle={{
            fontSize,
            textAlign: "right",
            flex: 1,
            marginRight: 20,
          }}
        >
          {`${formatPrice(price_area)}/m²`}
        </Typography>
        <Typography
          textStyle={{
            fontSize,
          }}
        >
          {formatPrice(price)}
        </Typography>
      </View>
      <Divider />
    </View>
  );
};
