import { Comparable } from "common/types/common.types";
import React, { useContext, useMemo, useState } from "react";
import { MapActionButton } from "./MapActionButton";
import { isComparableFull } from "../../helpers/comparable.helpers";
import { useParams } from "react-router-dom";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  getEndingByCountLT4,
  getEndingByCountLTBudvardis,
} from "common/helpers/text.helpers";
import { Button } from "common/components/buttons/Button";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import BuyIcon from "../../assets/icons/buy.svg";

interface BulkBuyButtonProps {
  filteredComparables: Comparable[];
}

export const BulkBuyButton: React.FC<BulkBuyButtonProps> = ({
  filteredComparables,
}) => {
  const { propertyId } = useParams();
  const { buyTransactions, isBuying } = useContext(TransactionsRCContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const transactionsToBuy = useMemo(() => {
    let transactionsToBuy = [];

    for (const comp of filteredComparables) {
      if (!isComparableFull(comp)) {
        transactionsToBuy.push(comp.comparable_transaction.id);
      }
    }
    return transactionsToBuy;
  }, [filteredComparables]);

  return (
    <>
      <MapActionButton
        icon={<BuyIcon />}
        title="Pirkti sandorius"
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Ar tikrai norite pirkti visus sandorius?</DialogTitle>
        <DialogContent
          style={{ width: "500px", height: "50px" }}
        >{`Ar tikrai norite pirkti visus žemėlapyje pažymėtus sandorius (pažymėt${getEndingByCountLTBudvardis(
          transactionsToBuy.length
        ).toLowerCase()} ${
          transactionsToBuy.length
        } sandor${getEndingByCountLT4(
          transactionsToBuy.length
        ).toLowerCase()})?`}</DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <OutlinedButton
            disabled={!!isBuying?.length}
            onPress={() => setIsModalOpen(false)}
            style={{ width: "150px" }}
          >
            ATŠAUKTI
          </OutlinedButton>
          <Button
            isLoading={!!isBuying?.length}
            onPress={async () => {
              await buyTransactions?.(transactionsToBuy, propertyId ?? "");
              setIsModalOpen(false);
            }}
            backgroundColor="purple2"
            style={{ width: "150px" }}
            textStyle={{
              color: "white",
            }}
          >
            PATVIRTINTI
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
