import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface LinkForButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
}

export const LinkForButton: React.FC<LinkForButtonProps & LinkProps> = ({
  style,
  children,
  disabled,
  ...props
}) => {
  if (disabled) {
    return <div style={style}>{children}</div>;
  }
  return (
    <Link
      {...props}
      style={{ ...style, textDecoration: "none", color: "unset" }}
    >
      {children}
    </Link>
  );
};
