import React from "react";
import { ContentCard } from "../../components/ContentCard";
import { SettingsRow } from "./SettingsRow";
import { Toggle } from "../../components/form/toggle/Toggle";
import { useOrganization } from "../../hooks/organization.hooks";
import { useMutation, useQueryClient } from "react-query";
import { setOrganizationTransactionsRetention } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { Organization } from "../../types/api.types";
import { getEndingByCountLT4 } from "common/helpers/text.helpers";

interface OrganizationSettingsRouteProps {}

export const OrganizationSettingsRoute: React.FC<
  OrganizationSettingsRouteProps
> = () => {
  const { data, refetch } = useOrganization();

  const queryClient = useQueryClient();

  const { mutate } = useMutation(setOrganizationTransactionsRetention, {
    onMutate: (retentionDays: number | null) => {
      const previousOrganization = data;
      queryClient.setQueryData<Organization | undefined>(
        "organization",
        (old) => {
          if (!old) return undefined;
          return {
            ...old,
            transactionRetentionDays: retentionDays,
          };
        }
      );

      return { previousOrganization };
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Organization | undefined>("organization", data);
      showToastSuccess("Nustatymai išsaugoti");
      refetch();
    },
    onError: (_, __, context) => {
      queryClient.setQueryData<Organization | undefined>(
        "organization",
        context?.previousOrganization
      );
      showToastError();
    },
  });

  if (!data) {
    return null;
  }

  const isChecked = (months: number | null) => {
    if (
      data.transactionRetentionDays === null ||
      data.transactionRetentionDays === undefined
    ) {
      return months === null;
    }
    if (months === null) {
      return false;
    }
    return data.transactionRetentionDays === months * 30;
  };

  return (
    <ContentCard title="Nustatymai">
      <SettingsRow label="RC galutinis naudotojas">
        {!!data.RC_EndUserInfo &&
          Object.entries(data.RC_EndUserInfo).map(([key, value]) => (
            <div key={key}>
              <b>{key}</b>: {value}
            </div>
          ))}
      </SettingsRow>
      <div style={{ height: "30px" }} />
      <SettingsRow label="Sandorių saugojimo laikotarpis">
        <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
          {[3, 12, null].map((months) => (
            <Toggle
              isChecked={isChecked(months)}
              onSelect={() => mutate(months === null ? null : months * 30)}
              label={
                months !== null
                  ? `${months} mėnes${getEndingByCountLT4(
                      months
                    ).toLowerCase()}`
                  : "Neribotai"
              }
            />
          ))}
        </div>
      </SettingsRow>
      <div style={{ marginTop: "50px" }} />
    </ContentCard>
  );
};
