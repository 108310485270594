import React, { useMemo, useState } from "react";
import { downloadTransactionsExcel } from "../../helpers/file.helpers";
import { useParams } from "react-router-dom";
import { isComparableFull } from "../../helpers/comparable.helpers";
import { Comparable } from "common/types/common.types";
import { MapActionButton } from "./MapActionButton";
import { showToastError } from "common/toast/toast";
import DownloadIcon from "../../assets/icons/download.svg";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import {
  getEndingByCountLT4,
  getEndingByCountLT5,
  getEndingByCountLTBudvardis,
  getEndingByCountLTKilmininkas,
} from "common/helpers/text.helpers";

interface BulkDownloadButtonProps {
  filteredComparables: Comparable[];
}

export const BulkDownloadButton: React.FC<BulkDownloadButtonProps> = ({
  filteredComparables,
}) => {
  const { propertyId } = useParams();

  const { downloadableTransactions, notDowloadableCount } = useMemo(() => {
    const transactions = [];
    let notDowloadableCount = 0;
    for (const comp of filteredComparables) {
      if (isComparableFull(comp)) {
        transactions.push(comp.comparable_transaction.id);
      } else {
        notDowloadableCount++;
      }
    }
    return { downloadableTransactions: transactions, notDowloadableCount };
  }, [filteredComparables]);

  const [isDownloading, setIsDownloading] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!downloadableTransactions.length) {
    return null;
  }

  const allCount = downloadableTransactions.length + notDowloadableCount;

  return (
    <>
      <MapActionButton
        icon={<DownloadIcon />}
        title="Atsisiųsti sandorius"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      />
      <ConfirmationDialog
        isOpen={isDialogOpen}
        title={`Ar norite atsisiųsti ${
          downloadableTransactions.length
        } įsigyt${getEndingByCountLTKilmininkas(
          downloadableTransactions.length
        ).toLowerCase()} sandor${getEndingByCountLT5(
          downloadableTransactions.length
        ).toLowerCase()}?`}
        description={`Žemėlapio režime pažymėti ${allCount} sandor${getEndingByCountLT4(
          allCount
        ).toLowerCase()}, iš jų ${notDowloadableCount} neįsigyt${getEndingByCountLTBudvardis(
          notDowloadableCount
        ).toLowerCase()}`}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={async () => {
          if (isDownloading) {
            return;
          }

          setIsDownloading(true);

          try {
            await downloadTransactionsExcel(
              downloadableTransactions,
              propertyId ?? ""
            );
            setIsDialogOpen(false);
          } catch (_) {
            showToastError();
          } finally {
            setIsDownloading(false);
          }
        }}
        actionButtonText="ATSISIŲSTI"
        hideDoNotShowAgain
        isLoading={isDownloading}
      />
    </>
  );
};
