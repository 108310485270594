import { IconButton } from "common/components/buttons/IconButton";
import { getRoundedPercent } from "common/helpers/common.helpers";
import { Comparable, ComparableFull } from "common/types/common.types";
import EditIcon from "../../assets/icons/edit.svg";
import React, { useState } from "react";
import { ComparableWeightEditModal } from "./ComparableWeightEditModal";
import { LinkButton } from "../buttons/LinkButton";

interface WeightsSummaryHeaderProps {
  comps: ComparableFull[];
  propertyId: string;
  readonly?: boolean;
}

export const WeightsSummaryHeader: React.FC<WeightsSummaryHeaderProps> = ({
  comps,
  propertyId,
  readonly,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      Svertinis svorio koeficientas
      {!readonly && (
        <>
          <LinkButton onClick={() => setIsModalOpen(true)}>
            Koreguoti koeficientus
          </LinkButton>
          <ComparableWeightEditModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            comps={comps}
            propertyId={propertyId}
          />
        </>
      )}
    </div>
  );
};
