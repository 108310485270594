import { Box } from "@mui/material";
import colors from "common/styles/colors";
import React from "react";
import PurpleCheckIcon from "../../../assets/icons/purpleCheck.svg";
import { Typography } from "common/components/Typography";

interface ToggleProps {
  isChecked: boolean;
  label: string;
  onSelect: () => void;
}

export const Toggle: React.FC<ToggleProps> = ({
  isChecked,
  onSelect,
  label,
}) => {
  const color = isChecked ? colors.purple2 : colors.beige;
  const bgColor = isChecked ? colors.purple4 : colors.white;

  return (
    <Box
      sx={{
        height: "32px",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: color,
        backgroundColor: bgColor,
        cursor: "pointer",
        px: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      onClick={onSelect}
    >
      {isChecked && (
        <div style={{ marginRight: "10px" }}>
          <PurpleCheckIcon />
        </div>
      )}
      <Typography textStyle={{ fontSize: 16, color }}>{label}</Typography>
    </Box>
  );
};
