import React from "react";
import { LinkForButton } from "../../components/links/LinkForButton";
import { Button } from "@mui/material";

interface PropertyEditButtonProps {
  propertyId: string;
  disabled?: boolean;
  to?: string;
}

export const PropertyEditButton: React.FC<PropertyEditButtonProps> = ({
  propertyId,
  disabled,
  to,
}) => {
  const ButtonComponent = (
    <Button variant="contained" disabled={disabled}>
      papildyti turto ypatybes
    </Button>
  );

  if (disabled) {
    return ButtonComponent;
  }

  return (
    <LinkForButton
      to={to ?? `/properties/${propertyId}/details`}
      style={{ alignSelf: "center", margin: "15px 0" }}
    >
      {ButtonComponent}
    </LinkForButton>
  );
};
