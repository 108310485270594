import colors from "common/styles/colors";
import React from "react";

interface ComparablesCountOverviewWrapperProps {
  children?: React.ReactNode;
  title: string;
}

export const ComparablesCountOverviewWrapper: React.FC<
  ComparablesCountOverviewWrapperProps
> = ({ children, title }) => {
  return (
    <div
      style={{
        backgroundColor: colors.greybg,
        padding: "20px",
        borderRadius: "5px",
      }}
    >
      <div style={{ fontWeight: 600, marginBottom: "10px" }}>{title}</div>
      {children}
    </div>
  );
};
