import React from "react";
import { View } from "react-native";
import {
  CommuteElement,
  CommuteMode,
  CommuteModel,
} from "common/types/common.types";
import PedestrianIcon from "../../assets/icons/commutes/pedestrian.svg";
import CarIcon from "../../assets/icons/commutes/car.svg";
import BusIcon from "../../assets/icons/commutes/bus.svg";
import { Typography } from "common/components/Typography";
import { round } from "common/helpers/common.helpers";
import { Skeleton } from "../skeleton/Skeleton";
import { isWeb } from "../../environment";

interface CommuteDistanceProps {
  commutes?: CommuteElement[];
  mode: CommuteMode;
  isLoading?: boolean;
}

const digitsToRoundDistance = 1;
const digitsToRoundTime = 0;

const roundTime = (time: number | undefined) => {
  if (!time) {
    return undefined;
  }
  return round(time / 60, digitsToRoundTime);
};

export const CommuteDistance: React.FC<CommuteDistanceProps> = ({
  commutes,
  mode,
  isLoading,
}) => {
  const commutesByMode = commutes?.filter((commute) => commute.mode === mode);

  const timeLabel = (() => {
    if (!commutesByMode) return undefined;
    let timeLabel;
    let pessimisticTime = commutesByMode.find(
      (commute) => commute.model === CommuteModel.Pessimistic
    )?.duration;
    pessimisticTime = roundTime(pessimisticTime);
    let defaultTime = commutesByMode.find(
      (commute) => commute.model !== CommuteModel.Pessimistic
    )?.duration;
    defaultTime = roundTime(defaultTime) ?? 0;
    if (defaultTime && pessimisticTime && defaultTime !== pessimisticTime) {
      timeLabel = `~${defaultTime}-${pessimisticTime} min`;
    } else {
      timeLabel = `${defaultTime} min`;
    }
    return timeLabel;
  })();

  const distanceLabel = (() => {
    if (!commutesByMode) return undefined;
    const distanceLabel = `${round(
      commutesByMode[0].distance / 1000,
      digitsToRoundDistance
    )} km`;
    return distanceLabel;
  })();

  const Icon = (() => {
    switch (mode) {
      case CommuteMode.Driving:
        // @ts-ignore
        return <CarIcon width={16} />;
      case CommuteMode.Transit:
        // @ts-ignore
        return <BusIcon width={15} />;
      case CommuteMode.Walking:
        // @ts-ignore
        return <PedestrianIcon width={12} />;
      default:
        return null;
    }
  })();

  return (
    <View
      style={{ alignItems: "center", flexDirection: "row", marginRight: 16 }}
    >
      <View
        style={{ width: 20, height: 22, alignItems: "center", marginRight: 8 }}
      >
        {Icon}
      </View>
      {isLoading ? (
        <Skeleton height={40} width={50} />
      ) : (
        <View
          style={{
            height: isWeb() ? 25 : 40,
            justifyContent: "center",
            flexDirection: isWeb() ? "row" : "column",
            alignItems: isWeb() ? "center" : "flex-start",
            gap: isWeb() ? 10 : 0,
          }}
        >
          <Typography textStyle={{ fontSize: 12 }}>{timeLabel}</Typography>
          <Typography textStyle={{ fontSize: 12 }}>{distanceLabel}</Typography>
        </View>
      )}
    </View>
  );
};
