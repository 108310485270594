import React from "react";
import { BasicOrganization as BasicOrganizationType } from "../../types/api.types";
import { BasicInfoCard } from "./BasicInfoCard";

interface BasicOrganizationProps {
  organization: BasicOrganizationType;
}

export const BasicOrganization: React.FC<BasicOrganizationProps> = ({
  organization,
}) => {
  return (
    <BasicInfoCard
      imageUrl={organization.logo}
      name={organization.name}
      description={organization.description}
      infoRows={[
        { label: "El. pašto adresas", value: organization.email },
        { label: "Telefono numeris", value: organization.phone },
        { label: "Tinklapis", value: organization.website },
      ]}
    />
  );
};
