import React, { useState } from "react";
import { ContentCard } from "../../components/ContentCard";
import { getPropertyComment } from "../../api/api";
import { useQuery } from "react-query";
import { Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { PropertyCommentEdit } from "./PropertyCommentEdit";
import { usePropertyEditRights } from "../../hooks/property.hooks";
import EditIcon from "@mui/icons-material/Edit";
import { PropertyCommentContent } from "./PropertyCommentContent";

interface PropertyCommentProps {
  propertyId: string;
}

export const PropertyComment: React.FC<PropertyCommentProps> = ({
  propertyId,
}) => {
  const { data, isLoading, isError } = useQuery(
    ["propertyComment", propertyId],
    () => getPropertyComment(propertyId)
  );

  const [isEditOpen, setIsEditOpen] = useState(false);

  const { canEdit } = usePropertyEditRights(propertyId);

  if (isLoading || isError) {
    return null;
  }

  return (
    <ContentCard title="Vertintojo komentaras">
      {!!data ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <PropertyCommentContent data={data} />
          {canEdit && (
            <IconButton
              onClick={() => {
                setIsEditOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>Nėra pridėtos papildomos informacijos</div>
          {canEdit && (
            <Button
              onClick={() => {
                setIsEditOpen(true);
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Pridėti
            </Button>
          )}
        </div>
      )}
      <PropertyCommentEdit
        open={isEditOpen}
        initialComment={data}
        propertyId={propertyId}
        onClose={() => {
          setIsEditOpen(false);
        }}
      />
    </ContentCard>
  );
};
