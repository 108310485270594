import colors from "common/styles/colors";
import React from "react";

interface LeadLandingPageActionItemProps {
  index: number;
  title: string;
  children?: React.ReactNode;
}

export const LeadLandingPageActionItem: React.FC<
  LeadLandingPageActionItemProps
> = ({ index, title, children }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "12px",
            backgroundColor: colors.purple2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            color: "white",
            fontWeight: 500,
          }}
        >
          {index}
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          {title}
        </div>
      </div>
      {children}
    </div>
  );
};
