import React, { useRef, useState } from "react";
import {
  Image,
  PropertyImage,
  PropertyPhotoCategory,
  PropertyPhotoCategoryTranslations,
} from "common/types/common.types";
import { PropertyPlaceholderImage } from "./PropertyPlaceholderImage";
import { ImageGallery } from "./ImageGallery";
import { Box } from "@mui/material";
import {
  getImageWidth,
  getResponsiveImageUri,
} from "common/helpers/image.helpers";

interface PropertyImageGalleryProps {
  images?: PropertyImage[];
  headerText?: string;
  height?: number | string;
  width?: number;
  borderRadius?: number;
  boxShadow?: number;
  borderBottomLeftRadius?: number;
  borderTopLeftRadius?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const PropertyImageGallery: React.FC<PropertyImageGalleryProps> = ({
  images,
  headerText,
  height,
  width,
  borderRadius,
  boxShadow,
  borderBottomLeftRadius,
  borderTopLeftRadius,
  onMouseEnter,
  onMouseLeave,
}) => {
  const firstImageUrl = images?.length ? images[0].url : undefined;
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const hasGallery = !!images?.length;

  const bRadius = borderRadius ?? 10;

  const windowWidthRef = useRef(window.innerWidth);

  return (
    <Box
      onClick={(e) => {
        if (hasGallery) {
          e.stopPropagation();
          setIsGalleryOpen(true);
        }
      }}
      sx={{
        cursor: hasGallery ? "pointer" : "auto",
        boxShadow,
        borderRadius: `${bRadius}px`,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PropertyPlaceholderImage
        borderRadius={bRadius === 0 ? undefined : bRadius}
        height={height ?? 330}
        width={width}
        uri={firstImageUrl}
        borderBottomLeftRadius={borderBottomLeftRadius}
        borderTopLeftRadius={borderTopLeftRadius}
        style={{
          height,
          width,
        }}
      />
      {hasGallery && (
        <ImageGallery
          images={
            images?.map((img) =>
              getResponsiveImageUri(
                img.url,
                getImageWidth(windowWidthRef.current)
              )
            ) ?? []
          }
          getHeaderSubtext={(index) =>
            PropertyPhotoCategoryTranslations[
              images?.[index]?.category ?? PropertyPhotoCategory.Other
            ]
          }
          isOpen={isGalleryOpen}
          onClose={() => {
            setIsGalleryOpen(false);
            onMouseLeave?.();
          }}
          headerText={headerText}
        />
      )}
    </Box>
  );
};
