import { ViewStyle } from "react-native";
import colors from "./colors";

export const baseInputFieldStyle: ViewStyle = {
  borderColor: colors.greyborder,
  borderWidth: 1,
  borderRadius: 5,
};

export const inputFieldStyle: ViewStyle = {
  ...baseInputFieldStyle,
  maxWidth: 230,
};
