import React from "react";
import { Box } from "@mui/material";

interface FormColumnsProps {
  children: React.ReactNode;
}

export const FormColumns: React.FC<FormColumnsProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        gap: "20px",
      }}
    >
      {React.Children.map(children, (child) => (
        <div style={{ flex: 1, width: "100%", alignSelf: "flex-start" }}>
          {child}
        </div>
      ))}
    </Box>
  );
};
