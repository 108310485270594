import { Button } from "@mui/material";
import { Typography } from "common/components/Typography";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { ComparableMessageModal } from "../components/messages/ComparableMessageModal";
import { ComparableMessage } from "../components/messages/ComparableMessage";
import { useComparableMessages } from "../components/messages/hooks";

interface ComparableMessagesProps {}

export const ComparableMessages: React.FC<ComparableMessagesProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = useComparableMessages();

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <Typography variant="h1" textStyle={{ fontSize: 26 }}>
          Informaciniai pranešimai
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          naujas pranešimas
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {data?.map((message, index) => (
          <ComparableMessage key={index} message={message} />
        ))}
      </div>
      <ComparableMessageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
