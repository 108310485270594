import { Dialog } from "@mui/material";
import React, { useEffect } from "react";
import * as yup from "yup";
import {
  ComparableMessage,
  ComparableMessageCreateRequest,
  MessageType,
} from "../../types/api.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormSelect } from "../form/FormSelect";
import { FormContainer } from "../containers/FormContainer";
import { useMutation, useQueryClient } from "react-query";
import {
  createComparableMessage,
  updateComparableMessage,
} from "../../api/api";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import { ErrorAlert } from "../alerts/ErrorAlert";
import { FormInputBox } from "../form/FormInputBox";
import { Typography } from "common/components/Typography";
import { IconButton } from "common/components/buttons/IconButton";
import { Divider } from "common/components/listItems/Divider";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { getMessageTypeIcon, getMessageTypeTitle } from "./helpers";
import { useMessagesStatusInvalidate } from "./hooks";

interface ComparableMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMessage?: ComparableMessageCreateRequest;
  messageId?: string;
}

const validationSchema = yup.object({
  type: yup
    .mixed<MessageType>()
    .oneOf(Object.values(MessageType))
    .required("Message type is required"),
  message: yup.string().required("Message content is required"),
  transactionId: yup.string().required("Transaction ID is required"),
});

export const ComparableMessageModal: React.FC<ComparableMessageModalProps> = ({
  isOpen,
  onClose,
  initialMessage,
  messageId,
}) => {
  const { comparableId } = useParams();
  const methods = useForm<ComparableMessageCreateRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...initialMessage,
      transactionId: comparableId,
    },
  });

  useEffect(() => {
    methods.reset({ ...initialMessage, transactionId: comparableId });
  }, [isOpen]);

  const queryClient = useQueryClient();

  const { invalidate } = useMessagesStatusInvalidate();

  const { mutate, isLoading, error } = useMutation(
    !messageId
      ? createComparableMessage
      : (data: ComparableMessageCreateRequest) =>
          updateComparableMessage(data, messageId),
    {
      onSuccess: (message) => {
        queryClient.setQueryData<ComparableMessage[]>(
          ["comparable_messages", comparableId ?? ""],
          (data) => {
            if (data?.some((m) => m._id === messageId)) {
              return data.map((m) => {
                if (m._id === message._id) {
                  return message;
                }
                return m;
              });
            }
            return [...(data ?? []), message];
          }
        );
        onClose();
        invalidate();
      },
    }
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div style={{ width: "600px" }}>
        <div
          style={{
            margin: "15px 24px",

            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">
            {!messageId ? "Naujas pranešimas" : "Koreguoti pranešimą"}
          </Typography>
          <IconButton onPress={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginHorizontal: "24px" }} />
        <FormContainer
          onSubmit={methods.handleSubmit((data) => mutate(data))}
          containerProps={{ maxWidth: "md" }}
        >
          <ErrorAlert error={error} />
          <FormProvider {...methods}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
              }}
            >
              <FormSelect
                label="Pranešimo priežastis"
                name="type"
                values={Object.values(MessageType)}
                getIcon={(value) => getMessageTypeIcon(value as MessageType)}
                getTitle={(value) => getMessageTypeTitle(value as MessageType)}
              />
            </div>
            <FormInputBox label="Komentaras" name="message" rows={4} />
          </FormProvider>
          <MUILoadingButton
            loading={isLoading}
            type="submit"
            style={{
              marginTop: "30px",
              width: "250px",
              alignSelf: "center",
              marginBottom: "30px",
            }}
          >
            išsaugoti
          </MUILoadingButton>
        </FormContainer>
      </div>
    </Dialog>
  );
};
