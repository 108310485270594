import React, { useEffect, useState } from "react";
import { setPropertyAppraiser } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { contentCardSx } from "../../components/ContentCard";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { InfoRow } from "../propertiesCRUD/InfoRow";
import {
  Appraiser,
  AppraiserProperty,
  AppraiserRole,
} from "../../types/appraiser.types";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";
import { useAppraisersInOrganization } from "../../hooks/auth.hooks";
import { showToastError } from "common/toast/toast";

interface PropertyAppraiserSetProps {
  property: AppraiserProperty;
}

export const PropertyAppraiserSet: React.FC<PropertyAppraiserSetProps> = ({
  property,
}) => {
  const [open, setOpen] = React.useState(false);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(setPropertyAppraiser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["appraiserProperties", property._id]);
      setOpen(false);
    },
  });

  const { data: appraisers, isLoading: isLoadingAppraisers } =
    useAppraisersInOrganization();

  const [selectedAppraiser, setSelectedAppraiser] = useState<Appraiser | null>(
    property.appraiser.role === AppraiserRole.ORGANIZATION_LEAD
      ? null
      : property.appraiser
  );

  return (
    <Box
      sx={{
        ...contentCardSx,
        display: "flex",
        alignItems: "center",
        gap: "40px",
      }}
    >
      <InfoRow
        label="Turto vertintojas"
        value={
          property.appraiser.role === AppraiserRole.ORGANIZATION_LEAD
            ? "-"
            : property.appraiser.email
        }
      />
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Keisti
      </Button>
      <SimpleDialog
        title="Pasirinkite vertintoją"
        open={open}
        isLoading={isLoading}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={() => {
          if (selectedAppraiser) {
            mutate({
              propertyId: property._id,
              appraiserId: selectedAppraiser._id,
            });
          } else {
            showToastError("Nepasirinktas vertintojas");
          }
        }}
      >
        <Autocomplete
          options={appraisers ?? []}
          loading={isLoadingAppraisers}
          value={selectedAppraiser}
          onChange={(_, newAppraiser) => {
            setSelectedAppraiser(newAppraiser);
          }}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option.email}
          isOptionEqualToValue={(option, value) => option._id === value?._id}
          style={{
            width: "350px",
          }}
        />
      </SimpleDialog>
    </Box>
  );
};
