import React from "react";
import PlusIcon from "../../assets/icons/math/plus.svg";
import MinusIcon from "../../assets/icons/math/minus.svg";
import { View, ViewStyle } from "react-native";
import { Typography } from "common/components/Typography";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { inputFieldStyle } from "../../styles/form.styles";
import colors from "../../styles/colors";
import { IconButton } from "../buttons/IconButton";

interface SmallNumberInputProps {
  value?: number;
  onChange?: (value: number) => void;
  maxValue: number;
  minValue: number;
  label?: string;
  error?: string;
  isRequired?: boolean;
  customStyle?: ViewStyle;
}

export const SmallNumberInput: React.FC<SmallNumberInputProps> = ({
  value,
  onChange,
  label,
  minValue,
  maxValue,
  error,
  isRequired,
  customStyle,
}) => {
  const isMinusDisabled = value === minValue;
  const isPlusDisabled = value === maxValue;

  const isValueInvalid = value === undefined || value === null || isNaN(value);
  return (
    <FormFieldWrapper label={label} error={error} isRequired={isRequired}>
      <View
        style={{
          ...inputFieldStyle,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderColor: error ? colors.red : inputFieldStyle.borderColor,
          height: 50,
          ...customStyle,
        }}
      >
        <IconButton
          isDisabled={isMinusDisabled}
          onPress={() => {
            if (isValueInvalid) {
              onChange?.(minValue);
            } else {
              onChange?.(value - 1);
            }
          }}
        >
          <MinusIcon />
        </IconButton>

        <Typography style={{ fontSize: 20 }}>{value}</Typography>

        <IconButton
          isDisabled={isPlusDisabled}
          onPress={() => {
            if (isValueInvalid) {
              onChange?.(minValue);
            } else {
              onChange?.(value + 1);
            }
          }}
        >
          <PlusIcon />
        </IconButton>
      </View>
    </FormFieldWrapper>
  );
};
