import { InputField } from "common/components/form/InputField";
import {
  AdditionalREType,
  Adjustments,
  RelatedRE,
} from "common/types/common.types";
import _ from "lodash";
import React from "react";

interface AdditionalREFieldProps {
  type: AdditionalREType;
  adjustments: Adjustments;
  onChange?: (adjustments: Adjustments) => void;
  relatedRE?: RelatedRE;
}

const typeDict: { [key in AdditionalREType]: string } = {
  [AdditionalREType.Basement]: "Sandėliukas",
  [AdditionalREType.Garage]: "Garažas",
  [AdditionalREType.PlotArea]: "Sklypas",
};

export const AdditionalREField: React.FC<AdditionalREFieldProps> = ({
  type,
  adjustments,
  onChange,
  relatedRE,
}) => {
  const additionalRE = adjustments.additional_re?.find(
    (re) => re.type === type
  );
  const caption = relatedRE
    ? `${relatedRE.details} (${relatedRE.area} m²)`
    : undefined;

  return (
    <div style={{ width: "200px" }}>
      <InputField
        value={additionalRE?.price}
        label={typeDict[type]}
        variant="decimal"
        wrapperProps={{
          caption: caption,
          textVariant: "body",
          textStyle: {
            fontSize: 16,
          },
          gap: 8,
        }}
        maxWidth={200}
        units="€"
        onChange={(value) => {
          const deepAdjustmentsClone = _.cloneDeep(adjustments);
          const re = deepAdjustmentsClone.additional_re?.find(
            (re) => re.type === type
          );
          const price = (value as number | undefined) ?? 0;
          if (!re) {
            deepAdjustmentsClone.additional_re = [
              ...(deepAdjustmentsClone.additional_re ?? []),
              { type, price },
            ];
          } else {
            re.price = price;
          }
          onChange?.(deepAdjustmentsClone);
        }}
      />
    </div>
  );
};
