import { useFormContext } from "react-hook-form";
import { modifyErrorText } from "../helpers/form.helpers";

export const useFormFieldErrors = (name: string, label?: string) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name]?.message?.toString();
  const errorText = error ? modifyErrorText(error, label) : undefined;
  return { errorText };
};
