import React, { useState } from "react";
import { ComparableMessage as ComparableMessageType } from "../../types/api.types";
import { IconButton } from "common/components/buttons/IconButton";
import EditIcon from "../../assets/icons/edit.svg";
import { ComparableMessageModal } from "./ComparableMessageModal";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import colors from "common/styles/colors";
import { getMessageTypeIcon, getMessageTypeTitle } from "./helpers";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { ComparableMessageDeleteModal } from "./ComparableMessageDeleteModal";
import { AppraiserRole } from "../../types/appraiser.types";
import { useAppraiser } from "../../hooks/auth.hooks";

interface ComparableMessageProps {
  message: ComparableMessageType;
}

const thStyle: React.CSSProperties = {
  textAlign: "left",
  paddingRight: "24px",
  fontWeight: 400,
  color: colors.grey,
  verticalAlign: "top",
};

export const ComparableMessage: React.FC<ComparableMessageProps> = ({
  message,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const Icon = getMessageTypeIcon(message.type);
  const { data: appraiser } = useAppraiser();

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <table>
        <tbody>
          <tr>
            <th style={thStyle}>Data</th>
            <td>{getLithuanianDateString(message.updatedAt)}</td>
          </tr>
          <tr>
            <th style={thStyle}>Priežastis</th>
            <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Icon />
              {getMessageTypeTitle(message.type)}
            </td>
          </tr>
          <tr>
            <th style={thStyle}>Komentaras</th>
            <td>{message.message}</td>
          </tr>
        </tbody>
      </table>
      {(appraiser?.role === AppraiserRole.ADMIN ||
        appraiser?._id === message.appraiserId) && (
        <>
          <IconButton
            onPress={() => {
              setIsDeleteModalOpen(true);
            }}
            style={{
              position: "absolute",
              right: 50,
              top: 10,
            }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            onPress={() => {
              setIsModalOpen(true);
            }}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
      <ComparableMessageDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        messageId={message._id}
      />

      <ComparableMessageModal
        isOpen={isModalOpen}
        messageId={message._id}
        initialMessage={{
          type: message.type,
          message: message.message,
          transactionId: message.transactionId,
        }}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
