import colors from "common/styles/colors";

export const labelStyle = {
  height: "50px",
  alignItems: "center",
  display: "flex",
  borderColor: colors.greyborder,
  borderRadius: "5px",
  borderWidth: "1px",
  padding: "12px",
  borderStyle: "solid",
};
