import React from "react";
import { Pressable, StyleProp, ViewStyle } from "react-native";

interface WithoutFeedbackButtonProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const WithoutFeedbackButton: React.FC<WithoutFeedbackButtonProps> = (
  props
) => {
  return (
    <Pressable onPress={props.onPress} style={props.style}>
      {props.children}
    </Pressable>
  );
};
