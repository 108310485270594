import { Divider } from "@mui/material";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import React from "react";

interface ContentCardTitleProps {
  title: string;
  caption?: string;
  dividerMargin?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

export const ContentCardTitle: React.FC<ContentCardTitleProps> = ({
  title,
  caption,
  dividerMargin,
  children,
  icon,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h1" textStyle={{ fontSize: 26 }}>
            {title}
          </Typography>
          {!!caption && (
            <Typography
              variant="caption"
              textStyle={{ marginTop: 10, color: colors.beige }}
            >
              {caption}
            </Typography>
          )}
          {children}
        </div>
        {icon}
      </div>
      <Divider style={{ margin: dividerMargin ?? "30px 0" }} />
    </div>
  );
};
