import axios from "axios";
import {
  BuildingAutocompleteResult,
  CityAutocompleteResult,
  EnergyClass,
  GeocodedAddress,
  StreetAutocompleteResult,
} from "../types/geocoding.types";

const geocodeInstance = axios.create({
  baseURL: "https://citynow-geocoding-fe4bv.ondigitalocean.app",
});

export const getCities = async (query: string) => {
  const { data } = await geocodeInstance.get<CityAutocompleteResult[]>(
    `/autocomplete/residential_areas`,
    {
      params: {
        city: query,
      },
    }
  );
  return data;
};

export const getStreets = async (query: string, cityId: string) => {
  const { data } = await geocodeInstance.get<StreetAutocompleteResult[]>(
    `/autocomplete/streets`,
    {
      params: {
        street: query,
        city_id: cityId,
      },
    }
  );
  return data;
};

export const getBuildingNumbers = async (
  query: string,
  streetId: string,
  cityId: string
) => {
  const { data } = await geocodeInstance.get<BuildingAutocompleteResult[]>(
    `/autocomplete/building_numbers`,
    {
      params: {
        building_num: query,
        street_id: streetId,
        city_id: cityId,
      },
    }
  );
  return data;
};

export const getGeocodedAddress = async (lat: number, lng: number) => {
  const { data } = await geocodeInstance.get<GeocodedAddress | null>(
    `/geocode/reverse`,
    {
      params: {
        lat,
        lng,
      },
    }
  );
  return data;
};

export const getEnergyClass = async (
  street_id: string,
  building_nr: string
) => {
  const { data } = await geocodeInstance.get<EnergyClass | null>(
    `/energy_classes/by_address`,
    {
      params: {
        street_id,
        building_nr,
      },
    }
  );
  return data;
};
