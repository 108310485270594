import React, { useState } from "react";
import { TableComponent } from "../tables/TableComponent";
import { useParams } from "react-router-dom";
import { useComparable } from "../../hooks/property.hooks";
import {
  AnyTransaction,
  TransactionType,
  getTransactionsTableRows,
} from "./tableTransaction.utils";
import { ContentCard } from "../ContentCard";
import { useQuery } from "react-query";
import { getAllMatches } from "../../api/api";
import { Comparable } from "common/types/common.types";
import { CircularProgress } from "@mui/material";
import { TransactionsScrollContext } from "./TransactionsScrollContext";

interface TransactionsCompareProps {}

export const TransactionsCompare: React.FC<TransactionsCompareProps> = ({}) => {
  const { comparableId } = useParams();
  const comparable = useComparable(comparableId ?? "");

  const { data, isLoading } = useQuery(
    ["all_matches", comparable?.comparable_transaction.id],
    () => getAllMatches((comparable as Comparable)?.comparable_transaction),
    {
      staleTime: Infinity,
      enabled: !!comparable,
    }
  );

  const [selectedTransactions, setSelectedTransactions] = useState<number[]>(
    new Array(10).fill(0)
  );

  if (!comparable) {
    return <div>Comparable not found</div>;
  }

  const getProjectsGroup = () => {
    if ((data?.project_units.length ?? 0) > 0) {
      return data?.project_units ?? [];
    }
    return data?.projects ?? [];
  };

  const transactionsGroups = [
    [
      {
        ...comparable?.comparable_transaction,
        type: TransactionType.RCTransaction,
      } as AnyTransaction,
    ],
    data?.listings
      .filter((listing) => listing.source === "aruodas_lt")
      .map((listing) => ({
        ...listing,
        type: TransactionType.AruodasListing,
      })) ?? [],
    data?.listings
      .filter((listing) => listing.source === "realu")
      .map((listing) => ({
        ...listing,
        type: TransactionType.RealuListing,
      })) ?? [],
    getProjectsGroup().map((project) => ({
      ...project,
      type: TransactionType.Project,
    })),
  ].filter((group) => group.length > 0) as AnyTransaction[][];

  return (
    <ContentCard title="Sandorio palyginimas su skelbimu ir/ar projektu">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <TransactionsScrollContext.Provider
          value={{
            onPrevPress: (index) => {
              setSelectedTransactions(
                selectedTransactions.map((value, i) =>
                  i === index
                    ? (value - 1 + transactionsGroups[i].length) %
                      transactionsGroups[i].length
                    : value
                )
              );
            },
            onNextPress: (index) => {
              setSelectedTransactions(
                selectedTransactions.map((value, i) =>
                  i === index
                    ? (value + 1) % transactionsGroups[i].length
                    : value
                )
              );
            },
            selectedTransactions,
          }}
        >
          <TableComponent
            rows={getTransactionsTableRows(
              transactionsGroups,
              selectedTransactions
            )}
          />
        </TransactionsScrollContext.Provider>
      )}
    </ContentCard>
  );
};
