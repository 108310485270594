import React from "react";
import {
  PropertyAddonWithPrice,
  PropertyAddon,
} from "../../../types/api.types";
import colors from "common/styles/colors";
import { addonsLabels } from "../../property/addons/AddonListSelect";
import { formatPrice } from "common/helpers/text.helpers";
import CheckIcon from "@mui/icons-material/Check";
import { OrderSummarySection } from "./OrderSummarySection";

interface AddonsSummaryProps {
  addons: PropertyAddonWithPrice[];
  title: string;
  isPurchased?: boolean;
}

export const AddonsSummary: React.FC<AddonsSummaryProps> = ({
  addons,
  title,
  isPurchased,
}) => {
  const totalPrice = addons.reduce((acc, addon) => acc + (addon.price ?? 0), 0);

  if (!addons.length) {
    return null;
  }

  return (
    <OrderSummarySection
      items={addons.map((addon) => ({
        title: addonsLabels[addon.type],
        price: addon.price,
      }))}
      total={totalPrice}
      title={title}
      isPurchased={isPurchased}
    />
  );
};
