import React, { useMemo } from "react";
import {
  useAppraiserPropertyFields,
  usePricePredictions,
} from "../../hooks/pricePredictions.hooks";
import {
  adjustmentFields,
  getComparableAddress,
  getComparableImages,
} from "common/helpers/comparables.helpers";
import { formatAreaPrice, formatPrice } from "common/helpers/text.helpers";
import { PropertyImageGallery } from "../imageGallery/PropertyImageGallery";
import { SummaryTableAdjustment } from "../SummaryTableAdjustment";
import { getPercentage } from "../../helpers/comparable.helpers";
import {
  AdditionalREType,
  ComparableFull,
  FinalPricePredictions,
} from "common/types/common.types";
import { useProperty } from "../../hooks/property.hooks";
import { Typography } from "common/components/Typography";
import EditIcon from "../../assets/icons/edit.svg";
import { IconButton } from "common/components/buttons/IconButton";
import { useNavigate } from "react-router-dom";
import { WeightsSummaryHeader } from "./WeightsSummaryHeader";
import { TableRow } from "../tables/types";
import { TableComponent } from "../tables/TableComponent";
import { Box, IconButton as IconButtonMUI, Tooltip } from "@mui/material";
import colors from "common/styles/colors";
import { Text } from "react-native";
import { ComparableNumber } from "../map/ComparableNumber";
import { getUniqueAdjustedFields } from "./comparablesSummary.helpers";
import WarningIcon from "../../assets/icons/warning.svg";
import { getRoundedPercent } from "common/helpers/common.helpers";
import { AdjustmentHeader } from "./AdjustmentHeader";
import { AdditionalAdjustmentHeader } from "./AdditionalAdjustmentHeader";
import RemoveButtonIcon from "../../assets/icons/removeButton.svg";
import { AppraiserProperty } from "../../types/appraiser.types";

export type ComparableSummary = ComparableFull & {
  realSoldPrice: number;
  realSoldPriceArea: number;
};

interface ComparablesSummaryTableProps {
  propertyId: string;
  propertyFromProps?: AppraiserProperty;
  comps: ComparableSummary[];
  isPrintable?: boolean;
  comparableStartIndex?: number;
  finalPricePredictions: FinalPricePredictions | undefined;
}

export const ComparablesSummaryTable: React.FC<
  ComparablesSummaryTableProps
> = ({
  propertyId,
  propertyFromProps,
  comps,
  isPrintable,
  finalPricePredictions,
  comparableStartIndex,
}) => {
  const { data: propertyData } = useProperty(propertyId, !propertyFromProps);
  const property = propertyFromProps ?? propertyData;

  const addRelatedRERow = (
    reType: AdditionalREType,
    relatedRERows: TableRow[]
  ) => {
    if (
      !comps.some((comp) =>
        comp.adjustments?.additional_re?.some(
          (additional) => additional.type === reType
        )
      )
    ) {
      return;
    }
    const name =
      reType === AdditionalREType.Basement ? "Sandėliukas" : "Garažas";
    relatedRERows.push({
      header: name,
      elements: [
        null,
        ...comps.map((comp) =>
          formatPrice(
            comp.adjustments?.additional_re?.find((re) => re.type === reType)
              ?.price
          )
        ),
      ],
    });
  };

  const getRelatedRERows = (): TableRow[] => {
    const relatedRERows: TableRow[] = [];

    addRelatedRERow(AdditionalREType.Basement, relatedRERows);
    addRelatedRERow(AdditionalREType.Garage, relatedRERows);
    if (!relatedRERows.length) return [];

    return [
      {
        header: "Turto vertė",
        elements: [
          null,
          ...comps.map((comp) => formatPrice(comp.realSoldPrice)),
        ],
      },
      ...relatedRERows,
    ];
  };

  const getIncrementPriceArea = (price: number | undefined) => {
    if (price === undefined) return undefined;
    return `${price > 0 ? "+" : ""}${formatAreaPrice(price)}`;
  };

  const uniqueAdjustedFields = getUniqueAdjustedFields(comps);

  const navigate = useNavigate();

  const getAdditionalPropertyFieldValue = (id: string) =>
    finalPricePredictions?.additional_property_fields?.find(
      (field) => field.appraiserPropertyFieldId === id
    )?.fieldValue;

  const avgRealSoldPriceArea = useMemo(
    () =>
      comps.reduce((acc, comp) => acc + comp.realSoldPriceArea, 0) /
      comps.length,
    [comps]
  );
  const realSoldPriceAreaElements = comps.map((comp) => {
    let text = undefined;

    const percentageLimit = 0.2;
    const percent = comp.realSoldPriceArea / avgRealSoldPriceArea;
    if (percent >= percentageLimit + 1) {
      text = `Atkreipkite dėmesį ar pasirinkote teisingą palyginamąjį sandorį. Šio objekto kv. kaina yra didesnė ${Math.round(
        (percent - 1) * 100
      )} proc. lyginant su kitais.`;
    }
    if (percent <= 1 - percentageLimit) {
      text = `Atkreipkite dėmesį ar pasirinkote teisingą palyginamąjį sandorį. Šio objekto kv. kaina yra mažesnė ${Math.round(
        (1 - percent) * 100
      )} proc. lyginant su kitais.`;
    }
    const formatedPrice = formatAreaPrice(comp.realSoldPriceArea);
    if (!text || isPrintable) return formatedPrice;
    return (
      <div
        style={{
          color: colors.premium,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Tooltip title={text} arrow>
          <IconButtonMUI>
            <WarningIcon />
          </IconButtonMUI>
        </Tooltip>
        {formatedPrice}
      </div>
    );
  });

  const { removeComparableFromCart } = usePricePredictions(
    propertyId,
    !isPrintable
  );

  if (!property) {
    return null;
  }

  const imageHeight = isPrintable ? 180 : 226;
  const imageWidth = isPrintable ? 200 : 268;

  let tableRows: TableRow[] = [
    {
      header: "",
      elements: [
        <PropertyImageGallery
          height={imageHeight}
          width={imageWidth}
          images={property?.images}
          headerText={property?.address}
          boxShadow={3}
          borderRadius={5}
        />,
        ...comps.map((comp) => (
          <PropertyImageGallery
            height={imageHeight}
            width={imageWidth}
            images={getComparableImages(comp.comparable_transaction)}
            headerText={getComparableAddress(comp.comparable_transaction)}
            boxShadow={3}
            borderRadius={5}
          />
        )),
        ,
      ],
      noBorder: true,
    },
    {
      header: "Adresas",
      elements: [
        property?.address,
        ...comps.map((comp, index) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ComparableNumber
              compNumber={(comparableStartIndex ?? 0) + index + 1}
            />
            {getComparableAddress(comp.comparable_transaction)}
          </div>
        )),
      ],
    },
    {
      header: "Pritaikytos pataisos",
      isCollapsible: true,
      elements: [
        null,
        ...comps.map((comp) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ display: "flex", gap: "5px" }}>
              {getIncrementPriceArea(comp.adjustments?.price_increase_area)}
              <span style={{ color: colors.purple2 }}>
                {`(${
                  getPercentage(
                    comp.adjustments?.adjusted_price_area ?? 0,
                    comp.realSoldPriceArea
                  ).percentageText
                })`}
              </span>
            </span>
            {!isPrintable && (
              <IconButton
                onPress={() =>
                  navigate(`../${comp.comparable_transaction.id}/adjustments`)
                }
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        )),
      ],
      children: [
        ...uniqueAdjustedFields.map((fieldName) => ({
          header: (
            <AdjustmentHeader
              comps={comps}
              propertyId={propertyId}
              readonly={isPrintable}
              fieldName={fieldName}
            />
          ),
          elements: [
            adjustmentFields[fieldName]?.propertyFieldValue(property),
            ...comps.map((comp) => {
              const adjustment = comp.adjustments?.adjusted_fields.find(
                (field) => field.field === fieldName
              );
              if (!adjustment) return "-";
              return (
                <SummaryTableAdjustment
                  value={adjustmentFields[fieldName]?.fieldValue(
                    comp.comparable_transaction
                  )}
                  priceIncreaseArea={getIncrementPriceArea(
                    adjustment?.price_area
                  )}
                  percent={adjustment?.percent}
                />
              );
            }),
          ],
        })),
        ...(finalPricePredictions?.additional_property_fields?.map((field) => ({
          header: (
            <AdditionalAdjustmentHeader
              comps={comps}
              propertyId={propertyId}
              readonly={isPrintable}
              appraiserPropertyFieldId={field.appraiserPropertyFieldId}
            />
          ),
          elements: [
            getAdditionalPropertyFieldValue(field.appraiserPropertyFieldId),
            ...comps.map((comp) => {
              const adjustment = comp.adjustments?.additional_fields?.find(
                (additionalField) =>
                  field.appraiserPropertyFieldId ===
                  additionalField.appraiserPropertyFieldId
              );
              if (!adjustment) return "-";
              return (
                <SummaryTableAdjustment
                  value={adjustment.comparableDescription}
                  priceIncreaseArea={getIncrementPriceArea(
                    adjustment.percent * comp.realSoldPriceArea
                  )}
                  percent={adjustment.percent}
                />
              );
            }),
          ],
        })) ?? []),
      ],
    },
    {
      header: "Kv. kaina prieš pataisas",
      elements: [null, ...realSoldPriceAreaElements],
    },
    {
      header: "Kaina prieš pataisas",
      isCollapsible: !!getRelatedRERows().length,
      elements: [null, ...comps.map((comp) => formatPrice(comp.realSoldPrice))],
      children: getRelatedRERows(),
    },
    {
      header: "Kv. kaina po pataisų",
      elements: [
        null,
        ...comps.map((comp) => (
          <span style={{ color: colors.purple2, fontWeight: 500 }}>
            {formatAreaPrice(comp.adjustments?.adjusted_price_area)}
          </span>
        )),
      ],
    },
    {
      header: "Kaina po pataisų",
      elements: [
        null,
        ...comps.map((comp) =>
          formatPrice(
            (comp.adjustments?.adjusted_price_area ?? 0) *
              comp.comparable_transaction.area
          )
        ),
      ],
    },
    {
      header: (
        <WeightsSummaryHeader
          comps={comps}
          propertyId={propertyId}
          readonly={isPrintable}
        />
      ),
      elements: [
        null,
        ...comps.map((comp) =>
          comp.comparable_weight !== undefined
            ? `${getRoundedPercent(comp.comparable_weight)} %`
            : "-"
        ),
      ],
    },
  ];

  const removeCompsRow: TableRow = {
    header: "",
    elements: [
      null,
      ...comps.map((comp, index) => (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            key={index}
            onPress={() => {
              removeComparableFromCart(comp.comparable_transaction.id);
            }}
          >
            <RemoveButtonIcon />
          </IconButton>
        </div>
      )),
    ],
    noBorder: true,
  };

  if (!isPrintable) {
    tableRows = [removeCompsRow, ...tableRows];
  }

  const isAdditional = (comparableStartIndex ?? 0) > 0;

  const minHeaderWidth = isPrintable ? 150 : 190;
  const maxHeaderWidth = isPrintable ? 150 : undefined;

  return (
    <div style={{ overflowX: "auto" }}>
      <Text>
        <Typography variant="h2" textStyle={{ fontSize: 20 }}>
          Sandoriai, kurie įtraukti į kainos skaičiavimą
        </Typography>
        {isAdditional && (
          <Typography
            variant="body"
            textStyle={{ fontSize: 20, color: colors.grey }}
          >
            {" (tęsinys)"}
          </Typography>
        )}
      </Text>
      <Box>
        <TableComponent
          sx={{
            "& tr > td:first-of-type": {
              fontWeight: 600,
              color: colors.purple2,
            },
          }}
          rows={tableRows}
          minHeaderWidth={minHeaderWidth}
          maxHeaderWidth={maxHeaderWidth}
          maxElementWidth={imageWidth + 40}
          paddingY="30px"
        />
      </Box>
    </div>
  );
};
