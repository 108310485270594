import { useQuery } from "react-query";
import { getRawTransaction } from "../api/api";
import { useParams } from "react-router-dom";
import { getUsedCreditsForProperty } from "../api/rcTransactions.api";

export function useRawTransaction(id: string, enabled?: boolean) {
  const { propertyId } = useParams();

  return useQuery(
    ["raw_transaction", id, propertyId ?? ""],
    () => getRawTransaction(id, propertyId ?? ""),
    {
      enabled,
    }
  );
}

export function useUsedCreditsForProperty(propertyId: string) {
  return useQuery(["used_credits", propertyId], () =>
    getUsedCreditsForProperty(propertyId)
  );
}
