import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useComparableFull, useProperty } from "../hooks/property.hooks";
import { ContentCard } from "../components/ContentCard";
import { PropertyInfo } from "../components/PropertyInfo";
import { AdjustmentsMapModal } from "../components/map/AdjustmentsMapModal";
import { AdjustmentsTable } from "../components/adjustments/AdjustmentsTable";
import { haversineDistance } from "common/helpers/map.helpers";
import { ComparableFull } from "common/types/common.types";
interface ComparableAdjustmentsProps {}

export const ComparableContext = React.createContext<{
  comparable?: ComparableFull;
}>({});

export const ComparableAdjustments: React.FC<
  ComparableAdjustmentsProps
> = () => {
  const { propertyId, comparableId } = useParams();
  const { data: property } = useProperty(propertyId ?? "");
  const comparable = useComparableFull(comparableId ?? "");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onViewMapClick = () => {
    setIsModalOpen(true);
  };

  if (!property || !comparable) {
    return <div>Comparable not found</div>;
  }

  return (
    <ComparableContext.Provider value={{ comparable }}>
      <ContentCard title="Palyginti atrinktus sandorius">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            justifyContent: "flex-end",

            padding: "0 56px",
            marginBottom: "20px",
          }}
        >
          <PropertyInfo property={property} onViewOnMapClick={onViewMapClick} />
          <PropertyInfo
            comparable={comparable.comparable_transaction}
            onViewOnMapClick={onViewMapClick}
            distance={Math.round(
              haversineDistance(
                [
                  comparable.comparable_transaction.location.lat,
                  comparable.comparable_transaction.location.lng,
                ],
                [property.lat, property.lng]
              ) * 1000
            )}
          />
        </div>
        <AdjustmentsTable comparable={comparable} property={property} />
      </ContentCard>

      <AdjustmentsMapModal
        comparables={[comparable]}
        property={property}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </ComparableContext.Provider>
  );
};
