import React from "react";
import { AccountSettingsForm } from "./AccountSettingsForm";
import { ContentCard } from "../../components/ContentCard";
import { DiscountCodesTable } from "./discountCodes/DiscountCodesTable";

interface AccountSettingsRouteProps {}

export const AccountSettingsRoute: React.FC<AccountSettingsRouteProps> = () => {
  return (
    <div>
      <AccountSettingsForm />
      <DiscountCodesTable />
    </div>
  );
};
