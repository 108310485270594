import React, { useMemo } from "react";
import { ContentCard } from "../../../components/ContentCard";
import { PropertyDistancesMap } from "./PropertyDistancesMap";
import { PropertyDistancesList } from "./PropertyDistancesList";
import { InfraByType, PropertyInfra } from "../../../types/api.types";
import { AppraiserProperty } from "../../../types/appraiser.types";

interface PropertyDistancesProps {
  infras: PropertyInfra[];
  property: AppraiserProperty;
}

export const PropertyDistances: React.FC<PropertyDistancesProps> = ({
  infras,
  property,
}) => {
  const infrasByType = useMemo(() => {
    const infrasByType: InfraByType[] = [];
    for (const infra of infras) {
      const infraType = infra.type;
      const index = infrasByType.findIndex((i) => i.type === infraType);

      if (index === -1) {
        infrasByType.push({
          type: infraType,
          infras: [infra],
        });
      } else {
        infrasByType[index].infras.push(infra);
      }
    }

    return infrasByType;
  }, [infras]);

  return (
    <ContentCard title="Atstumai">
      <PropertyDistancesMap infrasByType={infrasByType} property={property} />
      <PropertyDistancesList infrasByType={infrasByType} />
    </ContentCard>
  );
};
