import React from "react";

interface SimpleTableComponentProps {
  children?: React.ReactNode;
  customFontSize?: string;
  style?: React.CSSProperties;
}

export const SimpleTableComponent: React.FC<SimpleTableComponentProps> = ({
  children,
  customFontSize,
  style,
}) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        ...style,
        fontSize: customFontSize,
      }}
    >
      <tbody>{children}</tbody>
    </table>
  );
};
