import React from "react";
import { Pressable, StyleProp, ViewStyle } from "react-native";

interface OpacityButtonProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  activeOpacity?: number;
  children?: React.ReactNode;
}

export const OpacityButton: React.FC<OpacityButtonProps> = (props) => {
  return (
    <Pressable
      onPress={() => {
        if (props.disabled) return;
        props.onPress?.();
      }}
      style={({ pressed }) => [
        props.style,
        {
          opacity: pressed || props.disabled ? props.activeOpacity ?? 0.5 : 1,
          userSelect: "none",
        },
      ]}
      disabled={props.disabled}
    >
      {props.children}
    </Pressable>
  );
};
