import React, { useRef } from "react";
import {
  PropertyPermission,
  PropertyShareSettings,
} from "./PropertyShareSettings";
import { useMutation } from "react-query";
import { generatePropertyShareLink } from "../../api/api";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { setClipboard } from "common/clipboard/clipboard";

interface PropertyNewLinkShareSettingsProps {
  propertyId: string;
}

export const PropertyNewLinkShareSettings: React.FC<
  PropertyNewLinkShareSettingsProps
> = ({ propertyId }) => {
  const { isLoading, mutate } = useMutation(generatePropertyShareLink, {
    onSuccess: (data) => {
      setClipboard(data.link);
      showToastSuccess("Nuoroda nukopijuota į iškarpinę.");
    },
  });

  const currentPermissions = useRef<PropertyPermission[]>([]);

  return (
    <PropertyShareSettings
      onChange={(permissions) => {
        currentPermissions.current = permissions;
      }}
    >
      <MUILoadingButton
        loading={isLoading}
        onClick={() => {
          if (currentPermissions.current.length === 0) {
            showToastError("Pasirinkite bent vieną leidimą.");
            return;
          }
          mutate({ propertyId, permissions: currentPermissions.current });
        }}
        sx={{ marginTop: "30px" }}
      >
        Kurti nuorodą
      </MUILoadingButton>
    </PropertyShareSettings>
  );
};
