import { Box } from "@mui/material";
import { Typography } from "common/components/Typography";
import React from "react";

interface MenuClickLinkComponentProps {
  label: string;
  onClick?: () => void;
}

export const MenuClickLinkComponent: React.FC<MenuClickLinkComponentProps> = ({
  onClick,
  label,
}) => {
  return (
    <Box
      sx={{
        ":hover": {
          textDecoration: "underline",
          textDecorationColor: "black",
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography variant="h4">{label}</Typography>
    </Box>
  );
};
