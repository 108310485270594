import { GoogleMap, MarkerF } from "@react-google-maps/api";
import React from "react";

interface MarkerMapProps {
  lat: number;
  lng: number;
  width?: string;
  height: string;
}

export const MarkerMap: React.FC<MarkerMapProps> = ({
  lat,
  lng,
  width,
  height,
}) => {
  const containerStyle = {
    width,
    height: height,
  };

  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={15}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        <MarkerF position={{ lat, lng }} />
      </GoogleMap>
    </div>
  );
};
