import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRouteQuery } from "../../hooks/router.hooks";
import { sendPaymentNotification } from "../../api/api";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { LinkForButton } from "../../components/links/LinkForButton";
import { Button } from "@mui/material";
import { useMutation } from "react-query";

interface LeadSuccessProps {}

export const LeadSuccess: React.FC<LeadSuccessProps> = () => {
  const params = useRouteQuery();
  const orderToken = params.get("order-token");
  const link = params.get("link");

  const { mutate, isError, isLoading, data } = useMutation(
    sendPaymentNotification
  );

  useEffect(() => {
    if (link) {
      return;
    }
    mutate(orderToken ?? "");
  }, [orderToken, link]);

  const reportLink = data?.link ?? link;

  if (isLoading) {
    return <LoaderCentered />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {isError ? (
        <h4>Įvyko klaida</h4>
      ) : (
        <>
          <h3>Vertinimo užklausa sėkmingai sukurta!</h3>
          {!!reportLink && (
            <LinkForButton to={reportLink}>
              <Button variant="contained">Peržiūrėti užklausą</Button>
            </LinkForButton>
          )}
        </>
      )}
    </div>
  );
};
