import React, { useState } from "react";
import { TableRow } from "./types";
import colors from "common/styles/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IconButton } from "common/components/buttons/IconButton";

interface TableRowComponentProps {
  row: TableRow;
  leftPadding?: number;
  fontSize?: number;
  minHeaderWidth?: number;
  maxHeaderWidth?: number;
  maxElementWidth?: number;
  onExpandPress?: () => void;
}

const rowBorder: React.CSSProperties = {
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: colors.greybg,
};

const rowElementStyle: React.CSSProperties = {
  paddingLeft: "30px",
  fontSize: "14px",
};

export const TableRowComponent: React.FC<TableRowComponentProps> = ({
  row,
  leftPadding,
  fontSize,
  minHeaderWidth,
  onExpandPress,
  maxElementWidth,
  maxHeaderWidth,
}) => {
  const currentFontSize = fontSize ?? 14;

  const [isCollapsed, setIsCollapsed] = useState(false);

  if (row.children?.length) {
    return (
      <>
        <TableRowComponent
          row={{ ...row, children: [] }}
          onExpandPress={() => setIsCollapsed(!isCollapsed)}
        />
        {!isCollapsed &&
          row.children.map((rowChild, index) => (
            <TableRowComponent
              key={index}
              row={rowChild}
              leftPadding={(leftPadding ?? 0) + 30}
              fontSize={currentFontSize - 2}
            />
          ))}
      </>
    );
  }

  return (
    <tr
      style={{
        height: "50px",

        ...(row.noBorder ? {} : rowBorder),
      }}
    >
      <th
        style={{
          fontSize: `${currentFontSize}px`,
          fontWeight: 400,
          color: colors.grey,
          textAlign: "left",
          paddingLeft: `${leftPadding ?? 0}px`,
          minWidth: `${minHeaderWidth}px`,
          maxWidth: `${maxHeaderWidth}px`,
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {row.header}
          {!!row.isCollapsible && (
            <IconButton
              onPress={() => {
                setIsCollapsed(!isCollapsed);
                onExpandPress?.();
              }}
            >
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )}
        </span>
      </th>
      {row.elements.map((el, index) => (
        <td
          key={index}
          style={{
            ...rowElementStyle,
            fontSize: `${currentFontSize}px`,
            maxWidth: `${maxElementWidth}px`,
          }}
        >
          {el}
        </td>
      ))}
    </tr>
  );
};
