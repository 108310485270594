import React from "react";

interface CenteredContentWrapperProps {
  children: React.ReactNode;
  width?: string;
}

export const CenteredContentWrapper: React.FC<CenteredContentWrapperProps> = ({
  children,
  width,
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width }}>{children}</div>
    </div>
  );
};
