import { Slider } from "@mui/material";
import React from "react";
import { SliderInput } from "./SliderInput";

interface SliderWithInputProps {
  value: number | number[];
  onChange: (value: number | number[]) => void;
  min: number;
  max: number;
  step?: number;
}

export const SliderWithInput: React.FC<SliderWithInputProps> = ({
  value,
  onChange,
  min,
  max,
  step,
}) => {
  const isRange = typeof value !== "number";

  const getValue = (index?: number) => {
    if (isRange) {
      return value[index ?? 0];
    }
    return value;
  };

  const handleSliderInputChange = (inputValue: number, index: number) => {
    if (isRange && Array.isArray(value)) {
      const newValue = [...(value as number[])];
      newValue[index] = inputValue;
      onChange(newValue);
    } else {
      onChange(inputValue);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
      {isRange && (
        <SliderInput
          value={getValue(0)}
          onChange={(value) => handleSliderInputChange(value, 0)}
          min={min}
          max={isRange ? getValue(1) : max}
          step={step}
        />
      )}
      <Slider
        value={value}
        onChange={(_, value) => onChange(value)}
        style={{ flex: 1 }}
      />
      <SliderInput
        value={getValue(1)}
        onChange={(value) => handleSliderInputChange(value, 1)}
        min={isRange ? getValue(0) : min}
        max={max}
        step={step}
      />
    </div>
  );
};
