import React, { useContext, useState } from "react";
import { FilterFieldWrapper } from "../wrappers/FilterFieldWrapper";
import { Box, Button, Dialog, Input } from "@mui/material";
import { MapValuationZones } from "./MapValuationZones";
import { ComparableFilterType } from "../../helpers/comparableFilters.helpers";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import { ContentCardTitle } from "../ContentCardTitle";
import colors from "common/styles/colors";
import EditIcon from "../../assets/icons/edit.svg";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { showToastError } from "common/toast/toast";
import { FullScreenDialog } from "../dialogs/FullScreenDialog";
interface ValuationZoneFilterProps {
  onSelect: (selectedZones: string[]) => void;
}

export const ValuationZoneFilter: React.FC<ValuationZoneFilterProps> = ({
  onSelect,
}) => {
  const [isMapOpen, setIsMapOpen] = useState(false);

  const { getAppliedFilter } = useContext(ComparablesFiltersContext);

  const valuationZones = getAppliedFilter?.(ComparableFilterType.ValuationZone)
    ?.values as string[] | undefined;

  return (
    <FilterFieldWrapper label="Vertinimo zona">
      <Box
        sx={{
          borderColor: colors.greyborder,
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: "5px",
          padding: "10px 15px",
          "&:hover": {
            borderColor: colors.beige,
            cursor: "pointer",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "5px",
        }}
        onClick={() => setIsMapOpen(true)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {valuationZones?.map((zone) => (
            <div
              key={zone}
              style={{
                borderColor: colors.beige,
                borderWidth: "1px",
                borderStyle: "solid",
                padding: "5px 10px",
                borderRadius: "200px",
              }}
            >
              {zone}
            </div>
          ))}
        </div>
        <div
          style={{
            marginTop: "5px",
          }}
        >
          <EditIcon />
        </div>
      </Box>
      <FullScreenDialog
        title="Pasirinkti verčių zonas žemėlapyje"
        open={isMapOpen}
        onClose={() => setIsMapOpen(false)}
      >
        <MapValuationZones
          initialSelectedZones={valuationZones}
          onSelect={(selectedZones) => {
            if (!selectedZones.length) {
              showToastError("Pasirinkite bent vieną vertinimo zoną");
              return;
            }

            // RC api only supports up to 70 chars
            if (selectedZones.join(",").length > 70) {
              showToastError("Pasirinkitas per didelis kiekis vertinimo zonų");
              return;
            }
            onSelect(selectedZones);
            setIsMapOpen(false);
          }}
        />
      </FullScreenDialog>
    </FilterFieldWrapper>
  );
};
