import colors from "common/styles/colors";
import React from "react";

interface CompareHeaderProps {
  label?: string;
  title: string;
}

export const CompareHeader: React.FC<CompareHeaderProps> = ({
  label,
  title,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!!label && (
        <div style={{ fontSize: "14px", color: colors.grey }}>{label}</div>
      )}
      <div>{title}</div>
    </div>
  );
};
