import { Typography } from "common/components/Typography";
import { formatPrice } from "common/helpers/text.helpers";
import React from "react";

interface RelatedREPriceProps {
  label: string;
  price: number;
  isBold?: boolean;
  width?: string;
}

export const RelatedREPrice: React.FC<RelatedREPriceProps> = ({
  label,
  price,
  isBold,
  width,
}) => {
  return (
    <div
      style={{
        width: width ?? "120px",
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
      }}
    >
      <Typography textStyle={{ fontSize: 16 }}>{label}</Typography>
      <Typography
        variant={isBold ? "h3" : "body"}
        textStyle={{ fontSize: 20, marginTop: "20px" }}
      >
        {formatPrice(price, 1)}
      </Typography>
    </div>
  );
};
