export enum DesignationType {
  ResidentialApartments = "160",
  Recreation = "230",
  Residential1Flat = "110",
  Residential2Flats = "120",
  Residential3OrMoreFlats = "140",
  Hotels = "202",
  PlotArea = "995",
  Administrative = "210",
  ManufacturingIndustry = "222",
  Manufacturing = "298",
  Other = "296",
  Garage = "220",
  OtherSubFarm = "270",
}

export enum REType {
  Flat = "1",
  House = "2",
}

export function getTransactionREType(designationType: DesignationType) {
  switch (designationType) {
    case DesignationType.Residential1Flat:
      return REType.House;
    case DesignationType.Residential2Flats:
      return REType.House;
    case DesignationType.Residential3OrMoreFlats:
      return REType.House;
    default:
      return REType.Flat;
  }
}
