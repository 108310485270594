import React from "react";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { useQuery } from "react-query";
import { getAdminAdjustments } from "../../api/api";
import { AdjustmentsAdminRow } from "./AdjustmentsAdminRow";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import { ComparableTransactionFull } from "common/types/common.types";
import colors from "common/styles/colors";
import { ContentCardTitle } from "../../components/ContentCardTitle";

interface AdjustmentsAdminRouteProps {}

export const AdjustmentsAdminRoute: React.FC<
  AdjustmentsAdminRouteProps
> = () => {
  const { data } = useQuery("admin_adjustments", getAdminAdjustments);

  return (
    <div
      style={{
        backgroundColor: colors.white,
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "30px",
      }}
    >
      <ContentCardTitle title="Pataisos ir jų paaiškinimai" />
      <div style={{ height: "30px" }} />

      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader>Vertės pataisa</SimpleTableHeader>
          <SimpleTableHeader>Pataisos aprašymas</SimpleTableHeader>
          <SimpleTableHeader>Metodologija</SimpleTableHeader>
          <SimpleTableHeader>Veiksmas</SimpleTableHeader>
        </SimpleTableRow>
        {data?.adjustmentFields.map((field) => (
          <AdjustmentsAdminRow
            key={field.field}
            field={field.field}
            fieldFullName={
              adjustmentFields[field.field as keyof ComparableTransactionFull]
                ?.fieldName ?? ""
            }
            description={field.description}
            providerSelected={field.providerSelected}
            providers={field.providers}
          />
        ))}
        {data?.additionalFields.map((field) => (
          <AdjustmentsAdminRow
            key={field.field}
            field={field.field}
            description={field.description}
            appraiserPropertyFieldId={field.appraiserPropertyFieldId}
          />
        ))}
      </SimpleTableComponent>
    </div>
  );
};
