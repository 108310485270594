import { TimeType, WorkTime, WorkTimes } from "common/types/common.types";
import { convertToRoman } from "../../mobile/src/common/helpers/romanNumbers";

const fixTimeString = (timeString: string) => {
  if (timeString.length === 1) {
    return `0${timeString}`;
  }
  return timeString;
};

const generateTimeString = (time: TimeType) => {
  let hour = fixTimeString(time.hour.toString());
  let minute = fixTimeString(time.minute.toString());

  return `${hour}:${minute}`;
};

const generateTimeRangeString = (workTime: WorkTime) => {
  return `${generateTimeString(workTime.start)}-${generateTimeString(
    workTime.end
  )}`;
};

export const generateWorkTimesString = (workTimes: WorkTimes[]) => {
  let rangeStart = 1;

  let workTimesStrings: string[] = [];

  for (let index = 0; index < workTimes.length; index++) {
    const currentString = generateTimeRangeString(workTimes[index].work_time);
    const nextString =
      index + 1 === workTimes.length
        ? ""
        : generateTimeRangeString(workTimes[index + 1].work_time);

    if (currentString !== nextString) {
      const rangeEnd = workTimes[index].day;
      let romanRange: string;
      if (rangeStart === rangeEnd) {
        romanRange = convertToRoman(rangeStart);
      } else {
        romanRange = `${convertToRoman(rangeStart)}-${convertToRoman(
          rangeEnd
        )}`;
      }

      const workTimeString = `${romanRange} ${currentString}`;
      workTimesStrings.push(workTimeString);

      rangeStart = workTimes[index].day + 1;
    }
  }
  return workTimesStrings.join(" ");
};
