import { Radio } from "@mui/material";
import { StyledImage } from "common/components/images/StyledImage";
import React from "react";

interface PaymentMethodProps {
  isSelected: boolean;
  logoUrl: string;
  onSelect: () => void;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  isSelected,
  logoUrl,
  onSelect,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        cursor: "pointer",
        borderRadius: "5px",
        border: "1px solid #efefef",
        paddingRight: "20px",
        paddingBottom: "15px",
        paddingTop: "15px",
        paddingLeft: "5px",
        gap: "5px",
      }}
      onClick={onSelect}
    >
      <Radio checked={isSelected} onClick={onSelect} />
      <img src={logoUrl} style={{ alignSelf: "center" }} width="160px" />
    </div>
  );
};
