import colors from "common/styles/colors";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";

interface PropertyStatusIconProps {
  completed?: boolean;
  inProgress?: boolean;
  stage?: number;
}

export const PropertyStatusIcon: React.FC<PropertyStatusIconProps> = ({
  completed,
  inProgress,
  stage,
}) => {
  return (
    <div
      style={{
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        backgroundColor:
          completed || inProgress ? colors.purple2 : colors.beige,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: colors.white,
      }}
    >
      {completed ? <DoneIcon style={{ color: colors.white }} /> : stage}
    </div>
  );
};
