import { useQuery } from "react-query";
import { getAutocompletePredictions } from "../api/maps.api";

export function useMapsAutocomplete(
  input: string,
  lat?: number,
  lng?: number,
  sessiontoken?: string,
  disabled?: boolean
) {
  return useQuery(
    ["maps_autocomplete", input, lat, lng],
    () => getAutocompletePredictions(input, lat, lng, sessiontoken),
    {
      keepPreviousData: true,
      enabled: !disabled,
    }
  );
}
