import React from "react";
import { MapLegendRow } from "./MapLegendRow";
import { PropertyIcon } from "common/components/map/PropertyIcon";
import colors from "common/styles/colors";
import OkCheckIcon from "common/assets/icons/okCheck.svg";
import { Typography } from "common/components/Typography";
import {
  mainPropertyBGColor,
  usePropertyIconsProps,
} from "../../../hooks/propertyIcons.hooks";
interface MapLegendProps {}

export const MapLegend: React.FC<MapLegendProps> = () => {
  const { getIcon, getIconBGColor } = usePropertyIconsProps();

  return (
    <div
      style={{
        backgroundColor: "5px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h4" textStyle={{ fontSize: 14, color: colors.grey }}>
        Sutartiniai ženklai
      </Typography>
      <div style={{ height: "5px" }} />
      <MapLegendRow
        icon={<PropertyIcon backgroundColor={mainPropertyBGColor} />}
        label="vertinamasis objektas"
      />
      <MapLegendRow
        icon={
          <PropertyIcon
            customIcon={getIcon("notFull")}
            backgroundColor={getIconBGColor("notFull")}
          />
        }
        label="neįsigytas sandoris"
      />
      <MapLegendRow
        icon={
          <PropertyIcon
            customIcon={getIcon("full")}
            backgroundColor={getIconBGColor("full")}
          />
        }
        label="įsigytas sandoris"
      />
      <MapLegendRow
        icon={
          <PropertyIcon
            customIcon={getIcon("inCart")}
            backgroundColor={getIconBGColor("inCart")}
          />
        }
        label="įsigytas ir įtrauktas į kainos skaičiavimą sandoris"
      />
      <MapLegendRow
        icon={<OkCheckIcon />}
        label="ikonėlė rodanti, kad sandoris identifikuotas"
      />
    </div>
  );
};
