import React from "react";
import { View, Text, TextStyle, ViewStyle } from "react-native";
import { Typography } from "common/components/Typography";
import colors from "../../styles/colors";
import { TypographyType } from "../../types/typography.types";
import { FormSettingsContext } from "./hookForms/FormSettingsContext";

export interface FormFieldWrapperProps {
  children: React.ReactNode;
  label?: string;
  error?: string;
  caption?: string;
  isRequired?: boolean;
  gap?: number;
  textVariant?: TypographyType;
  textStyle?: TextStyle;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  children,
  label,
  error,
  isRequired,
  gap,
  textVariant,
  textStyle,
  caption,
}) => {
  const { desktopColumns, smallFont } = React.useContext(FormSettingsContext);

  const textVariant2 = smallFont ? "body" : textVariant ?? "h3";

  const textStyles: TextStyle = smallFont ? { fontSize: 16 } : {};

  const containerStyle: ViewStyle = desktopColumns
    ? { flexDirection: "row", alignItems: "center" }
    : {};

  return (
    <View>
      <View style={containerStyle}>
        {label && (
          <Text
            style={{
              marginBottom: desktopColumns ? 0 : gap ?? 20,
              flex: desktopColumns ? 1 : undefined,
            }}
          >
            <Typography
              variant={textVariant2}
              textStyle={{ ...textStyle, ...textStyles }}
            >
              {label}
            </Typography>
            {isRequired && (
              <Typography
                variant={textVariant2}
                textStyle={{ ...textStyle, ...textStyles, color: colors.red }}
              >
                *
              </Typography>
            )}
          </Text>
        )}
        <View style={{ flex: desktopColumns ? 1 : undefined }}>{children}</View>
      </View>
      <View style={containerStyle}>
        {!!desktopColumns && <View style={{ flex: 1 }} />}
        <View
          style={{
            flex: desktopColumns ? 1 : undefined,
          }}
        >
          {!!error && (
            <>
              <Typography
                variant="caption"
                textStyle={{ color: colors.red, marginTop: 5 }}
              >
                {error}
              </Typography>
            </>
          )}
          {!!caption && (
            <Typography variant="caption" textStyle={{ marginTop: 5 }}>
              {caption}
            </Typography>
          )}
        </View>
      </View>
    </View>
  );
};
