import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputField, InputFieldVariant } from "../InputField";
import { useFormFieldErrors } from "../../../hooks/form.hooks";
import { FormFieldWrapperProps } from "../FormFieldWrapper";

interface FormInputProps {
  label?: string;
  name: string;
  units?: string;
  isRequired?: boolean;
  variant: InputFieldVariant;
  wrapperProps?: Partial<FormFieldWrapperProps>;
  maxWidth?: number;
  disableErrors?: boolean;
  disabled?: boolean;
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  units,
  isRequired,
  variant,
  wrapperProps,
  maxWidth,
  disableErrors,
  disabled,
}) => {
  const { control, setValue } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  // handle the onChange event and reset react hook value if undefined
  const handleOnChange = (
    value: number | string | undefined,
    onChange: any
  ) => {
    if (value === undefined) {
      setValue(name, null);
    } else {
      onChange(value);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <InputField
            value={value}
            error={disableErrors ? undefined : errorText}
            onChange={(value) => handleOnChange(value, onChange)}
            label={label}
            units={units}
            isRequired={isRequired}
            variant={variant}
            wrapperProps={wrapperProps}
            maxWidth={maxWidth}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
