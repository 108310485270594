import React from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { getFinishingLabel } from "../../components/transactions/helpers";
import { PropertyImageGallery } from "../../components/imageGallery/PropertyImageGallery";
import { Typography } from "common/components/Typography";
import { PropertyRow } from "../../components/PropertyRow";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import { Box, Button, Divider, useMediaQuery } from "@mui/material";
import { MarkerMap } from "../../components/map/MarkerMap";
import { theme } from "../../styles/MUITheme";
import { WallTypeTranslations } from "../../helpers/comparableFields.helpers";
import { PropertyPlaceholderImage } from "../../components/imageGallery/PropertyPlaceholderImage";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateQueryParam } from "../../hooks/router.hooks";

interface PropertyInfoContentProps {
  property: AppraiserProperty;
  printable?: boolean;
}

const middleGap = { sm: "50px", xs: "20px" };

export const PropertyInfoContent: React.FC<PropertyInfoContentProps> = ({
  property,
  printable,
}) => {
  const finishing = getFinishingLabel(
    property.finishing,
    property.finishingFull
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const firstImageUrl = property.images?.length
    ? property.images[0].url
    : undefined;

  const { updateQueryParam } = useUpdateQueryParam();
  const isClickableGallery = !printable && !!firstImageUrl;
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          marginTop: "20px",
          gap: middleGap,
        }}
      >
        <div
          style={{
            flex: 1,
            cursor: isClickableGallery ? "pointer" : undefined,
            position: "relative",
          }}
          onClick={() => {
            if (!isClickableGallery) return;
            updateQueryParam("showGallery", "true");
          }}
        >
          <PropertyPlaceholderImage
            borderRadius={5}
            height={isMobile ? 300 : 400}
            uri={firstImageUrl}
          />
          {isClickableGallery && (
            <Button
              variant="outlined"
              style={{
                position: "absolute",
                bottom: 10,
                right: 10,
                backgroundColor: "white",
              }}
            >
              Peržiūrėti galeriją
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flexDirection: "column",

            flex: 1,
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h2"
            textStyle={{ fontSize: 24, marginBottom: "5px" }}
          >
            {property.label}
          </Typography>
          <PropertyRow label="Plotas:" value={`${property.area} m²`} />
          <PropertyRow
            label="Kambarių sk.:"
            value={property.rooms.toString()}
          />
          <PropertyRow
            label="Aukštas:"
            value={
              adjustmentFields.floor_position?.propertyFieldValue(property) ??
              "-"
            }
          />
          <PropertyRow
            label="Statybos metai:"
            value={property.year.toString()}
          />
          {!!property.plotArea && (
            <PropertyRow
              label="Sklypo plotas:"
              value={`${property.plotArea} a`}
            />
          )}
          {!!property.renovationYear && (
            <PropertyRow
              label="Rekonstrukcijos metai:"
              value={property.renovationYear.toString()}
            />
          )}
          {!!property.listingUrl && (
            <PropertyRow
              label="Skelbimo nuoroda:"
              value={
                <a href={property.listingUrl} target="_blank">
                  {property.listingUrl}
                </a>
              }
            />
          )}
          {!!finishing && <PropertyRow label="Įrengimas:" value={finishing} />}
          {!!property.energyClass && (
            <PropertyRow label="Energinė klasė:" value={property.energyClass} />
          )}
          {!!property.walls && (
            <PropertyRow
              label="Konstruktyvas:"
              value={WallTypeTranslations[property.walls]}
            />
          )}
        </div>
      </Box>
      <Divider style={{ margin: "30px 0" }} />
      <Box
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          display: "flex",

          gap: middleGap,
        }}
      >
        <div id="property-marker-map" style={{ flex: 1 }}>
          <MarkerMap
            lat={property.lat}
            lng={property.lng}
            height={isMobile ? "250px" : "350px"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography
            variant="h2"
            textStyle={{ fontSize: 28, marginBottom: 25 }}
          >
            Lokacija
          </Typography>
          <Typography>{property.address}</Typography>
        </div>
      </Box>
    </div>
  );
};
