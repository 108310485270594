import React, { useContext } from "react";
import { TextStyle, View } from "react-native";
import CheckboxChecked from "../../assets/icons/form/checkboxChecked.svg";
import CheckboxUnchecked from "../../assets/icons/form/checkboxUnchecked.svg";
import { Typography } from "common/components/Typography";
import { IconButton } from "../buttons/IconButton";
import { FormSettingsContext } from "./hookForms/FormSettingsContext";

interface CheckboxInputProps {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  style?: TextStyle;
  numberOfLines?: number;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  isChecked,
  onChange,
  label,
  style,
  numberOfLines,
}) => {
  const handleOnPress = () => {
    onChange(!isChecked);
  };

  const { disabled } = useContext(FormSettingsContext);

  return (
    <View
      style={{
        flexDirection: "row",
      }}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <View
        style={{
          alignSelf: "flex-start",
        }}
      >
        <IconButton onPress={handleOnPress}>
          {isChecked ? <CheckboxChecked /> : <CheckboxUnchecked />}
        </IconButton>
      </View>
      <Typography
        style={style ? style : { fontSize: 16, flex: 1, marginTop: 6 }}
        onPress={handleOnPress}
        suppressHighlighting
        numberOfLines={numberOfLines ?? 2}
      >
        {label}
      </Typography>
    </View>
  );
};
