import { addAlpha } from "common/helpers/colors.helpers";
import React from "react";

interface TagComponentProps {
  color: string;
  children?: React.ReactNode;
}

export const TagComponent: React.FC<TagComponentProps> = ({
  color,
  children,
}) => {
  return (
    <div
      style={{
        backgroundColor: addAlpha(color, 0.2),
        color: color,
        padding: "8px 16px",
        fontSize: "12px",
        fontWeight: 500,
        borderRadius: "5px",
      }}
    >
      {children}
    </div>
  );
};
