import { Button, CircularProgress, Dialog, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useRawTransaction } from "../../hooks/transactions.hooks";
import { RawTransactionField, rc_fields_parsers } from "./rawTransaction.utils";
import { Typography } from "common/components/Typography";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { Divider } from "common/components/listItems/Divider";
import { TableComponent } from "../tables/TableComponent";
import { formatArea, formatAreaAcres } from "common/helpers/text.helpers";
import { toNumber } from "lodash";
import { downloadTransactionsExcel } from "../../helpers/file.helpers";
import { useParams } from "react-router-dom";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import { showToastError } from "common/toast/toast";
import { DesignationType } from "common/types/comparableFields.types";

interface TransactionModalProps {
  open: boolean;
  onClose?: (e?: any) => void;
  id: string;
  prefetch?: boolean;
}

const tabStyle: React.CSSProperties = {
  fontSize: "14px",
};

export const TransactionModal: React.FC<TransactionModalProps> = ({
  open,
  id,
  onClose,
  prefetch,
}) => {
  const { data, isLoading } = useRawTransaction(id, prefetch ?? open);

  const { propertyId } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const getTransactionField = (
    transaction: RawTransactionField[],
    key: string
  ) => transaction?.find((field) => field.key === key)?.value;

  const getTabLabel = (transaction: RawTransactionField[]) => {
    const transaction_area = getTransactionField(transaction, "isig_plotas");
    const transaction_designation = getTransactionField(
      transaction,
      "pask_pav_i"
    );

    if (!transaction_area) {
      return transaction_designation;
    }

    const transaction_designation_id = getTransactionField(
      transaction,
      "pask_tipas"
    );

    const formatedArea =
      transaction_designation_id === DesignationType.PlotArea
        ? formatAreaAcres(toNumber(transaction_area))
        : formatArea(toNumber(transaction_area));

    return `${transaction_designation} (${formatedArea})`;
  };

  const transactions = [...(data ?? [])].sort(
    (a, b) =>
      toNumber(getTransactionField(b, "isig_plotas") ?? 0) -
      toNumber(getTransactionField(a, "isig_plotas") ?? 0)
  );

  const selectedTransaction = transactions?.[selectedTab];

  const [isDownloading, setIsDownloading] = useState(false);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: "30px",
          width: "657px",
        },
      }}
      maxWidth="md"
      scroll="body"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h2">Registrų centro informacija</Typography>
          <div style={{ marginTop: "-10px", marginRight: "-10px" }}>
            <IconButton onPress={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginVertical: "25px" }} />
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {!!data && (
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue);
            }}
            style={{ marginBottom: "15px" }}
            scrollButtons="auto"
            variant="scrollable"
          >
            {transactions.map((transaction, index) => (
              <Tab
                key={index}
                label={getTabLabel(transaction)}
                style={tabStyle}
              />
            ))}
          </Tabs>
        )}
        {!!selectedTransaction && (
          <TableComponent
            sx={{ width: "100%" }}
            rows={selectedTransaction.map((field) => {
              const parser = rc_fields_parsers.find(
                (parser) => parser.key === field.key
              );
              const value = parser?.parser(field.value) ?? field.value ?? "-";
              return {
                header: field.label,
                elements: [value],
              };
            })}
          />
        )}
        <MUILoadingButton
          loading={isDownloading}
          onClick={async () => {
            setIsDownloading(true);
            try {
              await downloadTransactionsExcel([id], propertyId ?? "");
            } catch (_) {
              showToastError();
            } finally {
              setIsDownloading(false);
            }
          }}
          variant="outlined"
          style={{ alignSelf: "end", marginTop: "20px" }}
        >
          Atsisiųsti XLS formatu
        </MUILoadingButton>
      </div>
    </Dialog>
  );
};
