import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { AdjustmentProvider } from "../../types/api.types";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";

interface AdjustmentsAdminDialogProps {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  providers?: AdjustmentProvider[];
  initialProvider?: string;
  initialDescription?: string;
  initialName: string;
  isNameEditable?: boolean;

  onSubmit?: (
    provider: string | undefined,
    description: string,
    name: string | undefined
  ) => void;
}

export const AdjustmentsAdminDialog: React.FC<AdjustmentsAdminDialogProps> = ({
  open,
  onClose,
  isLoading,
  initialProvider,
  providers,
  initialDescription,
  initialName,
  isNameEditable,
  onSubmit,
}) => {
  const [selectedProvider, setSelectedProvider] = useState(initialProvider);
  const [description, setDescription] = useState(initialDescription);
  const [name, setName] = useState(initialName);

  return (
    <SimpleDialog
      title="Koreguoti pataisą"
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      onSubmit={() => onSubmit?.(selectedProvider, description ?? "", name)}
    >
      <div style={{ display: "flex", gap: "20px" }}>
        <div style={{ flex: 1 }}>
          <div>Vertės pataisa</div>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={!isNameEditable}
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ flex: 1 }}>
          {!!providers && (
            <>
              <div>Numatytoji metodologija</div>
              <Select
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
                style={{ width: "100%" }}
              >
                {providers?.map((provider) => (
                  <MenuItem key={provider.name} value={provider.name}>
                    {provider.fullname}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>Pataisos aprašymas</div>
      <TextField
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </SimpleDialog>
  );
};
