import { Backdrop } from "@mui/material";
import { ActivityIndicator } from "react-native";
import React, { useContext } from "react";
import { TransactionsRCContext } from "./TransactionsRCProvider";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import { TransactionsBackdrop } from "./TransactionsBackdrop";

interface TransactionsLoaderProps {}

export const TransactionsLoader: React.FC<TransactionsLoaderProps> = () => {
  const { isLoading } = useContext(TransactionsRCContext);

  return (
    <TransactionsBackdrop open={isLoading ?? false}>
      <ActivityIndicator
        size={60}
        color={colors.purple2}
        style={{ marginBottom: "20px" }}
      />
      <Typography variant="h3">Prašome palaukti</Typography>
      <Typography>
        Atrenkami sandoriai. Atranka gali užtrukti iki minutės.
      </Typography>
    </TransactionsBackdrop>
  );
};
