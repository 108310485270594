import React from "react";
import { View } from "react-native";
import { TypographyType } from "../../types/typography.types";
import { Typography } from "../Typography";

export interface TitleCaptionProps {
  title?: string;
  caption?: string;
  titleVariant?: TypographyType;
  titleFontSize?: number;
  captionFontSize?: number;
  reverse?: boolean;
  badgeElement?: React.ReactElement;
  titleNumberOfLines?: number;
  captionNumberOfLines?: number;
  gap?: number;
}

export const TitleCaption: React.FC<TitleCaptionProps> = (props) => {
  return (
    <View
      style={{ flexDirection: !!props.reverse ? "column-reverse" : "column" }}
    >
      <View style={{ alignItems: "center", flexDirection: "row" }}>
        {props.title && (
          <Typography
            variant={props.titleVariant ?? "body"}
            numberOfLines={props.titleNumberOfLines ?? 2}
            textStyle={
              !!props.titleFontSize ? { fontSize: props.titleFontSize } : {}
            }
          >
            {props.title}
          </Typography>
        )}
        {props.badgeElement && (
          <View style={{ alignSelf: "center", marginLeft: 10 }}>
            {props.badgeElement}
          </View>
        )}
      </View>
      {props.title && props.caption && (
        <View style={{ height: props.gap ?? 4 }} />
      )}
      {props.caption && (
        <Typography
          variant="caption"
          numberOfLines={props.captionNumberOfLines ?? 2}
          textStyle={
            !!props.captionFontSize ? { fontSize: props.captionFontSize } : {}
          }
        >
          {props.caption}
        </Typography>
      )}
    </View>
  );
};
