import React, { useContext, useEffect, useMemo, useState } from "react";
import { useOutlet, useParams } from "react-router-dom";
import { useProperty } from "../hooks/property.hooks";
import _ from "lodash";
import { ComparablesMap } from "../components/map/ComparablesMap";
import { ComparablesList } from "../components/map/ComparablesList";
import { ComparablesSelectionHeader } from "../components/ComparablesSelectionHeader";
import { usePricePredictions } from "../hooks/pricePredictions.hooks";
import { getPricePredictionFilters } from "../api/api";
import { showToastError } from "common/toast/toast";
import { ComparablesFiltersContext } from "../components/ComparablesFilterProvider";
import {
  ComparableFilter,
  getFilterFunctionByType,
} from "../helpers/comparableFilters.helpers";
import { useUsedCreditsForProperty } from "../hooks/transactions.hooks";
import { TransactionsLoader } from "../components/TransactionsLoader";
import { TransactionsRCContext } from "../components/TransactionsRCProvider";
import { rcTransactionsProdMode } from "../api/constants";
import { TransactionsLoadConfirmation } from "../components/TransactionsLoadConfirmation";
import { LoaderCentered } from "../components/loaders/LoaderCentered";

interface ComparablesSelectionProps {}

export const headerHeight = 100;

export const ComparablesSelection: React.FC<ComparablesSelectionProps> = () => {
  const { propertyId } = useParams();

  const { data: property, isLoading } = useProperty(propertyId ?? "");

  const outlet = useOutlet();

  const { isLoadingPricePredictions } = usePricePredictions(propertyId ?? "");

  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [isInitialFiltersLoad, setIsInitialFiltersLoad] = useState(false);

  const { addFilter } = useContext(ComparablesFiltersContext);
  const { refetchBaseFilters } = useContext(TransactionsRCContext);

  const loadInitialFilters = async () => {
    let skipRefetch = false;

    try {
      const filters = await getPricePredictionFilters(propertyId ?? "");

      if (!filters?.length) {
        setIsInitialFiltersLoad(true);
        skipRefetch = true;
      }

      const fullFilters: ComparableFilter[] =
        filters?.map((filter) => ({
          ...filter,
          filterFunction: getFilterFunctionByType(filter.type, filter.values),
        })) ?? [];

      for (const filter of fullFilters) {
        if (filter.filterFunction === undefined) continue;
        addFilter?.(filter);
      }
    } catch (e) {
      showToastError("Failed to load filters from server");
    }

    if (rcTransactionsProdMode && !skipRefetch) {
      refetchBaseFilters?.(propertyId ?? "");
    }

    setIsLoadingFilters(false);
  };

  useEffect(() => {
    loadInitialFilters();
  }, []);

  const { isLoading: isLoadingUsedCredits } = useUsedCreditsForProperty(
    propertyId ?? ""
  );

  if (
    isLoading ||
    !property ||
    isLoadingPricePredictions ||
    isLoadingFilters ||
    isLoadingUsedCredits
  ) {
    return <LoaderCentered />;
  }

  return (
    <div>
      <div
        style={{ display: !!outlet ? "none" : "flex", flexDirection: "column" }}
      >
        <ComparablesSelectionHeader
          property={property}
          isInitialFiltersLoad={isInitialFiltersLoad}
        />
        <div
          style={{
            flexDirection: "row",
            height: `calc(100vh - ${headerHeight}px)`,
            display: "flex",
          }}
        >
          <div style={{ flex: 2, minWidth: "490px" }}>
            <ComparablesList />
          </div>
          <div style={{ flex: 5 }}>
            <ComparablesMap property={property} />
          </div>
        </div>

        <TransactionsLoader />
        <TransactionsLoadConfirmation />
      </div>
      {!!outlet && outlet}
    </div>
  );
};
