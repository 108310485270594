import { MonthDate } from "./types";

export const getMaxDate = (dateA: MonthDate, dateB: MonthDate) => {
  if (dateA.year === dateB.year) {
    if (dateA.month > dateB.month) {
      return dateA;
    }
    return dateB;
  }

  if (dateA.year > dateB.year) {
    return dateA;
  }
  return dateB;
};

export const getMonthLabel = (date: MonthDate) => {
  const monthDict: { [key: number]: string } = {
    1: "Sausis",
    2: "Vasaris",
    3: "Kovas",
    4: "Balandis",
    5: "Gegužė",
    6: "Birželis",
    7: "Liepa",
    8: "Rugpjūtis",
    9: "Rugsėjis",
    10: "Spalis",
    11: "Lapkritis",
    12: "Gruodis",
  };

  return `${monthDict[date.month]} ${date.year}`;
};
