import React from "react";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { UsedCreditsByMonth } from "../../types/api.types";
import { AppraiserMonthUsageTable } from "./AppraiserMonthUsageTable";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IconButton } from "common/components/buttons/IconButton";
import { getMonthLabel } from "../../components/datepicker/helpers";
import { formatMonthlyLimitPrice } from "common/helpers/text.helpers";

interface StatisticsRowProps {
  data: UsedCreditsByMonth;
}

export const getMonthCreditsUsed = (data: UsedCreditsByMonth) => {
  let count = 0;

  for (const appraiser of data.appraisers) {
    count += appraiser.totalCredits;
  }

  return count;
};

export const StatisticsRow: React.FC<StatisticsRowProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <SimpleTableRow onClick={() => setIsExpanded(!isExpanded)}>
        <SimpleTableElement>
          {isExpanded ? (
            <IconButton onPress={() => setIsExpanded(false)}>
              <ExpandLessIcon />
            </IconButton>
          ) : (
            <IconButton onPress={() => setIsExpanded(true)}>
              <ExpandMoreIcon />
            </IconButton>
          )}
        </SimpleTableElement>
        <SimpleTableElement isFirstElement>
          {getMonthLabel({
            month: data.month,
            year: data.year,
          })}
        </SimpleTableElement>
        <SimpleTableElement>
          {formatMonthlyLimitPrice(getMonthCreditsUsed(data))}
        </SimpleTableElement>
      </SimpleTableRow>
      {isExpanded && (
        <SimpleTableRow>
          <SimpleTableElement />
          <SimpleTableElement isFirstElement>
            <div style={{ margin: "20px 0" }}>
              <AppraiserMonthUsageTable appraisers={data.appraisers} />
            </div>
          </SimpleTableElement>
          <SimpleTableElement />
        </SimpleTableRow>
      )}
    </>
  );
};
