import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useFormFieldErrors } from "common/hooks/form.hooks";
import { FormFieldWrapper } from "common/components/form/FormFieldWrapper";
import { LocalizedDatepicker } from "./LocalizedDatepicker";

interface FormDatepickerProps {
  label?: string;
  name: string;
  isRequired?: boolean;
}

export const FormDatepicker: React.FC<FormDatepickerProps> = ({
  label,
  name,
  isRequired,
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <FormFieldWrapper
            label={label}
            error={errorText}
            isRequired={isRequired}
          >
            <LocalizedDatepicker value={value} onChange={onChange} />
          </FormFieldWrapper>
        );
      }}
    />
  );
};
