import { convertToRoman } from "../../mobile/src/common/helpers/romanNumbers";
import dayjs from "dayjs";

export function getMinutesFromNow(date: Date) {
  const differenceInTime = new Date().getTime() - new Date(date).getTime();
  const differenceInMinutes = Math.floor(differenceInTime / (1000 * 60));
  return differenceInMinutes;
}

export function getNotificationDateString(date: Date) {
  const differenceInMinutes = getMinutesFromNow(date);

  if (differenceInMinutes < 60) {
    return `Prieš ${differenceInMinutes} min`;
  }

  const differenceInHours = Math.floor(differenceInMinutes / 60);

  if (differenceInHours < 24) {
    return `Prieš ${differenceInHours} h`;
  }

  const differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInDays === 1) {
    return `Vakar`;
  }

  return `Prieš ${differenceInDays} d`;
}

export function getProjectDateString(
  year: number | null,
  quarter: number | null
) {
  if (!year) return "-";
  let dateString = `${year} m.`;
  if (!!quarter) dateString = `${dateString} ${convertToRoman(quarter)} ketv.`;
  return dateString;
}

export function getLithuanianDateString(date: Date | string) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join(".");
}

export function getLithuanianDateTimeString(date: Date | string) {
  const dateString = getLithuanianDateString(date);
  const createdAt = new Date(date);

  let createdAtHours: string | number = createdAt.getHours();
  if (createdAtHours < 10) {
    createdAtHours = `0${createdAtHours}`;
  }
  let createdAtMinutes: string | number = createdAt.getMinutes();
  if (createdAtMinutes < 10) {
    createdAtMinutes = `0${createdAtMinutes}`;
  }

  return `${dateString} ${createdAtHours}:${createdAtMinutes}`;
}

export const getDayjsDate = (date: any) => {
  if (!date) return undefined;
  return dayjs(date) as any;
};
