import { AdditionalREType, RelatedRE } from "common/types/common.types";
import React from "react";
import BasementIcon from "../assets/icons/basement.svg";
import ParkingIcon from "../assets/icons/parking.svg";
import PlotAreaIcon from "../assets/icons/plotAreaIcon.svg";
import { Typography } from "common/components/Typography";
import { formatArea, formatAreaAcres } from "common/helpers/text.helpers";
import { IconButton, Tooltip } from "@mui/material";

interface RelatedRETagProps {
  re: RelatedRE;
  full?: boolean;
}

export const RelatedRETag: React.FC<RelatedRETagProps> = ({ re, full }) => {
  const getIcon = () => {
    switch (re.type) {
      case AdditionalREType.Basement:
        return <BasementIcon />;
      case AdditionalREType.Garage:
        return <ParkingIcon />;
      case AdditionalREType.PlotArea:
        return <PlotAreaIcon />;
    }
  };

  const getArea = (area: number) => {
    if (re.type === AdditionalREType.PlotArea) {
      return formatAreaAcres(area);
    }
    return formatArea(area);
  };

  const getText = () => {
    switch (re.type) {
      case AdditionalREType.Basement:
        return "Su sandėliuku";
      case AdditionalREType.Garage:
        return "Su parkingu";
      case AdditionalREType.PlotArea:
        return "Su sklypu";
    }
  };

  const textForFull = `${getText()} (${formatArea(re.area)})`;

  const textTooltip = `${getText()}, kurio įsigyta ${getArea(
    re.area
  )} iš ${getArea(re.area_total)}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Tooltip title={textTooltip}>
        <IconButton size="small" style={{ padding: 0 }}>
          {getIcon()}
        </IconButton>
      </Tooltip>
      {full && (
        <Typography
          variant="caption"
          textStyle={{ fontSize: 12, marginLeft: 3 }}
        >
          {textForFull}
        </Typography>
      )}
    </div>
  );
};
