import React from "react";
import { ContentCard } from "../../components/ContentCard";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { AppraiserProfile } from "../../types/appraiser.types";
import { useAppraiser } from "../../hooks/auth.hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { setAppraiserProfileInfo } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { FormImageInput } from "../../components/form/FormImageInput";
import { FormInput } from "common/components/form/hookForms/FormInput";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormDatepicker } from "../../components/form/FormDatepicker";
import dayjs from "dayjs";

interface AccountSettingsFormProps {}

const schema = Yup.object().shape({
  profileImageUrl: Yup.string().required(),
  fullName: Yup.string().required(),
  positionTitle: Yup.string().required(),
  phoneNumber: Yup.string()
    .required()
    .matches(/^\+?[1-9]\d{1,14}$/, "Phone number must be valid."),
  website: Yup.string()
    .url("Įvestas neteisingas tinklalapis")
    .notRequired()
    .nullable(),
  certificateNumber: Yup.string(),
  certificateValidFrom: Yup.date().nullable(),
}) as Yup.ObjectSchema<AppraiserProfile>;

export const AccountSettingsForm: React.FC<AccountSettingsFormProps> = () => {
  const { data: appraiser } = useAppraiser();

  const methods = useForm<AppraiserProfile>({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: appraiser?.fullName ?? "",
      positionTitle: appraiser?.positionTitle ?? "",
      phoneNumber: appraiser?.phoneNumber ?? "",
      website: appraiser?.website ?? "",
      profileImageUrl: appraiser?.profileImageUrl,
      certificateNumber: appraiser?.certificateNumber,
      certificateValidFrom: !!appraiser?.certificateValidFrom
        ? (dayjs(appraiser.certificateValidFrom) as any)
        : undefined,
    },
  });

  const { mutate, isLoading } = useMutation(setAppraiserProfileInfo, {
    onError: () => {
      showToastError();
    },
    onSuccess: () => {
      showToastSuccess("Profilio informacija sėkmingai išsaugota.");
    },
  });

  return (
    <ContentCard title="Mano profilio informacija">
      <FormProvider {...methods}>
        <FormSettingsContext.Provider
          value={{ desktopColumns: true, smallFont: true, maxInputWidth: 600 }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
            onSubmit={methods.handleSubmit((data) => {
              mutate(data);
            })}
          >
            <FormImageInput label="Profilio nuotrauka" name="profileImageUrl" />
            <FormInput
              variant="text"
              label="Vardas Pavardė"
              name="fullName"
              isRequired
            />
            <FormInput
              variant="text"
              label="Pareigų pavadinimas"
              name="positionTitle"
              isRequired
            />
            <FormInput
              variant="text"
              label="Telefono numeris"
              name="phoneNumber"
              isRequired
            />
            <FormInput variant="text" label="Tinklapis" name="website" />

            <FormInput
              variant="text"
              label="NT vertintojo kvalifikacijos pažymėjimas"
              name="certificateNumber"
            />
            <FormDatepicker
              label="Pažymėjimas išduotas"
              name="certificateValidFrom"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                style={{
                  width: "345px",
                }}
                loading={isLoading}
              >
                Išsaugoti
              </LoadingButton>
            </div>
          </form>
        </FormSettingsContext.Provider>
      </FormProvider>
    </ContentCard>
  );
};
