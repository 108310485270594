import { Alert } from "@mui/material";
import { getErrorMessage } from "common/helpers/error.helpers";
import React from "react";

interface ErrorAlertProps {
  error: any;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ error }) => {
  const errorMessage = getErrorMessage(error);

  if (!errorMessage) {
    return null;
  }

  return <Alert severity="error">{errorMessage}</Alert>;
};
