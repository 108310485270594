import { getRoundedPercent } from "common/helpers/common.helpers";
import React from "react";
import { InfoTooltip } from "../tooltips/InfoTooltip";

interface ComparablesCountOverviewRowProps {
  label: string;
  count: number;
  allCount: number;
  tooltipText?: string;
  icon?: React.ReactNode;
  iconBackgroundColor?: string;
}

export const ComparablesCountOverviewRow: React.FC<
  ComparablesCountOverviewRowProps
> = ({ label, count, allCount, tooltipText, iconBackgroundColor, icon }) => {
  const percent = getRoundedPercent(allCount === 0 ? 0 : count / allCount);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "8px",
      }}
    >
      {!!icon && !!iconBackgroundColor && (
        <div
          style={{
            backgroundColor: iconBackgroundColor,
            width: "18px",
            height: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "2px",
            marginRight: "10px",
          }}
        >
          {icon}
        </div>
      )}
      <div>{`${label} - ${percent}% (${count})`}</div>
      {!!tooltipText && (
        <div style={{ marginLeft: "4px" }}>
          <InfoTooltip title={tooltipText} />
        </div>
      )}
    </div>
  );
};
