import React from "react";
import {
  useComparablesSummary,
  useFinalPricePredictions,
} from "../hooks/pricePredictions.hooks";
import { useParams } from "react-router-dom";
import { splitIntoChunks } from "common/helpers/common.helpers";
import { ComparablesSummaryTable } from "../components/comparablesSummary/ComparablesSummaryTable";
import { ValuationSummary } from "../components/comparablesSummary/ValuationSummary";
import { getPredictionsWithRealPrices } from "../helpers/pricePredictions.helpers";
import { useScrollToTop } from "../hooks/router.hooks";
import { DescriptionsTable } from "../components/comparablesSummary/DescriptionsTable";
import { LoaderCentered } from "../components/loaders/LoaderCentered";
import { ComparableSummaryTableChunked } from "../components/comparablesSummary/ComparableSummaryTableChunked";

interface ComparableSummaryPrintProps {}

export const ComparableSummaryPrint: React.FC<
  ComparableSummaryPrintProps
> = () => {
  const { propertyId } = useParams();
  useScrollToTop();

  const { data } = useFinalPricePredictions(propertyId ?? "");

  if (!data) return <LoaderCentered />;

  return (
    <ComparableSummaryTableChunked
      propertyId={propertyId}
      finalPricePredictions={data}
    />
  );
};
