import React from "react";
import { TotalReportPrice } from "../../../types/api.types";
import { formatPrice } from "common/helpers/text.helpers";
import { Divider } from "common/components/listItems/Divider";
import colors from "common/styles/colors";

interface DiscountAmountTableProps {
  reportPrice: TotalReportPrice;
}

export const DiscountAmountTable: React.FC<DiscountAmountTableProps> = ({
  reportPrice,
}) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "250px",
      }}
    >
      <tbody>
        {reportPrice.discount !== null && (
          <>
            <tr>
              <td>Tarpinė suma</td>
              <td style={{ textAlign: "right" }}>
                {formatPrice(reportPrice.fullPrice, 2)}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: `1px solid ${colors.greybg}`,
              }}
            >
              <td>Nuolaida</td>
              <td style={{ textAlign: "right", padding: "10px 0" }}>
                {formatPrice(-reportPrice.discount.discountAmount, 2)}
              </td>
            </tr>
          </>
        )}
        <tr>
          <td>Galutinė suma</td>
          <td
            style={{
              textAlign: "right",
              padding: "10px 0",
              fontWeight: 600,
            }}
          >
            {formatPrice(reportPrice.totalPrice, 2)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
