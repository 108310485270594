import { Button } from "common/components/buttons/Button";
import colors from "common/styles/colors";
import React, { useContext } from "react";
import {
  ComparableFilter,
  ComparableFilterType,
} from "../../helpers/comparableFilters.helpers";
import { getEndingByCountLT3 } from "common/helpers/text.helpers";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import { useRCTransactions } from "../../helpers/rcTransactions.hooks";
import { AppraiserProperty } from "../../types/appraiser.types";

interface ConfirmFiltersButtonsProps {
  onConfirm: (wasRefetched: boolean) => void;
  filteredComparablesCount?: number;
  appliedMapFilters?: ComparableFilter[];
  onRefetch: () => void;
  property: AppraiserProperty;
}

const baseFilters = [
  ComparableFilterType.Area,
  ComparableFilterType.Dates,
  ComparableFilterType.ValuationZone,
  ComparableFilterType.Year,
  ComparableFilterType.Designation,
];

export const ConfirmFiltersButtons: React.FC<ConfirmFiltersButtonsProps> = ({
  onConfirm,
  onRefetch,
  filteredComparablesCount,
  appliedMapFilters,
  property,
}) => {
  const { refetch, isLoading } = useContext(TransactionsRCContext);
  const { getRCFiltersQueryData } = useRCTransactions();

  const { appliedFilters, setInitialFilters } = useContext(
    ComparablesFiltersContext
  );

  const isFiltersEdited = (() => {
    for (const baseFilter of baseFilters) {
      const initial = appliedMapFilters?.find(
        (filt) => filt.type === baseFilter
      );
      const current = appliedFilters?.find((filt) => filt.type === baseFilter);

      if (!_.isEqual(initial?.values, current?.values)) {
        return true;
      }
    }
    return false;
  })();

  return (
    <>
      <div style={{ width: "200px" }}>
        <OutlinedButton
          onPress={() => {
            setInitialFilters?.(property);
          }}
          disabled={isLoading}
        >
          ATSTATYTI
        </OutlinedButton>
      </div>
      <div style={{ width: "300px" }}>
        <Button
          backgroundColor="purple2"
          textStyle={{ color: colors.white }}
          onPress={async () => {
            if (isFiltersEdited) {
              const queryData = getRCFiltersQueryData();
              onRefetch();
              await refetch?.(queryData, property._id);
              onConfirm(true);
            } else {
              onConfirm(false);
            }
          }}
          isLoading={isLoading}
        >
          {`IEŠKOTI ${
            isFiltersEdited
              ? "RC SANDORIŲ"
              : `${filteredComparablesCount} SANDORI${getEndingByCountLT3(
                  filteredComparablesCount
                )}`
          }`}
        </Button>
      </div>
    </>
  );
};
