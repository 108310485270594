import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SmallNumberInput } from "../SmallNumberInput";
import { useFormFieldErrors } from "../../../hooks/form.hooks";
import { ViewStyle } from "react-native";

interface SmallFormNumberInputProps {
  maxValue: number;
  minValue: number;
  label?: string;
  name: string;
  isRequired?: boolean;
  customStyle?: ViewStyle;
}

export const SmallFormNumberInput: React.FC<SmallFormNumberInputProps> = ({
  maxValue,
  minValue,
  label,
  name,
  isRequired,
  customStyle,
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <SmallNumberInput
            value={value}
            error={errorText}
            onChange={onChange}
            maxValue={maxValue}
            minValue={minValue}
            label={label}
            isRequired={isRequired}
            customStyle={customStyle}
          />
        );
      }}
    />
  );
};
