import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Forward from "../../assets/icons/forward.svg";
import { HighlightButton } from "common/components/buttons/base/HighlightButton";

interface ListItemProps {
  onPress?: () => void;
  onLongPress?: () => void;
  noPress?: boolean;
  noIcon?: boolean;
  height: number;
  marginRight?: number;
  customIcon?: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  borderRadius?: number;
  backgroundColor?: string;
  disabled?: boolean;
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const onPress = () => {
    if (props.disabled) {
      return;
    }
    props.onPress?.();
  };

  return (
    <View style={{ width: "100%" }}>
      <HighlightButton
        onPress={props.noPress ? undefined : onPress}
        style={props.style}
        borderRadius={props.borderRadius}
        backgroundColor={props.backgroundColor}
        onLongPress={props.onLongPress}
        disabled={props.disabled}
      >
        <View
          style={{
            height: props.height,
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 1 }}>{props.children}</View>
          {!props.noPress && !props.noIcon && (
            <View style={{ marginRight: props.marginRight }}>
              {props.customIcon ?? <Forward />}
            </View>
          )}
        </View>
      </HighlightButton>
    </View>
  );
};
