import React from "react";
import { getPredictionsWithRealPrices } from "../../helpers/pricePredictions.helpers";
import { FinalPricePredictions } from "common/types/common.types";
import {
  ComparablesSummaryTable,
  ComparableSummary,
} from "./ComparablesSummaryTable";
import { AppraiserProperty } from "../../types/appraiser.types";
import { splitIntoChunks } from "common/helpers/common.helpers";
import { ValuationSummary } from "./ValuationSummary";
import { DescriptionsTable } from "./DescriptionsTable";

interface ComparableSummaryTableChunkedProps {
  finalPricePredictions: FinalPricePredictions;
  propertyFromProps?: AppraiserProperty;
  propertyId?: string;
  chunkSize?: number | null;
}

export const ComparableSummaryTableChunked: React.FC<
  ComparableSummaryTableChunkedProps
> = ({ finalPricePredictions, propertyId, propertyFromProps, chunkSize }) => {
  const comps = getPredictionsWithRealPrices(finalPricePredictions);

  const chunkSizeNew = chunkSize ?? 3;

  const compChunks =
    chunkSize === null ? [comps] : splitIntoChunks(chunkSizeNew, comps, false);

  return (
    <div style={{ backgroundColor: "white" }}>
      {compChunks.map((chunk, index) => (
        <div
          style={{
            pageBreakInside: "avoid",
          }}
        >
          <ComparablesSummaryTable
            key={index}
            finalPricePredictions={finalPricePredictions}
            propertyId={propertyId ?? ""}
            comps={chunk}
            isPrintable
            comparableStartIndex={index * chunkSizeNew}
            propertyFromProps={propertyFromProps}
          />
        </div>
      ))}
      <div
        style={{
          pageBreakInside: "avoid",
        }}
      >
        <ValuationSummary
          propertyId={propertyId ?? ""}
          finalPricePredictions={finalPricePredictions}
          propertyFromProps={propertyFromProps}
        />
      </div>
      <div style={{ height: "30px" }} />
      {!!finalPricePredictions.descriptions && (
        <div
          style={{
            pageBreakInside: "avoid",
          }}
        >
          <DescriptionsTable
            descriptions={finalPricePredictions.descriptions}
          />
        </div>
      )}
    </div>
  );
};
