import { Typography } from "common/components/Typography";
import { formatPrice } from "common/helpers/text.helpers";
import React from "react";

interface AdjustmentSummaryRowProps {
  label: string;
  price: number;
  priceArea: number;
}

export const AdjustmentSummaryRow: React.FC<AdjustmentSummaryRowProps> = ({
  label,
  price,
  priceArea,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", width: "400px" }}>
      <Typography textStyle={{ flex: 2, fontSize: 16 }}>{label}</Typography>
      <Typography
        variant="h4"
        textStyle={{ flex: 1, textAlign: "right" }}
      >{`${formatPrice(priceArea)}/m²`}</Typography>
      <Typography variant="h4" textStyle={{ flex: 1, textAlign: "right" }}>
        {formatPrice(price)}
      </Typography>
    </div>
  );
};
