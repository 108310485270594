import { round } from "./common.helpers";

const getEndingByCountLT = (endings: string[], count?: number) => {
  if (count === undefined) return endings[2];

  let ending = count % 100;
  if (ending > 10 && ending < 20) {
    return endings[0];
  }
  ending = ending % 10;
  if (ending === 0) {
    return endings[0];
  }

  if (ending === 1) {
    return endings[1];
  }
  return endings[2];
};

export const getEndingByCountLTKilmininkas = (count?: number) => {
  return getEndingByCountLT(["Ų", "Ą", "US"], count);
};

export const getEndingByCountLTVardininkas = (count?: number) => {
  return getEndingByCountLT(["Ų", "AS", "AI"], count);
};

export const getEndingByCountLTBudvardis = (count?: number) => {
  return getEndingByCountLT(["Ų", "AS", "I"], count);
};

export const getEndingByCountLTBudvardis2 = (count?: number) => {
  return getEndingByCountLT(["USIŲ", "ĘS", "Ę"], count);
};

export const getEndingByCountLT1 = (count?: number) => {
  return getEndingByCountLT(["Ų", "A", "OS"], count);
};

export const getEndingByCountLT2 = (count?: number) => {
  return getEndingByCountLT(["A", "A", "OS"], count);
};

export const getEndingByCountLT3 = (count?: number) => {
  return getEndingByCountLT(["Ų", "O", "Ų"], count);
};
export const getEndingByCountLT4 = (count?: number) => {
  return getEndingByCountLT(["IŲ", "IS", "IAI"], count);
};
export const getEndingByCountLT5 = (count?: number) => {
  return getEndingByCountLT(["IŲ", "Į", "IUS"], count);
};

export const formatNumber = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const getRangeText = (
  min: number | null | undefined,
  max: number | null | undefined,
  beginning: string,
  ending: string,
  fullRange?: boolean
) => {
  if (min === null || max === null || min === undefined || max === undefined) {
    return null;
  }
  if (min === max && !fullRange)
    return `${beginning}${formatNumber(min)}${ending}`;

  return `${beginning}${formatNumber(
    min
  )}${ending} - ${beginning}${formatNumber(max)}${ending}`;
};

export const convertPriceAreaToAbsolute = (price: number, area: number) => {
  return Math.round(price * area);
};

export const formatPrice = (
  price?: number | null,
  digits: number = 0,
  currency: string = "€"
) => {
  if (price === undefined || price === null) {
    return `- ${currency}`;
  }
  const labelAsNumber = formatNumber(round(price, digits));
  return `${labelAsNumber} ${currency}`;
};

export const formatMonthlyLimitPrice = (price?: number | null) => {
  if (price === undefined || price === null) {
    return formatPrice(price);
  }
  return formatPrice(price / 100, 2);
};

export const formatArea = (area?: number | null) =>
  area === null || area === undefined ? undefined : `${area} m²`;

export const formatAreaAcres = (area?: number | null) =>
  area === null || area === undefined ? undefined : `${round(area * 100, 3)} a`;

export const formatAreaPrice = (
  price?: number | null,
  digits: number = 0,
  currency: string = "€"
) => {
  return `${formatPrice(price, digits, currency)}/m²`;
};

export function stringToIntOrUndefined(value: string): number | undefined {
  const intValue = parseInt(value, 10);
  return Number.isNaN(intValue) ? undefined : intValue;
}
