import React from "react";
import { LoginRequest } from "../../types/api.types";
import { useForm, FormProvider } from "react-hook-form";
import { FormInput } from "common/components/form/hookForms/FormInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "common/components/Typography";
import { useMutation } from "react-query";
import { loginAppraiser } from "../../api/api";
import { useAuthSideEffects } from "../../hooks/auth.hooks";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { FormContainer } from "../../components/containers/FormContainer";
import { Link } from "@mui/material";

interface LoginProps {}

const validationSchema: Yup.ObjectSchema<LoginRequest> = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const Login: React.FC<LoginProps> = () => {
  const methods = useForm<LoginRequest>({
    resolver: yupResolver(validationSchema),
  });

  const { onAuthSuccess } = useAuthSideEffects();
  const { mutate, isLoading, error } = useMutation(loginAppraiser, {
    onSuccess: onAuthSuccess,
  });

  return (
    <FormContainer onSubmit={methods.handleSubmit((data) => mutate(data))}>
      <Typography variant="h1" textStyle={{ textAlign: "center" }}>
        Prisijungti
      </Typography>
      <ErrorAlert error={error} />
      <FormProvider {...methods}>
        <FormInput
          label="El. paštas"
          name="email"
          variant="text"
          maxWidth={700}
        />
        <FormInput
          label="Slaptažodis"
          name="password"
          variant="password"
          maxWidth={700}
        />
      </FormProvider>
      <MUILoadingButton loading={isLoading} type="submit">
        PRISIJUNGTI
      </MUILoadingButton>

      <Link
        style={{ alignSelf: "center", marginTop: "30px" }}
        href="https://docs.valuations.lt"
        target="_blank"
        underline="none"
      >
        Apie produktą ir mokomoji medžiaga
      </Link>
    </FormContainer>
  );
};
