import { Button, Radio } from "@mui/material";
import colors from "common/styles/colors";
import React from "react";

interface PropertyTypeOptionProps {
  checked: boolean;
  label: string;
  onClick?: () => void;
}

export const PropertyTypeOption: React.FC<PropertyTypeOptionProps> = ({
  checked,
  label,
  onClick,
}) => {
  return (
    <Button
      variant="outlined"
      startIcon={<Radio checked={checked} />}
      style={{ borderColor: colors.beige }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
