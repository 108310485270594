export const getFinishingLabel = (
  finishing: 0 | 1 | boolean | number | null | undefined,
  finishingFull: number | null | undefined
) => {
  if (finishing === null || finishing === undefined) {
    return null;
  }
  const text = !!finishing ? "Įrengtas" : "Dalinė apdaila";

  if (finishingFull === null || finishingFull === undefined) {
    return text;
  }

  return `${text} (${finishingFull}%)`;
};
