import { Asset, launchImageLibrary } from "react-native-image-picker";
import { isWeb } from "../../environment";
import { v4 as uuid } from "uuid";
export const pickImages = async (selectionLimit?: number) => {
  const result = await launchImageLibrary({
    mediaType: "photo",
    selectionLimit,
    maxWidth: 2000,
    maxHeight: 2000,
    quality: 0.4,
  });
  if (selectionLimit !== undefined) {
    result.assets = result.assets?.splice(0, selectionLimit ?? 1);
  }

  return result;
};

function base64ToFile(base64String: string, filename: string) {
  const arr = base64String.split(",");
  const mime = arr?.[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getImageData = (imageData: Asset[], formDataKey?: string) => {
  const formData = new FormData();
  for (const data of imageData) {
    const localUri = data.uri ?? "";
    if (isWeb()) {
      //@ts-ignore
      formData.append("photos", base64ToFile(data.uri, uuid()));

      continue;
    }
    const filename = localUri.split("/").pop();
    const filenameEnding = filename?.split(".").pop();
    const imageType = filenameEnding ? `image/${filenameEnding}` : "image";

    // @ts-ignore
    formData.append(formDataKey ?? "photos[]", {
      // @ts-ignore
      uri: data.uri,
      // @ts-ignore
      name: filename,
      // @ts-ignore
      type: imageType,
    });
  }

  return formData;
};
