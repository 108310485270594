import { PropertiesList } from "./components/PropertiesList";
import { Typography } from "common/components/Typography";
import { Button } from "@mui/material";
import { LinkForButton } from "./components/links/LinkForButton";

function App() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h1">Turto vertinimai</Typography>
        <LinkForButton to="/properties/create/inputMethod">
          <Button variant="contained">sukurti vertinimą</Button>
        </LinkForButton>
      </div>
      <PropertiesList />
    </div>
  );
}

export default App;
