import React from "react";
import { View } from "react-native";
import { GoogleMapsPrediction } from "../../types/common.types";
import { OpacityButton } from "../buttons/base/OpacityButton";
import LocationPinIcon from "../../assets/icons/locationPinOutlined.svg";
import { TitleCaption } from "../listItems/TitleCaption";

interface LocationPredictionsListProps {
  predictions: GoogleMapsPrediction[];
  onPredictionPress: (placeId: string) => void;
}

export const LocationPredictionsList: React.FC<
  LocationPredictionsListProps
> = ({ predictions, onPredictionPress }) => {
  return (
    <View>
      {predictions.map((prediction) => (
        <OpacityButton
          key={prediction.description}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
            paddingRight: 20,
          }}
          onPress={() => {
            onPredictionPress(prediction.place_id);
          }}
        >
          <View style={{ marginRight: 10 }}>
            <LocationPinIcon />
          </View>
          <View style={{ flex: 1 }}>
            <TitleCaption
              title={prediction.structured_formatting.main_text}
              caption={prediction.structured_formatting.secondary_text}
              titleNumberOfLines={1}
              captionNumberOfLines={1}
              gap={0}
            />
          </View>
        </OpacityButton>
      ))}
    </View>
  );
};
