import React, { useEffect } from "react";
import { RegisterRequest } from "../../types/api.types";
import * as yup from "yup";
import { mediumStrongPasswordSchema } from "../../schemas/password.schema";
import { useRouteQuery } from "../../hooks/router.hooks";
import { useAuthSideEffects, useDecodedToken } from "../../hooks/auth.hooks";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import { FormContainer } from "../../components/containers/FormContainer";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { registerAppraiser, resetAppraiserPassword } from "../../api/api";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";

interface AppraiserPasswordSetProps {
  type: "registration" | "reset";
}

const validationSchema: yup.ObjectSchema<RegisterRequest> = yup.object({
  token: yup.string().required(),
  password: mediumStrongPasswordSchema,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Slaptažodžiai privalo sutapti")
    .required("Pakartokite slaptažodį"),
});

export const AppraiserPasswordSet: React.FC<AppraiserPasswordSetProps> = ({
  type,
}) => {
  const query = useRouteQuery();
  const token = query.get("token") ?? "";
  const { data, isLoading: isLoadingToken } = useDecodedToken();

  const methods = useForm<RegisterRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      token,
    },
  });

  useEffect(() => {
    methods.setValue("token", token);
  }, [token]);
  const navigate = useNavigate();
  const { onAuthSuccess } = useAuthSideEffects();

  const mutationFn =
    type === "registration" ? registerAppraiser : resetAppraiserPassword;
  const { mutate, isLoading, error } = useMutation(mutationFn, {
    onSuccess: (data) => {
      onAuthSuccess(data);
      navigate("/");
    },
  });
  if (isLoadingToken) {
    return <LoaderCentered />;
  }

  if (!data) {
    return <div>Error</div>;
  }

  if (!data.data?.email) {
    return (
      <Typography variant="h2" textStyle={{ color: colors.red }}>
        Registracijos nuoroda negaliojanti
      </Typography>
    );
  }

  return (
    <FormContainer onSubmit={methods.handleSubmit((data) => mutate(data))}>
      <Typography variant="h1" textStyle={{ textAlign: "center" }}>
        {type === "registration" ? "Registruotis" : "Atstatyti slaptažodį"}
      </Typography>
      <Typography
        variant="h2"
        textStyle={{ textAlign: "center", marginBottom: "20px" }}
      >
        {data.data.email}
      </Typography>
      <ErrorAlert error={error} />
      <FormProvider {...methods}>
        <FormInput
          label="Slaptažodis"
          name="password"
          variant="password"
          maxWidth={700}
        />
        <FormInput
          label="Patvirtinti slaptažodį"
          name="confirmPassword"
          variant="password"
          maxWidth={700}
        />
      </FormProvider>
      <MUILoadingButton loading={isLoading} type="submit">
        PRISIJUNGTI
      </MUILoadingButton>
    </FormContainer>
  );
};
