import React from "react";
import {
  PropertyRequestStatus,
  PropertyStatus,
} from "../../types/appraiser.types";
import { PropertyStatusIcon } from "./PropertyStatusIcon";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import colors from "common/styles/colors";
import { Box } from "@mui/material";

interface PropertyStatusesProps {
  propertyStatuses: PropertyStatus[];
  latestStatus: PropertyRequestStatus;
}

const steps = [
  { status: PropertyRequestStatus.Pending, title: "Užklausa gauta" },
  { status: PropertyRequestStatus.InProgress, title: "Užklausa vykdoma" },
  { status: PropertyRequestStatus.Evaluated, title: null },
  { status: PropertyRequestStatus.Completed, title: "Užklausa įvykdyta" },
];

export const PropertyStatuses: React.FC<PropertyStatusesProps> = ({
  propertyStatuses,
  latestStatus,
}) => {
  const currentStage =
    steps.findIndex((step) => step.status === latestStatus) + 1;

  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        mb: "30px",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {steps.map((step, index) => {
          if (step.title === null) return null;

          const filteredIndex = steps
            .filter((s) => !!s.title)
            .findIndex((s) => s.status === step.status);

          const status = propertyStatuses.find(
            (status) => status.status === step.status
          );
          return (
            <React.Fragment key={step.status}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <PropertyStatusIcon
                  completed={
                    index + 1 < currentStage ||
                    (index + 1 === currentStage &&
                      latestStatus === PropertyRequestStatus.Completed)
                  }
                  inProgress={index + 1 <= currentStage}
                  stage={filteredIndex + 1}
                />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  {step.title}
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  {!!status?.createdAt
                    ? getLithuanianDateString(status.createdAt)
                    : "-"}
                </div>
              </div>
              {index + 1 < steps.length && (
                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: colors.beige,
                    margin: "0 10px",
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Box>
  );
};
