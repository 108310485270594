import { round } from "common/helpers/common.helpers";
import colors from "common/styles/colors";
import React from "react";
import { getAdjustmentPercent } from "../helpers/pricePredictions.helpers";

interface SummaryTableAdjustmentProps {
  priceIncreaseArea?: string;
  value?: string;
  percent?: number;
}

export const SummaryTableAdjustment: React.FC<SummaryTableAdjustmentProps> = ({
  value,
  priceIncreaseArea,
  percent,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <span>{value}</span>
      <span style={{ display: "flex", gap: "5px", whiteSpace: "nowrap" }}>
        {priceIncreaseArea}
        <span
          style={{
            color: colors.purple2,
          }}
        >{`(${getAdjustmentPercent(percent)})`}</span>
      </span>
    </div>
  );
};
