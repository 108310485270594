import React, { useMemo } from "react";
import { ComparableMarker } from "../../../components/map/ComparableMarker";
import { PropertyInfra } from "../../../types/api.types";
import colors from "common/styles/colors";
import { PropertyDistanceCard } from "./PropertyDistanceCard";

interface InfraMarkerProps {
  isSelected: boolean;
  onClick: () => void;
  infra: PropertyInfra;
  badgeCount: number;
}

export const InfraMarker: React.FC<InfraMarkerProps> = ({
  isSelected,
  onClick,
  infra,
  badgeCount,
}) => {
  const icon = useMemo(
    () => (
      <div
        style={{
          backgroundImage: `url(${infra.icon})`,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    ),
    [infra.icon]
  );

  return (
    <ComparableMarker
      isSelected={isSelected}
      markerProps={{
        lat: infra.lat,
        lng: infra.lng,
        backgroundColor: colors.white,
        customIcon: icon,
        onClick,
        badgeCount,
      }}
    >
      <div
        style={{
          backgroundColor: colors.white,
          padding: "10px",
          borderRadius: "10px",
          width: "350px",
        }}
      >
        <PropertyDistanceCard infra={infra} />
      </div>
    </ComparableMarker>
  );
};
