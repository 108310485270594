import { Dialog } from "@mui/material";
import { SearchBoxInput } from "common/components/location/SearchBoxInput";
import React, { useEffect, useState } from "react";
import { LocationPredictionsList } from "common/components/location/LocationPredictionsList";
import { useMapsAutocomplete } from "common/hooks/map.hooks";
import { cityCoords } from "common/constants/coords.constants";
import { v4 as uuid } from "uuid";

interface AddressSelectionModalProps {
  open: boolean;
  onSelect?: (placeId: string, sessionToken: string) => void;
  onClose?: () => void;
}

export const AddressSelectionModal: React.FC<AddressSelectionModalProps> = ({
  open,
  onSelect,
  onClose,
}) => {
  const [input, setInput] = useState("");
  const [sessionToken, setSessionToken] = useState<string>("");

  const { data } = useMapsAutocomplete(
    input,
    cityCoords.vilnius.latitude,
    cityCoords.vilnius.longitude,
    sessionToken,
    !open
  );

  useEffect(() => {
    setSessionToken(uuid());
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        style={{ width: "100%", height: "100%" }}
        PaperProps={{
          style: {
            width: "700px",
            padding: "15px",
            height: "700px",
          },
        }}
      >
        <SearchBoxInput
          input={input}
          onInputChange={(input) => setInput(input)}
        />
        <div>
          {data?.predictions && (
            <LocationPredictionsList
              predictions={data?.predictions}
              onPredictionPress={(placeId) => onSelect?.(placeId, sessionToken)}
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};
