import { Typography } from "common/components/Typography";
import React from "react";
import BigCloseIcon from "../../assets/icons/bigClose.svg";
import { IconButton } from "common/components/buttons/IconButton";
import colors from "common/styles/colors";
import { Box } from "@mui/material";

interface GalleryHeaderProps {
  selectedImageIndex: number;
  galleryLength: number;
  text?: string;
  subtext?: string;
  onClose?: () => void;
}

export const GalleryHeader: React.FC<GalleryHeaderProps> = ({
  selectedImageIndex,
  galleryLength,
  text,
  subtext,
  onClose,
}) => {
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        backgroundColor: colors.purple2,
        display: "flex",
        flexDirection: "row",
        padding: "8px",
        alignItems: "center",
        boxShadow: 3,
        zIndex: 1000,
      }}
    >
      <Typography variant="h1" textStyle={{ color: colors.white }}>{`${
        selectedImageIndex + 1
      }/${galleryLength}`}</Typography>
      <div
        style={{
          position: "absolute",
          justifyContent: "center",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" textStyle={{ color: colors.white }}>
          {text}
        </Typography>
        <Typography variant="h3" textStyle={{ color: colors.white }}>
          {subtext}
        </Typography>
      </div>

      <IconButton size={48} onPress={onClose}>
        <BigCloseIcon />
      </IconButton>
    </Box>
  );
};
