import { Autocomplete, TextField } from "@mui/material";
import { SelectType } from "common/components/form/RadioButtonGroup";

interface MultiSelectProps<T> {
  values: SelectType<T>[];
  onSelect?: (values: T[]) => void;
  selectedValues?: T[];
}

export function MultiSelect<T>({
  values,
  onSelect,
  selectedValues,
}: MultiSelectProps<T>) {
  return (
    <Autocomplete
      multiple
      options={values.map((value) => value.value)}
      getOptionLabel={(option) =>
        values.find((value) => value.value === option)?.label ?? ""
      }
      value={selectedValues ?? []}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      onChange={(_, value) => onSelect?.(value)}
      style={{ marginTop: "2px" }}
    />
  );
}
