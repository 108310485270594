import { Tab, Tabs } from "@mui/material";
import React from "react";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { useQuery } from "react-query";
import { Property } from "./Property";
import { PropertyLocation } from "./PropertyLocation";
import { useParams } from "react-router-dom";
import { PropertyMarketInsights } from "./marketInsights/PropertyMarketInsights";
import { PropertyMarketInsightsRoute } from "./marketInsights/PropertyMarketInsightsRoute";
import { PropertyLocationReport } from "./PropertyLocationReport";
import { PropertyShareButton } from "./PropertyShareButton";

interface PropertyRouteProps {}

const tabs = [
  {
    value: "info",
    label: "Turto informacija",
  },
  {
    value: "location",
    label: "Vietos ataskaita",
  },
  {
    value: "marketInsights",
    label: "Rinkos apžvalga",
  },
];

export const PropertyRoute: React.FC<PropertyRouteProps> = () => {
  const query = useRouteQuery();
  const section = query.get("section") ?? "info";

  const { updateQueryParam } = useUpdateQueryParam();

  const { propertyId } = useParams();
  if (!propertyId) {
    return <div>Path not found</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PropertyShareButton propertyId={propertyId} />
      <Tabs
        value={section}
        onChange={(_, value) => {
          updateQueryParam("section", value);
        }}
        style={{ marginBottom: "20px" }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <div>
        {section === "info" && <Property propertyId={propertyId} />}
        {section === "location" && (
          <PropertyLocationReport propertyId={propertyId} />
        )}
        {section === "marketInsights" && (
          <PropertyMarketInsightsRoute propertyId={propertyId} />
        )}
      </div>
    </div>
  );
};
