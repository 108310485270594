import {
  DeleteResult,
  LeadContact,
  MyProperty,
  MyPropertyAddRequest,
  UploadedPhoto,
} from "common/types/common.types";
import axiosMobileInstance from "./axiosMobileInstance/axiosMobileInstance";
import { PropertyType } from "../types/api.types";

const getSelectedAddons = () => {
  const addons = sessionStorage.getItem("selectedAddons");
  return addons ? JSON.parse(addons) : [];
};

export const addAppraiserProperty = async (
  property: MyPropertyAddRequest,
  fromLead?: boolean,
  appraiserId?: string,
  simpleType?: PropertyType
) => {
  const { data } = await axiosMobileInstance.post<MyProperty>(
    "/appraiserProperties",
    { ...property },
    {
      params: {
        appraiserId,
        fromLead: !!fromLead,
        simpleType,
        selectedAddons: getSelectedAddons(),
      },
    }
  );
  return data;
};

export const updateAppraiserProperty = async (
  property: MyPropertyAddRequest,
  propertyId: string
) => {
  const propertyToken = sessionStorage.getItem(`propertyToken.${propertyId}`);

  const { data } = await axiosMobileInstance.put<MyProperty>(
    `/appraiserProperties/${propertyId}`,
    property,
    {
      headers: {
        "x-property-token": propertyToken,
      },
    }
  );
  return data;
};

export const getAppraiserPropertyPhotos = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<UploadedPhoto[]>(
    `/appraiserProperties/${propertyId}/photos`,
    {
      headers: {
        "x-property-token": sessionStorage.getItem(
          `propertyToken.${propertyId}`
        ),
      },
    }
  );
  return data;
};

export const deleteAppraiserPropertyPhotos = async (
  photos: string[],
  propertyId?: string
) => {
  const propertyToken = propertyId
    ? sessionStorage.getItem(`propertyToken.${propertyId}`)
    : null;
  const { data } = await axiosMobileInstance.delete<DeleteResult>(
    "/appraiserProperties/photos",
    {
      data: { photos },
      headers: {
        "x-property-token": propertyToken,
      },
    }
  );
  return data;
};

export const uploadAppraiserPropertyPhotos = async (
  propertyId: string,
  formData: FormData
) => {
  const propertyToken = sessionStorage.getItem(`propertyToken.${propertyId}`);

  const { data } = await axiosMobileInstance.post<UploadedPhoto>(
    `/appraiserProperties/${propertyId}/photos`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-property-token": propertyToken,
      },
    }
  );
  return data;
};

export const addAppraiserPropertyFromUrl = async (
  url: string,
  fromLead?: boolean,
  appraiserId?: string,
  simpleType?: PropertyType
) => {
  const { data } = await axiosMobileInstance.post<MyProperty>(
    "/appraiserProperties/url",
    {},
    {
      params: {
        url,
        fromLead: !!fromLead,
        appraiserId,
        simpleType,
        selectedAddons: getSelectedAddons(),
      },
    }
  );
  return data;
};

export const deleteAppraiserProperty = async (propertyId: string) => {
  await axiosMobileInstance.delete(`/appraiserProperties/${propertyId}`);
};
