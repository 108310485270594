import React from "react";

interface FormSettingsContextProps {
  desktopColumns?: boolean;
  maxInputWidth?: number;
  inputHeight?: number;
  smallFont?: boolean;
  disabled?: boolean;
}

export const FormSettingsContext =
  React.createContext<FormSettingsContextProps>({});
