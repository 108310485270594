import { CheckboxInput } from "common/components/form/CheckboxInput";
import React from "react";
import { PropertyAddonType } from "../../../types/api.types";
import { PropertyCreationContext } from "../../propertiesCRUD/PropertyCreationProvider";
import { formatPrice } from "common/helpers/text.helpers";

interface AddonSelectProps {
  addon: PropertyAddonType;
  label: string;
  price: number;
}

export const AddonSelect: React.FC<AddonSelectProps> = ({
  addon,
  label,
  price,
}) => {
  const { toggleAddon, isAddonSelected } = React.useContext(
    PropertyCreationContext
  );

  const handleToggle = () => {
    toggleAddon?.(addon);
  };

  return (
    <div
      style={{
        border: "1px solid #e0e0e0",
        borderRadius: "5px",
        width: "360px",
        padding: "5px",
        cursor: "pointer",
      }}
      onClick={handleToggle}
    >
      <CheckboxInput
        label={label}
        isChecked={isAddonSelected?.(addon) ?? false}
        onChange={handleToggle}
        style={{
          alignSelf: "center",
        }}
      />
      <div
        style={{
          fontWeight: 600,
          fontSize: "24px",
          marginLeft: "40px",
        }}
      >
        {formatPrice(price)}
      </div>
    </div>
  );
};
