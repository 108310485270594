import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  createReportOrder,
  getPaymentMethods,
  getPropertyReportPrice,
  getPropertyShare,
} from "../../../api/api";
import { PaymentMethod } from "./PaymentMethod";
import { ContentCard } from "../../../components/ContentCard";
import { PaymentsGroup } from "./PaymentsGroup";
import { PropertyCreationContext } from "../PropertyCreationProvider";
import { PropertyPlaceholderImage } from "../../../components/imageGallery/PropertyPlaceholderImage";
import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import colors from "common/styles/colors";
import { getListString } from "common/helpers/common.helpers";
import { DesignationTypeTranslations } from "../../../helpers/comparableFields.helpers";
import { DesignationType } from "common/types/comparableFields.types";
import { theme } from "../../../styles/MUITheme";
import LoadingButton from "@mui/lab/LoadingButton";
import { showToastError } from "common/toast/toast";
import { usePropertyShare } from "../../../hooks/property.hooks";
import {
  useRouteQuery,
  useScrollToTop,
  useUpdateQueryParam,
} from "../../../hooks/router.hooks";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { DiscountCodeField } from "./DiscountCodeField";
import { DiscountAmountTable } from "./DiscountAmountTable";
import { formatPrice } from "common/helpers/text.helpers";
import {
  PaymentMethodType,
  PropertyType,
  PropertyTypeTranslations,
} from "../../../types/api.types";
import { CheckboxInput } from "common/components/form/CheckboxInput";
import { AddonsSummary } from "./AddonsSummary";
import { CompositeAssetsSummary } from "./CompositeAssetsSummary";

interface ReportPaymentRouteProps {}

export const ReportPaymentRoute: React.FC<ReportPaymentRouteProps> = () => {
  const { data, isLoading } = useQuery("payment_methods", () =>
    getPaymentMethods()
  );

  const [selectedMethod, setSelectedMethod] = useState<string>();
  const [isCardPaymentSelected, setIsCardPaymentSelected] =
    useState<boolean>(false);

  const createdPropertyId = useRouteQuery().get("createdPropertyId");

  const discountCode = useRouteQuery().get("discountCode");

  const token =
    sessionStorage.getItem(`propertyToken.${createdPropertyId}`) ?? "";

  const { data: propertyData, isLoading: isLoadingProperty } =
    usePropertyShare(token);

  const [isNavigating, setIsNavigating] = useState(false);

  useScrollToTop();

  const {
    isLoading: isCreatingOrder,
    mutate,
    mutateAsync,
  } = useMutation(createReportOrder, {
    onSuccess: (data) => {
      setIsNavigating(true);
      window.location.href = data.paymentUrl;
    },
    onError: () => {
      showToastError();
    },
  });

  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const {
    data: reportPrice,
    isLoading: isLoadingPrice,
    isFetching,
  } = useQuery(
    ["report_price", createdPropertyId, discountCode],
    async () => {
      const data = await getPropertyReportPrice(
        createdPropertyId ?? "",
        discountCode ?? undefined
      );

      if (data.discount?.discountPercentage === 100) {
        await mutateAsync({
          selectedMethod: "",
          methodType: PaymentMethodType.Free,
          propertyToken: token,
          discountCode: discountCode ?? undefined,
        });
      }

      return data;
    },
    {
      enabled: !!createdPropertyId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setSelectedMethod(
        data.paymentMethods.paymentInitiation.setup.LT.paymentMethods[0].code
      );
      setIsCardPaymentSelected(false);
    }
  }, [isLoading]);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!createdPropertyId) {
    return <div>Klaida: Nerastas sukurto turto ID</div>;
  }

  if (isLoading || isLoadingProperty || isLoadingPrice) {
    return <LoaderCentered />;
  }

  if (!data || !propertyData || !reportPrice) {
    return <div>Error</div>;
  }

  const methods = data.paymentMethods.paymentInitiation.setup.LT.paymentMethods;

  return (
    <div>
      <ContentCard title="Užsakymas">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            borderRadius: "10px",
            border: `1px solid ${colors.purple2}`,
          }}
        >
          <Box
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              display: "flex",
              alignItems: { xs: "center", sm: "start" },

              textAlign: { xs: "center", sm: "start" },
            }}
          >
            <PropertyPlaceholderImage
              key={propertyData.property.images[0]?.url ?? "placeholder"}
              uri={propertyData.property.images[0]?.url}
              width={isMobile ? 200 : 120}
              height={125}
              borderBottomLeftRadius={isMobile ? 0 : 10}
              borderTopLeftRadius={isMobile ? 0 : 10}
            />
            <div style={{ padding: "10px" }}>
              <div>Konsultacinė ataskaita</div>
              <div
                style={{
                  color: colors.grey,
                  marginTop: "10px",
                }}
              >
                {getListString([
                  propertyData.property.address,
                  PropertyTypeTranslations[
                    propertyData.property.simpleType ?? PropertyType.Apartment
                  ],
                ])}
              </div>
            </div>
          </Box>
          <div
            style={{
              alignSelf: "center",
              fontSize: "20px",
              fontWeight: 600,
              margin: "20px",
            }}
          >
            {formatPrice(reportPrice.basePrice, 2)}
          </div>
        </Box>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <AddonsSummary
            addons={reportPrice.addonsWithPrices}
            title="Papildomos ataskaitos dalys"
            isPurchased={false}
          />
          <CompositeAssetsSummary
            assets={reportPrice.compositeAssetsWithPrices}
            title="Papildomas priklausomas turtas"
            isPurchased={false}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            marginTop: "20px",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <DiscountCodeField reportPrice={reportPrice} isLoading={isFetching} />

          <DiscountAmountTable reportPrice={reportPrice} />
        </Box>
      </ContentCard>
      <ContentCard title="Mokėjimo būdas">
        <PaymentsGroup title="Elekroninė bankininkystė">
          {methods?.map((method) => (
            <PaymentMethod
              isSelected={selectedMethod === method.code}
              logoUrl={method.logoUrl}
              onSelect={() => {
                setSelectedMethod(method.code);
                setIsCardPaymentSelected(false);
              }}
            />
          ))}
        </PaymentsGroup>
        <div style={{ height: "30px" }} />
        <PaymentsGroup title="Kreditine arba debetine kortele">
          <PaymentMethod
            isSelected={isCardPaymentSelected}
            logoUrl={data?.paymentMethods.cardPayments.logoUrl}
            onSelect={() => {
              setIsCardPaymentSelected(true);
              setSelectedMethod(undefined);
            }}
          />
        </PaymentsGroup>
      </ContentCard>
      <div>
        <CheckboxInput
          label="Suprantu, kad preliminari nustatyta kaina gali skirtis nuo galutinės nustatytos vertės, išsamaus turto vertinimo būdu"
          isChecked={isConsentChecked}
          onChange={(val) => setIsConsentChecked(val)}
          numberOfLines={4}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <LoadingButton
          style={{ width: "345px", margin: "10px", marginTop: "30px" }}
          variant="contained"
          loading={isCreatingOrder || isNavigating}
          disabled={!isConsentChecked}
          onClick={() => {
            mutate({
              selectedMethod,
              methodType: isCardPaymentSelected
                ? PaymentMethodType.Card
                : PaymentMethodType.Bank,
              propertyToken: token,
              discountCode: discountCode ?? undefined,
            });
          }}
        >{`Mokėti (${formatPrice(reportPrice.totalPrice, 2)})`}</LoadingButton>
      </div>
    </div>
  );
};
