import React, { useState } from "react";
import {
  useAppraisersInOrganizationCredits,
  useOrganizationUsedCredits,
} from "../../hooks/auth.hooks";
import { Typography } from "common/components/Typography";
import {
  formatMonthlyLimitPrice,
  formatPrice,
} from "common/helpers/text.helpers";
import { MonthlyLimitDialog } from "./MonthlyLimitDialog";
import { useMutation } from "react-query";
import { setOrganizationMonthlyCreditLimit } from "../../api/api";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { showToastError } from "common/toast/toast";
import colors from "common/styles/colors";
import { LimitBar } from "./LimitBar";

interface OrganizationCreditsProps {}

export const OrganizationCredits: React.FC<OrganizationCreditsProps> = () => {
  const { data, refetch } = useOrganizationUsedCredits();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isLoading } = useMutation(
    async (data: { monthlyCreditLimit: number | null }) => {
      await setOrganizationMonthlyCreditLimit(data.monthlyCreditLimit);
      await refetch();
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
      },
      onError: () => {
        showToastError();
      },
    }
  );

  if (!data) {
    return null;
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography
            textStyle={{ fontSize: 16 }}
          >{`Panaudotas limitas šį mėn.:`}</Typography>
          <Typography
            variant="h3"
            textStyle={{
              marginLeft: "20px",
              color: colors.purple2,
              fontSize: 16,
            }}
          >
            {formatMonthlyLimitPrice(data.totalCreditsUsed)}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Typography textStyle={{ fontSize: 16 }}>
            Bendras mėnesio limitas
          </Typography>
          <div>
            <Typography textStyle={{ fontSize: 16 }}>
              {formatMonthlyLimitPrice(data.monthlyCreditLimit)}
            </Typography>
            <IconButton onClick={() => setIsModalOpen(true)}>
              <EditIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <LimitBar
        percent={
          !data.monthlyCreditLimit
            ? 1
            : data.totalCreditsUsed / data.monthlyCreditLimit
        }
      />

      <MonthlyLimitDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isLoading={isLoading}
        contentText={`Pakeiskite organizacijos bendrą mėnesio limitą`}
        initialLimit={data.monthlyCreditLimit ?? 0}
        onSubmit={(value) => {
          mutate({
            monthlyCreditLimit: value ?? 0,
          });
        }}
      />
    </div>
  );
};
