import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import React from "react";

interface MapLegendRowProps {
  icon: React.ReactElement;
  label: string;
}

export const MapLegendRow: React.FC<MapLegendRowProps> = ({ icon, label }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        style={{
          width: "32px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "15px",
        }}
      >
        {icon}
      </div>
      <Typography
        textStyle={{ fontSize: 12, color: colors.grey }}
      >{`- ${label}.`}</Typography>
    </div>
  );
};
