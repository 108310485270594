import { AxiosInstance } from "axios";
import { queryClient } from "../../modules/queryClient";

export function setupMobileInstanceInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        queryClient.setQueryData("appraiser", undefined);
      }
      return Promise.reject(error);
    }
  );
}
