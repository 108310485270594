import React, { useContext, useMemo } from "react";
import { Typography } from "common/components/Typography";
import { Box, Button, IconButton } from "@mui/material";
import { headerHeight } from "../routes/ComparablesSelection";
import { ComparableFilter } from "./filters/ComparableFilter";
import { Comparable } from "common/types/common.types";
import {
  ComparableFilterType as ComparableFilterTypeEnum,
  ComparableFilter as ComparableFilterType,
  getDefaultDesignationFilter,
} from "../helpers/comparableFilters.helpers";
import { PropertyPlaceholderImage } from "./imageGallery/PropertyPlaceholderImage";
import { getListString } from "common/helpers/common.helpers";
import { usePricePredictions } from "../hooks/pricePredictions.hooks";
import { formatAreaPrice, formatPrice } from "common/helpers/text.helpers";
import { AppraiserProperty } from "../types/appraiser.types";
import { setPricePredictionFilters } from "../api/api";
import { showToastError } from "common/toast/toast";
import {
  getDefaultAreaValues,
  getDefaultValuationZoneFilter,
  getDefaultYearValues,
  getModalFilters,
} from "../helpers/comparableFilters.helpers";
import { SavedComparableFilter } from "../types/api.types";
import { LinkForButton } from "./links/LinkForButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  ComparablesFilterProvider,
  ComparablesFiltersContext,
} from "./ComparablesFilterProvider";
import { getPropertyListString } from "../helpers/property.helpers";
import { LimitBar } from "../routes/payments/LimitBar";

interface ComparablesSelectionHeaderProps {
  property: AppraiserProperty;
  isInitialFiltersLoad?: boolean;
}

export const ComparablesSelectionHeader: React.FC<
  ComparablesSelectionHeaderProps
> = ({ property, isInitialFiltersLoad }) => {
  const { appliedFilters, setAppliedFilters, getAppliedFiltersCount } =
    useContext(ComparablesFiltersContext);

  const captionString = getPropertyListString({
    area: property.area,
    rooms: property.rooms,
    floorNr: property.floorNr,
    floorCount: property.floorCount,
    year: property.year,
  });

  const { pricePredictions } = usePricePredictions(property._id);

  const navigate = useNavigate();

  const onFiltersSet = (filters: ComparableFilterType[]) => {
    setAppliedFilters?.(filters);
    try {
      setPricePredictionFilters(
        property._id,
        getModalFilters(filters).map((filter) => ({
          values: filter.values,
          type: filter.type,
        }))
      );
    } catch (e) {
      showToastError("Failed to save filters in server");
    }
  };

  const expectedPriceArea = (property.expectedValue ?? 0) / property.area;

  return (
    <Box
      sx={{
        height: `${headerHeight}px`,
        display: "flex",
        justifyContent: "space-between",
        pr: "70px",
        pl: "20px",
        backgroundColor: "white",
        boxShadow: 3,
        overflow: "visible",
        zIndex: 1000,
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)} sx={{ mr: "24px" }}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <PropertyPlaceholderImage
          height={headerHeight}
          width={150}
          uri={property.images?.[0]?.url}
        />
        <Box
          sx={{
            px: "12px",
            py: "10px",
            display: "flex",
            flexDirection: "row",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Typography variant="h5" textStyle={{ fontSize: 16 }}>
              {property.address}
            </Typography>
            <Typography variant="caption" textStyle={{ fontSize: 12 }}>
              {captionString}
            </Typography>
          </Box>
          <Box>
            <div style={{ fontSize: "12px" }}>Apskaičiuota turto kaina</div>
            <div
              style={{ display: "flex", gap: "6px", alignItems: "flex-end" }}
            >
              <Typography variant="h2">
                {formatPrice(
                  (pricePredictions?.predicted_price.average_price_area ?? 0) *
                    property.area
                )}
              </Typography>
              <Typography>{`(${formatPrice(
                pricePredictions?.predicted_price.average_price_area
              )}/m²)`}</Typography>
            </div>
            {!!expectedPriceArea && (
              <div
                style={{
                  width: "300px",
                  marginTop: "12px",
                }}
              >
                <LimitBar
                  percent={
                    (pricePredictions?.predicted_price.average_price_area ??
                      0) / expectedPriceArea
                  }
                  height="8px"
                />
                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Lūkestinė kaina</span>
                  <span>
                    {formatPrice(property.expectedValue)} (
                    {formatAreaPrice(expectedPriceArea)})
                  </span>
                </div>
              </div>
            )}
          </Box>
        </Box>
        <LinkForButton
          to={`summary`}
          style={{ alignSelf: "flex-end", margin: "15px" }}
          disabled={!pricePredictions?.comparables.length}
        >
          <Button
            variant="contained"
            disabled={!pricePredictions?.comparables.length}
          >
            Peržiūrėti
          </Button>
        </LinkForButton>
      </div>
      <div style={{ margin: "10px", alignSelf: "center" }}>
        <ComparablesFilterProvider>
          <ComparableFilter
            appliedMapFilters={appliedFilters ?? []}
            onFiltersSet={onFiltersSet}
            badgeCount={getAppliedFiltersCount?.()}
            isInitialFiltersLoad={isInitialFiltersLoad}
            property={property}
          />
        </ComparablesFilterProvider>
      </div>
    </Box>
  );
};
