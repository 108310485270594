import { Typography } from "common/components/Typography";
import React from "react";

interface AdjustmentTypographyProps {
  children: React.ReactNode;
  color?: string;
}

export const adjustmentFontSize = 16;

export const AdjustmentTypography: React.FC<AdjustmentTypographyProps> = ({
  children,
  color,
}) => {
  return (
    <Typography
      textStyle={{ fontSize: adjustmentFontSize, color }}
      numberOfLines={1}
    >
      {children}
    </Typography>
  );
};
