import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";

interface MapJSApiLoaderProps {
  children: React.ReactNode;
}

const options = {
  id: "google-map-script",
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  libraries: ["drawing" as "drawing"],
};

export const MapJSApiLoader: React.FC<MapJSApiLoaderProps> = ({ children }) => {
  const { isLoaded } = useJsApiLoader(options);

  if (!isLoaded) {
    return null;
  }
  return <>{children}</>;
};
