import React, { useState } from "react";
import { useMutation } from "react-query";
import { analyzePdfReport } from "../api/api";
import { MarkerMap } from "../components/map/MarkerMap";

interface PdfReportProps {
  // Your props here, if any
}

export const PdfReport: React.FC<PdfReportProps> = () => {
  const [file, setFile] = useState<File | null>(null);

  // Setting up the mutation with React Query
  const mutation = useMutation(analyzePdfReport);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    if (file) {
      mutation.mutate(file);
    }
  };

  // Helper function to display value or a hyphen if null
  const displayValue = (value: number | string | null) => {
    return value !== null ? value : "-";
  };

  return (
    <div>
      <h1>Įkelti PDF ataskaitą</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      <button onClick={handleSubmit} disabled={mutation.isLoading}>
        {mutation.isLoading ? "Analizuojama..." : "Analizuoti ataskaitą"}
      </button>
      {mutation.isError && <p>Įvyko klaida</p>}
      {mutation.isSuccess && mutation.data && (
        <div>
          <div>
            <h2>Analizuota ataskaita:</h2>
            {/* Using inline styles to make labels bold */}
            <p>
              <strong>Plotas:</strong> {displayValue(mutation.data.area)}
            </p>
            <p>
              <strong>Aukštas:</strong> {displayValue(mutation.data.floors)}
            </p>
            <p>
              <strong>Viso aukštų:</strong>{" "}
              {displayValue(mutation.data.floors_total)}
            </p>
            <p>
              <strong>Adresas:</strong> {displayValue(mutation.data.address)}
            </p>
            <p>
              <strong>Statybos metai:</strong>{" "}
              {displayValue(mutation.data.year_construction)}
            </p>
            <p>
              <strong>Rekonstrukcijos metai:</strong>{" "}
              {displayValue(mutation.data.year_reconstruction)}
            </p>
            <p>
              <strong>Parduota už:</strong>{" "}
              {displayValue(mutation.data.sold_price)}
            </p>
            <p>
              <strong>Vieta:</strong> {displayValue(mutation.data.location)}
            </p>
          </div>
          {mutation.data.lat !== null && mutation.data.lng !== null && (
            <MarkerMap
              lat={mutation.data.lat}
              lng={mutation.data.lng}
              height="350px"
            />
          )}
        </div>
      )}
    </div>
  );
};
