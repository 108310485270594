import React from "react";
import { Button, ButtonProps } from "./Button";
import colors from "../../styles/colors";

interface OutlinedButtonProps {}

export const OutlinedButton: React.FC<OutlinedButtonProps & ButtonProps> = ({
  ...props
}) => {
  return (
    <Button
      {...props}
      noShadow
      style={[
        {
          borderWidth: 1,
          borderColor: `${colors.beige}33`,
        },
        props.style,
      ]}
    />
  );
};
