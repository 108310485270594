import { useCallback, useContext } from "react";
import {
  DesignationType,
  getTransactionREType,
} from "common/types/comparableFields.types";
import { ComparableFilterType } from "./comparableFilters.helpers";
import { ComparablesFiltersContext } from "../components/ComparablesFilterProvider";

export function useRCTransactions() {
  const { getAppliedFilter } = useContext(ComparablesFiltersContext);

  const getRCFiltersQueryData = useCallback(() => {
    const area = getAppliedFilter?.(ComparableFilterType.Area)?.values;
    const year = getAppliedFilter?.(ComparableFilterType.Year)?.values;
    const dates = getAppliedFilter?.(ComparableFilterType.Dates)?.values;
    const valuationZones = getAppliedFilter?.(
      ComparableFilterType.ValuationZone
    )?.values;
    let designationTypes = getAppliedFilter?.(
      ComparableFilterType.Designation
    )?.values;

    if (designationTypes?.includes(DesignationType.Manufacturing)) {
      designationTypes = [
        ...designationTypes,
        DesignationType.ManufacturingIndustry,
      ];
    }

    const addZeroToMonth = (month: number) => {
      return month < 10 ? `0${month}` : month;
    };

    const queryData = {
      sdata_nuo: `${dates?.start.year}-${addZeroToMonth(dates?.start.month)}`,
      sdata_iki: `${dates?.end.year}-${addZeroToMonth(dates?.end.month)}`,
      plotas_nuo: area?.[0]?.toString(),
      plotas_iki: area?.[1]?.toString(),
      metai_nuo: year?.[0]?.toString(),
      metai_iki: year?.[1]?.toString(),
      zove_nr: valuationZones?.join(","),
      pask_tipas: designationTypes?.join(","),
    };

    return queryData;
  }, [getAppliedFilter]);

  return { getRCFiltersQueryData };
}
