import { Typography } from "common/components/Typography";
import React from "react";

interface SettingsRowProps {
  label: string;
  children?: React.ReactNode;
}

export const SettingsRow: React.FC<SettingsRowProps> = ({
  children,
  label,
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div style={{ width: "300px" }}>
        <Typography textStyle={{ fontSize: 16 }}>{label}</Typography>
      </div>
      <div>{children}</div>
    </div>
  );
};
