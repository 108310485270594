import axios from "axios";
import { apiVersion, backendBaseUrl } from "../constants";
import { setupMobileInstanceInterceptors } from "./interceptors";

const axiosMobileInstance = axios.create({
  baseURL: backendBaseUrl,
  params: {
    version: apiVersion,
  },
  withCredentials: true,
});

setupMobileInstanceInterceptors(axiosMobileInstance);

export default axiosMobileInstance;
