import React from "react";
import {
  useAppraisersInOrganization,
  useAuthSideEffects,
} from "../hooks/auth.hooks";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import { AddAppraiserModal } from "../components/admin/AddAppraiserModal";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import { AppraiserPasswordLink } from "../components/admin/AppraiserPasswordLink";
import { IconButton, Tooltip } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { useMutation } from "react-query";
import { loginAsAppraiser } from "../api/api";
import { showToastError } from "common/toast/toast";

interface AppraisersManagementProps {}

export const AppraisersManagement: React.FC<AppraisersManagementProps> = () => {
  const { data: appraisers } = useAppraisersInOrganization();
  const { onAuthSuccess } = useAuthSideEffects();
  const { mutate, isLoading } = useMutation(loginAsAppraiser, {
    onSuccess: (data) => {
      onAuthSuccess(data);
      window.location.href = "/account-settings";
    },
    onError: () => {
      showToastError();
    },
  });

  return (
    <div>
      <div
        style={{
          marginBottom: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Vertintojai organizacijoje</Typography>
        <AddAppraiserModal />
      </div>
      {appraisers?.map((appraiser) => {
        const hasNotAcceptedInvitation =
          !!appraiser.invitedAt && !appraiser.acceptedAt;
        const invitation = hasNotAcceptedInvitation
          ? `Pakviestas ${getLithuanianDateTimeString(
              appraiser.invitedAt as Date
            )}`
          : `Narys nuo ${getLithuanianDateTimeString(
              appraiser.acceptedAt ?? appraiser.createdAt
            )}`;

        return (
          <div
            key={appraiser._id}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              backgroundColor: colors.white,
              height: "60px",
              padding: "0px 20px",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          >
            <Typography textStyle={{ flex: 1 }}>{appraiser.email}</Typography>
            <Typography textStyle={{ flex: 1 }}>{invitation}</Typography>
            <Typography textStyle={{ flex: 0.5 }}>{appraiser.role}</Typography>
            <div style={{ flex: 1.5 }}>
              {hasNotAcceptedInvitation ? (
                <AppraiserPasswordLink
                  appraiser={appraiser}
                  type="registration"
                  decodedToken={appraiser.decodedRegistrationToken}
                  link={appraiser.registrationLink}
                />
              ) : (
                <AppraiserPasswordLink
                  appraiser={appraiser}
                  type="reset"
                  decodedToken={appraiser.decodedPasswordResetToken}
                  link={appraiser.resetLink}
                />
              )}
            </div>
            <Tooltip title={`Prisijungti kaip ${appraiser.email}`}>
              <IconButton
                disabled={isLoading}
                onClick={() => {
                  mutate(appraiser._id);
                }}
              >
                <LoginIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
