import React from "react";
import { Dialog } from "@mui/material";
import BigCloseIcon from "../../assets/icons/bigClose.svg";
import { IconButton } from "common/components/buttons/IconButton";
import { AdjustmentsMap, AdjustmentsMapProps } from "./AdjustmentsMap";

interface AdjustmentsMapModalProps {
  isOpen: boolean;
  onClose?: () => void;
}

export const AdjustmentsMapModal: React.FC<
  AdjustmentsMapModalProps & AdjustmentsMapProps
> = ({ isOpen, onClose, ...props }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { mx: "100px", height: "100vh", width: "100vw" } }}
    >
      <AdjustmentsMap {...props}>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <IconButton size={48} onPress={onClose}>
            <BigCloseIcon />
          </IconButton>
        </div>
      </AdjustmentsMap>
    </Dialog>
  );
};
