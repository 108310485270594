import { useQuery } from "react-query";
import { getValuationZone, getValuationZonesFromBoundingBox } from "../api/api";

export const useValuationZone = (lat: number, lng: number) => {
  return useQuery(
    ["valution_zone", lat, lng],
    () => getValuationZone(lat, lng),
    {
      staleTime: Infinity,
    }
  );
};

export const useValuationZonesFromBoundingBox = (
  latMin: number | undefined,
  latMax: number | undefined,
  lngMin: number | undefined,
  lngMax: number | undefined
) => {
  const isEnabled =
    latMin !== undefined &&
    latMax !== undefined &&
    lngMin !== undefined &&
    lngMax !== undefined;

  return useQuery(
    ["valuation_zones", latMin, latMax, lngMin, lngMax],
    ({ signal }) =>
      getValuationZonesFromBoundingBox(
        latMin ?? 0,
        latMax ?? 0,
        lngMin ?? 0,
        lngMax ?? 0,
        signal
      ),

    {
      staleTime: Infinity,
      enabled: isEnabled,
      keepPreviousData: true,
    }
  );
};
