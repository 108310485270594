import { Comparable } from "common/types/common.types";
import React, { useContext, useMemo, useState } from "react";
import { WebTabItems } from "../WebTabItems";
import { TabItemType } from "common/components/tabs/types";
import { Typography } from "common/components/Typography";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import { GroupedVirtuoso } from "react-virtuoso";
import { Box } from "@mui/material";
import { getEndingByCountLTVardininkas } from "common/helpers/text.helpers";
import { MapListInteractionsContext } from "../MapListInteractionsProvider";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { useParams } from "react-router-dom";
import { StickyHeaderWrapper } from "../wrappers/StickyHeaderWrapper";
import { ComparableCardWithPricePredictions } from "./ComparableCardWithPricePredictions";
import { InfoBox } from "../alerts/InfoBox";
import { useComparablesData } from "../../hooks/property.hooks";
import { PropertyCreditsOverview } from "./PropertyCreditsOverview";

interface ComparablesListProps {}

enum SortingType {
  Similarity = "Similarity",
  MaxPrice = "MaxPrice",
  Newest = "Newest",
}

const tabItems: TabItemType<SortingType>[] = [
  {
    name: "Panašiausi",
    value: SortingType.Similarity,
  },
  {
    name: "Didžiausia vertė",
    value: SortingType.MaxPrice,
  },
  {
    name: "Naujausi",
    value: SortingType.Newest,
  },
];

const noComparableInCartText =
  "Nėra įtrauktų sandorių į kainos skaičiavimą. Pasirinkite iš žemėlapio ar 'Kiti panašūs sandoriai' sąrašo.";

const notFoundTransactionsText =
  "Sandorių pagal Jūsų paieškos kriterijus neradome.";

export const ComparablesList: React.FC<ComparablesListProps> = () => {
  const [selectedTabItem, setSelectedTabItem] = useState<
    TabItemType<SortingType>
  >(tabItems[0]);

  const { getFilteredComparables } = useContext(ComparablesFiltersContext);
  const { onListComparableClick } = useContext(MapListInteractionsContext);

  const { propertyId } = useParams();
  const { isComparableInCart } = usePricePredictions(propertyId ?? "");

  const { dataCombined: comparables, comparablesInCart } = useComparablesData();

  const filteredComparables = useMemo(() => {
    const comps = getFilteredComparables?.(comparables) ?? [];
    return comps.filter(
      (comp) => !isComparableInCart(comp.comparable_transaction.id)
    );
  }, [getFilteredComparables, comparables, isComparableInCart]);

  const sortComparables = (comparables: Comparable[]) => {
    switch (selectedTabItem.value) {
      case SortingType.Similarity:
        return comparables.sort(
          (a, b) =>
            b.similarities.joint_similarity - a.similarities.joint_similarity
        );
      // case SortingType.MaxPrice:
      //   return comparables.sort(
      //     (a, b) =>
      //       b.comparable_transaction.sold_price_area -
      //       a.comparable_transaction.sold_price_area
      //   );
      case SortingType.Newest:
        return comparables.sort(
          (a, b) =>
            new Date(b.comparable_transaction.sold_date ?? "").getTime() -
            new Date(a.comparable_transaction.sold_date ?? "").getTime()
        );
      default:
        return comparables;
    }
  };
  const sortedComparables = useMemo(() => {
    return sortComparables(filteredComparables);
  }, [filteredComparables, selectedTabItem]);

  const HeaderCart = (
    <StickyHeaderWrapper>
      <Typography variant="h3" textStyle={{ fontSize: 20 }}>
        Sandoriai įtraukti į kainos skaičiavimą
      </Typography>
    </StickyHeaderWrapper>
  );

  const HeaderComparables = (
    <StickyHeaderWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" textStyle={{ fontSize: 20 }}>
          Kiti panašūs sandoriai
        </Typography>
        <Typography variant="caption">{`${
          sortedComparables.length
        } rezultat${getEndingByCountLTVardininkas(
          sortedComparables.length
        ).toLowerCase()}`}</Typography>
      </div>
      <div
        style={{
          marginTop: "15px",
        }}
      >
        <WebTabItems
          tabItems={tabItems}
          selected={selectedTabItem}
          onClick={(value) => setSelectedTabItem(value)}
        />
      </div>
    </StickyHeaderWrapper>
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: "15px" }}>
        <PropertyCreditsOverview />
      </div>
      <div style={{ flex: 1 }}>
        <GroupedVirtuoso
          groupCounts={[
            comparablesInCart.length || 1,
            sortedComparables.length || 1,
          ]}
          groupContent={(index) => {
            if (index === 0) {
              return HeaderCart;
            } else {
              return HeaderComparables;
            }
          }}
          itemContent={(index, groupIndex) => {
            const compsList =
              groupIndex === 0 ? comparablesInCart : sortedComparables;

            const realIndex =
              groupIndex === 1
                ? index - (comparablesInCart.length || 1)
                : index;

            if (realIndex == compsList.length && realIndex == 0) {
              return (
                <Box
                  sx={{
                    px: "15px",
                    pt: "10px",
                    pb: "15px",
                  }}
                >
                  <InfoBox
                    text={
                      groupIndex === 0
                        ? noComparableInCartText
                        : notFoundTransactionsText
                    }
                  />
                </Box>
              );
            }

            if (realIndex >= compsList.length || realIndex < 0) return null;
            const comp = compsList[realIndex];

            return (
              <Box
                sx={{
                  px: "15px",
                  pt: "10px",
                  pb: realIndex + 1 === compsList.length ? "15px" : 0,
                }}
                key={comp.comparable_transaction.id}
              >
                <ComparableCardWithPricePredictions
                  comparables={[comp]}
                  onClick={() => {
                    onListComparableClick?.(comp);
                  }}
                />
              </Box>
            );
          }}
        />
      </div>
    </div>
  );
};
