import { Button, Dialog } from "@mui/material";
import { GoogleMap, MarkerF, OverlayViewF } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { litCenterCoords } from "./MapField";
import MarkerIcon from "../../../assets/icons/marker.svg";
import { FullScreenDialog } from "../../../components/dialogs/FullScreenDialog";
import { useQuery } from "react-query";
import { getGeocodedAddress } from "../../../api/geocode.api";
import { MUILoadingButton } from "../../../components/buttons/MUILoadingButton";
import { GeocodedAddress } from "../../../types/geocoding.types";

interface MapFieldDialogProps {
  initialCoords?: {
    lat: number;
    lng: number;
  };
  onSelect?: (geocodedAddress: GeocodedAddress) => void;
  open: boolean;
  onClose: () => void;
}

export const MapFieldDialog: React.FC<MapFieldDialogProps> = ({
  initialCoords,
  onSelect,
  onClose,
  open,
}) => {
  const [coords, setCoords] = useState(initialCoords);

  const { data, isLoading } = useQuery(
    ["geocoded_address", coords?.lat, coords?.lng],
    () => getGeocodedAddress(coords?.lat ?? 0, coords?.lng ?? 0),
    {
      enabled: !!coords,
    }
  );

  const initialZoomRef = React.useRef(!!coords ? 15 : 6);

  useEffect(() => {
    const disableZoom = () => {
      const meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      document.getElementsByTagName("head")[0].appendChild(meta);
    };

    const enableZoom = () => {
      const meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content = "width=device-width, initial-scale=1.0";
      document.getElementsByTagName("head")[0].appendChild(meta);
    };

    if (open) {
      disableZoom();
    } else {
      enableZoom();
    }

    return () => {
      enableZoom();
    };
  }, [open]);

  return (
    <FullScreenDialog
      title="Pasirinkti būsto vietą žemėlapyje"
      open={open}
      onClose={onClose}
    >
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          scrollwheel: true,
          fullscreenControl: false,
          gestureHandling: "greedy",
        }}
        center={initialCoords ?? litCenterCoords}
        zoom={initialZoomRef.current}
        onClick={(e) => {
          const lat = e.latLng?.lat();
          const lng = e.latLng?.lng();
          if (!lat || !lng) return;
          setCoords({
            lat,
            lng,
          });
        }}
      >
        {!!coords && (
          <OverlayViewF
            mapPaneName="overlayLayer"
            position={coords}
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2,
              y: -height,
            })}
          >
            <MarkerIcon />
          </OverlayViewF>
        )}
      </GoogleMap>
      <MUILoadingButton
        onClick={() => {
          if (!!data) {
            onSelect?.(data);
          }
        }}
        disabled={isLoading || !data}
        loading={isLoading}
        style={{
          marginTop: "20px",
          width: "300px",
          alignSelf: "center",
        }}
        variant="contained"
      >
        Išsaugoti
      </MUILoadingButton>
    </FullScreenDialog>
  );
};
