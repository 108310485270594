import * as yup from "yup";

export const mediumStrongPasswordSchema = yup
  .string()
  .min(8, "Slaptažodis turi būti bent 8 simbolių ilgio")
  .matches(/[a-z]/, "Slaptažodyje turi būti bent viena mažoji raidė")
  .matches(/[A-Z]/, "Slaptažodyje turi būti bent viena didžioji raidė")
  .matches(
    /[\d!@#$%^&*()_+[\]{}|;:'",.<>?\\/-]/,
    "Slaptažodyje turi būti bent vienas skaičius arba specialusis simbolis"
  )
  .required("Slaptažodis yra privalomas");
