import React, { useImperativeHandle, useState } from "react";
import { useQuery } from "react-query";
import {
  getAdjustmentsDescriptions,
  getAdjustmentsDescriptionsForComparable,
} from "../../api/api";
import { useParams } from "react-router-dom";
import { TableRow } from "../tables/types";
import { ComparableSummary } from "./ComparablesSummaryTable";
import { getUniqueAdjustedFields } from "./comparablesSummary.helpers";
import { ComparableTransaction } from "common/types/common.types";
import {
  useAppraiserPropertyFields,
  usePricePredictions,
} from "../../hooks/pricePredictions.hooks";
import { AdjustmentsDescriptionParagraphs } from "./AdjustmentsDescriptionParagraphs";
import { TableComponent } from "../tables/TableComponent";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import { Typography } from "common/components/Typography";
import { IconButton } from "common/components/buttons/IconButton";
import EditIcon from "../../assets/icons/edit.svg";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { TextField } from "@mui/material";
import { AdjustmentDescription } from "../../types/api.types";
import { DescriptionsTable } from "./DescriptionsTable";

interface AdjustmentsJustificationProps {
  comps: ComparableSummary[];
}

export interface AdjustmentsJustificationHandles {
  getDescriptions: () => AdjustmentDescription[];
}

export const AdjustmentsJustification = React.forwardRef<
  AdjustmentsJustificationHandles,
  AdjustmentsJustificationProps
>(({ comps }, ref) => {
  const { propertyId } = useParams();
  const { data } = useQuery("adjustmentsJustification", () =>
    getAdjustmentsDescriptionsForComparable(propertyId ?? "")
  );

  const { data: adjustmentsDescriptions } = useQuery(
    "adjustments_descriptions",
    getAdjustmentsDescriptions
  );

  const { appraiserPropertyFields } = useAppraiserPropertyFields();
  const {
    updateAdjustmentDescription,
    updateAdditionalAdjustmentDescription,
    isLoading,
    pricePredictions,
  } = usePricePredictions(propertyId ?? "");

  const [selectedFieldEdit, setSelectedFieldEdit] =
    useState<AdjustmentDescription>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const uniqueAdjustedFields = getUniqueAdjustedFields(comps);

  const getDescriptionParagraphs = (field: keyof ComparableTransaction) => {
    const description = pricePredictions?.descriptions?.find(
      (d) => d.field === field
    );
    if (description) {
      return [description.description];
    }

    const paragraphs =
      data
        ?.filter((d) => d.field === field)
        .map((d) => d.description)
        .filter((d) => !!d) ?? [];
    const generalDescription = adjustmentsDescriptions?.find(
      (d) => d.field === field
    )?.description;

    return [generalDescription, ...paragraphs].filter((d) => !!d) as string[];
  };

  const getAppraiserPropertyFieldDescription = (
    appraiserPropertyFieldId: string
  ) => {
    const description = pricePredictions?.descriptions?.find(
      (d) => d.appraiserPropertyFieldId === appraiserPropertyFieldId
    )?.description;

    if (description) {
      return description;
    }

    const generalDescription = adjustmentsDescriptions?.find(
      (d) => d.appraiserPropertyFieldId === appraiserPropertyFieldId
    )?.description;

    return generalDescription;
  };

  const getDescriptions = (): AdjustmentDescription[] => {
    const descriptionsFromFields = uniqueAdjustedFields.map((field) => {
      const paragraphs = getDescriptionParagraphs(field);
      return {
        field,
        description: paragraphs.join("\n\n"),
        header: adjustmentFields[field]?.fieldName ?? field,
      };
    });

    const descriptionsFromAppraiserFields =
      appraiserPropertyFields?.map((field) => {
        const generalDescription = getAppraiserPropertyFieldDescription(
          field._id
        );

        return {
          appraiserPropertyFieldId: field._id,
          description: generalDescription ?? "",
          header: field.name,
        };
      }) ?? [];

    return [...descriptionsFromFields, ...descriptionsFromAppraiserFields];
  };

  useImperativeHandle(ref, () => ({
    getDescriptions: () => getDescriptions().filter((d) => !!d.description),
  }));

  if (!data) {
    return null;
  }

  return (
    <div>
      <DescriptionsTable
        descriptions={getDescriptions()}
        onEdit={(description) => {
          setSelectedFieldEdit(description);
          setIsModalOpen(true);
        }}
      />
      <SimpleDialog
        title={`Koreguoti "${selectedFieldEdit?.header}" pataisos pagrindimą`}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          if (!selectedFieldEdit) {
            return;
          }
          if (selectedFieldEdit.appraiserPropertyFieldId) {
            updateAdditionalAdjustmentDescription(
              selectedFieldEdit.appraiserPropertyFieldId,
              selectedFieldEdit.description
            );
          } else {
            updateAdjustmentDescription(
              selectedFieldEdit.field ?? "",
              selectedFieldEdit.description
            );
          }
          setIsModalOpen(false);
        }}
        isLoading={isLoading}
      >
        <TextField
          multiline
          rows={10}
          value={selectedFieldEdit?.description}
          onChange={(e) => {
            if (!selectedFieldEdit) {
              return;
            }
            setSelectedFieldEdit({
              ...selectedFieldEdit,
              description: e.target.value,
            });
          }}
        />
      </SimpleDialog>
    </div>
  );
});
