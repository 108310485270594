import React, { useEffect } from "react";
import { useProperties } from "../hooks/property.hooks";
import { PropertyInList } from "./properties/PropertyInList";
import { PaginationComponent } from "./pagination/PaginationComponent";
import {
  useAppraiserFilterQuery,
  usePropertyAssessmentStatusQuery,
  useUpdateQueryParam,
} from "../hooks/router.hooks";
import { Menu, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { AppraiserFilter, PropertyAssessmentStatus } from "../types/api.types";
import { useAppraiser } from "../hooks/auth.hooks";
import { AppraiserRole } from "../types/appraiser.types";
import { PropertyListTab } from "./PropertyListTab";
interface PropertiesListProps {}

const tabs = [
  {
    value: PropertyAssessmentStatus.Unfinished,
    label: "Nebaigtos užklausos",
  },
  {
    value: PropertyAssessmentStatus.New,
    label: "Naujos užklausos",
  },
  {
    value: PropertyAssessmentStatus.InProgress,
    label: "Vykdomi vertinimai",
  },
  {
    value: PropertyAssessmentStatus.WaitingForReview,
    label: "Laukiantys patvirtinimo",
  },
  {
    value: PropertyAssessmentStatus.Final,
    label: "Įvykdyti vertinimai",
  },
];

const appraiserFilterOptions = [
  {
    label: "Visi vertinimai",
    value: AppraiserFilter.All,
  },
  {
    label: "Mano vertinimai",
    value: AppraiserFilter.My,
  },
];

export const PropertiesList: React.FC<PropertiesListProps> = () => {
  const status = usePropertyAssessmentStatusQuery();

  const { data } = useProperties();
  const { updateQueryParam } = useUpdateQueryParam();

  const appraiserFilter = useAppraiserFilterQuery();

  return (
    <div>
      <Select value={appraiserFilter ?? ""}>
        {appraiserFilterOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => updateQueryParam("appraiserFilter", option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Tabs
        value={status}
        style={{
          marginBottom: "20px",
        }}
      >
        {tabs.map((tab) => (
          <PropertyListTab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            onClick={() => {
              updateQueryParam("status", tab.value, ["page"]);
            }}
          />
        ))}
      </Tabs>

      {data?.data.map((property) => (
        <div style={{ marginBottom: "10px" }} key={property._id}>
          <PropertyInList property={property} />
        </div>
      ))}
      {data?.data.length === 0 && (
        <div
          style={{ textAlign: "center", fontSize: "18px", marginTop: "70px" }}
        >
          Sąrašas tuščias
        </div>
      )}
      {!!data?.pagination && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <PaginationComponent pagination={data.pagination} />
        </div>
      )}
    </div>
  );
};
