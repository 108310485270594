import { IconButton } from "common/components/buttons/IconButton";
import { InputField } from "common/components/form/InputField";
import { formatPrice } from "common/helpers/text.helpers";
import RemoveButtonIcon from "../../assets/icons/removeButton.svg";
import React from "react";
import {
  AdjustmentTypography,
  adjustmentFontSize,
} from "./AdjustmentTypography";
import colors from "common/styles/colors";
import { Button } from "@mui/material";
import { LinkButton } from "../buttons/LinkButton";
import { ChangeMethodology } from "./ChangeMethodology";
import { AdjustedField } from "common/types/common.types";
import { InfoTooltip } from "../tooltips/InfoTooltip";

interface AdjustmentRowProps {
  name?: string;
  propertyDesription?: string;
  comparableDescription?: string;
  priceArea: number;
  onChange: (value: number) => void;
  onChangeFull?: (value: AdjustedField) => void;
  onRemoveClick?: () => void;
  percent: number;
  fieldname?: string;
  config?: {
    fullname: string;
    name: string;
  };
  adjustmentDescription?: string;
}

export const AdjustmentRow: React.FC<AdjustmentRowProps> = (props) => {
  return (
    <tr
      style={{
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: colors.greybg,
      }}
    >
      <td style={{ paddingLeft: 0, width: "300px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <AdjustmentTypography>{props.name}</AdjustmentTypography>
          {!!props.adjustmentDescription && (
            <InfoTooltip
              title={
                <div style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
                  {props.adjustmentDescription}
                </div>
              }
            />
          )}
        </div>
        {!!props.config && (
          <>
            <div
              style={{
                color: colors.grey,
                marginTop: "2px",
                marginBottom: "2px",
                width: "150px",
              }}
            >
              {props.config.fullname}
            </div>
            <ChangeMethodology
              field={props.fieldname ?? ""}
              selected={props.config.name}
              fieldname={props.name ?? ""}
              onChange={props.onChangeFull}
              adjustmentDescription={props.adjustmentDescription}
            />
          </>
        )}
      </td>
      <td style={{ width: "100%" }}>
        <AdjustmentTypography>{props.propertyDesription}</AdjustmentTypography>
      </td>
      <td>
        <AdjustmentTypography>
          {props.comparableDescription}
        </AdjustmentTypography>
      </td>
      <td>
        <AdjustmentTypography>
          {`${props.priceArea > 0 ? "+" : ""}${formatPrice(
            props.priceArea,
            2
          )}/m²`}
        </AdjustmentTypography>
      </td>
      <td
        style={{
          paddingRight: 0,
        }}
      >
        <div
          style={{
            gap: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ minWidth: "150px" }}>
            <InputField
              value={props.percent + 1}
              variant="decimal"
              onChange={(value) => {
                props.onChange(value === undefined ? 0 : (value as number) - 1);
              }}
              fontSize={adjustmentFontSize}
            />
          </div>
          {!!props.onRemoveClick && (
            <IconButton onPress={props.onRemoveClick}>
              <RemoveButtonIcon />
            </IconButton>
          )}
        </div>
      </td>
    </tr>
  );
};
