import React from "react";

interface AdjustmentsDescriptionParagraphsProps {
  description: string;
}

export const AdjustmentsDescriptionParagraphs: React.FC<
  AdjustmentsDescriptionParagraphsProps
> = ({ description }) => {
  return <div style={{ whiteSpace: "pre-wrap" }}>{description}</div>;
};
