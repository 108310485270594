import React, { useState } from "react";
import {
  useRouteQuery,
  useUpdateQueryParam,
} from "../../../hooks/router.hooks";
import { Button, TextField } from "@mui/material";
import { TotalReportPrice } from "../../../types/api.types";
import LoadingButton from "@mui/lab/LoadingButton";

interface DiscountCodeFieldProps {
  reportPrice: TotalReportPrice;
  isLoading: boolean;
}

export const DiscountCodeField: React.FC<DiscountCodeFieldProps> = ({
  reportPrice,
  isLoading,
}) => {
  const discountCode = useRouteQuery().get("discountCode");

  const [discountCodeInput, setDiscountCodeInput] = useState<string>(
    discountCode ?? ""
  );

  const { updateQueryParam } = useUpdateQueryParam();

  const codeApplied = discountCodeInput === discountCode && discountCode;

  return (
    <div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: 600,
          marginBottom: "30px",
        }}
      >
        Turite nuolaidos kodą?
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <TextField
          value={discountCodeInput}
          onChange={(e) => {
            setDiscountCodeInput(e.target.value);
          }}
          label="Įveskite kodą"
          error={!!reportPrice.discountCodeError}
          helperText={reportPrice.discountCodeError}
        />
        <div
          style={{
            marginTop: "3px",
          }}
        >
          {codeApplied ? (
            <Button
              variant="outlined"
              onClick={() => {
                updateQueryParam("discountCode", "");
                setDiscountCodeInput("");
              }}
            >
              Ištrinti
            </Button>
          ) : (
            <LoadingButton
              variant="contained"
              onClick={() => {
                updateQueryParam("discountCode", discountCodeInput);
              }}
              loading={isLoading}
            >
              Naudoti kodą
            </LoadingButton>
          )}
        </div>
      </div>
    </div>
  );
};
