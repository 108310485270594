import { Comparable, ComparableFull } from "common/types/common.types";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { ComparableMarker } from "./ComparableMarker";
import { ComparableCardWithPricePredictions } from "./ComparableCardWithPricePredictions";
import colors from "common/styles/colors";
import { isCompMatched } from "common/helpers/comparables.helpers";
import { haveSameKeys } from "common/helpers/common.helpers";
import { isEqual, omit } from "lodash";
import { isComparableFull } from "../../helpers/comparable.helpers";
import LockUnlockedIcon from "../../assets/icons/lockUnlocked.svg";
import LockLockedIcon from "../../assets/icons/lockLocked.svg";
import {
  IconType,
  usePropertyIconsProps,
} from "../../hooks/propertyIcons.hooks";

interface ComparableMarkerWithPricePredictionsProps {
  comparables: (Comparable | ComparableFull)[];
  selectedCompId?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: (compId: string) => void;
}

const getCompXOffset = (compsClustersLength: number, index: number) => {
  if (compsClustersLength === 1) return 0;

  if (compsClustersLength === 2) {
    if (index === 0) return -20;
    if (index === 1) return 20;
  }

  if (compsClustersLength === 3) {
    if (index === 0) return -40;
    if (index === 1) return 0;
    if (index === 2) return 40;
  }
};

export const ComparableMarkerWithPricePredictions: React.FC<ComparableMarkerWithPricePredictionsProps> =
  React.memo(
    ({ comparables, onMouseEnter, onMouseLeave, selectedCompId, onClick }) => {
      const lat = comparables[0].comparable_transaction.location.lat;
      const lng = comparables[0].comparable_transaction.location.lng;

      const { propertyId } = useParams();
      const { isComparableInCart } = usePricePredictions(propertyId ?? "");

      const comparablesInCart = comparables.filter((comp) =>
        isComparableInCart(comp.comparable_transaction.id.toString())
      );

      const comparablesFull = comparables.filter(
        (comp) =>
          isComparableFull(comp) &&
          !isComparableInCart(comp.comparable_transaction.id.toString())
      );

      const comparablesNotFull = comparables.filter(
        (comp) =>
          !isComparableFull(comp) &&
          !isComparableInCart(comp.comparable_transaction.id.toString())
      );

      const compsClusters = [
        { comps: comparablesNotFull, type: "notFull" },
        { comps: comparablesFull, type: "full" },
        { comps: comparablesInCart, type: "inCart" },
      ].filter(({ comps }) => comps.length > 0);

      const { getIcon, getIconBGColor } = usePropertyIconsProps();

      return (
        <>
          {compsClusters.map((cluster, index) => {
            const isSelected = cluster.comps.some(
              (comp) => comp.comparable_transaction.id === selectedCompId
            );

            return (
              <ComparableMarker
                key={index}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                isSelected={isSelected}
                markerProps={{
                  lat,
                  lng,
                  onClick: () =>
                    onClick?.(cluster.comps[0].comparable_transaction.id),
                  withOkIcon: cluster.comps.some((comp) =>
                    isCompMatched(comp.comparable_transaction)
                  ),
                  multipleCount: cluster.comps.length,
                  backgroundColor: getIconBGColor(cluster.type as IconType),
                  customIcon: getIcon(cluster.type as IconType),
                }}
                xOffSet={getCompXOffset(compsClusters.length, index)}
              >
                <ComparableCardWithPricePredictions
                  comparables={cluster.comps}
                  selectedCompId={selectedCompId}
                />
              </ComparableMarker>
            );
          })}
        </>
      );
    },
    (prevProps, nextProps) => {
      const mapComps = (comp: Comparable) => {
        return {
          id: `${comp.comparable_transaction.id}_${isComparableFull(comp)}`,
        };
      };
      if (
        !haveSameKeys(
          prevProps.comparables.map(mapComps),
          nextProps.comparables.map(mapComps),
          "id"
        )
      ) {
        return false;
      }

      const prevPropsWithoutField = omit(prevProps, "comparables");
      const nextPropsWithoutField = omit(nextProps, "comparables");
      return isEqual(prevPropsWithoutField, nextPropsWithoutField);
    }
  );
