import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import InfoIcon from "../../assets/icons/infoIcon.svg";

interface InfoTooltipProps {
  title: React.ReactNode;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <IconButton size="small">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};
