import React from "react";
import { MonthDate } from "./types";
import { getEndingByCountLT4 } from "common/helpers/text.helpers";
import _ from "lodash";
import { getMonthDateRangeFromCurrentDate } from "../../helpers/comparableFilters.helpers";
import { Toggle } from "../form/toggle/Toggle";

interface MonthRangePresetProps {
  selectedDatesInFilter?: { start: MonthDate; end: MonthDate };
  onSelect?: (startDate: MonthDate, endDate: MonthDate) => void;
  monthLength: number;
}

export const MonthRangePreset: React.FC<MonthRangePresetProps> = ({
  onSelect,
  monthLength,
  selectedDatesInFilter,
}) => {
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthLength + 1,
    1
  );

  const startMonth = {
    year: startDate.getFullYear(),
    month: startDate.getMonth() + 1,
  };
  const endMonth = {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
  };

  const isChecked =
    _.isEqual(startMonth, selectedDatesInFilter?.start) &&
    _.isEqual(endMonth, selectedDatesInFilter?.end);

  return (
    <Toggle
      isChecked={isChecked}
      label={`${monthLength} mėnes${getEndingByCountLT4(
        monthLength
      ).toLowerCase()}`}
      onSelect={() => {
        const dates = getMonthDateRangeFromCurrentDate(monthLength);

        onSelect?.(dates.startDate, dates.endDate);
      }}
    />
  );
};
