import { DialogTitle } from "@mui/material";
import { IconButton } from "common/components/buttons/IconButton";
import { CloseIcon } from "common/components/icons/CloseIcon";
import React from "react";

interface DialogHeaderProps {
  title: string;
  onClose: () => void;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  onClose,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "12px",
      }}
    >
      <DialogTitle>{title}</DialogTitle>

      <IconButton onPress={() => onClose()}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
