import { Box } from "@mui/material";
import colors from "common/styles/colors";
import React from "react";

interface StickyHeaderWrapperProps {
  children: React.ReactNode;
}

export const StickyHeaderWrapper: React.FC<StickyHeaderWrapperProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        padding: "15px",
        backgroundColor: colors.bg,
      }}
    >
      {children}
    </Box>
  );
};
