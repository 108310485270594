import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "common/components/buttons/Button";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import { CheckboxInput } from "common/components/form/CheckboxInput";
import React from "react";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";

interface TransactionsBuyConfirmationModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onConfirm: (doNotShowAgain: boolean) => void;
}

export const TransactionsBuyConfirmationModal: React.FC<
  TransactionsBuyConfirmationModalProps
> = ({ onClose, isModalOpen, onConfirm }) => {
  return (
    <ConfirmationDialog
      isOpen={isModalOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Ar sutinkate su sandorio pirkimu?"
      description="Atlikdami šį veiksmą, jūs sutinkate su sandorio pirkimu. Jei sutinkate paspauskite „Patvirtinti“"
      actionButtonText="PATVIRTINTI"
    />
  );
};
