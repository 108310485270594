import React from "react";

interface PaymentsGroupProps {
  title: string;
  children: React.ReactNode;
}

export const PaymentsGroup: React.FC<PaymentsGroupProps> = ({
  title,
  children,
}) => {
  return (
    <div>
      <div style={{ marginBottom: "15px" }}>{title}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        {children}
      </div>
    </div>
  );
};
