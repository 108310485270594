import React from "react";
import { PropertyInfra } from "../../../types/api.types";
import { getListString } from "common/helpers/common.helpers";
import { generateWorkTimesString } from "common/helpers/workTimeHelpers";
import { CommuteDistance } from "common/components/infra/CommuteDistance";
import colors from "common/styles/colors";
import { Divider } from "common/components/listItems/Divider";
import { PropertyDistanceCard } from "./PropertyDistanceCard";
interface DistancesSectionProps {
  name: string;
  infras: PropertyInfra[];
}

export const DistancesSection: React.FC<DistancesSectionProps> = ({
  name,
  infras,
}) => {
  return (
    <div>
      <div
        style={{
          fontSize: "20px",
          marginBottom: "15px",
        }}
      >
        {name}
      </div>
      <div>
        {infras.map((infra, index) => {
          return (
            <div
              key={infra._id}
              style={{
                marginBottom: "20px",
              }}
            >
              <PropertyDistanceCard infra={infra} infraNumber={index + 1} />

              <Divider
                style={{
                  marginTop: "10px",
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
