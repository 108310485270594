import { useQuery } from "react-query";
import { getMyPropertyPhotos, getUser } from "../api/api";
import { myPropertyPhotosQueryKey } from "../constants/queryKeys.constants";

import _ from "lodash";

export function useUser() {
  return useQuery("user", getUser, {
    staleTime: 1000 * 5,
    isDataEqual: (oldUser, newUser) => _.isEqual(oldUser, newUser),
    notifyOnChangeProps: ["data"],
  });
}

export function useMyPropertyPhotos(
  propertyId: string | undefined,
  getPhotosFunction = getMyPropertyPhotos
) {
  return useQuery(
    [myPropertyPhotosQueryKey, propertyId],
    () => getPhotosFunction(propertyId ?? ""),
    {
      enabled: !!propertyId,
    }
  );
}
