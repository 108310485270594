import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isCompMatched } from "common/helpers/comparables.helpers";
import { Comparable } from "common/types/common.types";
import { TransactionModal } from "../transactions/TransactionModal";
import { isComparableFull } from "../../helpers/comparable.helpers";

interface ComparableCardMenuProps {
  comparable: Comparable;
}

export const ComparableCardMenu: React.FC<ComparableCardMenuProps> = ({
  comparable,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const navigate = useNavigate();

  const isFull = isComparableFull(comparable);

  const showMoreInfo = isFull;
  const showMatched = isCompMatched(comparable.comparable_transaction);
  const showCompare = isFull;

  return (
    <>
      <IconButton
        style={{ marginRight: -8, marginBottom: -2 }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e: any) => {
          if (!!e.stopPropagation) e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        {showMoreInfo && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setIsDetailsModalOpen(true);
              setAnchorEl(null);
            }}
          >
            Žiūrėti sandorio informaciją
          </MenuItem>
        )}
        {showMatched && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${comparable.comparable_transaction.id}/compare`);
              setAnchorEl(null);
            }}
          >
            Žiūrėti sandorio identifikavimą
          </MenuItem>
        )}
        {showCompare && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${comparable.comparable_transaction.id}/adjustments`);
              setAnchorEl(null);
            }}
          >
            Palyginti objektus
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${comparable.comparable_transaction.id}/messages`);
            setAnchorEl(null);
          }}
        >
          Informaciniai pranešimai
        </MenuItem>
      </Menu>
      <TransactionModal
        open={isDetailsModalOpen}
        onClose={(e) => {
          if (!!e?.stopPropagation) e.stopPropagation();
          setIsDetailsModalOpen(false);
        }}
        id={comparable.comparable_transaction.id}
      />
    </>
  );
};
