import { Container, ContainerProps } from "@mui/material";
import React from "react";

interface FormContainerProps {
  onSubmit: () => void;
  children: React.ReactNode;
  containerProps?: ContainerProps;
}

export const FormContainer: React.FC<FormContainerProps> = ({
  onSubmit,
  children,
  containerProps,
}) => {
  return (
    <Container maxWidth="xs" {...containerProps}>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "30px",
        }}
        onSubmit={onSubmit}
      >
        {children}
      </form>
    </Container>
  );
};
