import {
  AddressWithCoords,
  GoogleMapsPredictions,
} from "../types/common.types";
import CommonConfig from "../commonConfig";

export const getAutocompletePredictions = async (
  input: string,
  lat?: number,
  lng?: number,
  sessiontoken?: string
) => {
  const { data } = await CommonConfig.axiosInstance.get<GoogleMapsPredictions>(
    "/googlemaps/autocomplete",
    {
      params: {
        input,
        lat,
        lng,
        sessiontoken,
      },
    }
  );

  return data;
};

export const getAddressFromPlaceId = async (
  placeId: string,
  sessiontoken: string
) => {
  const { data } = await CommonConfig.axiosInstance.get<AddressWithCoords>(
    "/googlemaps/address/place",
    {
      params: {
        placeId,
        sessiontoken,
      },
    }
  );
  return data;
};
