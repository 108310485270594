import React from "react";
import { StyledImage } from "./StyledImage";
import { View } from "react-native";
import CloseImageIcon from "../../assets/icons/closeImage.svg";
import { IconButton } from "../buttons/IconButton";
import { isWeb } from "../../environment";
interface FormImageProps {
  uri: string;
  onCloseButtonPress: () => void;
}

const margin = 5;

export const FormImage: React.FC<FormImageProps> = ({
  uri,
  onCloseButtonPress,
}) => {
  return (
    <View style={isWeb() ? { width: 230, margin } : undefined}>
      <StyledImage
        imageProps={{ source: { uri } }}
        borderRadius={5}
        showLoader
        style={
          isWeb()
            ? { margin }
            : {
                flex: 1,
                margin,
              }
        }
        height={isWeb() ? 160 : 120}
        width={isWeb() ? 230 : undefined}
      />
      <View
        style={{
          position: "absolute",
          top: 14,
          right: 6,
        }}
      >
        <IconButton onPress={onCloseButtonPress}>
          <CloseImageIcon />
        </IconButton>
      </View>
    </View>
  );
};
