import React, { useContext } from "react";
import { TransactionsBackdrop } from "./TransactionsBackdrop";
import { TransactionsRCContext } from "./TransactionsRCProvider";
import { Typography } from "common/components/Typography";
import { getEndingByCountLT5 } from "common/helpers/text.helpers";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { MUILoadingButton } from "./buttons/MUILoadingButton";
import { showToastError } from "common/toast/toast";

interface TransactionsLoadConfirmationProps {}

export const TransactionsLoadConfirmation: React.FC<
  TransactionsLoadConfirmationProps
> = () => {
  const { transactionsNeededToBuy, dataUsed, refetch } = useContext(
    TransactionsRCContext
  );
  const navigate = useNavigate();

  return (
    <TransactionsBackdrop
      open={transactionsNeededToBuy !== undefined ? true : false}
      gap="30px"
    >
      <Typography variant="h3">Neįsigyti sandoriai</Typography>
      <Typography>
        Norint atlikti vertinimą, reikia įsigyti {transactionsNeededToBuy}{" "}
        sandor{getEndingByCountLT5(transactionsNeededToBuy).toLowerCase()}.
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          style={{ width: "150px" }}
          variant="outlined"
        >
          Grįžti
        </Button>
        <Button
          style={{ width: "150px" }}
          onClick={() => {
            if (!dataUsed) {
              showToastError();
              return;
            }
            refetch?.(dataUsed.data, dataUsed.propertyId, true);
          }}
          variant="contained"
        >
          Patvirtinti
        </Button>
      </div>
    </TransactionsBackdrop>
  );
};
