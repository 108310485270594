import React from "react";
import { ViewStyle } from "react-native";
import { OpacityButton } from "./base/OpacityButton";

interface IconButtonProps {
  onPress?: () => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
  size?: number;
  style?: ViewStyle;
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const size = props.size ?? 40;

  return (
    <OpacityButton
      onPress={props.onPress}
      disabled={props.isDisabled}
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        justifyContent: "center",
        alignItems: "center",
        opacity: props.isDisabled ? 0.5 : 1,
        ...props.style,
      }}
    >
      {props.children}
    </OpacityButton>
  );
};
