import React from "react";
import { usePropertyValuation } from "../hooks/property.hooks";
import { useParams } from "react-router-dom";
import { ErrorCentered } from "../components/loaders/ErrorCentered";
import { LoaderCentered } from "../components/loaders/LoaderCentered";
import { PropertyShare } from "./PropertyShare";
import { PropertyWithGallery } from "./property/PropertyWithGallery";

interface PropertyShareFromValuationProps {}

export const PropertyShareFromValuation: React.FC<
  PropertyShareFromValuationProps
> = () => {
  const { id } = useParams();
  const { data, isError, isLoading } = usePropertyValuation(id ?? "");

  if (isError) {
    return <ErrorCentered />;
  }

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!data) {
    return null;
  }

  return (
    <PropertyWithGallery property={data.property}>
      <PropertyShare data={data} />
    </PropertyWithGallery>
  );
};
