import {
  CommuteElement,
  Comparable,
  ComparableFull,
  FinalPricePredictions,
  PredictedPrice,
  WorkTimes,
} from "common/types/common.types";
import { ComparableFilterType } from "../helpers/comparableFilters.helpers";
import { AppraiserProperty, AppraiserRole } from "./appraiser.types";
import { PropertyPermission } from "../routes/property/PropertyShareSettings";
import { DesignationType } from "common/types/comparableFields.types";

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RegisterRequest {
  token: string;
  password: string;
}

export interface BasicOrganization {
  _id: string;
  name: string;
  description?: string;
  email?: string;
  phone?: string;
  website?: string;
  logo?: string;
}

export interface Organization extends BasicOrganization {
  monthlyCreditLimit?: number;
  transactionRetentionDays?: number | null;
  createdAt: string;
  updatedAt: string;
  RC_EndUserInfo?: { [key: string]: string };
}

export interface CreateAppraiserRequest {
  email: string;
  role: AppraiserRole;
}

export interface AppraiserPropertyField {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export interface PaginatedResponse<T> {
  data: T;
  pagination: Pagination & {
    total: number;
  };
}

export enum PropertyAssessmentStatus {
  Unfinished = "unfinished",
  New = "new",
  InProgress = "inProgress",
  WaitingForReview = "waitingForReview",
  Final = "final",
}

export enum AppraiserFilter {
  All = "all",
  My = "my",
}

export interface DecodedToken<T> {
  data?: T;
  error?: {
    name: string;
  };
}

export type DecodedAppraiserToken = DecodedToken<{ email: string }>;

export interface SavedComparableFilter {
  type: ComparableFilterType;
  values: any;
}

export interface AnalyzedReport {
  area: number | null;
  floors: number | null;
  floors_total: number | null;
  address: string;
  year_construction: number | null;
  year_reconstruction: number | null;
  sold_price: number | null;
  location: string;
  lat: number | null;
  lng: number | null;
}

export enum MessageType {
  INFO = "info",
  FAKED = "faked",
  WRONG_DATA = "wrong_data",
}

export interface StatusMessage {
  type: MessageType;
  message?: string;
}

export interface ComparableMessageCreateRequest {
  type: MessageType;
  message: string;
  transactionId: string;
}

export interface ComparableMessage extends ComparableMessageCreateRequest {
  _id: string;
  organizationId: string;
  appraiserId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface TransactionsSearchResponse {
  base_transactions: Comparable[];
  full_transactions: ComparableFull[];
  failed_geocoding_count: number;
  multiple_objects_count: number;
  search_count: number;
}

export interface ValuationZone {
  zone_nr: string;
}

export interface ValuationZoneFull extends ValuationZone {
  geometry: {
    coordinates: number[][][];
  };
}

export interface UsedCreditsRow {
  creditsUsed: number;
  actionsCount: number;
  actionType: "197" | "198";
}

export interface OrganizationUsedCredits {
  totalCreditsUsed: number;
  monthlyCreditLimit: number | null;
  currentMonth: number;
}

export interface AppraiserUsedCredits {
  appraiserId: string;
  email: string;
  totalCredits: number;
}

export interface UsedCreditsByMonth {
  month: number;
  year: number;
  appraisers: AppraiserUsedCredits[];
}

export interface AdjustmentProvider {
  name: string;
  fullname: string;
}

export interface AdjustmentField {
  field: string;
  providers: AdjustmentProvider[];
  providerSelected: string;
  description?: string;
}

export interface AdditionalField {
  field: string;
  appraiserPropertyFieldId: string;
  description?: string;
}

export interface AdminAdjustments {
  adjustmentFields: AdjustmentField[];
  additionalFields: AdditionalField[];
}

export interface AdjustmentDescription {
  description: string;
  field?: string;
  appraiserPropertyFieldId?: string;
  header?: string;
}

export interface AdjustmentDescriptionForComparable {
  field: string;
  provider: string;
  percent: number;
  comparable_index: number;
  comparable_id: string;
  description?: string | null;
}

export interface TransactionsImportResult {
  found_in_db_count: number;
  saved_in_db_count: number;
  not_supported_count: number;
}

export interface MontonioPaymentMethod {
  name: string;
  logoUrl: string;
  supportedCurrencies: string[];
  uiPosition: number;
  code: string;
}
export interface PaymentMethods {
  paymentMethods: {
    paymentInitiation: {
      setup: {
        LT: {
          supportedCurrencies: string[];
          paymentMethods: MontonioPaymentMethod[];
        };
      };
    };
    cardPayments: {
      logoUrl: string;
    };
  };
}

export enum PaymentMethodType {
  Bank = "bank",
  Card = "card",
  Free = "free",
}

export enum PropertyType {
  Apartment = "apartment",
  House = "house",
  Cottage = "cottage",
}

export const PropertyTypeTranslations: Record<PropertyType, string> = {
  [PropertyType.Apartment]: "Butas",
  [PropertyType.House]: "Namas",
  [PropertyType.Cottage]: "Kotedžas",
};

export interface PropertyShared {
  editToken?: string | null;
  property: AppraiserProperty;
  basicOrganization?: BasicOrganization;
  propertyComment?: PropertyComment | null;
  comparables?: ComparableFull[];
  predictedPrice?: PredictedPrice;
  compositeAssets?: CompositeAsset[];
  pricePrediction?: FinalPricePredictions;
  documents?: UploadedFile[];
  distances?: PropertyInfra[];
  purchasedAddons?: PropertyAddonWithPrice[];
  purchasedCompositeAssets?: CompositeAsset[];
  marketInsights?: ChartData[];
  locationDetails?: PropertyLocationDetails[];
}

export interface PropertyComment {
  comment: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface DiscountCodeCreateRequest {
  code: string;
  discount: number;
  validFrom: Date;
  validUntil: Date;
}

export interface DiscountCode extends DiscountCodeCreateRequest {
  _id: string;
  uuid: string;
  usedCount: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface PropertyAddon {
  type: PropertyAddonType;
}

export interface PropertyAddonWithPrice extends PropertyAddon {
  price?: number;
}

export interface CompositeAssetWithPrice {
  _id: string;
  designationTypeId: DesignationType;
  price?: number;
}

export interface TotalReportPrice {
  basePrice: number;
  discount: {
    discountAmount: number;
    discountPercentage: number;
  } | null;
  discountCodeError: string | null;
  addonsPrice: number;
  addonsWithPrices: PropertyAddonWithPrice[];
  compositeAssetsPrice: number;
  compositeAssetsWithPrices: CompositeAssetWithPrice[];
  fullPrice: number;
  totalPrice: number;
}

export enum PropertyLocationDetailsType {
  REGIA1 = "REGIA1",
  REGIA2 = "REGIA2",
  FLOODS = "FLOODS",
}

export interface PropertyLocationDetails {
  _id: string;
  locationType: PropertyLocationDetailsType;
  url?: string;
  screenshotUrl?: string;
  propertyId: string;
  generatingCompleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum PropertyInfraType {
  Pharmacy = "pharmacy",
  Shop = "shop",
}

export interface InfraByType {
  type: string;
  infras: PropertyInfra[];
}

export interface PropertyInfra {
  _id: string;
  type: PropertyInfraType;
  subtype: string;

  lat: number;
  lng: number;
  address: string;
  name?: string | null;
  meta?: any;
  work_times?: WorkTimes[] | null;

  distances: CommuteElement[];
  icon: string;
}

export enum AreaUnit {
  SqMeters = "m2",
  Acres = "a",
  Hectares = "h",
}

export interface CompositeAssetRequest {
  propertyId: string;
  name: string;
  designationTypeId?: string;
  uniqueNr?: string;
  areaTotal: number;
  areaUnit: AreaUnit;
  assetPart1: number;
  assetPart2: number;
  value?: number;
  isCustomer: boolean;
}

export interface CompositeAsset extends CompositeAssetRequest {
  _id: string;
  isPlotArea?: boolean;
  isPaid?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ConfirmationRequest {
  organizationName: string;
  appraiserFullName: string;
  certificateNumber?: string | null;
  certificateValidFrom?: Date | null;

  appraiserFullNameConfirm: string;
  confirmCheckbox?: boolean;
}

export interface Confirmation extends ConfirmationRequest {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UploadedFile {
  _id: string;
  fileName: string;
  url: string;
  key: string;
}

export enum PropertyAddonType {
  ComparablesView = "comparables",
  CalculationsView = "calculations",
  LocationReport = "locationReport",
  MarketInsights = "marketInsights",
}

export const PropertyAddonToPermission: Record<
  PropertyAddonType,
  PropertyPermission
> = {
  [PropertyAddonType.ComparablesView]: PropertyPermission.ViewComparables,
  [PropertyAddonType.CalculationsView]: PropertyPermission.ViewCalculations,
  [PropertyAddonType.LocationReport]: PropertyPermission.ViewDistances,
  [PropertyAddonType.MarketInsights]: PropertyPermission.ViewMarketInsights,
};

export type PropertyAddonPrices = Record<PropertyAddonType, number>;

export enum ChartType {
  Lines = "lines",
  Bars = "bars",
}
export interface ChartDataItem {
  x: string;
  y: number;
}

export enum ChartSection {
  MarketInsights = "market_insights",
  PopulationStatistics = "population_statistics",
}

export interface ChartData {
  title: string;
  chart_type: ChartType;
  data: ChartDataItem[];
  x_axis_label: string;
  y_axis_label: string;
  source: string;
  section: ChartSection;
  subtitle?: string;
}
