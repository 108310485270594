import { StyledImage } from "common/components/images/StyledImage";
import React from "react";
import { BasicAppraiser } from "./BasicAppraiser";
import { ContentCard } from "../../components/ContentCard";
import { PropertyTypeOption } from "./PropertyTypeOption";
import { PropertyType } from "../../types/api.types";
import { useQuery } from "react-query";
import { getBasicAppraiser, getReportPrices } from "../../api/api";
import { LeadActionCard } from "./LeadActionCard";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { getDesignationTypeFromSimpleType } from "../../helpers/leadProperty.helpers";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { Box, Link, Tooltip } from "@mui/material";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { BasicOrganization } from "./BasicOrganization";
import { AddonListSelect } from "../property/addons/AddonListSelect";
import { LeadLandingPageActionItem } from "./LeadLandingPageActionItem";

interface LeadLandingPageProps {}

const valuationServices = [
  {
    label: "Buto vertinimas",
    value: PropertyType.Apartment,
  },
  {
    label: "Namo vertinimas",
    value: PropertyType.House,
  },
  {
    label: "Kotedžo vertinimas",
    value: PropertyType.Cottage,
  },
];

export const LeadLandingPage: React.FC<LeadLandingPageProps> = () => {
  const [selectedPropertyType, setSelectedPropertyType] =
    React.useState<PropertyType>(PropertyType.Apartment);

  const appraiserId = useRouteQuery().get("appraiserId");
  const discountCode = useRouteQuery().get("discountCode");

  const { selectedAddons } = React.useContext(PropertyCreationContext);

  const { data: reportPrices, isLoading: isLoadingPrices } = useQuery(
    [
      "report_prices",
      appraiserId,
      discountCode,
      JSON.stringify(selectedAddons),
    ],
    () =>
      getReportPrices(
        appraiserId ?? "",
        discountCode ?? undefined,
        selectedAddons
      ),
    {
      enabled: !!appraiserId,
      keepPreviousData: true,
    }
  );

  const navigate = useNavigate();

  const { getUpdatedQueryParam } = useUpdateQueryParam();

  const { data: basicData, isLoading } = useQuery(
    ["basic_appraiser", appraiserId],
    () => getBasicAppraiser(appraiserId ?? ""),
    {
      enabled: !!appraiserId,
    }
  );

  const { appraiser, organization } = basicData ?? {};

  if (isLoading || isLoadingPrices) {
    return <LoaderCentered />;
  }

  if ((!appraiser && !organization) || !reportPrices) {
    return <div>Klaida</div>;
  }

  const prices = reportPrices?.data[selectedPropertyType];

  return (
    <div>
      {!!appraiser && (
        <BasicAppraiser appraiser={appraiser} organization={organization} />
      )}
      {!!organization && !appraiser && (
        <BasicOrganization organization={organization} />
      )}

      <ContentCard title="Preliminarios turto kainos ataskaita">
        <div>
          {
            "Užpildykite turto vertinimo užklausos formą ir sužinokite preliminarią nekilnojamojo turto kainą, remiantis įvykusiais sandoriais. Vertinimą atliks profesionalus, NT vertintojo licenciją turintis specialistas"
          }
        </div>
        <div style={{ marginTop: "20px" }}>
          {
            "Jeigu nustatyta turto kaina atitiks lūkesčius, galėsite užsakyti išsamią turto vertinimo ataskaitą. Ši ataskaita reikalinga įkeičiant turtą "
          }
          <Tooltip title="Šio vertintojo ataskaitas pripažįsta SEB, Luminor, Swedbank, Citadele, Medicinos bankas, Mano bankas, kredito unijos, sutelktinio finansavimo platformos, kitos kredito įstaigos">
            <Link>kredito įstaigai</Link>
          </Tooltip>
          {
            ", paveldint ar dovanojant turtą ar kitais tikslais. Galutinė turto vertė gali nežymiai skirtis, vertinimo eigoje patikslinus turto detales, vertintojui apžiūrėjus turtą ar pateikus detalesnius dokumentus"
          }
        </div>

        <div style={{ marginTop: "40px" }} />
        <LeadLandingPageActionItem index={1} title="Pasirinkite turto tipą">
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginBottom: "30px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {valuationServices.map((service) => (
              <PropertyTypeOption
                key={service.value}
                checked={selectedPropertyType === service.value}
                label={service.label}
                onClick={() => setSelectedPropertyType?.(service.value)}
              />
            ))}
          </Box>
        </LeadLandingPageActionItem>
        <LeadLandingPageActionItem
          index={2}
          title="Papildomos ataskaitos dalys"
        >
          <AddonListSelect />
        </LeadLandingPageActionItem>
        <div style={{ marginTop: "40px" }} />
        <LeadActionCard
          fullPrice={prices.fullPrice}
          discountedPrice={prices.discount ? prices.totalPrice : undefined}
          code={discountCode}
          onOrderClick={() => {
            const updateLocation = getUpdatedQueryParam([
              {
                paramName: "type",
                newValue:
                  getDesignationTypeFromSimpleType(selectedPropertyType),
              },
              { paramName: "simpleType", newValue: selectedPropertyType },
            ]);

            navigate({
              pathname: "../inputMethod",
              search: updateLocation.search,
            });
          }}
        />
      </ContentCard>
    </div>
  );
};
