import React from "react";
import { UsedCreditsRow } from "../../types/api.types";
import { Typography } from "common/components/Typography";

interface PropertyCreditsRowProps {
  usedCreditsRow: UsedCreditsRow;
}

const actionTypeDictionary = {
  "197": "Sandorių paieškos",
  "198": "Įsigyti sandoriai",
};

export const PropertyCreditsRow: React.FC<PropertyCreditsRowProps> = ({
  usedCreditsRow,
}) => {
  return (
    <tr>
      <td>
        <Typography variant="caption" textStyle={{ fontSize: 14 }}>
          {actionTypeDictionary[usedCreditsRow.actionType]}
        </Typography>
      </td>
      <td style={{ paddingLeft: "20px" }}>
        <Typography textStyle={{ fontSize: 14 }}>
          {usedCreditsRow.actionsCount}
        </Typography>
      </td>
      <td style={{ paddingLeft: "20px" }}>
        <Typography textStyle={{ fontSize: 14 }}>{`${
          usedCreditsRow.creditsUsed / 100
        } €`}</Typography>
      </td>
    </tr>
  );
};
