import React, { useContext, useState } from "react";
import {
  InputMethod,
  InputMethodSelection,
} from "common/components/propertyCRUD/InputMethodSelection";
import { Button } from "common/components/buttons/Button";
import colors from "common/styles/colors";
import { useMutation } from "react-query";
import { getErrorMessage } from "common/helpers/error.helpers";
import { getUrlErrorMessage } from "common/helpers/url.helpers";
import { useNavigate, useParams } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import { showToastError } from "common/toast/toast";
import { addAppraiserPropertyFromUrl } from "../../api/appraiserProperties.api";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { setPropertyToken } from "common/hooks/propertyDetails.hooks";
import { PropertyType } from "../../types/api.types";

interface InputMethodSelectionRouteProps {}

export const InputMethodSelectionRoute: React.FC<
  InputMethodSelectionRouteProps
> = () => {
  const [selectedInputMethod, setSelectedInputMethod] = useState<InputMethod>(
    InputMethod.Input
  );
  const { navigateWithQueryParams } = useUpdateQueryParam();

  const navigate = useNavigate();

  const [url, setUrl] = useState("");
  const { fromLead } = useContext(PropertyCreationContext);

  const appraiserId = useRouteQuery().get("appraiserId") ?? undefined;

  const simpleType = useRouteQuery().get("simpleType") as
    | PropertyType
    | undefined;

  const { isLoading, mutate } = useMutation(
    (url: string) =>
      addAppraiserPropertyFromUrl(url, fromLead, appraiserId, simpleType),
    {
      onSuccess: (property) => {
        if (!!fromLead) {
          setPropertyToken(property);
          navigateWithQueryParams("../contact", [
            { paramName: "createdPropertyId", newValue: property._id },
          ]);
        } else {
          navigate(`/properties/${property._id}`);
        }
      },
      onError: (error: any) => {
        const message = getErrorMessage(error);

        if (message === undefined) {
          showToastError();
        } else {
          showToastError(message);
        }
      },
    }
  );

  const query = useRouteQuery();
  const designationType = query.get("type");

  return (
    <ContentCard
      title="Sukurti naują turtą vertinimui"
      caption="Pasirinkite kokiu būdu norėtumėte sukurti turtą jo vertinimui."
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: 500 }}>
          <InputMethodSelection
            selectedInputMethod={selectedInputMethod}
            onInputMethodChange={(inputMethod) =>
              setSelectedInputMethod(inputMethod)
            }
            urlInput={url}
            onUrlChange={(url) => setUrl(url)}
            noCaption
          />
          <div style={{ marginTop: 30 }}>
            <Button
              backgroundColor="purple2"
              textStyle={{ color: colors.white }}
              isLoading={isLoading}
              onPress={() => {
                if (selectedInputMethod === InputMethod.Input) {
                  if (!!fromLead) {
                    navigateWithQueryParams(
                      designationType ? "../details" : "../type"
                    );
                  } else {
                    navigate("/properties/create/type");
                  }
                } else {
                  const errorMessage = getUrlErrorMessage(url);

                  if (errorMessage !== undefined) {
                    showToastError(errorMessage);

                    return;
                  }
                  mutate(url);
                }
              }}
            >
              TOLIAU
            </Button>
          </div>
        </div>
      </div>
    </ContentCard>
  );
};
