import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useMutation } from "react-query";
import { uploadImage } from "../../api/api";
import { showToastError } from "common/toast/toast";
import { StyledImage } from "common/components/images/StyledImage";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

interface ImageInputProps {
  imageUrl?: string;
  onImageUploaded: (url: string) => void;
}

export const ImageInput: React.FC<ImageInputProps> = ({
  imageUrl,
  onImageUploaded,
}) => {
  const { mutate, isLoading } = useMutation(uploadImage, {
    onSuccess: (data) => {
      onImageUploaded(data.url);
    },
    onError: () => {
      showToastError();
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      mutate(event.target.files[0]);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput")?.click();
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
        id="fileInput"
      />
      {!!imageUrl && (
        <StyledImage
          key={imageUrl}
          imageProps={{ source: { uri: imageUrl } }}
          height={80}
          width={80}
          borderRadius={40}
          showLoader
        />
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: !!imageUrl ? "center" : "start",
        }}
      >
        <LoadingButton
          variant="outlined"
          loading={isLoading}
          onClick={handleClick}
          startIcon={<FileUploadOutlinedIcon />}
        >
          Įkelti nuotrauką
        </LoadingButton>
      </div>
    </div>
  );
};
