import React from "react";
import { StyleProp, ViewStyle, Pressable, View } from "react-native";

interface HighlightButtonProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  borderRadius?: number;
  backgroundColor?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  flex?: number;
  onLongPress?: () => void;
}

export const HighlightButton: React.FC<HighlightButtonProps> = (props) => {
  return (
    <View
      style={{
        backgroundColor: "#EEEEEE",
        borderRadius: props.borderRadius,
        flex: props.flex,
      }}
    >
      <Pressable
        onPress={props.onPress}
        onLongPress={props.onLongPress}
        style={({ pressed }) => [
          props.style,
          {
            opacity: pressed || props.disabled ? 0.7 : 1,
            backgroundColor: props.backgroundColor ?? "white",
          },
          props.borderRadius ? { borderRadius: props.borderRadius } : {},
        ]}
        disabled={props.disabled}
      >
        {props.children}
      </Pressable>
    </View>
  );
};
