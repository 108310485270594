import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button } from "common/components/buttons/Button";
import { InputField } from "common/components/form/InputField";
import { round } from "common/helpers/common.helpers";
import React, { useCallback, useEffect, useState } from "react";

interface MonthlyLimitDialogProps {
  isOpen: boolean;
  onClose: () => void;
  contentText: string;
  initialLimit?: number | null;
  onSubmit?: (limit: number | undefined | null) => void;
  isLoading?: boolean;
}

export const MonthlyLimitDialog: React.FC<MonthlyLimitDialogProps> = ({
  isOpen,
  onClose,
  contentText,
  initialLimit,
  onSubmit,
  isLoading,
}) => {
  const getLimit = useCallback(() => {
    if (initialLimit === null || initialLimit === undefined) {
      return null;
    }
    return round(initialLimit / 100, 2);
  }, [initialLimit]);

  const [limit, setLimit] = useState(getLimit());

  useEffect(() => {
    setLimit(getLimit());
  }, [getLimit]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Pakeisti limitą</DialogTitle>
      <DialogContent>
        <Typography>{contentText}</Typography>
        <div style={{ marginTop: "15px" }}>
          <InputField
            variant="decimal"
            value={limit}
            onChange={(value) => {
              setLimit(value as number);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onPress={() => {
            if (limit === null || limit === undefined) {
              onSubmit?.(null);
            } else {
              onSubmit?.(round(limit * 100));
            }
          }}
          isLoading={isLoading}
          backgroundColor="purple2"
          textStyle={{
            color: "white",
          }}
          style={{ width: "150px" }}
        >
          IŠSAUGOTI
        </Button>
      </DialogActions>
    </Dialog>
  );
};
